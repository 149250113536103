do ->

    SendInviteController = ($scope, FCPreviewEmailData) ->
        vm = @
        vm.showLearnMore = false

        vm.from = FCPreviewEmailData.from
        vm.subject = FCPreviewEmailData.subject
        vm.emailBody = FCPreviewEmailData.emailBody

        return

    angular
        .module 'fundersclub.users'
        .controller 'SendInviteController', SendInviteController
