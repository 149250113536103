do ->
    ValidateAuthyController = ($http) ->
        vm = @
        vm.sms_sent_msg = ''
        vm.requesting_sms = false

        vm.request_sms = (url) ->
            vm.requesting_sms = true
            $http.post(url)
                .then (response) ->
                    vm.sms_sent_msg = response.data
                .finally ->
                    vm.requesting_sms = false
            return false

        # End ValidateAuthyController
        return


    angular
        .module 'fundersclub.users'
        .controller 'ValidateAuthyController', ValidateAuthyController
