do ->

    # Displays all the investment assets within this outgoing investment.
    AccreditationQuestionnaireController = (EmploymentStatus, WayOfInvesting) ->
        vm = @
        vm.employment_status = EmploymentStatus
        vm.way_of_investing = WayOfInvesting
        vm.net_worth_disclaimer_visible = false

        # End AccreditationQuestionnaireController
        return


    angular
        .module 'fundersclub.accreditation'
        .controller 'AccreditationQuestionnaireController', AccreditationQuestionnaireController

