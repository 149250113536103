do ->

    # Form responsible for displaying and editing fund screenshots.
    fcTextAngular = ->
        thisScope =
            'model': '=fcTextAngular'
            'inputName': '@inputName'

        thisController = ($element) ->
            vm = @

            vm.cleanHtml = ($html) ->
                element = angular.element("<div>#{$html}</div>")
                tables = element.find('table')
                if tables.length
                    tables.remove()
                    alert 'The tables have been stripped from the pasted content, as this field does not support tables'
                return element.html()

            vm.makeToolbarSticky = ->
                $element.find('.ta-toolbar').fixedsticky()
                return true

            vm.makeToolbarNotSticky = ->
                $element.find('.ta-toolbar').fixedsticky('destroy')
                return true

            return

        thisDirective =
            template: '''
                <div>
                    <div
                        text-angular
                        ng-model="fcta.model"
                        fc-bind-name="fcta.inputName"
                        ng-focus="fcta.makeToolbarSticky()"
                        ng-blur="fcta.makeToolbarNotSticky()"
                        ta-paste="fcta.cleanHtml($html)">
                    </div>
                </div>
            '''
            restrict: 'A'
            scope: thisScope
            bindToController: true
            controllerAs: 'fcta'
            controller: thisController
            replace: true
        return thisDirective

    angular
        .module 'fundersclub.forms'
        .directive 'fcTextAngular', fcTextAngular
