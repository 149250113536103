class FcFundUpdateItem implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        update: '<',
    };
    public controller = FcFundUpdateItemController;
    public templateUrl = 'funds/fund-update-item.component.html';
}

class FcFundUpdateItemController implements ng.IComponentController {
    public update: any;
    public hidden = true;
    public needShowLink = false;

    constructor(private $element: any, private $timeout: ng.ITimeoutService) {}

    public $postLink() {
        this.$timeout(() => {
            // Magic value of 100 comes from CSS class FundProfile-updateItem-toggleOverlay
            if (this.$element.find('[data-contents]').height() > 100) {
                this.needShowLink = true;
            }
        }, 0);
    }

    /* tslint:disable:no-unused-variable */
    private toggleHidden(): void {
        this.hidden = !this.hidden;
    }
}

angular
    .module('fundersclub.funds')
    .component('fcFundUpdateItem', new FcFundUpdateItem());
