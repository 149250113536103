class FcJobOfferings implements ng.IComponentOptions {
    public controller = FcJobOfferingsController;
    public templateUrl = 'public-pages/job-offerings.component.html';
}

class FcJobOfferingsController implements ng.IComponentController {
    private companies: Array<any>;
    /* tslint:disable:no-unused-variable */
    private tags: any = {
        company: new Array<any>(),
        fcvc: new Array<any>(),
        industry: new Array<any>(),
        location: new Array<any>(),
        role: new Array<any>(),
    };
    private displayTags: any = [];
    private availableTags: any = [];

    constructor(
        private _: UnderscoreStatic,
        private $scope: ng.IRootScopeService,
        private Company: any,
        private FCChoices: any,
        private FCJobOfferingsData: any
    ) {
    }

    public $onInit(): void {
        this.companies = this.FCJobOfferingsData.companies.map((companyData: any) => {
            let company: any = new this.Company(companyData);
            company.filteredJobOfferings = company.job_offerings;
            return company;
        });

        this.tags = {
            company: new Array<any>(),
            fcvc: new Array<any>(),
            industry: new Array<any>(),
            location: new Array<any>(),
            role: new Array<any>(),
        };

        this.$scope.$watch(
            () => this.tags,
            (tags) => {
                this.displayTags = [];
                Object.keys(tags).map(key => {
                  tags[key].forEach((tag: string) => this.displayTags.push({type: key, value: tag}));
                });
                this.availableTags = [
                    {
                        display: 'Company',
                        tags: this.companies
                            .filter(item => this.tags.company.indexOf(item.company_name) === -1)
                            .map(item => item.company_name),
                        type: 'company',
                    },
                    {
                        display: 'Role',
                        tags: this.FCJobOfferingsData.roles
                            .filter((item: any) => this.tags.role.indexOf(item) === -1),
                        type: 'role',
                    },
                    {
                        display: 'Industry',
                        tags: this.FCJobOfferingsData.industries
                            .filter((item: any) => (item[0] && this.tags.industry.indexOf(item[0]) === -1))
                            .map((item: any) => item[0]),
                        type: 'industry',
                    },
                    {
                        display: 'Location',
                        tags: this.FCJobOfferingsData.locations
                            .filter((item: any) => this.tags.location.indexOf(item) === -1),
                        type: 'location',
                    },
                    {
                        display: 'FCVC',
                        tags: Object.keys(this.FCJobOfferingsData.fcvc)
                            .filter((item: any) => this.tags.fcvc.indexOf(item) === -1),
                        type: 'fcvc',
                    },
                ];
                this.filterJobOfferings();
            }, true
        );

    }

    public addSearchTerm(type: string, value: string) {
        if (this.tags[type].includes(value)) {
            return;
        } else {
            this.tags[type].push(value);
        }
    }

    public clearSearchTerm(tag: any) {
        let index = this.tags[tag.type].indexOf(tag.value);
        if (index > -1) {
          this.tags[tag.type].splice(index, 1);
        }
    }

    /* tslint:disable:no-unused-variable */
    private clearFilters(): void {
        this.tags = {
            company: new Array<any>(),
            fcvc: new Array<any>(),
            industry: new Array<any>(),
            location: new Array<any>(),
            role: new Array<any>(),
        };

        for (let company of this.companies) {
            company.filteredJobOfferings = company.job_offerings;
        }

    }

    private filterLocation(jobOffering: any): boolean {
        return (!this.tags.location.length || this.tags.location.includes(jobOffering.location_filter));
    }

    private filterRole(jobOffering: any): boolean {
        return (!this.tags.role.length || this.tags.role.includes(jobOffering.team));
    }

    /* tslint:disable:no-unused-variable */
    private filterJobOfferings(): void {
        for (let company of this.companies) {
            let companyInFCVC = true;
            if (this.tags.fcvc.length) {
                companyInFCVC = false;
                for (let i = 0; i < this.tags.fcvc.length; i++) {
                    if (this.FCJobOfferingsData.fcvc[this.tags.fcvc[i]].includes(company.company_name)) {
                        companyInFCVC = true;
                    }
                }
            }

            if ((this.tags.industry.length && !this.tags.industry.includes(company.company_industry)) ||
                (!companyInFCVC)) {
                company.filteredJobOfferings = [];
            } else {
                company.filteredJobOfferings = (company.job_offerings
                        .filter(this.filterLocation.bind(this))
                        .filter(this.filterRole.bind(this))
                );
            }
        }
    }

    /* tslint:disable:no-unused-variable */
    private numJobs(): number {
        return this._.reduce(this.companies, (numJobs: number, company: any) => {
            return numJobs + company.filteredJobOfferings.length;
        }, 0);
    }

    /* tslint:disable:no-unused-variable */
    private showClear(): boolean {
        return !!(
            this.tags.industry.length ||
            this.tags.location.length ||
            this.tags.role.length ||
            this.tags.fcvc.length
        );
    }
}

angular
    .module('fundersclub.publicPages')
    .component('fcJobOfferings', new FcJobOfferings());
