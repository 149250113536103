class FcFundUpdatesList implements ng.IComponentOptions {
    public controller = FcFundUpdatesListController;
    public templateUrl = 'funds/fund-updates-list.component.html';
}

class FcFundUpdatesListController implements ng.IComponentController {
    public displayLimit: number = 3;
    public showAll: boolean = false;

    constructor (
        private FCFundUpdates: Array<any>
    ) {}

    /* tslint:disable:no-unused-variable */
    private toggleItems(): void {
        this.showAll = !this.showAll;
        if (this.showAll) {
            this.displayLimit = this.FCFundUpdates.length;
        } else {
            this.displayLimit = 3;
        }
    }
}

angular
    .module('fundersclub.funds')
    .component('fcFundUpdatesList', new FcFundUpdatesList());
