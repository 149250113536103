class FcInvestorProfileVerifyQcModal implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        modalInstance: '<',
        resolve: '<',
    };
    public controller = FcInvestorProfileVerifyQcModalController;
    public templateUrl = 'users/investor-profile-verify-qc-modal.component.html';
}

class FcInvestorProfileVerifyQcModalController implements ng.IComponentController {
    public modalInstance: ng.ui.bootstrap.IModalServiceInstance;
    public resolve: {
        investorProfile: IInvestorProfile,
        onUpdated: any,
        triggerValidateOnOpen: boolean,
    };

    private errors: any;
    private fcSave: any;
    private model: any;

    constructor(
        private fcModel: ng.resource.IResourceService
    ) {}

    public $onInit() {
        let VerifyQCModel = this.fcModel(this.resolve.investorProfile.urls.verify_qc);
        this.model = new VerifyQCModel();
    }

    public noneClicked() {
        if (this.model.qc_none_applies) {
            this.model.qc_net_worth = false;
            this.model.qc_is_qualified_purchaser = false;
            this.model.qc_all_owners_qualified_clients = false;
        }
    }

    public nonNoneClicked() {
        if (this.model.qc_net_worth ||
            this.model.qc_is_qualified_purchaser ||
            this.model.qc_all_owners_qualified_clients) {
            this.model.qc_none_applies = false;
        }
    }

    /* tslint:disable:no-unused-variable */
    private save() {
        this.errors = {};
        this.model
            .$save()
            .then((response: any) => {
                this.resolve.investorProfile.verified_qc = response.verified_qc;
                this.resolve.onUpdated(this.resolve.investorProfile);
                this.modalInstance.close();
            })
            .catch((response: any) => {
                angular.extend(this.errors, response.data);
            })
            .finally(() => this.fcSave.saveAttemptFinished(this.errors));
    };
}

angular
    .module('fundersclub.users')
    .component('fcInvestorProfileVerifyQcModal', new FcInvestorProfileVerifyQcModal());
