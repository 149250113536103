class FcFundElectionSelection implements ng.IComponentOptions {
    public bindings: { [binding: string]: string } = {
        selectionData: '<',
    };
    public controller = FcFundElectionSelectionController;
    public templateUrl = 'funds/fund-election-share.component.html';
}

class FcFundElectionSelectionController implements ng.IComponentController {
    public customValue: number;
    public legalName: string;
    public selectionData: any;
    public percentageChooseMap: { [key: string]: any } = {
        '25%': 25,
        '50%': 50,
        '75%': 75,
        '100%': 100,
        'custom': undefined,
    };
    public percentageOfCarry: number;
    public percentageToSell: number;
    public participationType: any = this.FCChoices.FundElectionSelectionParticipation.Unknown;
    public pollingInterval: any;
    public showAdditionalDocuments: boolean = false;

    private legalDoc: any;
    private legalDoc2: any;
    private share: any;
    private selectedPercentage: string;
    private generatingLegalDoc = false;
    private errors: any;
    private showSignSharesDocsForm: boolean = false;
    private modalOpened: boolean = false;
    private wasTriggeredForBaseDoc: boolean = false;
    private wasTriggeredForAdditionalDoc: boolean = false;

    constructor(
        private $uibModal: any,
        private $window: any,
        private $interval: ng.IIntervalService,
        private FCChoices: any,
        private SignableLegalDocument: any,
        private FundElectionSelection: any,
        private _: UnderscoreStatic
    ) {

    }

    public $onInit() {
        this.share = new this.FundElectionSelection(this.selectionData);
        if (!this._.isEmpty(this.share.legal_doc)) {
            this.legalDoc = new this.SignableLegalDocument(this.share.legal_doc);
        } else {
            // subscription doc not generated
            this.legalDoc = new this.SignableLegalDocument({
                id: null,
                status: null,
            });
        }
        if (!this._.isEmpty(this.share.legal_doc2)) {
            this.legalDoc2 = new this.SignableLegalDocument(this.share.legal_doc2);
        } else {
            // subscription doc not generated
            this.legalDoc2 = new this.SignableLegalDocument({
                id: null,
                status: null,
            });

        }
        if (
            (this.share.volunteer_carry_was_recreated === true) ||
            (this.share.participation_type === this.FCChoices.FundElectionSelectionParticipation.ReceiveShares &&
                this.percentageToSell)
        ) {
            this.participationType = this.FCChoices.FundElectionSelectionParticipation.ReceiveShares;
            this.showSignSharesDocsForm = true;
        }
        this.redirectIfItIsCompleted();
    }

    public getPercentageValue() {
        if (this.selectedPercentage === 'custom') {
            this.percentageToSell = this.customValue;
        } else {
            this.percentageToSell = this.percentageChooseMap[this.selectedPercentage];
            this.customValue = undefined;
        }
        if (this.selectedPercentage !== 'opt-out') {
            this.participationType = this.FCChoices.FundElectionSelectionParticipation.ReceiveShares;
        } else {
            this.participationType = this.FCChoices.FundElectionSelectionParticipation.Refuse;
        }
    }

    public showGenerateAndSignDocumentButton() {
        if (this.participationType === this.FCChoices.FundElectionSelectionParticipation.Refuse) {
            return true;
        } else if (
            this.participationType === this.FCChoices.FundElectionSelectionParticipation.VolunteerCarry &&
            this.selectedPercentage === 'custom' && this.customValue
        ) {
            return true;
        } else if (
            this.participationType === this.FCChoices.FundElectionSelectionParticipation.VolunteerCarry &&
            (this.selectedPercentage !== 'custom' && this.selectedPercentage)) {
            return true;
        }
        return false;
    }

    public prepareSignSharesDocsForm() {
        this.showSignSharesDocsForm = true;
    }

    public showInactiveSendButton() {
        if (this.participationType === this.FCChoices.FundElectionSelectionParticipation.VolunteerCarry &&
            this.selectedPercentage === 'custom' && !this.customValue) {
            return true;
        } else if (this.participationType === this.FCChoices.FundElectionSelectionParticipation.VolunteerCarry &&
            !this.selectedPercentage) {
            return true;
        } else if (this.participationType === this.FCChoices.FundElectionSelectionParticipation.Unknown) {
            return true;
        }
        return false;
    }

    public redirectIfItIsCompleted() {
        if (this.isCompleted()) {
            this.redirectToThankYouPage();
        }
    }

    public isCompleted() {
        if ((this.legalDoc.status === this.FCChoices.SigningStatus.RequiresCountersignature
            || this.legalDoc.status === this.FCChoices.SigningStatus.Signed)
            && (this.share.participation_type === this.FCChoices.FundElectionSelectionParticipation.Refuse
                || this.share.participation_type === this.FCChoices.FundElectionSelectionParticipation.VolunteerCarry)
        ) {
            return true;
        } else if (
            (this.legalDoc.status === this.FCChoices.SigningStatus.RequiresCountersignature
                || this.legalDoc.status === this.FCChoices.SigningStatus.Signed)
            && (this.legalDoc2.status === this.FCChoices.SigningStatus.RequiresCountersignature
            || this.legalDoc2.status === this.FCChoices.SigningStatus.Signed)
            && this.share.participation_type === this.FCChoices.FundElectionSelectionParticipation.ReceiveShares
        ) {
            return true;
        }
        if (
            this.share.participation_type === this.FCChoices.FundElectionSelectionParticipation.ReceiveShares
            && !(
                this.legalDoc.status !== this.FCChoices.SigningStatus.RequiresCountersignature
                && this.legalDoc2.status !== this.FCChoices.SigningStatus.RequiresCountersignature
            )
        ) {
            this.showSignSharesDocsForm = true;
            this.participationType = this.share.participation_type;
        }
        return false;
    }

    public openFcSignLegaldocModal(docType: string): boolean {
        if (docType === this.FCChoices.FundElectionDocType.Base && this.legalDoc.signing_url) {
            return true;
        } else if (docType === this.FCChoices.FundElectionDocType.Additional && this.legalDoc2.signing_url) {
            return true;
        }
        return false;
    }

    public openModal(docType: any) {

        this.pollingInterval = this.$interval(() => {

            if (this.openFcSignLegaldocModal(docType)) {
                this.modalOpened = true;
                this.$uibModal.open({
                    animation: false,
                    ariaDescribedBy: 'modal-body',
                    ariaLabelledBy: 'modal-title',
                    component: 'fcSignLegaldocModal',
                    resolve: {
                        docName: () => 'Request for Fund Election Form',
                        getLegaldocStatus: () => this.getLegaldocStatus.bind(this, docType),
                        getSigningUrl: () => this.getSigningUrl.bind(this, docType),
                        onResetForm: () => this.onResetForm.bind(this, docType),
                    },
                    size: 'lg',
                    windowClass: 'complete-investment--sign-legal-doc--modal',
                }).closed.then(() => {
                    this.redirectIfItIsCompleted();

                });
            }
            if (this.modalOpened) {
                this.$interval.cancel(this.pollingInterval);
            }
        }, 1000);
    }

    public signLegalDoc(docType: any) {
        if (
            (docType === this.FCChoices.FundElectionDocType.Base && this.legalDoc.signing_url)
            || (docType === this.FCChoices.FundElectionDocType.Additional && this.legalDoc2.signing_url)
        ) {
            this.openModal(docType);
        } else if (!this.wasTriggeredForDocType(docType)) {
            this.share.participation_type = this.participationType;
            this.share.percentage_to_sell = this.percentageToSell;
            this.share.doc_type = docType;
            this.generatingLegalDoc = true;
            this.errors = {};
            if (docType === this.FCChoices.FundElectionDocType.Base && !this.legalDoc.signing_url) {
                this.wasTriggeredForBaseDoc = true;
                this.legalDoc.pollForUpdates();
            } else if (docType === this.FCChoices.FundElectionDocType.Additional && !this.legalDoc2.signing_url) {
                this.wasTriggeredForAdditionalDoc = true;
                this.legalDoc2.pollForUpdates();
            }
            this.share.generateLegalDoc()
                .then((response: any) => {
                    angular.extend(this.share, response.data);
                    if (docType === this.FCChoices.FundElectionDocType.Base) {
                        angular.extend(this.legalDoc, response.data.legal_doc);
                    } else {
                        angular.extend(this.legalDoc2, response.data.legal_doc2);
                    }
                    this.signLegalDoc(docType);
                })
                .catch((response: any) => {
                    angular.extend(this.errors, response.data);
                })
                .finally(() => {
                    this.generatingLegalDoc = false;
                });
        }
    }

    public onResetForm(docType: any) {
        return this.share.abandonLegalDoc()
            .then((response: any) => {
                angular.extend(this.share, response.data);
                if (docType === this.FCChoices.FundElectionDocType.Additional) {
                    angular.extend(this.legalDoc2, response.data.legal_doc2);
                } else {
                    angular.extend(this.legalDoc, response.data.legal_doc);
                }
            })
            .finally(() => {
                this.resetWasTriggered(docType);
            });
    }

    public getLegaldocStatus(docType: string): string {
        if (docType === this.FCChoices.FundElectionDocType.Base) {
            return this.legalDoc.status;
        } else {
            return this.legalDoc2.status;
        }

    }

    public isLegalDocSigned(docType: string): boolean {
        if (docType === this.FCChoices.FundElectionDocType.Base) {
            return this.legalDoc.status === this.FCChoices.SigningStatus.RequiresCountersignature
                || this.legalDoc.status === this.FCChoices.SigningStatus.Signed;
        } else {
            return this.legalDoc2.status === this.FCChoices.SigningStatus.RequiresCountersignature
                || this.legalDoc2.status === this.FCChoices.SigningStatus.Signed;
        }
    }

    public getSigningUrl(docType: string): string {
        if (docType === this.FCChoices.FundElectionDocType.Base) {
            return this.legalDoc.signing_url;
        } else {
            return this.legalDoc2.signing_url;
        }
    }

    public redirectToThankYouPage() {
        this.$window.location.href = '/my-investments/fund-election-thank-page/';
    }

    public wasTriggeredForDocType(docType: any): boolean {
        if (docType === this.FCChoices.FundElectionDocType.Base) {
            return this.wasTriggeredForBaseDoc;
        } else {
            return this.wasTriggeredForAdditionalDoc;
        }
    }

    public resetWasTriggered(docType: string) {
        if (docType === this.FCChoices.FundElectionDocType.Additional) {
            this.wasTriggeredForAdditionalDoc = false;
        } else {
            this.wasTriggeredForBaseDoc = false;
        }
    }

    public resetSelectedPercentage() {
        if (this.participationType !== this.FCChoices.FundElectionSelectionParticipation.VolunteerCarry) {
            this.selectedPercentage = null;
            this.customValue = null;
        }
    }

}

angular
    .module('fundersclub.funds')
    .component('fcFundElectionSelection', new FcFundElectionSelection());
