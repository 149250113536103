class FcMcList implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        mcListUrl: '@',
        subscribeUrl: '@',
        unsubscribeUrl: '@',
    };
    public controller = FcMcListController;
    public templateUrl = 'email/mc-list.component.html';
}

class FcMcListController implements ng.IComponentController {
    private loading: boolean = true;
    private mcList: any;
    private mcListUrl: string;

    constructor (private $http: ng.IHttpService) {}

    public $onInit() {
        this.mcList = {};
        this.$http({
            method: 'GET',
            url: this.mcListUrl,
        }).then((resp: any) => {
            angular.extend(this.mcList, resp.data);
            this.loading = false;
        });
        this.loading = true;
    }
}

angular
    .module('fundersclub.email')
    .component('fcMcList', new FcMcList());
