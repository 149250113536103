do ->

    # Our special save button, which has multiple states depending on the state of
    # the current form it's within.
    fcSave = ($parse) ->
        thisControllerAs = 'sb'
        defaultSaveText = 'Save'
        defaultSavedText = 'Saved'

        # This directive's link function.
        thisLink = (scope, element, attrs, formCtrl) ->
            scope[thisControllerAs].formCtrl = formCtrl

            if attrs.fcSave
                scope.saveHandler = $parse(attrs.fcSave)
            else
                scope.saveHandler = ->
                    return  # noop

            scope.$watch (-> formCtrl.$pristine), (newPristine, oldPristine) ->
                # form starts in a pristine state but we dont want this animation to
                # happen on first render
                if oldPristine != newPristine and newPristine
                    scope[thisControllerAs].saving = false

            # TODO(chazeah): Delete this once hasError has burned.
            scope.$watch (-> formCtrl.hasError), (error) ->
                if error
                    scope[thisControllerAs].saving = false
                    formCtrl.hasError = false

            return

        # This directive's controller function.
        thisController = ($scope, $attrs) ->
            vm = @
            vm.saving = false
            vm.save_text = $attrs.fcSaveText or defaultSaveText
            vm.saved_text = $attrs.fcSavedText or defaultSavedText
            vm.canAlwaysSave = $attrs.canAlwaysSave or false

            # Publish this controller on its parent scope as "fcSave". This
            # approach is somewhat hacky, but the alternatives are:
            # 1. Leverage `require`. This is impossible since we don't use
            #    consistent controller names (which is fine).
            # 2. Use `$parse` and `this` to dynamically resolve the parent. This
            #    appears to be what ngForm does (see getSetter):
            #    https://github.com/angular/angular.js/blob/master/src/ng/directive/form.js#L475
            #    But honestly, that approach is confusing as hell.
            # This approach leverages the fact that we already specify the parent
            # controller's name in the attributes, e.g.
            #    fc-save="eac.save()" or fc-save="fsic.save()"
            # By parsing out its alias, we can identify it on the shared scope.
            parent_controller_as = ($attrs.fcSave or '').split('.')[0]
            if $scope[parent_controller_as]
                $scope[parent_controller_as]['fcSave'] = vm

            vm.saveAttemptFinished = (listOfErrors) ->
                vm.saving = false
                if angular.equals({}, listOfErrors)
                    vm.formCtrl.$setPristine()
                else
                    vm.formCtrl.$setDirty()

            vm.triggerSave = ->
                vm.saving = true
                # Give the optional saveHandler a way to abort saving
                if $scope.saveHandler($scope) == false
                    vm.saving = false

            vm.displayAsDisabled = ->
                if not vm.canAlwaysSave and vm.formCtrl.$pristine
                    return true

                return vm.saving

            return

        thisDirective =
            restrict: 'A'
            require: '^form'
            link: thisLink
            bindToController: true
            controllerAs: thisControllerAs
            controller: thisController
            replace: true
            templateUrl: 'forms/save-button.directive.html'
        return thisDirective


    angular
        .module 'fundersclub.forms'
        .directive 'fcSave', fcSave
