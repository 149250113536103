class FcEarlyReservation implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        earlyReservationData: '<',
        investorProfileData: '<',
    };
    public controller = FcEarlyReservationController;
    public templateUrl = 'invest/early-reservation.component.html';
}

class FcEarlyReservationController implements ng.IComponentController {
    private earlyReservationData: any;
    private earlyReservation: IEarlyReservation;
    private investorProfileData: any;
    private investorProfile: IInvestorProfile;
    private errors: {[error: string]: Array<string>} = {};
    private reserving: boolean = false;

    constructor(
        private $intercom: any,
        private $window: any,
        private EarlyReservation: IFcModelResource<IEarlyReservation>,
        private InvestorProfile: IFcModelResource<IInvestorProfile>
    ) {}

    public $onInit(): void {
        this.earlyReservation = new this.EarlyReservation(this.earlyReservationData);
        this.investorProfile = new this.InvestorProfile(this.investorProfileData);
    }

    /* tslint:disable:no-unused-variable */
    private onInvestorProfileUpdated(investorProfile: IInvestorProfile) {
        this.investorProfile = investorProfile;
    }

    /* tslint:disable:no-unused-variable */
    private onInvestorProfileSelected(investorProfile: IInvestorProfile) {
        this.investorProfile = investorProfile;
        this.earlyReservation.investor_profile = this.investorProfile.url;
    }

    /* tslint:disable:no-unused-variable */
    private onInvestmentAmountUpdated(investmentAmount: string): void {
        this.earlyReservation.investment_amount = (
            parseFloat(investmentAmount.replace(/,/g, ''))
        );
    }

    /* tslint:disable:no-unused-variable */
    private reserve(): void {
        this.reserving = true;
        this.errors = {};

        this.earlyReservation.$save()
            .then((response: any) => {
                this.$window.location.href = (
                    this.earlyReservation.fund_info.profile_page_url
                );
            })
            .catch((response: any) => {
                angular.extend(this.errors, response.data);
                this.reserving = false;
            });
    }
}

angular
    .module('fundersclub.invest')
    .component('fcEarlyReservation', new FcEarlyReservation());
