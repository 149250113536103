class FcInvestorProfileEditModal implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        modalInstance: '<',
        resolve: '<',
    };
    public controller = FcInvestorProfileEditModalController;
    public templateUrl = 'users/investor-profile-edit-modal.component.html';
}

class FcInvestorProfileEditModalController implements ng.IComponentController {
    public modalInstance: ng.ui.bootstrap.IModalServiceInstance;
    public resolve: {
        investorProfile: IInvestorProfile,
        onUpdated: any,
        triggerValidateOnOpen: boolean,
    };

    /* tslint:disable:no-unused-variable */
    private _onSaved(investorProfile: IInvestorProfile): void {
        this.resolve.onUpdated(investorProfile);
        this.modalInstance.close();
    }
}

angular
    .module('fundersclub.users')
    .component('fcInvestorProfileEditModal', new FcInvestorProfileEditModal());
