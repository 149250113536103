do ->

    # Handles disconnecting an FC account from a linked social account.
    fcSocialAccountManager = ($http, transformRequestAsFormPost) ->
        fcSocialAccountManager =
            disconnect: (disconnect_url, account) ->
                $http
                    method: 'post'
                    url: disconnect_url
                    transformRequest: transformRequestAsFormPost
                    headers:
                        'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
                    data:
                        'account': account
        fcSocialAccountManager


    # Get module and add factories.
    angular
        .module 'fundersclub.users'
        .factory 'fcSocialAccountManager', fcSocialAccountManager

