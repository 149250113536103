do ->
    fcBankAccountAdd = (
        $http,
        $q,
        BankAccount,
        FCAPITokens,
        FCToastManager,
        fcPaymentsManager
    ) ->
        thisScope =
            onCreateCallback: '&onCreateCallback'
            onErrorCallback: '&onErrorCallback'

        thisController = ($scope) ->
            vm = @

            vm.bankAccount = {}
            vm.onErrorCallback = vm.onErrorCallback or (->)

            vm.add_plaid = (bank) ->
                vm.errors = {}
                vm.plaid_accounts = null
                vm.select_plaid_account = false

                $http.post('/api/bankaccounts/create_link_token/')
                    .then (response) ->
                        Plaid.create
                            apiVersion: 'v2'
                            env: FCAPITokens.plaid_client_environment
                            clientName: 'FundersClub'
                            token: response.data.link_token
                            product: ['auth']
                            onSuccess: (public_token) =>
                                vm.authenticate_with_plaid_token(public_token)
                        .open()

            vm.authenticate_with_plaid_token = (token) ->
                vm.loading = true

                fcPaymentsManager.getAccountsForPublicToken(token)
                    .then (accounts) ->
                        vm.plaid_accounts = accounts
                        vm.select_plaid_account = true
                        vm.loading = false
                    .catch (errors) ->
                        angular.extend(vm.errors, errors)
                        vm.loading = false

            vm.add_plaid_account = (bankaccount_id) ->
                vm.errors = []
                if not bankaccount_id
                    vm.errors.non_field_errors = ['This field is required.']
                    return

                newBankAccount = new BankAccount({bankaccount_id: bankaccount_id})
                newBankAccount.$save()
                    .then (response) ->
                        vm.onCreateCallback({'newBankAccount': newBankAccount})
                        vm.select_plaid_account = false
                        FCToastManager.checkForToasts()
                    .catch (response) ->
                        angular.extend(vm.errors, response.data)
                        vm.onErrorCallback()
                    .finally ->
                        vm.fcSave.saveAttemptFinished(vm.errors)

            vm.add = ->
                vm.errors = {}

                fcPaymentsManager.createBankAccount(vm.bankAccount)
                    .then (response) ->
                        newBankAccount = new BankAccount({bankaccount_id: response.data.id})
                        newBankAccount.$save()
                            .then (response) ->
                                vm.onCreateCallback({'newBankAccount': newBankAccount})
                                FCToastManager.checkForToasts()
                            .catch (response) ->
                                angular.extend(vm.errors, response.data)
                                vm.onErrorCallback()
                            .finally ->
                                vm.fcSave.saveAttemptFinished(vm.errors)
                    .catch (response) ->
                        angular.extend(vm.errors, response.data)
                        vm.onErrorCallback()
                    .finally ->
                        vm.fcSave.saveAttemptFinished(vm.errors)

            return

        thisDirective =
            scope: thisScope
            restrict: 'A'
            templateUrl: 'payments/bank-account-add.directive.html'
            bindToController: true
            controllerAs: 'baac'
            controller: thisController
        return thisDirective

    angular
        .module 'fundersclub.payments'
        .directive 'fcBankAccountAdd', fcBankAccountAdd
