do ->

    # Handles rendering the assets for a given outgoing investment.
    fcOutgoingInvestmentTile = (OutgoingInvestment) ->
        thisScope =
            # A means of communicating with the parent directive. When this
            # value increases, this tile refreshes its valuations info.
            'assetDataVersion': '='
            'outgoingInvestmentId': '@fcOutgoingInvestmentTile'

        thisLink = (scope, element, attrs, ctrls, transcludeFn) ->
            # This ugly shit accomplishes two things:
            # 1. Finds default values in the transcluded DOM.
            # 2. Injects those values into the directives isolate scope as defaults.
            transcludeFn(scope, (clone) ->
                # Pick up default values.
                for asset in clone.find('.asset-default-value')
                    # Having to access the controller by name here is dumb.
                    scope.foit.assets.push(
                        display_name: asset.getAttribute('data-display-name')
                        terms:
                            security_currency_display: asset.getAttribute('data-currency-display')
                        value: asset.getAttribute('data-value')
                        has_basic_terms: asset.getAttribute('data-has-basic-terms') == 'True'
                        company_id: asset.getAttribute('data-company-id')
                    )

                #clone.find('.asset-default-value').remove()
                element.append(clone)
            )
            return

        thisController = ($scope) ->
            vm = @
            vm.assets = []

            # Make API call to update asset values.
            vm.updateAssetValues = ->
                OutgoingInvestment.get({id: vm.outgoingInvestmentId}, (response) ->
                    vm.assets = response.investment_assets
                )

            # When the underlying data for this investment has changed, update values.
            $scope.$watch(
                (scope) -> vm.assetDataVersion,
                (newValue, oldValue) ->
                    # Only perform value if counter increased.
                    if newValue > oldValue
                        vm.updateAssetValues()
            )

            return

        thisDirective =
            restrict: 'A'
            scope: thisScope
            transclude: true
            bindToController: true
            controllerAs: 'foit'
            controller: thisController
            link: thisLink
        return thisDirective

    angular
        .module 'fundersclub.assets'
        .directive 'fcOutgoingInvestmentTile', fcOutgoingInvestmentTile
