class FcFundKpiEdit implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        fundKpi: '<',
        onCanceled: '&',
        onSaved: '&',
    };
    public controller = FcFundKpiEditController;
    public templateUrl = 'funds/fund-kpi-edit.component.html';
}

class FcFundKpiEditController implements ng.IComponentController {
    public fundKpi: any;
    public onCanceled: () => void;
    public onSaved: (data: {fundKpi: any}) => void;

    private dateChoices: Array<string>;
    private errors: any;
    private form: any;
    private fcSave: any;
    /* tslint:disable:no-unused-variable */
    private isDatepickerPopupOpen: boolean;
    private periodFormats: any = {};

    constructor(private FCChoices: any, private FundKPI: any, private moment: moment.MomentStatic) {}

    public $onInit(): void {
        this.fundKpi = angular.copy(this.fundKpi);
        this.periodFormats = {
            [this.FCChoices.Periods.Weekly]: 'MM/DD/YYYY',
            [this.FCChoices.Periods.Monthly]: 'MMMM YYYY',
            [this.FCChoices.Periods.Quarterly]: '[Q]Q YYYY',
            [this.FCChoices.Periods.Yearly]: 'YYYY',
        };
        this.dateChoices = this.fundKpi.chart_available_dates.map((date: string) => {
            return [
                date,
                this.moment(date).format(this.periodFormats[this.fundKpi.kpi.period]),
            ];
        });

        if (!this.fundKpi.start_date) {
            this.fundKpi.start_date = this.dateChoices[0][0];
        }

        if (!this.fundKpi.end_date) {
            this.fundKpi.end_date = this.dateChoices[this.dateChoices.length - 1][0];
        }
    }

    public $postLink(): void {
        // This form is nested inside of another form.  Don't want editing
        // on this form to affect the dirty/pristine state of the parent form
        this.form.$$parentForm = {
            $removeControl: angular.noop,
            $setDirty: angular.noop,
            $setValidity: angular.noop,
        };
    }

    /* tslint:disable:no-unused-variable */
    private save(): void {
        this.errors = {};
        this.fundKpi
            .$save()
            .then((response: any) => this.onSaved({fundKpi: new this.FundKPI(response)}))
            .catch((response: any) => angular.extend(this.errors, response.data))
            .finally(() => this.fcSave.saveAttemptFinished(this.errors));
    }
}

angular
    .module('fundersclub.companies')
    .component('fcFundKpiEdit', new FcFundKpiEdit());
