let fcPortfolioFilter = () =>  {
    let thisFilter = (companies: Array<any>, searchFilters: {[name: string]: any}): Array<any> => {
        let filteredCompanies: Array<any> = [];
        for (let company of companies) {
            let match = true;
            /* tslint:disable:forin */
            for (let category in searchFilters) {
                /* Within each category, test if a company or-matches any of the filters */
                match = searchFilters[category].indexOf(company[category]) !== -1;
                if (!match) {
                    break;
                }
            }
            if (match) {
                filteredCompanies.push(company);
            }
        }
        return filteredCompanies;
    };

    return thisFilter;
};

let fcPortfolioKeyword = () =>  {
    let thisFilter = (companies: Array<any>, keywords: String[]): Array<any> => {
        let filteredCompanies: Array<any> = [];
        for (let company of companies) {
            let match = true;
            for (let keyword of keywords) {
                let keywordLower = keyword.toLowerCase();
                /* tslint:disable:no-string-literal */
                if (company['company_name'].toLowerCase().indexOf(keywordLower) === -1 &&
                        company['public_blurb'].toLowerCase().indexOf(keywordLower) === -1 &&
                        company['company_location_display'].toLowerCase().indexOf(keywordLower) === -1
                ) {
                    match = false;
                    break;
                }
            }
            if (match) {
                filteredCompanies.push(company);
            }
        }
        return filteredCompanies;
    };

    return thisFilter;
};

let fcPortfolioCompanies = () => {
    let thisFilter = (companies: Array<any>, searchFilters: {[name: string]: any}, keywords: String[]): string => {
        let filteredCompanies = fcPortfolioFilter()(companies, searchFilters);
        filteredCompanies = fcPortfolioKeyword()(filteredCompanies, keywords);
        return filteredCompanies.length !== 1 ? `${filteredCompanies.length} Companies` : '1 Company';
    };
    return thisFilter;
};

angular
    .module('fundersclub.publicPages')
    .filter('fcPortfolioFilter', fcPortfolioFilter)
    .filter('fcPortfolioKeyword', fcPortfolioKeyword)
    .filter('fcPortfolioCompanies', fcPortfolioCompanies);
