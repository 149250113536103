class FcCopyPopover implements ng.IComponentOptions {
    // The strange typings here are necessary.
    // https://github.com/Microsoft/TypeScript/issues/6041
    public bindings: {[binding: string]: string} = {
        textToCopy: '<',
    };
    public controller = FcCopyPopoverController;
    public templateUrl = 'shared/copy-popover.component.html';
};

class FcCopyPopoverController implements ng.IComponentController {
    public textToCopy: string;
    private popoverText: string;

    constructor(private $timeout: ng.ITimeoutService) {}

    public $onInit(): void {
        this.popoverText = 'Click to copy';
    }

    /* tslint:disable:no-unused-variable */
    private onCopy(): void {
        let originalPopoverText: string = this.popoverText;
        this.popoverText = 'Copied!';

        this.$timeout(() => {
            this.popoverText = originalPopoverText;
        }, 750);
    }
};

angular
    .module('fundersclub')
    .component('fcCopyPopover', new FcCopyPopover());
