class FcReserveDetailsCard implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        additionalProrataInterest: '<',
        confirmationCode: '<',
        displayOnly: '<',
        distributionsAmount: '=',
        distributionsConfirmed: '<',
        distributionsFundsDisplay: '<',
        distributionsReservedAmount: '<',
        errors: '<',
        fund: '<',
        investmentCreditAmount: '<',
        isGeneratedZombie: '<',
        isOnlinePayment: '<',
        onCancelInvestment: '&',
        onInvest: '&',
        onUseDistributionsUpdated: '&',
        onUseInvestmentCreditUpdated: '&',
        reservationPaymentMethod: '<',
        reservedAmount: '<',
        transactionAmount: '<',
        usableDistributionsAmount: '<',
        usableInvestmentCreditAmount: '<',
        waitlistedAmount: '<',
    };
    public controller = FcReserveDetailsCardController;
    public templateUrl = 'invest/details-card.component.html';
}

class FcReserveDetailsCardController implements ng.IComponentController {
    public additionalProrataInterest: number;
    public confirmationCode: string;
    public displayOnly: boolean;
    public distributionsAmount: any;
    public distributionsConfirmed: boolean;
    public distributionsFundsDisplay: string;
    public distributionsReservedAmount: number;
    public errors: any;
    public fund: any;
    public investmentCreditAmount: number;
    public isGeneratedZombie: boolean;
    public isOnlinePayment: boolean;
    public onCancelInvestment: () => ng.IPromise<any>;
    public onInvest: () => ng.IPromise<any>;
    public onUseDistributionsUpdated: (
        useDistributions: {useDistributions: boolean}
    ) => void;
    public onUseInvestmentCreditUpdated: (
        useInvestmentCredit: {useInvestmentCredit: boolean}
    ) => void;
    public reservationPaymentMethod: string;
    public reservedAmount: number;
    public transactionAmount: number;
    public usableDistributionsAmount: number;
    public usableInvestmentCreditAmount: number;
    public waitlistedAmount: number;

    private cancelling = false;
    private cancelled = false;
    private investing = false;
    private canUseInvestmentCredit: boolean;
    private useInvestmentCredit: boolean;
    private useDistributions: boolean;

    constructor(private FCChoices: any, private FCGlobals: any) {}

    public $onInit() {
        if (typeof this.distributionsAmount === 'string') {
            this.distributionsAmount = parseFloat(this.distributionsAmount);
        }
        this.canUseInvestmentCredit = !!this.usableInvestmentCreditAmount;
        this.useDistributions = !!this.distributionsAmount;
        this.useInvestmentCredit = !!this.investmentCreditAmount;
    }

    public $onChanges(changes: any) {
        if (
            changes.hasOwnProperty('reservedAmount') || changes.hasOwnProperty('waitlistedAmount')
        ) {
            let previousAmount = this.distributionsReservedAmount;
            this.distributionsReservedAmount = Math.min(
                this.usableDistributionsAmount,
                this.waitlistedAmount + this.reservedAmount
            );
            if (this.distributionsReservedAmount !== previousAmount) {
                this.onUseDistributionsUpdated({useDistributions: this.useDistributions});
            }
        }
        /* Check if usable distributions amount changed - this happens when the user switches
         * to or from an IRA investor profile */
        if (
            changes.hasOwnProperty('usableDistributionsAmount') &&
            !changes.usableDistributionsAmount.currentValue
        ) {
            this.useDistributions = false;
            this.onUseDistributionsUpdated({useDistributions: false});
        }
    }

    /* tslint:disable:no-unused-variable */
    private canUseDistributions() {
        return !!this.usableDistributionsAmount;
    }

    /* tslint:disable:no-unused-variable */
    private cancelInvestment() {
        this.cancelling = true;
        this.onCancelInvestment().finally(() => {
            this.cancelled = true;
            this.cancelling = false;
        });
    }

    /* tslint:disable:no-unused-variable */
    private invest() {
        this.investing = true;
        this.onInvest().catch(() => {
            this.investing = false;
        });
    }

    private transferFeeAmount() {
        if (!this.isOnlinePayment) {
            return 0;
        }

        // Duped from payments/models.py `calc_transfer_fees`
        let fees = this.transactionAmount * this.FCGlobals.PAYMENTS_TRANSFER_FEES_AMOUNT;
        fees = Math.min(fees, this.FCGlobals.PAYMENTS_MAX_ACH_TRANSFER_FEE);
        return fees;
    }
}

angular
    .module('fundersclub.invest')
    .component('fcReserveDetailsCard', new FcReserveDetailsCard());
