let CompanyInternalSettingsController = function(
    Company: any,
    CompanyTeamMember: any,
    FCChoices: any,
    FCCompanyData: any,
    FCCompanyExtraData: any,
    FCCompanyFoundersData: any,
    FCPOCRelationshipOwnerChoices: any
) {
    let vm = this;
    vm.errors = {};
    vm.banner_choices = FCChoices.CompanyBannerChoices;
    vm.country_choices = FCChoices.CountryChoices;
    vm.tshirtSizeChoices = FCChoices.TshirtSizeChoices;
    vm.company = new Company(FCCompanyData);
    vm.founders = FCCompanyFoundersData.map((founder: any) => new CompanyTeamMember(founder));
    vm.poc_relationship_owner_choices = FCPOCRelationshipOwnerChoices;
    vm.confidential_on_load = vm.company.is_confidential;
    vm.multi_asset_funds = FCCompanyExtraData.multi_asset_funds;
    vm.saved = false;

    vm.save = () => {
        vm.errors = {};

        // If we don't do this it sends the word "null" to the API
        if (vm.company.poc_relationship_owner === null) {
            vm.company.poc_relationship_owner = '';
        }
        // If we don't do this it sends the word "null" to the API
        if (vm.company.banner === null) {
            vm.company.banner = '';
        }

        vm.company.$save([
            'banner',
            'country_of_incorporation',
            'create_multi_asset_no_longer_confidential_update',
            'is_confidential',
            'poc_relationship_owner',
            'was_onboarding_gift_sent',
        ]).then((response: any) => {
            vm.saved = true;
        }).catch((response: any) => {
            angular.extend(vm.errors, response.data);
        }).finally(() => {
            vm.fcSave.saveAttemptFinished(vm.errors);
        });
    };
};

angular
    .module('fundersclub.companies')
    .controller('CompanyInternalSettingsController', CompanyInternalSettingsController);
