do ->

    # Creates a list of numbers
    # (taken from http://stackoverflow.com/questions/20506360/angular-repeat-span-n-times)
    range = ->
        thisFilter = (val, n) ->
            n = parseInt(n)
            for i in [0..n - 1]
                val.push(i)
            val
        thisFilter

    unique = ->
        thisFilter = (items, filterOn) ->
            if !filterOn
                return items

            if (filterOn || angular.isUndefined(filterOn)) && angular.isArray(items)
                hashCheck = {}
                newItems = []

                extractValueToCompare = (item) ->
                    if angular.isObject(item) && angular.isString(filterOn)
                        return item[filterOn]
                    else
                        return items

                angular.forEach(items, (item) ->
                    valueToCheck = extractValueToCompare(item)
                    isDuplicate = true
                    if valueToCheck not in hashCheck
                        isDuplicate = false
                        hashCheck[valueToCheck] = true

                    if !isDuplicate
                        newItems.push(item)
                )
                items = newItems

            items
        thisFilter

    slugify = ->
        thisFilter = (val) ->
            val?.toLowerCase().replace(/[^\w ]+/g,'').replace(/ +/g,'-')
        thisFilter

    angular
        .module 'fundersclub'
        .filter 'range', range
        .filter 'unique', unique
        .filter 'slugify', slugify
