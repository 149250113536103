class FcStartupInvestorsList implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        allInvestors: '<',
        onCreated: '&',
        onRemoved: '&',
        onSelected: '&',
        onUpdated: '&',
        selectedInvestors: '<',
    };
    public controller = FcStartupInvestorsListController;
    public templateUrl = 'funds/startup-investors-list.component.html';
}

class FcStartupInvestorsListController implements ng.IComponentController {
    public allInvestors: Array<any>;
    public onCreated: (data: {startupInvestor: any}) => void;
    public onRemoved: (data: {startupInvestor: any}) => void;
    public onSelected: (data: {startupInvestor: any}) => void;
    public onUpdated: (data: {startupInvestor: any, updateAllInvestors: boolean}) => void;
    public selectedInvestors: Array<any>;

    private startupInvestorEditFormsExpanded: any = {};
    private startupInvestorCreateFormExpanded: boolean = false;
    private isStaff: boolean;
    private savedStartupInvestorsToUpdate: Array<any> = [];

    constructor(
        private _: UnderscoreStatic,
        private $filter: ng.IFilterService,
        private FCGlobals: any
    ) {}

    public $onInit(): void {
        this.isStaff = this.FCGlobals.IS_STAFF;
    }

    /* tslint:disable:no-unused-variable */
    private onStartupInvestorCreated(startupInvestor: any): void {
        this.onCreated({startupInvestor: startupInvestor});
        this.startupInvestorCreateFormExpanded = false;
    }

    /* tslint:disable:no-unused-variable */
    private onStartupInvestorSaved(startupInvestor: any): void {
        // Add to array of fund investors that will updated in the future so that
        // the edit form has a chance to collapse correctly
        this.savedStartupInvestorsToUpdate.push(startupInvestor);
        this.startupInvestorEditFormsExpanded[startupInvestor.id] = false;
    }

    /* tslint:disable:no-unused-variable */
    private onStartupInvestorEditingCanceled(startupInvestor: any): void {
        this.startupInvestorEditFormsExpanded[startupInvestor.id] = false;
    }

    /* tslint:disable:no-unused-variable */
    private filteredInvestors(search: string): Array<any> {
        let filteredResults: Array<any> = this
            .$filter('filter')(this.allInvestors, {name: search})
            .concat(this.$filter('filter')(this.allInvestors, {bio: search}));

        // Need to remove dupes (startupInvestors where both name and bio match)
        return this._.uniq(filteredResults, false, (startupInvestor: any) => startupInvestor.id);
    }

    /* tslint:disable:no-unused-variable */
    private onStartupInvestorCreatingCanceled() {
        this.startupInvestorCreateFormExpanded = false;
    }

    /* tslint:disable:no-unused-variable */
    private select(data: {startupInvestor: any}): void {
        this.onSelected({startupInvestor: data.startupInvestor});
    }

    private updateSavedStartupInvestors(): void {
        for (let startupInvestor of this.savedStartupInvestorsToUpdate) {
            this.onUpdated({startupInvestor: startupInvestor, updateAllInvestors: true});
        }
        this.savedStartupInvestorsToUpdate = [];
    }
}

angular
    .module('fundersclub.funds')
    .component('fcStartupInvestorsList', new FcStartupInvestorsList());
