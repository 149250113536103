class FcPortfolioItems implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        companies: '<',
        filterChoices: '<',
        initialFilters: '@',
        initialKeywords: '@',
        initialSort: '@',
    };
    public controller = FcPortfolioItemsController;
    public templateUrl = 'public-pages/portfolio-items.component.html';
}

class FcPortfolioItemsController implements ng.IComponentController {
    public companies: Array<any>;
    public filters: Array<any> = [];
    public filterChoices: Array<any>;
    public filterLookup: {[filter: string]: any} = {};
    public filterSearch: {[name: string]: any} = {};
    public initialFilters: string;
    public initialKeywords: string;
    public initialSort: string;
    public keyword: string = '';
    public keywords: Array<string> = [];
    public reverse: boolean;
    public searchTerms: Array<any> = [];
    public sort: string = '';

    constructor(
        private _: UnderscoreStatic,
        private $window: ng.IWindowService,
        private FCChoices: any
    ) {}

    public $onInit(): void {
        if (this.initialSort === undefined) {
            this.initialSort = '';
        }
        this.reverse = this.initialSort.charAt(0) === '-';
        this.sort = this.reverse ? this.initialSort.slice(1) : this.initialSort;

        for (let filterCategory of this.filterChoices) {
            /* tslint:disable:no-string-literal */
            for (let filterChoice of filterCategory['choices']) {
                this.filterLookup[filterChoice[1]] = [filterCategory['field'], filterChoice[0]];
            }
        }

        if (this.initialFilters) {
            this.filters = this.initialFilters.split(',');
        }
        let filters: {[category: string]: any}  = {};
        for (let filter of this.filters) {
            let category = this.filterLookup[filter];
            if (category !== undefined) {
                if (!filters.hasOwnProperty(category[0])) {
                    filters[category[0]] = [];
                }
                if (filters[category[0]].indexOf(category[1]) === -1) {
                    filters[category[0]].push(category[1]);
                }
            }
        }
        this.filterSearch = filters;

        if (this.initialKeywords) {
            this.keywords = this.initialKeywords.split(',');
        }

        this.searchTerms = this.searchTerms.concat(this.filters.map((filter) => {
            return {display: filter, type: 'filter'};
        }));
        this.searchTerms = this.searchTerms.concat(this.keywords.map((keyword) => {
            return {display: keyword, type: 'keyword'};
        }));
    }

    private addSearchTerm(display: string, type: string) {
        this.searchTerms.push({display: display, type: type});
    }

    private clearFilter(display: string): void {
        let category = this.filterLookup[display][0];
        let filter = this.filterLookup[display][1];

        let locationFilter = this.filterSearch[category];
        if (locationFilter !== undefined && locationFilter.indexOf(filter) > -1) {
            locationFilter.splice(locationFilter.indexOf(filter), 1);
            if (locationFilter.length === 0) {
                delete this.filterSearch[category];
            }
        }
        this.filters.splice(this.filters.indexOf(display), 1);
        this.removeSearchTerm(display, 'filter');
        this.setLocationSearch();
    }

    private clearKeyword(keyword: string): void {
        let keywordIndex = this.keywords.indexOf(keyword);
        if (keywordIndex > -1) {
            this.keywords.splice(keywordIndex, 1);
        }
        this.removeSearchTerm(keyword, 'keyword');
        this.setLocationSearch();
    }

    /* tslint:disable:no-unused-variable */
    private clearSearchTerm(searchTerm: any) {
        if (searchTerm.type === 'filter') {
            this.clearFilter(searchTerm.display);
        } else if (searchTerm.type === 'keyword') {
            this.clearKeyword(searchTerm.display);
        }
    }

    /* tslint:disable:no-unused-variable */
    private clearSearchTerms(): void {
        this.filters = [];
        this.filterSearch = {};
        this.keywords = [];
        this.searchTerms = [];
        this.setLocationSearch();
    }

    /* tslint:disable:no-unused-variable */
    private hasSearchTerms(): boolean {
        return !(
            this._.isEmpty(this.filters) &&
            this._.isEmpty(this.filterSearch) &&
            this._.isEmpty(this.keywords) &&
            this._.isEmpty(this.searchTerms)
        );
    }

    /* tslint:disable:no-unused-variable */
    private handleKeywordInput($event: any): void {
        // On enter add this keyword to the list of keywords
        if ($event.which === 13) {
            if (this.keywords.indexOf(this.keyword) === -1) {
                this.keywords.push(this.keyword);
                this.addSearchTerm(this.keyword, 'keyword');
            }
            this.keyword = '';
        }

        // When a user hits backspace when there is no text in this keyword input
        // remove the previous keyword
        if ($event.which === 8) {
            if (!this.keyword) {
                const searchTerm: any = this._.last(this.searchTerms);
                if (searchTerm) {
                    if (searchTerm.type === 'filter') {
                        this.clearFilter(searchTerm.display);
                    } else if (searchTerm.type === 'keyword') {
                        this.clearKeyword(searchTerm.display);
                    }
                }
            }
        }

        this.setLocationSearch();
    }

    /* tslint:disable:no-unused-variable */
    private refilter(category: string, filter: string, display: string): void {
        if (!this.filterSearch.hasOwnProperty(category)) {
            this.filterSearch[category] = [];
        }
        if (this.filterSearch[category].indexOf(filter) === -1) {
            this.filterSearch[category].push(filter);
        }
        if (this.filters.indexOf(display) === -1) {
            this.filters.push(display);
            this.addSearchTerm(display, 'filter');
        }
        this.setLocationSearch();
    }

    private removeSearchTerm(display: string, type: string) {
        let index: number = this._.findIndex(this.searchTerms, (searchTerm: any) => {
            return display === searchTerm.display && type === searchTerm.type;
        });
        this.searchTerms.splice(index, 1);
    }

    /* tslint:disable:no-unused-variable */
    private resort(sort: string): void {
        if (this.sort === sort) {
            if (this.reverse) {
                this.reverse = false;
                this.sort = '';
            } else {
                this.reverse = !this.reverse;
            }
        } else {
            this.sort = sort;
            this.reverse = false;
        }

        this.setLocationSearch();
    }

    private setLocationSearch(): void {
        let queryParams: string[] = [];

        if (this.sort !== '') {
            let signedSort = this.reverse ? `-${this.sort}` : this.sort;
            queryParams.push(`sort=${signedSort}`);
        }
        if (this.filters.length > 0) {
            queryParams.push(`filters=${this.filters.join(',').replace('&', '%26')}`);
        }
        if (this.keywords.length > 0) {
            queryParams.push(`keywords=${this.keywords.join(',')}`);
        }
        this.$window.history.replaceState({}, '', `?${queryParams.join('&')}`);
    }
}

angular
    .module('fundersclub.publicPages')
    .component('fcPortfolioItems', new FcPortfolioItems());
