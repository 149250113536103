do ->
    # Generate fc-bind-href, fc-bind-title, etc attributes.
    # These are needed to bind an element attribute to an angular expression
    # since we disabled interpolation
    camelCase = (input) -> input.toLowerCase().replace(/-(.)/g, (match, group1) -> group1.toUpperCase())
    attrs = [
        'colspan'
        'data-clipboard-text'
        'href'
        'id'
        'max'
        'name'
        'src'
        'target'
        'title'
        'type'
    ]
    for htmlAttr in attrs
        ccAttr = camelCase(htmlAttr)
        ngAttr = 'fcBind' + ccAttr.charAt(0).toUpperCase() + ccAttr.slice(1)

        do (htmlAttr, ngAttr) ->
            directive = ->
                thisLink = (scope, element, attr) ->
                    scope.$watch(attr[ngAttr], (value) ->
                        attr.$set(htmlAttr, value)
                    )

                thisDirective =
                    restrict: 'A'
                    link: thisLink
                return thisDirective

            angular
                .module 'fundersclub'
                .directive ngAttr, directive
