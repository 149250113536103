do ->

    angular
        .module 'fundersclub.users', [
            'ngAnimate'
            'ngFileUpload'
            'ngToast'
            'fundersclub.api'
            'fundersclub.models'
        ]
