class FcCompanyAssetTrackingMarkdowns implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        companyAssetTrackingData: '<',
    };
    public controller = FcCompanyAssetTrackingMarkdownsController;
    public templateUrl = 'investment-assets/company-asset-tracking-markdowns.component.html';
}

class FcCompanyAssetTrackingMarkdownsController implements ng.IComponentController {
    public companyAssetTrackingData: any;

    private allMarkdownsAdded: any;
    private assetTracking: any;
    private errors: {[error: string]: any} = {};
    private fcSave: any;
    private newMarkdowns: any;

    constructor(
        private CompanyAssetTracking: any,
        private FCChoices: any
    ) {}

    public $onInit() {
        this.assetTracking = new this.CompanyAssetTracking(this.companyAssetTrackingData);
    }

    /* tslint:disable:no-unused-variable */
    private done() {
        return this.assetTracking.markdowns_reviewed;
    }

    /* tslint:disable:no-unused-variable */
    private reset() {
        this.assetTracking.markdowns_reviewed = this.FCChoices.MarkdownReviewedChoices.NotSet;
        this.allMarkdownsAdded = false;
        this.newMarkdowns = null;

        this.assetTracking
            .$save(['markdowns_reviewed'])
            .catch((response: any) => {
                alert('Failed resetting. Please refresh the page!');
            });
    }

    /* tslint:disable:no-unused-variable */
    private save() {
        this.errors = {};

        let markdownsReviewed = this.FCChoices.MarkdownReviewedChoices.NotSet;
        if (this.newMarkdowns === 'no') {
            markdownsReviewed = this.FCChoices.MarkdownReviewedChoices.NoNewMarkdowns;
        } else if (this.newMarkdowns === 'nsi') {
            markdownsReviewed = this.FCChoices.MarkdownReviewedChoices.NSI;
        } else if (this.allMarkdownsAdded) {
            markdownsReviewed = this.FCChoices.MarkdownReviewedChoices.YesAndAdded;
        }

        this.assetTracking.markdowns_reviewed = markdownsReviewed;

        this.assetTracking
            .$save(['markdowns_reviewed'])
            .then((response: any) => {
                // Empty on purpose
            })
            .catch((response: any) => {
                angular.extend(this.errors, response.data);
            })
            .finally(() => this.fcSave.saveAttemptFinished(this.errors));
    }
}

angular
    .module('fundersclub.assets')
    .component('fcCompanyAssetTrackingMarkdowns', new FcCompanyAssetTrackingMarkdowns());
