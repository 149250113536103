class FcInvestorProfilesList implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        investorProfilesData: '<',
        userProfileData: '<',
    };
    public controller = FcInvestInvestorProfilesList;
    public templateUrl = 'users/investor-profiles-list.component.html';
}

class FcInvestInvestorProfilesList implements ng.IComponentController {
    public investorProfilesData: Array<any>;
    public userProfileData: any;

    private showAddForm = false;
    private investorProfiles: Array<IInvestorProfile>;
    private userProfile: any;

    constructor(private InvestorProfile: any,  private UserProfile: IUserProfileClass) {}

    public $onInit() {
        this.investorProfiles = this.investorProfilesData.map((investorProfile: any) => {
            return new this.InvestorProfile(investorProfile);
        });
        this.userProfile = new this.UserProfile(this.userProfileData);
    }

    /* tslint:disable:no-unused-variable */
    private onAdded(investorProfile: any) {
        this.investorProfiles.push(investorProfile);
        this.showAddForm = false;
    }

    /* tslint:disable:no-unused-variable */
    private onAddingCanceled() {
        this.showAddForm = false;
    }
}

angular
    .module('fundersclub.users')
    .component('fcInvestorProfilesList', new FcInvestorProfilesList());
