do ->

    # Strips decimals of their decimal portion if it is empty (useful for currency
    # where you don't want to show cents place is empty)
    # 10.00 -> 10
    # 10.25 -> 10.25
    stripEmptyDecimal = ->
        thisFilter = (input) ->
            if not input?.length
                return ''
            input.replace(/\.0+$/, '')

        thisFilter

    # Given an array, creates a comma separated english list (using and where appropriate)
    englishList = ->
        thisFilter = (input) ->
            if not input?.length
                return ''
            if input.length == 1
                return input[0]
            else
                last_index = input.length - 1
                return "#{input.slice(0, last_index).join(', ')} and #{input[last_index]}"

        thisFilter

    # Renders a given amount of time in a human-readable format.
    humanizeDuration = ->
        thisFilter = (input) ->
            return moment.duration(parseFloat(input), 'seconds').humanize()

        thisFilter


    # Renders a non-abbreviated dollar amount.
    dollars = ->
        thisFilter = (n, decimal=false, t=',', d='.', c='$', zero='$0') ->
            # Return a dash if not number/too small for $$$.
            if isNaN(n) or Math.abs(n) < 0.01
                return zero

            s = if n < 0 then "-#{c}" else c
            i = if decimal then Math.abs(n).toFixed(2) else Math.abs(n).toFixed(0)
            j = (if (j = i.length) > 3 then j % 3 else 0)
            s += i.substr(0, j) + ',' if j
            # Replace some messed up formatting.
            val = (s + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + ',')).replace(/\,\./g, '\.')
            return val

        thisFilter


    # Renders an abbreviated dollar amount.
    # e.g. 7000 -> $7k, 1000000 -> $1M
    dollarsAbbv = ->
        thisFilter = (number, currency, zero='–') ->
            # Confirm it's a number
            formatted = dollars()(number, null, null, null, zero)
            if formatted == zero then return zero

            if number >= 1000000
                return dollars()(number/1000000, true, null, null, currency) + 'M'
            else if number >= 1000
                return dollars()(number/1000, null, null, null, currency) + 'k'
            else
                return dollars()(number, true, null, null, currency)

        thisFilter


    # Returns just the text content of the specified HTML string.
    # e.g. "<div>Hello</div>" -> "Hello"
    stripHtml = ->
        thisFilter = (input) ->
            # Need surrounding <div> to ensure plaintext doesn't fail.
            angular.element("<div>#{input}</div>").text()

        thisFilter

    # Renders the contents of the given string, truncated to the
    # provided length, and then with an ellipsis appended to it
    truncateWithEllipsis = ->
        thisFilter = (input, max_length) ->
            if input.length > 50 then input.slice(0, 50) + '…' else input

        thisFilter

    # Capitalize first letter
    capitalize = ->
        thisFilter = (input) ->
            input[0].toUpperCase() + input[1..-1].toLowerCase()

        thisFilter

    # convert true/false to yes/no
    yesNo = ->
        thisFilter = (input) ->
            if input.toLowerCase() == 'false'
                return 'No'
            if input then 'Yes' else 'No'

        thisFilter

    angular
        .module 'fundersclub'
        .filter 'englishList', englishList
        .filter 'humanizeDuration', humanizeDuration
        .filter 'dollars', dollars
        .filter 'dollarsAbbv', dollarsAbbv
        .filter 'stripHtml', stripHtml
        .filter 'truncateWithEllipsis', truncateWithEllipsis
        .filter 'capitalize', capitalize
        .filter 'stripEmptyDecimal', stripEmptyDecimal
        .filter 'yesNo', yesNo
