do ->

    fcBrokerageAccountAdd = ($q, fcPaymentsManager, BrokerageAccount, InvestorProfile, UserData, $http) ->
        thisScope =
            onCreateCallback: '&onCreateCallback'
            onErrorCallback: '&onErrorCallback'
            onReady: '&onReady'
            investorProfilesData: '<'
            investorProfile: '<'

        thisController = ($scope) ->
            vm = @
            vm.onErrorCallback = vm.onErrorCallback or (->)
            vm.errors = {}
            vm.brokerageAccount = {}
            vm.brokerageFirm = {}
            vm.dtcParticipants = []

            if vm.investorProfilesData
                vm.availableInvestorProfiles = vm.investorProfilesData
                    .map (investorProfile) ->
                        return new InvestorProfile(investorProfile);
            else
                vm.availableInvestorProfiles = [ vm.investorProfile ]

            if vm.availableInvestorProfiles.length == 1
                vm.brokerageAccount.investor_profile = vm.availableInvestorProfiles[0]

            # Fetch DTC participants from payments system
            fcPaymentsManager.getDTCparticipants()
                .then (response) ->
                    vm.dtcParticipants = response.data

            vm.add = (opts) ->
                vm.errors = {}
                opts = opts or {}
                opts.clearForm = opts.clearForm or false

                if !vm.brokerageAccount.investor_profile
                    angular.extend(vm.errors, {investor_profile: ['This field is required']})
                    vm.fcSave.saveAttemptFinished(vm.errors)
                    return

                vm.brokerageAccount.user_id = UserData.user_id
                vm.brokerageAccount.user_id_sig = UserData.user_id_sig
                vm.brokerageAccount.fc_user_profile_name = UserData.fc_user_profile_name
                vm.brokerageAccount.fc_user_profile_id = UserData.fc_user_profile_id
                vm.brokerageAccount.fc_investor_profile_name = vm.brokerageAccount.investor_profile.full_name

                fcPaymentsManager.createBrokerageAccount(vm.brokerageAccount)
                    .then (response) ->
                        if opts.customPostHook
                            opts.customPostHook(response.data.id)
                        else
                            newBrokerageAccount = new BrokerageAccount({
                                brokerageaccount_id: response.data.id,
                                investor_profile_id: vm.brokerageAccount.investor_profile.id
                            })
                            newBrokerageAccount.$save()
                                .then (response) ->
                                    vm.onCreateCallback({'newBrokerageAccount': newBrokerageAccount})
                                    if opts.clearForm
                                        vm.brokerageAccount = {}
                                .catch (response) ->
                                    angular.extend(vm.errors, response.data)
                                    vm.onErrorCallback({'errors': vm.errors})
                                .finally ->
                                    vm.fcSave.saveAttemptFinished(vm.errors)
                    .catch (response) ->
                        if response.data.hasOwnProperty('non_field_errors') && response.data.non_field_errors.includes("The fields firm_dtc, account_number must make a unique set.")
                            angular.extend(vm.errors, {non_field_errors: ["An account already exists in our system with this information"]})
                        else
                            angular.extend(vm.errors, response.data)
                        vm.onErrorCallback({'errors': vm.errors})
                    .finally ->
                        vm.fcSave.saveAttemptFinished(vm.errors)

            vm.cancel = ->
                vm.errors = {}
                vm.brokerageAccount = {}

            vm.filteredFirms = (searchStr) ->
                results = vm.dtcParticipants.filter((a) -> a.name.includes(searchStr.toUpperCase()))
                return results

            # Auto populate DTC number from firm name
            vm.selectFirm = (selectedFirm) ->
                vm.brokerageAccount.firm_dtc = selectedFirm.dtc_number
                vm.brokerageAccount.brokerage_firm_name = selectedFirm.name

            # Auto populate firm name from DTC number
            vm.updateDtc = () ->
                if vm.brokerageAccount.firm_dtc.length == 4
                    selectedFirm = vm.dtcParticipants.find((a) -> a.dtc_number == vm.brokerageAccount.firm_dtc)
                    if selectedFirm
                        vm.brokerageFirm = selectedFirm
                        vm.brokerageAccount.brokerage_firm_name = selectedFirm.name

            if vm.onReady
                vm.onReady({'instance': vm})

            return

        thisDirective =
            scope: thisScope
            restrict: 'A'
            templateUrl: 'payments/brokerage-account-add.directive.html'
            bindToController: true
            controllerAs: 'baac'
            controller: thisController
        return thisDirective

    angular
        .module 'fundersclub.payments'
        .directive 'fcBrokerageAccountAdd', fcBrokerageAccountAdd
