class FcExtDistDetailsSignTaxDocModal implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        modalInstance: '<',
        resolve: '<',
    };
    public controller = FcExtDistDetailsSignTaxDocModalController;
    public templateUrl = 'funds/ext-dist-details-sign-tax-doc-modal.component.html';
}

class FcExtDistDetailsSignTaxDocModalController implements ng.IComponentController {
    public modalInstance: ng.ui.bootstrap.IModalServiceInstance;
    public resolve: {
        getExtDistDetails: any,
    };
    public resetting: boolean = false;

    private initialDocStatus: any;
    private isSafari: boolean = false;
    private signingUrl: any;

    constructor(
        private $intercom: any,
        private FCChoices: any,
        private $scope: ng.IScope,
        private $window: ng.IWindowService
    ) {}

    public $onInit() {
        this.$intercom.hideLauncher();

        this.isSafari = (
            this.$window.navigator.userAgent.indexOf('Safari') !== -1 &&
            this.$window.navigator.userAgent.indexOf('Chrome') === -1
        );

        // Auto-close when signed
        this.$scope.$watch(
            () => { return this.isLegaldocSigned(); },
            (newVal) => {
                if (newVal) {
                    this.close();
                }
            }
        );

        this.initialDocStatus = this.resolve.getExtDistDetails().tax_doc_status;
        this.signingUrl = this.resolve.getExtDistDetails().tax_doc_signing_url;
    }

    /* tslint:disable:no-unused-variable */
    private close() {
        this.modalInstance.close(this);
    }

    /* tslint:disable:no-unused-variable */
    private isLegaldocSigned() {
        return (this.resolve.getExtDistDetails().tax_doc_status === this.FCChoices.SigningStatus.Signed);
    }

    /* tslint:disable:no-unused-variable */
    private resetForm() {
        this.resetting = true;
        this.resolve.getExtDistDetails().resetTaxForm().then(() => {
            this.$window.location.reload();
        });
    }
}

angular
    .module('fundersclub.funds')
    .component('fcExtDistDetailsSignTaxDocModal', new FcExtDistDetailsSignTaxDocModal());
