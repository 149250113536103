do ->

    fcSignupAccount = ->
        thisScope =
            'googleConnectUrl': '@googleConnectUrl'
            'facebookConnectUrl': '@facebookConnectUrl'
            'linkedinConnectUrl': '@linkedinConnectUrl'
            'googleLoginUrl': '@googleLoginUrl'
            'facebookLoginUrl': '@facebookLoginUrl'
            'linkedinLoginUrl': '@linkedinLoginUrl'
            'register': '&registerCallback'
            'signupLead': '=lead'

        thisController = ($scope, $window) ->
            vm = @
            vm.form = vm.register({'directive': vm})
            vm.showErrorMessages = false
            vm.directiveFields = [
                'terms_agreed',
                'email',
            ]
            vm.login_option = 'social'
            vm.errors = {}

            vm.connectingFacebook = false
            vm.connectingGoogle = false
            vm.connectingLinkedin = false
            vm.connectedGoogle = false
            vm.connectedFacebook = false
            vm.connectedLinkedin = false

            vm.connectionErrors = (errors) ->
                for field, error of errors
                    vm.showErrorMessages = true
                    vm.errors["allauth_#{field}"] = error.join(', ')

            vm.facebookAuth = ->
                vm.connectingFacebook = true
                facebookUrl = if vm.hasSocialAccountConnected() then vm.facebookConnectUrl else vm.facebookLoginUrl
                newWindow = window.open(facebookUrl, 'name', 'height=600,width=600')
                $window.connectedCallback = (errors) ->
                    $scope.$apply ->
                        if not _.isEmpty(errors)
                            vm.connectionErrors(errors)
                        else
                            vm.connectedFacebook = true
                            vm.showErrorMessages = false
                            vm.errors = {}
                        vm.connectingFacebook = false
                        newWindow.close()

            vm.linkedinAuth = ->
                vm.connectingLinkedin = true
                linkedinUrl = if vm.hasSocialAccountConnected() then vm.linkedinConnectUrl else vm.linkedinLoginUrl
                newWindow = window.open(linkedinUrl, 'name', 'height=600,width=600')
                $window.connectedCallback = (errors) ->
                    $scope.$apply ->
                        if not _.isEmpty(errors)
                            vm.connectionErrors(errors)
                        else
                            vm.connectedLinkedin = true
                            vm.showErrorMessages = false
                            vm.errors = {}
                        vm.connectingLinkedin = false
                        newWindow.close()

            vm.googleAuth = ->
                vm.connectingGoogle = true
                googleUrl = if vm.hasSocialAccountConnected() then vm.googleConnectUrl else vm.googleLoginUrl
                newWindow = window.open(googleUrl, 'name', 'height=600,width=600')
                $window.connectedCallback = (errors) ->
                    $scope.$apply ->
                        if not _.isEmpty(errors)
                            vm.connectionErrors(errors)
                        else
                            vm.connectedGoogle = true
                            vm.showErrorMessages = false
                            vm.errors = {}
                        vm.connectingGoogle = false
                        newWindow.close()

            # Convenience functions to know what tab we're on.
            vm.tab_is_social = -> vm.login_option == 'social'
            vm.tab_is_email = -> vm.login_option == 'email'

            vm.hasSocialAccountConnected = ->
                vm.connectedLinkedin || vm.connectedFacebook || vm.connectedGoogle

            # In order to commit, need to either have connected at least
            # one social account or have provided an email and 2x passwords.
            vm.validate = ->
                # Ensure at least one social account has been added.
                if vm.tab_is_social() and !vm.hasSocialAccountConnected()
                    if vm.showErrorMessages
                        for field in ['email', 'password1', 'password2']
                            delete vm.errors[field]
                        vm.errors['choose_social_or_email'] = 'Please either connect a social account or enter a password.'
                    return false

                # If an unexpected error shows up from the backend
                if vm.errors['signup']?
                    vm.showErorsMessages = true
                    return false

                restOfFormValid = vm.form.$valid
                # If other fields (e.g. email/password) have no errors showing,
                # clear out non-field errors.
                if restOfFormValid
                    vm.errors = {}

                return restOfFormValid

            vm.highlightContinueButton = ->
                return vm.validate()

            vm.showErrorMessage = (fieldName) ->
                return vm.showErrorMessages && vm.form[fieldName]?.$invalid

            return

        thisDirective =
            bindToController: true
            controller: thisController
            controllerAs: 'isuc'
            replace: true
            restrict: 'E'
            scope: thisScope
            templateUrl: 'signup/steps/account.directive.html'

        thisDirective


    angular
        .module 'fundersclub.signup'
        .directive 'fcSignupAccount', fcSignupAccount
