class FcIdentityVerificationModal implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        modalInstance: '<',
        resolve: '<',
    };
    public controller = FcIdentityVerificationModalController;
    public templateUrl = 'users/identity-verification-modal.component.html';
}

class FcIdentityVerificationModalController implements ng.IComponentController {
    public modalInstance: ng.ui.bootstrap.IModalServiceInstance;
    public resolve: {
        identityDocuments: Array<any>,
        investorProfile: IInvestorProfile,
        onIdentityDocumentsUpdated: (data: {investorProfile: IInvestorProfile}) => void,
        onInvestorProfileUpdated: (data: {identityDocuments: Array<any>}) => void,
        onSaved: () => void,
        onUserProfileUpdated: (data: {userProfileData: Array<any>}) => void,
        userProfile: any,
    };

    public $onInit() {
        this.resolve.identityDocuments = angular.copy(this.resolve.identityDocuments);
        this.resolve.investorProfile = angular.copy(this.resolve.investorProfile);
        this.resolve.userProfile = angular.copy(this.resolve.userProfile);
    }
}

angular
    .module('fundersclub.users')
    .component('fcIdentityVerificationModal', new FcIdentityVerificationModal());
