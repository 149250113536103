class FcCompanyAssetTrackingAssetsReviewForm implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        asset: '<',
    };
    public controller = FcCompanyAssetTrackingAssetsReviewFormController;
    public templateUrl = 'investment-assets/company-asset-tracking-assets-review-form.component.html';
}

class FcCompanyAssetTrackingAssetsReviewFormController implements ng.IComponentController {
    public asset: any;

    private errors: {[error: string]: any} = {};
    private fcSave: any;
    // private form: any;

    constructor(
        private CompanyAssetTracking: any,
        private InvestmentAsset: any
    ) {}

    /* tslint:disable:no-unused-variable */
    private reset() {
        this.asset.received_final_documents = null;
        this.asset.doc_numbers_reviewed = null;
        this.asset.received_stock_certificates = '';
        this.save();
    }

    /* tslint:disable:no-unused-variable */
    private save() {
        this.errors = {};

        this.asset
            .$save([
                'ignore_in_asset_tracker',
                'received_final_documents',
                'doc_numbers_reviewed',
                'received_stock_certificates',
            ])
            .then((response: any) => {
                // Empty on purpose
            })
            .catch((response: any) => {
                angular.extend(this.errors, response.data);
            })
            .finally(() => this.fcSave.saveAttemptFinished(this.errors));
    }
}

angular
    .module('fundersclub.assets')
    .component('fcCompanyAssetTrackingAssetsReviewForm', new FcCompanyAssetTrackingAssetsReviewForm());
