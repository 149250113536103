let IntercomFactory = function($window: any) {
    let Intercom = {
        'hideLauncher': function() {
            $('#intercom-container').hide();
        },
        'showLauncher': function() {
            $('#intercom-container').show();
        },
        'showNewMessage': function() {
            $window.Intercom('showNewMessage');
        },
    };
    return Intercom;
};

angular
    .module('fundersclub')
    .factory('$intercom', IntercomFactory);
