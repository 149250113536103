do ->
    InvestmentAsset = (fcModel, $http) ->
        InvestmentAsset = fcModel '/api/investmentassets/:id/', {
            id: '@id'
        }

        InvestmentAsset


    InvestmentTerms = (fcModel) ->
        InvestmentTerms = fcModel '/api/investmentterms/:id/', {
            id: '@id'
        }

        InvestmentTerms.prototype.purchase_price = ->
            if @is_equity()
                if not (@security_price_per_share and @security_number_of_shares)
                    return '-'

                purchase_price = @security_price_per_share * @security_number_of_shares
                if isNaN(purchase_price)
                    return '-'
                return purchase_price

            if @is_debt()
                if not @security_principal_amount
                    return '-'
                return @security_principal_amount

            if @is_saft()
                if not (@security_issue_price_per_token and @security_number_of_tokens)
                    return '-'

                purchase_price = @security_issue_price_per_token * @security_number_of_tokens
                if isNaN(purchase_price)
                    return '-'
                return purchase_price

            return '-'

        InvestmentTerms.prototype.is_equity = ->
            @security_type in @security_types.Equity

        InvestmentTerms.prototype.is_debt = ->
            @security_type in @security_types.Debt

        InvestmentTerms.prototype.is_complex_debt = ->
            @security_type in @security_types.ComplexDebt

        InvestmentTerms.prototype.is_preferred_stock = ->
            @security_type == @security_types.PreferredStock

        InvestmentTerms.prototype.is_saft = ->
            @security_type == @security_types.SAFT

        InvestmentTerms.prototype.is_warrant = ->
            @security_type == @security_types.Warrant

        InvestmentTerms.prototype.is_crypto = ->
            @security_type == @security_types.Crypto

        InvestmentTerms.prototype.warrant_warning = ->
            if @warrant_expiration_date
                if moment(@warrant_expiration_date) < moment()
                    return 'Warrant expiration date has passed.'
                if moment(@warrant_expiration_date) < moment().add(90, 'days')
                    return 'Warrant will expire in ' + moment(@warrant_expiration_date).fromNow() + '.'

        InvestmentTerms


    OutgoingInvestment = (fcModel) ->
        OutgoingInvestment = fcModel '/api/outgoinginvestments/:id/', {
            id: '@id'
        }

        OutgoingInvestment


    ProceedFromExit = (fcModel, $http) ->
        ProceedFromExit = fcModel '/api/proceedsfromexits/:id/', {
            id: '@id'
        }

        ProceedFromExit.prototype.addAsset = (assetField, isUnstructured, unstructuredType) ->
            $http.post @urls.add_asset, {
                'asset_field': assetField
                'is_unstructured': isUnstructured,
                'unstructured_type': unstructuredType
            }

        ProceedFromExit.prototype.copyAssetExpected = ->
            $http.post @urls.copy_asset_expected

        ProceedFromExit

    ValuationEvent = (fcModel, $http) ->
        ValuationEvent = fcModel '/api/valuationevents/:id/', {
            id: '@id'
        }

        ValuationEvent.prototype.addProceed = ->
            $http.post @urls.add_proceed

        ValuationEvent

    # Get module and add factories.
    angular
        .module 'fundersclub.models'
        .factory 'InvestmentAsset', InvestmentAsset
        .factory 'InvestmentTerms', InvestmentTerms
        .factory 'OutgoingInvestment', OutgoingInvestment
        .factory 'ProceedFromExit', ProceedFromExit
        .factory 'ValuationEvent', ValuationEvent
