do ->

    PartnershipProfileController = ->
        vm = @

        vm.showAllPastFunds = false
        vm.showAllPortfolioHighlights = false
        vm.showAllBackground = false

        vm.activeTab = 'overview'

        # End PartnershipProfileController
        return


    angular
        .module 'fundersclub.partnerships'
        .controller 'PartnershipProfileController', PartnershipProfileController
