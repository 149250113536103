do ->
    IntroductionRequest = (fcModel) ->
        IntroductionRequest = fcModel '/api/introductionrequests/:id/',
            id: '@id'

        IntroductionRequest

    angular
        .module 'fundersclub.models'
        .factory 'IntroductionRequest', IntroductionRequest
