let SignableLegalDocument = function($interval: any, fcModel: ng.resource.IResourceService) {
    let SignableLegalDocumentModel = fcModel('/api/legaldocuments/:id/', { id: '@id' }, {});

    SignableLegalDocumentModel.prototype.pollForUpdates = function() {
        let pollingInterval: any = $interval(() => {
            if (this.id) {
                if (this.user_has_signed) {
                    $interval.cancel(pollingInterval);
                    return;
                }

                if (this.id) {
                    this.$get().then((response: any) => {
                        angular.extend(this, response);
                    });
                }
            }
        }, 1000);
    };

    return SignableLegalDocumentModel;
};

angular
    .module('fundersclub.models')
    .factory('SignableLegalDocument', SignableLegalDocument);
