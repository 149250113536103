class FcCollapse implements ng.IComponentOptions {
    // The strange typings here are necessary.
    // https://github.com/Microsoft/TypeScript/issues/6041
    public bindings: {[binding: string]: string} = {
        expand: '<',
        onCollapsed: '&?',
    };
    public controller = FcCollapseController;
    public template = `
        <div
            uib-collapse="!$ctrl.expandContents"
            collapsed="$ctrl.postCollapse()"
        >
            <div ng-if="$ctrl.renderContents">
                <ng-transclude></ng-transclude>
            </div>
        </div>
    `;
    public transclude = true;
};

interface IFcCollapseOnChangesObject {
    [key: string]: ng.IChangesObject;
    // We have to explicitly include the keys we want to access.
    expand: ng.IChangesObject;
}

class FcCollapseController implements ng.IComponentController {
    public expand: boolean;

    private expandContents: boolean = false;
    private onCollapsed: () => void;
    private renderContents: boolean = false;

    constructor(private $timeout: ng.ITimeoutService) {}

    public $onInit() {
        if (this.expand) {
            this.expandContents = true;
        }
    }

    public $onChanges(changesObj: IFcCollapseOnChangesObject): void {
        if (changesObj.expand.currentValue) {
            this.renderContents = true;
            // Wait a few moments to ensure that Angular has time
            // to render content. Then trigger the expand animation.
            this.$timeout(() => {
                this.expandContents = true;
            }, 5);
        } else {
            this.expandContents = false;
            // renderContents handled in `collapsed` callback.
        }
    }

    /* tslint:disable:no-unused-variable */
    private postCollapse(): void {
        this.renderContents = false;

        if (this.onCollapsed) {
            this.onCollapsed();
        }
    }
};

angular
    .module('fundersclub')
    .component('fcCollapse', new FcCollapse());
