do ->

    # Google Geo (e.g. City name) Autocomplete.
    fcGoogleLocationAutocomplete = ->
        thisScope =
            fcGoogleLocationAutocomplete: '@'

        thisLink = (scope, element, attributes, ngModel) ->
            # If the Google library failed to load, abort.
            if not window['google'] then return

            # Instantiate new Autocomplete widget.
            scope.autocomplete = new google.maps.places.Autocomplete(
                element[0]
                types: [
                    scope.fcGoogleLocationAutocomplete or '(cities)'
                ]
            )

            # Ensure that values updated in widget are updated in model.
            google.maps.event.addListener(
                scope.autocomplete
                'place_changed'
                (evt) ->
                    scope.$apply ->
                        if ngModel? then ngModel.$setViewValue element.val()
            )

        thisDirective =
            scope: thisScope
            restrict: 'A'
            require: '?ngModel'
            link: thisLink
        return thisDirective


    # Background image. Updates CSS's background-image whenever
    # the value of the fc-background-image attribute is changed.
    fcBackgroundImage = ->
        thisLink = (scope, element, attrs) ->
            scope.$watch attrs['fcBackgroundImage'], (value) ->
                element.css
                    'background-image': "url(#{value})"

        thisDirective =
            restrict: 'A'
            link: thisLink
        return thisDirective


    # File upload directive to store a FileList inside the binded path
    # When binding a <input type="file"> field, ng-model will not work.
    # Instead, you should use fc-files-model (e.g. fc-files-model="myModel.logo")
    # This will set the logo property of myModel to a HTML5 FileList object.
    # When calling .save/.create/etc on models that have FileList, you will need to
    # use the transformFormRequestToFormData function. For example:
    # CompanyTeamMember = (fcModel, transformFormRequestToFormData) ->
    #     CompanyTeamMember = fcModel '/api/companyteammembers/:id/', {
    #         id: '@id'
    #     }, {
    #         create: {
    #             method: 'POST'
    #             transformRequest: transformFormRequestToFormData
    #             headers:
    #                 'Content-type': undefined
    #         }
    #     }
    #
    #    CompanyTeamMember
    fcFilesModel = ->
        thisController = ($parse, $element, $attrs, $scope) ->
            exp = $parse($attrs.fcFilesModel)
            $element.on('change', ->
                exp.assign($scope, this.files)
                $scope.$apply()
            )
            return

        thisDirective =
            controller: thisController
        return thisDirective


    # Convert a string to a float (should be used on input type="number" that
    # are bound to a string and not a number)
    fcStrToNum = ->
        thisLink = (scope, element, attributes, ngModel) ->
            ngModel.$parsers.push((value) -> '' + value)
            ngModel.$formatters.push((value) -> parseFloat(value, 10))

        thisDirective =
            restrict: 'A'
            require: 'ngModel'
            link: thisLink
        return thisDirective


    # Convert YYYY-MM-DD date used by our API to an actual Date object
    fcApiStrToDate = ->
        thisLink = (scope, element, attributes, ngModel) ->
            ngModel.$parsers.push((value) ->
                if not value
                    return

                return moment(value).format('YYYY-MM-DD')
            )
            ngModel.$formatters.push((value) ->
                return moment(value, 'YYYY-MM-DD').toDate()
            )

        thisDirective =
            restrict: 'A'
            require: 'ngModel'
            link: thisLink
        return thisDirective


    fcApiStrToDateTime = ->
        thisLink = (scope, element, attributes, ngModel) ->
            ngModel.$parsers.push((value) ->
                val = moment(value).toISOString()

                if val == 'Invalid date'
                    ''
                else
                    val
            )
            ngModel.$formatters.push((value) ->
                if value == null
                    null
                else
                    moment(value).toDate()
            )

        thisDirective =
            restrict: 'A'
            require: 'ngModel'
            link: thisLink
        return thisDirective


    fcMatchPasswords = ->
        thisLink = (scope, elem, attrs, control) ->
            comparePasswords = ->
                password_1 = scope.$eval(attrs.ngModel)
                password_2 = scope.$eval(attrs.fcMatchPasswords)
                password_1 == password_2

            scope.$watch comparePasswords, (matching) ->
                control.$setValidity 'unique', matching


        thisDirective =
            restrict: 'A'
            require: 'ngModel'
            scope: true
            link: thisLink
        return thisDirective


    # Using normal maxlength makes it so that when the model value is longer than
    # the maxlength value the model value gets set to undefined.  This is undesirable
    # behavior for certain models that have a lot of legacy values (eg. CompanyTeamMember.bio)
    # where we dont want the model value to get set to undefined if it is longer than maxlength
    # Also see https://stackoverflow.com/questions/17075969/ng-maxlength-screws-up-my-model
    fcMaxlengthAlwaysKeepValue = ->
        thisLink = (scope, element, attributes, ngModelCtrl) ->
            maxlength = Number(attributes.fcMaxlengthAlwaysKeepValue)
            ngModelCtrl.$parsers.push((text) ->
                if text.length > maxlength
                    transformedInput = text.substring(0, maxlength)
                    ngModelCtrl.$setViewValue(transformedInput)
                    ngModelCtrl.$render()
                    return transformedInput
                text
            )

        thisDirective =
            restrict: 'A'
            require: 'ngModel'
            link: thisLink
        return thisDirective


    angular
        .module 'fundersclub.forms'
        .directive 'fcGoogleLocationAutocomplete', fcGoogleLocationAutocomplete
        .directive 'fcBackgroundImage', fcBackgroundImage
        .directive 'fcFilesModel', fcFilesModel
        .directive 'fcStrToNum', fcStrToNum
        .directive 'fcApiStrToDate', fcApiStrToDate
        .directive 'fcApiStrToDateTime', fcApiStrToDateTime
        .directive 'fcMatchPasswords', fcMatchPasswords
        .directive 'fcMaxlengthAlwaysKeepValue', fcMaxlengthAlwaysKeepValue
