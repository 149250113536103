do ->

    PartnershipDashboardController = (FCPartnershipDashboardData, filterFilter, $window) ->
        vm = @
        vm.funds = FCPartnershipDashboardData.funds
        vm.searchText = ''
        vm.selectedFund = null
        vm.showEmptyMessage = false

        # Determine if we should select a fund.
        vm.updateHintText = ->
            results = filterFilter(vm.funds, {'fund_display_name': vm.searchText})
            vm.showEmptyMessage = results.length == 0
            vm.selectedFund = if results.length == 1 then results[0] else null

        # Navigate to a selected fund.
        vm.submitSearch = ->
            if vm.selectedFund
                $window.location.href = vm.selectedFund.urls.partnership_fund_dashboard


        # End PartnershipDashboardController
        return


    angular
        .module 'fundersclub.partnerships'
        .controller 'PartnershipDashboardController', PartnershipDashboardController
