do ->
    # Form responsible for displaying and editing fund update items.
    fcFundTestimonialEdit = ($http, FCFundTestimonialData, FCFundData, FundTestimonial) ->
        thisScope =
            'testimonial_item': '=?testimonial'
            'edit_form_enabled': '=editFormEnabled'
            'fund_profile_url': '@fundProfileUrl'
            'createdCallback': '&onCreate'
            'expandCallback': '&onExpand'
            'collapseCallback': '&onCollapse'
            'deleteCallback': '&onDelete'
            'testimonial_type': '@testimonialType'

        thisController = ->
            vm = @
            vm.errors = {}
            vm.edit_form_expanded = false

            vm.testimonial_item_template =
                fund_profile: FCFundData.fund_profile.url
                quote: ''
                testimonial_type: vm.testimonial_type

            if not vm.testimonial_item
                vm.testimonial_item = new FundTestimonial angular.copy(vm.testimonial_item_template)

            vm.initial_testimonial_item_data = angular.copy vm.testimonial_item
            vm.save_text = if vm.testimonial_item.id then 'Save' else 'Add'
            vm.saved_text = if vm.testimonial_item.id then 'Saved' else 'Pending'

            # Expand the editing form for this update item.
            vm.expandEditForm = (expand_form) ->
                currently_expanded = vm.edit_form_expanded
                becoming_expanded = if typeof expand_form is 'boolean' then expand_form else !currently_expanded

                # Don't allow user to collapse form unless they've dealt with their
                # changes (e.g. Save or Cancel).
                if not becoming_expanded and not vm.form.$pristine then return

                # If a expansion callback was provided, fire it off.
                vm.edit_form_expanded = becoming_expanded
                if becoming_expanded then vm.expandCallback() else vm.collapseCallback()

            # Cancel
            vm.cancel = ->
                # Reset form data.
                angular.copy(vm.initial_testimonial_item_data, vm.testimonial_item)
                vm.form.$setPristine()
                vm.errors = {}
                vm.expandEditForm(false)

            # Delete
            vm.deleteTestimonialItem = ->
                remove = confirm('Are you sure you want to delete this update?')
                if not remove
                    return

                vm.testimonial_item.$delete()
                    .then ->
                        vm.deleteCallback()

            # Add
            vm.save = ->
                adding = not vm.testimonial_item.id

                vm.errors = {}

                vm.testimonial_item.$save()
                    # unsaved-warning-form monitors the form pristine/dirty state
                    .then (response) ->
                        # Can't happen in a finally block because .expandEditForm checks
                        # the form's $pristine state
                        vm.fcSave.saveAttemptFinished(vm.errors)
                        if adding
                            vm.createdCallback({'testimonial': response})
                            vm.testimonial_item = new FundTestimonial angular.copy(vm.testimonial_item_template)
                            vm.expandEditForm(false)
                        else
                            angular.copy(response, vm.initial_testimonial_item_data)
                    .catch (response) ->
                        angular.extend(vm.errors, response.data)
                        vm.fcSave.saveAttemptFinished(vm.errors)

            # Resets the form state.
            vm.resetForm = ->
                # Clear form feedback, clear out input values.
                vm.errors = {}
                vm.submitting = false
                vm.testimonial_item = angular.copy(testimonial_item_template)

            return

        thisDirective =
            templateUrl: 'funds/testimonial-edit.directive.html'
            restrict: 'A'
            scope: thisScope
            bindToController: true
            controllerAs: 'ftic'
            controller: thisController
        return thisDirective

    angular
        .module 'fundersclub.funds'
        .directive 'fcFundTestimonialEdit', fcFundTestimonialEdit
