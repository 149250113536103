let EntityQuestionnaire = function(fcModel: ng.resource.IResourceService) {
    return fcModel('/api/investments/:id/entity_questionnaire/', { id: '@investment_id' }, {
        create: {
            method: 'POST',
        },
        update: {
            method: 'POST',
        },
    });
};

let InvestorProfileEntityQuestionnaire = function(fcModel: ng.resource.IResourceService) {
    return fcModel('/api/investorprofiles/:id/questionnaire/', { id: '@investor_profile_id' }, {
        create: {
            method: 'POST',
        },
        update: {
            method: 'POST',
        },
    });
};

let InvestorIdentityDocument = function(fcModel: ng.resource.IResourceService, transformFormRequestToFormData: any) {
    return fcModel('/api/identitydocuments/:id/', { id: '@id' }, {
        'create': {
            headers: {
                'Content-type': undefined,
            },
            method: 'POST',
            transformRequest: transformFormRequestToFormData,
        },
    });
};

interface IInvestment extends ng.resource.IResource<IInvestment> {
    backgroundSaveRequests: ng.IPromise<any>;
    complete: boolean;
    deletion_reasons: Array<string>;
    distributions_amount: number;
    fund: any;
    id: number;
    initial_additional_prorata_interest: number;
    initial_waitlisted_amount: number;
    investment_amount: number;
    investment_credit_amount: number;
    investor_profile: string;
    is_waitlisted: boolean;
    prorata_amount: number;
    questionnaire_completed: boolean;
    reservation_bank_account: string;
    reservation_payment_method: string;
    urls: any;
    usable_distributions_amount: number;
    usable_investment_credit_amount: number;
    use_distributions: boolean;
    use_investment_credit: boolean;
    waitlisted_amount_accepted: number;

    abandonSubscriptionAgreement(): ng.IHttpPromise<any>;
    backgroundSave(fields: Array<string>, errors: any, callback?: any): ng.IHttpPromise<any>;
    cancel(): ng.IHttpPromise<any>;
    reserve(): ng.IHttpPromise<any>;
    reserveFirstTime(
        paymentMethod: any,
        bankAccount: any,
        investorProfile: IFcModelResource<IInvestorProfile>,
        investorProfileType: string
    ): ng.IHttpPromise<any>;
    updateDeletionReasons(): ng.IHttpPromise<any>;
    selectWireRefundAccount(wireAccountId: any): ng.IHttpPromise<any>;
}

let Investment = function(
    $http: ng.IHttpService,
    $q: ng.IQService,
    fcModel: ng.resource.IResourceService
) {
    let InvestmentModel: IFcModelResource<IInvestment> = fcModel(
        '/api/investments/:id/',
        { id: '@id' }
    );

    InvestmentModel.prototype.abandonSubscriptionAgreement = function() {
        return $http.post(this.urls.abandon_legal_doc, {});
    };

    InvestmentModel.prototype.cancel = function() {
        return $http.post(`/api/investments/${this.id}/cancel/`, {});
    };

    InvestmentModel.prototype.reserve = function() {
        return $http.post(`/api/investments/${this.id}/reserve/`, {
            fund_amount_under_oversubscribed_without_minimum: (
                this.fund.amount_under_oversubscribed_without_minimum
            ),
            fund_oversubscribed: this.fund.oversubscribed,
            investment_amount: this.investment_amount,
            investor_profile: this.investor_profile,
            reservation_bank_account: this.reservation_bank_account,
            reservation_payment_method: this.reservation_payment_method,
            use_distributions: this.use_distributions ? this.use_distributions : false,
            use_investment_credit: this.use_investment_credit,
        });
    };

    InvestmentModel.prototype.reserveFirstTime = function(
        paymentMethod: any,
        bankAccount: any,
        investorProfile: IFcModelResource<IInvestorProfile>,
        investorProfileType: string
    ) {
        return $http.post(`/api/investments/${this.id}/reserve_first_time/`, {
            fund_amount_under_oversubscribed_without_minimum: (
                this.fund.amount_under_oversubscribed_without_minimum
            ),
            fund_oversubscribed: this.fund.oversubscribed,
            investment_amount: this.investment_amount,
            investor_profile: investorProfile,
            investor_profile_type: investorProfileType,
            reservation_bank_account: bankAccount,
            reservation_payment_method: paymentMethod,
        });
    };

    InvestmentModel.prototype.backgroundSave = function(fields: Array<string>, errors: any, callback?: any) {
        // Convert ['field_name'] into {field_name: obj[field_name]}
        let params = fields.reduce((allParams: {[key: string]: any}, fieldName: string) => {
            allParams[fieldName] = this[fieldName];
            return allParams;
        }, {});

        let makePatchCall = () => {
            return $q((resolve: any) => {
                // Make API call explicitly (vs using $save) so we can handle
                // response as we like.
                this.backgroundSaveRequests = $http.patch(`/api/investments/${this.id}/`, params)
                    .then((response: any) => {
                        // Only delete the error keys related to this update.
                        angular.forEach(params, (value, key) => {
                            delete errors[key];
                        });
                        if (callback) {
                            callback(response);
                        }
                    })
                    .catch((response: any) => {
                        angular.extend(errors, response.data);
                    })
                    .finally(() => {
                        resolve();
                    });
            });
        };

        // Only execute once all previous save calls have completed.
        if (this.backgroundSaveRequests) {
            this.backgroundSaveRequests.then(makePatchCall);
        } else {
            this.backgroundSaveRequests = makePatchCall();
        }
    };

    InvestmentModel.prototype.sendWiringInstructions = function() {
        return $http.post(this.urls.send_wire_instructions, {});
    };

    InvestmentModel.prototype.updateDeletionReasons = function() {
        return $http.post(this.urls.update_deletion_reasons, {
            deletion_reasons: this.deletion_reasons,
        });
    };

    InvestmentModel.prototype.selectWireRefundAccount = function(wireAccountId: any) {
        return $http.post(this.urls.select_wire_refund_account, {
            wire_account_id: wireAccountId,
        });
    };

    return InvestmentModel;
};

interface IFundOptInInvestmentClass extends IFcModelResource<any> {
    batchUpdate?(optInIds: Array<number>, amount: number, cancelled: boolean): ng.IHttpPromise<any>;
    allConfirmed?(optInInvestments: Array<any>): boolean;
}

let FundOptInInvestment = function(fcModel: ng.resource.IResourceService, $http: any) {
    let FundOptInInvestmentModel: IFundOptInInvestmentClass = fcModel(
        '/api/fundoptininvestments/:id/', { id: '@id' }
    );

    FundOptInInvestmentModel.batchUpdate = function(optInIds, amount, cancelled) {
        return $http
            .post('/api/fundoptininvestments/batch_update/', {
                'cancelled': cancelled,
                'confirmed': true,
                'ids': optInIds,
                'requested_amount': amount,
            });
    };

    FundOptInInvestmentModel.allConfirmed = function(optInInvestments) {
        const confirmedOptIns = optInInvestments.filter(
            function(optInInvestment: any) {
                return optInInvestment.confirmed;
            }
        );
        return confirmedOptIns.length === optInInvestments.length;
    };

    return FundOptInInvestmentModel;
};

angular
    .module('fundersclub.models')
    .factory('EntityQuestionnaire', EntityQuestionnaire)
    .factory('InvestorProfileEntityQuestionnaire', InvestorProfileEntityQuestionnaire)
    .factory('Investment', Investment)
    .factory('InvestorIdentityDocument', InvestorIdentityDocument)
    .factory('FundOptInInvestment', FundOptInInvestment);
