do ->

    SignupLead = (fcModel, $http, transformFormRequestToFormData) ->
        SignupLead = fcModel '/api/signupleads/:id/', {
            id: '@id'
        }, {
            partialupdate:
                method: 'PATCH'
                headers:
                    'Content-type': 'application/json'
        }

        SignupLead.prototype.finalize = ->
            $http.patch("/api/signupleads/#{this.id}/finalize/", this).then (response) ->
                return response

        SignupLead

    angular
        .module 'fundersclub.signup'
        .factory 'SignupLead', SignupLead
