class FcInvestmentsForSale implements ng.IComponentOptions {
    public bindings: { [binding: string]: string } = {
        allInvestments: '<',
        assetsDate: '<',

    };
    public controller = FcInvestmentsForSaleController;
    public templateUrl = 'users/investments-for-sale.component.html';
}

class FcInvestmentsForSaleController implements ng.IComponentController {
    public allInvestments: Array<any>;
    public assetsDate: any;

    public isModified: boolean;
    public totalIndicatedInterest: number = 0;
    public invalidForm: boolean = false;
    public fundStatusEnum = {
        Dissolved: 0,
        NoLongerActive: 1,
        Ok: 2,
    };

    constructor(
        private $window: any,
        private $http: ng.IHttpService,
        private FCChoices: any
    ) {
        this.isModified = false;
    }

    public formIsValid() {
        return this.isModified && !this.invalidForm;
    }

    public roundValue(value: number) {
        return Math.round(value);
    }

    public calculateTotal() {
        let total: number = 0;
        for (let investment of this.allInvestments) {
            if (investment.indicatedInterest > 0) {
                total += investment.indicatedInterest;
            }
        }
        this.totalIndicatedInterest = total;
    }

    public calculateIndicatedInterest(inv: any) {
        this.disableInput(inv);
        if (inv.percentage >= 0 && inv.percentage <= 100) {
            let sellPercentage = inv.percentage / 100;
            if (inv.sellOption === this.FCChoices.SellOptionChoices.Regular) {
                inv.indicatedInterest = inv.value * sellPercentage;
            } else if (inv.sellOption === this.FCChoices.SellOptionChoices.Premium) {
                inv.indicatedInterest = inv.value * (1 + inv.sellOptionPecentage / 100) * sellPercentage;
            } else if (inv.sellOption === this.FCChoices.SellOptionChoices.Discount) {
                inv.indicatedInterest = inv.value * (1 - inv.sellOptionPecentage / 100) * sellPercentage;
            }
        }
        this.calculateTotal();
    }

    public submintForm() {
        this.totalIndicatedInterest = undefined;
        let data: any[] = [];
        for (let investment of this.allInvestments) {
            if (investment.indicatedInterest > 0) {
                data.push(
                    {
                        'investment': investment.id,
                        'value': investment.value,
                        'invested': investment.invested,
                        'indicated_interest': this.roundValue(investment.indicatedInterest),
                        'percentage': investment.percentage,
                        'sell_option': investment.sellOption,
                        'sell_percentage': investment.sellOptionPecentage,
                        'internal_assets_date': this.assetsDate,
                    }
                );
            }
            investment.percentage = undefined;
            investment.indicatedInterest = 0;
            investment.sellOption = this.FCChoices.SellOptionChoices.Regular;
            investment.sellOptionPecentage = undefined;
            investment.disableInput = true;
        }
        this.isModified = false;
        this.$http.post('/my-investments/for-sale/', {
            investments: data,
        });
        alert('Your form has been sent.');
    }

    public disableInput(investment: any) {
        if (investment.sellOption === this.FCChoices.SellOptionChoices.Regular) {
            investment.sellOptionPecentage = undefined;
            investment.disableInput = true;
        } else {
            investment.disableInput = false;
        }
    }

    public fundStatus(investment: any) {
        if (investment.has_been_dissolved === true) {
            return this.fundStatusEnum.Dissolved;
        } else if (investment.value < 1 || investment.value === null) {
            return this.fundStatusEnum.NoLongerActive;
        }
        return this.fundStatusEnum.Ok;
    }
}

angular
    .module('fundersclub.users')
    .component('fcInvestmentsForSale', new FcInvestmentsForSale());
