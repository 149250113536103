class FcCompanySwagSizes implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        defaultFounderPicture: '<',
        foundersData: '<',
    };
    public controller = FcCompanySwagSizesController;
    public templateUrl = 'companies/swag-sizes.component.html';
}

class FcCompanySwagSizesController implements ng.IComponentController {
    public defaultFounderPicture: string;
    public foundersData: Array<any>;

    private fcSave: any;
    private founders: any;

    constructor(
        private $q: ng.IQService,
        private CompanyFounder: any
    ) {}

    public $onInit() {
        this.founders = this.foundersData.map((founder) => new this.CompanyFounder(founder));
    }

    /* tslint:disable:no-unused-variable */
    private save() {
        let promises = this.founders.map((founder: any) => {
            let deferred = this.$q.defer();
            founder
                .$save(['tshirt_size'])
                .then((response: any) => deferred.resolve());
            return deferred.promise;
        });
        this.$q
            .all(promises)
            .then(() => this.fcSave.saveAttemptFinished({}));
    }
}

angular
    .module('fundersclub.companies')
    .component('fcCompanySwagSizes', new FcCompanySwagSizes());
