let fcGuideSignup = function() {
    let thisScope = {
        'comingSoon': '@',
        'email': '@',
    };

    let thisController = function($http: any, $window: any) {
        let vm = this;
        vm.errors = {};
        vm.submitting = false;
        vm.submitted = false;

        vm.submit = () => {
            vm.submitting = true;
            $http({
                data: $.param({
                    'captured_from': $window.location.pathname,
                    'email': vm.email,
                }),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                method: 'POST',
                url: '/accounts/guides-subscription/',
            }).then(() => {
                vm.errors = {};
                vm.submitted = true;
            }).catch((response: any) => {
                vm.errors = response.data;
            }).finally(() => {
                vm.submitting = false;
            });
        };
    };

    let thisDirective = {
        bindToController: true,
        controller: thisController,
        controllerAs: 'gsc',
        replace: true,
        restrict: 'A',
        scope: thisScope,
        templateUrl: function(elem: any, attrs: any) {
            return 'learn/guide-signup' + (attrs.templateSpecifier || '') + '.directive.html';
        },
    };
    return thisDirective;
};

angular
    .module('fundersclub.learn')
    .directive('fcGuideSignup', fcGuideSignup);
