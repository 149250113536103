angular
    .module('fundersclub.icp')
    .config(function(
        $stateProvider: any,
        $windowProvider: any
    ) {
        let $window = $windowProvider.$get();
        if ($window.clientSideRouting && $window.ICPRouting) {
            $stateProvider
                .state('icp', {
                    params: {
                        transaction: null,
                    },
                    template: `
                        <fc-icp-home
                            fund="$ctrl.fund"
                            individual-identity-documents="$ctrl.individualIdentityDocuments"
                            individual-investor-profile="$ctrl.individualInvestorProfile"
                            investment="$ctrl.investment"
                            investor-profile-identity-documents="$ctrl.investorProfileIdentityDocuments"
                            investor-profile="$ctrl.investorProfile"
                            opt-in-investments="$ctrl.optInInvestments"
                            payment="$ctrl.payment"
                            scroll-to="$ctrl.scrollTo"
                            subscription-agreement="$ctrl.subscriptionAgreement"
                            tax-docs="$ctrl.taxDocs"
                            user-profile="$ctrl.userProfile"
                            ng-if="!$ctrl.showBackToHomeLink()"
                        ></fc-icp-home>
                        <ui-view></ui-view>`,
                    url: '/',
                })
                .state('icp.confirmTransaction', {
                    params: {
                        transaction: null,
                        transactionId: null,
                    },
                    template: `
                        <fc-confirm-transaction></fc-confirm-transaction>`,
                    url: 'confirm-transaction/:transactionId',
                })
                .state('icp.criticalFundChanges', {
                    template: `
                        <fc-complete-investment-fund-opt-ins
                            fund="$ctrl.fund"
                            investment="$ctrl.investment"
                            opt-in-investments="$ctrl.optInInvestments"
                        ></fc-complete-investment-fund-opt-ins>`,
                    url: 'critical-fund-changes',
                })
                .state('icp.entityAccreditation', {
                    template: `
                        <fc-entity-accreditation
                            fund="$ctrl.fund"
                            investment="$ctrl.investment"
                            investor-profile="$ctrl.investorProfile"
                            on-investor-profile-updated="
                                $ctrl.onInvestorProfileUpdated(investorProfile)
                            "
                            on-investment-updated="$ctrl.onInvestmentUpdated(investment)"
                            on-questionnaire-submitted="
                                $ctrl.onEntityAccreditationQuestionnaireSubmitted()
                            "
                        ></fc-entity-accreditation>`,
                    url: 'entity-accreditation',
                })
                .state('icp.entityIdentityVerification', {
                    template: `
                        <fc-entity-identity-verification
                            identity-documents="$ctrl.investorProfileIdentityDocuments"
                            investor-profile="$ctrl.investorProfile"
                            on-investor-profile-updated="
                                $ctrl.onInvestorProfileUpdated(investorProfile)
                            "
                            on-identity-documents-updated="
                                $ctrl.onIdentityDocumentsUpdated(identityDocuments)
                            "
                            on-saved="$ctrl.onIdentityVerificationSaved()"
                        ></fc-entity-identity-verification>`,
                    url: 'entity-identity-verification',
                })
                .state('icp.individualIdentityVerification', {
                    template: `
                        <fc-individual-identity-verification
                            identity-documents="$ctrl.individualIdentityDocuments"
                            investor-profile="$ctrl.individualInvestorProfile"
                            on-investor-profile-updated="
                                $ctrl.onIndividualInvestorProfileUpdated(investorProfile)
                            "
                            on-identity-documents-updated="
                                $ctrl.onIndividualIdentityDocumentsUpdated(identityDocuments)
                            "
                            on-saved="$ctrl.onIdentityVerificationSaved()"
                            on-user-profile-updated="$ctrl.onUserProfileUpdated(userProfile)"
                            user-profile="$ctrl.userProfile"
                        ></fc-individual-identity-verification>`,
                    url: 'individual-identity-verification',
                })
                .state('icp.payment', {
                    params: {
                        paymentType: {
                            dynamic: true,
                            squash: true,
                            value: null,
                        },
                    },
                    template: `
                        <fc-payment
                            fund="$ctrl.fund"
                            investment="$ctrl.investment"
                            investor-profile="$ctrl.investorProfile"
                            payment="$ctrl.payment"
                        ></fc-payment>`,
                    url: 'payment/:paymentType',
                })
                .state('icp.prepareSubscriptionAgreement', {
                    template: `
                        <fc-prepare-subscription-agreement
                            investment="$ctrl.investment"
                            investor-profile="$ctrl.investorProfile"
                            legal-doc="$ctrl.subscriptionAgreement"
                            on-subscription-agreement-generated="
                                $ctrl.onSubscriptionAgreementGenerated(subscriptionAgreement)
                            "
                            user-profile="$ctrl.userProfile"
                        ></fc-prepare-subscription-agreement>`,
                    url: 'prepare-subscription-agreement',
                })
                 .state('icp.signSubscriptionAgreement', {
                    template: `
                        <fc-sign-legaldoc-interstitial
                            doc-name="'Subscription Agreement'"
                            legal-doc="$ctrl.subscriptionAgreement"
                            on-reset-form="$ctrl.onResetSubscriptionAgreement()"
                            on-signing-modal-closed="$ctrl.onLegaldocSigningModalClosed()"
                        ></fc-sign-legaldoc-interstitial>`,
                    url: 'sign-subscription-agreement',
                })
                .state('icp.testHellosign', {
                    params: {
                        testUrl: {
                            dynamic: true,
                            squash: true,
                            value: null,
                        },
                    },
                    template: `
                        <fc-test-hellosign
                            on-signing-modal-closed="$ctrl.onTestHellosignClosed()"
                        ></fc-test-hellosign>`,
                    url: 'test-hellosign',
                })
                .state('icp.prepareTaxDocs', {
                    template: `
                        <fc-prepare-tax-docs
                            investor-profile="$ctrl.investorProfile"
                            on-tax-doc-generated="$ctrl.onTaxDocGenerated(taxDoc)"
                        ></fc-prepare-tax-docs>`,
                    url: 'prepare-tax-docs',
                })
                 .state('icp.signTaxDocs', {
                    template: `
                        <fc-sign-legaldoc-interstitial
                            doc-name="'Tax Documents'"
                            legal-doc="$ctrl.taxDocs"
                            on-reset-form="$ctrl.onResetTaxDocs()"
                            on-signing-modal-closed="$ctrl.onLegaldocSigningModalClosed()"
                        ></fc-sign-legaldoc-interstitial>`,
                    url: 'sign-tax-docs',
                })
                .state('icp.selectRefundAccount', {
                    template: `
                        <fc-icp-select-refund-account
                            investment="$ctrl.investment"
                        ></fc-icp-select-refund-account>`,
                    url: 'select-refund-account',
                })
                .state('icp.verifyBankAccount', {
                    params: {
                        transactionId: null,
                    },
                    template: `
                        <fc-verify-bank-account
                            bank-account="$ctrl.bankAccountToVerify"
                            on-verified="$ctrl.onBankAccountVerified(bankAccount)"
                        ></fc-verify-bank-account>`,
                    url: 'verify-bank-account/:transactionId',
                });
        }
    });
