do ->
    fcToast = ($http) ->
        thisScope =
            body: '@'
            kind: '@'
            extra: '='

        thisController = ($attrs) ->
            vm = @
            return

        thisDirective =
            restrict: 'E'
            bindToController: true
            templateUrl: 'shared/toast.directive.html'
            controllerAs: 'tc'
            controller: thisController
            scope: thisScope

        return thisDirective

    angular
        .module 'fundersclub'
        .directive 'fcToast', fcToast
