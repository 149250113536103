let fcFoundersApplyWeek = () => {
    let thisScope = {
        'application': '=application',
        'errors': '&errors',
        'fieldPrefix': '@fieldPrefix',
    };

    let thisController = function() {
        let vm = this;

        vm.getField = function(field: any) {
            return [vm.fieldPrefix, field].join('-');
        };

        vm.showErrorMessage = function(field: any) {
            return vm.errors[vm.getField(field)];
        };
    };

    let thisDirective = {
        bindToController: true,
        controller: thisController,
        controllerAs: 'faw',
        replace: false,
        restrict: 'A',
        scope: thisScope,
        templateUrl: 'public-pages/founders-apply-week-form.directive.html',
    };

    return thisDirective;
};

angular
    .module('fundersclub.publicPages')
    .directive('fcFoundersApplyWeek', fcFoundersApplyWeek);
