class FcOpenPanelReview implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        fullDisplay: '<?',
    };
    public controller = FcOpenPanelReviewController;
    public templateUrl = 'funds/open-panel-review.component.html';
}

class FcOpenPanelReviewController implements ng.IComponentController {
    public fullDisplay: boolean;

    constructor(
        private $uibModal: ng.ui.bootstrap.IModalService,
        private PanelFeedback: any,
        private PanelReviewData: any
    ) {}

    /* tslint:disable:no-unused-variable */
    private isNew() {
        return !this.PanelReviewData.panel_feedback.id;
    }

    /* tslint:disable:no-unused-variable */
    private openPanelReviewModal(): void {
        this.$uibModal
            .open({
                animation: false,
                backdrop: false,
                component: 'FcPanelReview',
                resolve: {
                    panelFeedback: () => {
                        let panelFeedBack: any = angular.copy(
                            this.PanelReviewData.panel_feedback
                        );
                        return new this.PanelFeedback(panelFeedBack);
                    },
                },
                windowClass: 'FullTakeoverModal',
            })
            .result
            .then((panelFeedback: any) => {
                this.PanelReviewData.panel_feedback = panelFeedback.toJSON();
            });
    }
}

class FcPanelReview implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        modalInstance: '<',
        resolve: '<',
    };
    public controller = FcPanelReviewController;
    public templateUrl = 'funds/panel-review.component.html';
}

class FcPanelReviewController implements ng.IComponentController {
    public modalInstance: ng.ui.bootstrap.IModalServiceInstance;
    public resolve: {
        panelFeedback: any,
    };

    private panelFeedback: any;
    private ratingMeanings: {[rating: string]: string} = {
        '0': 'No rating',
        '1': 'Definitely would not invest',
        '2': 'Probably would not invest',
        '3': 'Probably would invest',
        '4': 'Definitely would invest',
    };
    private saving: boolean = false;
    private tmpRating: number;

    public $onInit(): void {
        this.panelFeedback = this.resolve.panelFeedback;
        this.tmpRating = this.panelFeedback.rating;
        this.panelFeedback.negative_feedback.push({content: ''});
        this.panelFeedback.positive_feedback.push({content: ''});
        this.panelFeedback.questions.push({content: ''});
    }

    /* tslint:disable:no-unused-variable */
    private maybeClearInput($index: number, inputs: Array<any>): void {
        // If an input is empty and it is not the last input, get rid of it
        if (
            inputs.length > 1 &&
            $index !== (inputs.length - 1) &&
            inputs[$index].content === ''
        ) {
            inputs.splice($index, 1);
        }
    }

    /* tslint:disable:no-unused-variable */
    private save(): void {
        this.saving = true;
        this.panelFeedback.$save()
            .then((response: any) => {
                this.modalInstance.close(response);
            })
            .finally(() => this.saving = false);
    }

    /* tslint:disable:no-unused-variable */
    private setInputs(inputs: Array<any>): void {
        // If there isn't an empty input at the end of this list of inputs, add one
        if (inputs[inputs.length - 1].content !== '') {
            inputs.push({content: ''});
        }
    }

    /* tslint:disable:no-unused-variable */
    private setRating(ratingNum: number): void {
        this.tmpRating = ratingNum;
        this.panelFeedback.rating = ratingNum;
    }
}

angular
    .module('fundersclub.funds')
    .component('fcOpenPanelReview', new FcOpenPanelReview())
    .component('fcPanelReview', new FcPanelReview());
