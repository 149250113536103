class FcFundSectionHeaderEdit implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        field: '<',
        title: '<',
    };
    public controller = FcFundSectionHeaderEditController;
    public templateUrl = 'funds/fund-section-header-edit.component.html';
    public transclude = true;
}

class FcFundSectionHeaderEditController implements ng.IComponentController {
    private fund: any;
    private field: string;

    constructor(private Fund: any, private FCFundData: any) {}

    public $onInit(): void {
        this.fund = new this.Fund(this.FCFundData.fund);
    }

    /* tslint:disable:no-unused-variable */
    private setHide(hide: boolean): void {
        this.fund[this.field] = hide;
        this.fund.$save([this.field]).catch((response: any) => {
            alert('Error saving settings. Please refresh the page and try again');
        });
    }
}

angular
    .module('fundersclub.companies')
    .component('fcFundSectionHeaderEdit', new FcFundSectionHeaderEdit());
