class FcFundCapitalManagement implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        fundData: '<',
    };
    public controller = FcFundCapitalManagementController;
    public templateUrl = 'funds/capital-management.component.html';
}

class FcFundCapitalManagementController implements ng.IComponentController {
    public adding = false;
    public fundData: any;
    public totalDeployed: number;
    public totalInvestments: number;

    private fund: any;
    private allRequests: Array<any> = [];
    /* tslint:disable:no-unused-variable */
    private showTotalInvestableDetails = false;
    /* tslint:disable:no-unused-variable */
    private showRemainingCapitalDetails = false;

    constructor(
        private FCChoices: any,
        private WithheldInvestableCapital: any
    ) {}

    public $onInit(): void {
        this.fund = this.fundData;

        for (let wireRequest of this.fund.wire_requests) {
            /* tslint:disable:no-string-literal */
            wireRequest.isWireRequest = true;
            wireRequest.sortDate = wireRequest.submitted_at || wireRequest.created_at;
            this.allRequests.push(wireRequest);
        }
        for (let wic of this.fund.withheld_investable_capital) {
            wic.isWireRequest = false;
            wic.sortDate = wic.created_at;
            this.allRequests.push(wic);
        }
        this.calculateTotalDeployed();
    }

    /* tslint:disable:no-unused-variable */
    private addWithheldInvestableCapital(): void {
        this.adding = true;
        let wic = new this.WithheldInvestableCapital({'fund_id': this.fund.id});
        wic.$create()
            .then((response: any) => {
                response.isWireRequest = false;
                response.sortDate = response.created_at;
                response.new = true;
                this.allRequests.push(response);
                this.adding = false;
            });
    }

    private calculateTotalDeployed(): void {
        this.totalDeployed = 0;
        this.totalInvestments = 0;

        for (let request of this.allRequests) {
            if (!request.isWireRequest && request.status === this.FCChoices.Statuses.Open) {
                this.totalDeployed += Number(request.amount);
                this.totalInvestments += 1;
            } else if (request.isWireRequest) {
                this.totalDeployed += Number(request.wiring_amount);
                this.totalInvestments += 1;
            }
        }
    }
}

angular
    .module('fundersclub.funds')
    .component('fcFundCapitalManagement', new FcFundCapitalManagement());
