class FcReservePaymentMethod implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        bankAccount: '<',
        errors: '<',
        fund: '<',
        investorType: '<',
        onSelected: '&',
        paymentMethod: '<',
    };
    public controller = FcReservePaymentMethodController;
    public templateUrl = 'invest/payment-method.component.html';
}

class FcReservePaymentMethodController implements ng.IComponentController {
    public errors: Array<string>;
    public bankAccount: any;
    public fund: any;
    public investorType: string;
    public onSelected: (bankAccount: any) => void;
    public onUpdated: (bankAccount: any) => void;
    public paymentMethod: string;

    constructor(
        private $uibModal: ng.ui.bootstrap.IModalService,
        private FCChoices: any
    ) {}

    /* tslint:disable:no-unused-variable */
    private changePaymentMethod(): void {
        let modal: any = this.$uibModal
            .open({
                animation: false,
                backdrop: false,
                component: 'FcChangePaymentMethod',
                resolve: {
                    bankAccount: () => this.bankAccount,
                    canPayOffline: () => !this.fund.online_payments_only,
                    canPayOnline: () => this.fund.online_payments,
                    onSelected: () => this._onSelected.bind(this),
                    paymentByCheckDisabled: () => this.fund.payment_by_check_disabled,
                    paymentByWireDisabled: () => this.fund.payment_by_wire_disabled,
                    paymentMethod: () => this.paymentMethod,
                },
                windowClass: 'FullTakeoverModal',
            });
    }

    private _onSelected(paymentMethodData: {bankAccount: any, paymentMethod: string}): void {
        this.paymentMethod = paymentMethodData.paymentMethod;
        this.bankAccount = paymentMethodData.bankAccount;
        this.onSelected({paymentMethodData: paymentMethodData});
    }

    private usingIRAInvestorProfile(): boolean {
        return this.investorType === this.FCChoices.InvestorTypeChoices.IRA;
    }

    /* tslint:disable:no-unused-variable */
    private isPayingOffline(): boolean {
        return this.paymentMethod === this.FCChoices.ReservationPaymentMethods.Offline;
    }

    /* tslint:disable:no-unused-variable */
    private isPayingOnline(): boolean {
        return this.paymentMethod === this.FCChoices.ReservationPaymentMethods.Online;
    }
}

angular
    .module('fundersclub.invest')
    .component('fcReservePaymentMethod', new FcReservePaymentMethod());
