class FcKpiEditList implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        company: '<',
        kpiLabels: '<',
        kpisData: '<',
    };
    public controller = FcKpiEditListController;
    public templateUrl = 'companies/kpi-edit-list.component.html';
}

class FcKpiEditListController implements ng.IComponentController {
    public company: any;
    public kpiLabels: Array<string>;
    public kpisData: Array<any>;

    private expandedChildId: number = null;
    private kpis: Array<any>;

    constructor(
        private _: UnderscoreStatic,
        private KeyPerformanceIndicator: any
    ) {}

    public $onInit() {
        this.kpis = this.kpisData.map((kpi: any) => new this.KeyPerformanceIndicator(kpi));
    }

    /* tslint:disable:no-unused-variable */
    private add() {
        let kpi: any = new this.KeyPerformanceIndicator({company: this.company.url});
        kpi.$create()
            .then((response: any) => {
                this.kpis.unshift(kpi);
                this.expandedChildId = kpi.id;
            });
    }

    private onCollapsed() {
        this.expandedChildId = null;
    }

    private onDeleted(kpiId: number) {
        let index = this._.findIndex(this.kpis, (kpi: any) => kpi.id === kpiId);
        this.kpis.splice(index, 1);
        this.expandedChildId = null;
    }

    private onSaved(newKpi: any) {
        let index = this._.findIndex(this.kpis, (kpi: any) => kpi.id === newKpi.id);
        this.kpis[index] = newKpi;
    }
}

angular
    .module('fundersclub.companies')
    .component('fcKpiEditList', new FcKpiEditList());
