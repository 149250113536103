do ->

    # Form responsible for displaying and editing company update items.
    fcInvestorUpdateEdit = ->
        thisScope =
            'update': '=fcInvestorUpdateEdit'
            'acquiredCompanies': '=fcAcquiredCompanies'
            'company': '=fcCompany'
            'edit_form_enabled': '=editFormEnabled'
            'expandCallback': '&onExpand'
            'collapseCallback': '&onCollapse'
            'deleteCallback': '&onDelete'

        thisController = ($scope, $http, InvestorUpdate, Upload, InvestorUpdateFile) ->
            vm = @
            vm.errors = {}
            vm.edit_form_expanded = false
            vm.initial_update_data = angular.copy(vm.update)

            # Expand the editing form for this update item.
            vm.expandEditForm = (expand_form) ->
                currently_expanded = vm.edit_form_expanded
                becoming_expanded = if typeof expand_form is 'boolean' then expand_form else !currently_expanded

                # Don't allow user to collapse form unless they've dealt with their
                # changes (e.g. Save or Cancel).
                if not becoming_expanded and not vm.form.$pristine then return

                # If a expansion callback was provided, fire it off.
                vm.edit_form_expanded = becoming_expanded
                if becoming_expanded then vm.expandCallback() else vm.collapseCallback()

            if vm.initial_update_data.expand_after_render
                delete vm.initial_update_data.expand_after_render
                vm.expandEditForm()

            # Save
            vm.save = ->
                vm.errors = {}
                vm.update.$save()
                    # unsaved-warning-form monitors the form pristine/dirty state
                    .then (response) ->
                        vm.update = new InvestorUpdate(response)
                        angular.copy(response, vm.initial_update_data)
                    .catch (response) ->
                        angular.extend(vm.errors, response.data)
                    .finally () ->
                        vm.fcSave.saveAttemptFinished(vm.errors)

            # Cancel
            vm.cancel = ->
                # Reset form data.
                angular.copy(vm.initial_update_data, vm.update)
                vm.form.$setPristine()
                vm.errors = {}
                vm.expandEditForm(false)

            # Delete
            vm.deleteUpdate = ->
                remove = confirm('Are you sure you want to delete this update?')
                if remove
                    vm.update.$delete()
                        .then ->
                            vm.deleteCallback()

            # Publish
            vm.publish = ->
                if vm.update.should_send_email_on_publish
                    msg = 'Are you sure you wish to publish this update? An email will be sent to all investors in the company.'
                else
                    msg = 'Are you sure you wish to publish this item? NO email will be sent.'
                if not confirm(msg)
                    return

                # 0 is InvestorUpdateChoices.Published
                vm.update.update_status = 0
                vm.save()

            # Upload files when they are added
            $scope.$watch(
                (scope) -> vm.update.new_file
                (newValues) ->
                    if not newValues then return
                    for file in newValues
                        if not file or file.$error then return

                        new_file = new InvestorUpdateFile
                            basename: file.name
                            uploading: true
                            update: vm.update.url
                            update_file: file
                        new_file.$save()

                        vm.update.files.push(new_file)
            )

            vm.deleteFile = (file) ->
                file.uploading = true
                $http.delete(file.url).then ->
                    vm.update.files.splice(vm.update.files.indexOf(file), 1)

            return

        thisDirective =
            templateUrl: 'companies/updates-edit.directive.html'
            restrict: 'A'
            scope: thisScope
            bindToController: true
            controllerAs: 'cue'
            controller: thisController
        return thisDirective

    angular
        .module 'fundersclub.companies'
        .directive 'fcInvestorUpdateEdit', fcInvestorUpdateEdit
