FC.browseFundsSetup = () =>
    $(function() {
        $('[data-past-funds] .company-or-fund-line-item:nth-child(n + 6)').hide();

        $('[data-show-all-past-funds]').on('click', function(event) {
            event.preventDefault();
            $('[data-past-funds] .company-or-fund-line-item').show();
            return $(this).remove();
        });

        let displayNoFunds = function($noFunds: any, fundType: any) {
            $noFunds.find('[data-no-funds-type]').addClass('collapse');
            $noFunds.find(`[data-no-funds-type=${fundType}]`).removeClass('collapse');
            return $noFunds.removeClass('collapse');
        };

        let syncDisplay = function() {
            let fundType = $(this).data('fund-type');
            // Current menu could be either horizontal list or mobile dropdown.
            let visibleMenu = $('[data-fund-type-menu]:visible');
            visibleMenu.find('.active').removeClass('active');
            visibleMenu.find(`[data-fund-type=${fundType}]`).parent().addClass('active');
            $('[data-no-funds]').addClass('collapse');

            let hideFundTypeFilter: string;
            let showFundTypeFilter: string;
            if (fundType === 'internal') {
                showFundTypeFilter = '=False';
                hideFundTypeFilter = '=True';
            } else if (fundType === 'external') {
                showFundTypeFilter = '=True';
                hideFundTypeFilter = '=False';
            } else {
                showFundTypeFilter = '';
                hideFundTypeFilter = '=None';
            }

            let fundsToShow = $(`[data-is-external-fund${showFundTypeFilter}]`).parent();
            let fundsToHide = $(`[data-is-external-fund${hideFundTypeFilter}]`).parent();
            let availableFundsToShow = $(
                `[data-available-funds] [data-is-external-fund${showFundTypeFilter}]`
            ).parent();
            let upcomingFundsToShow = $(
                `[data-upcoming-funds] [data-is-external-fund${showFundTypeFilter}]`
            ).parent();

            if (!availableFundsToShow.length) {
                displayNoFunds($('[data-no-funds="available"]'), fundType);
            }

            if (!upcomingFundsToShow.length) {
                displayNoFunds($('[data-no-funds="upcoming"]'), fundType);
            }

            fundsToHide.hide();
            fundsToShow.show();

            if ($('[data-show-all-past-funds]').length) {
                return $('[data-past-funds] .company-or-fund-line-item:visible').slice(5).hide();
            }
        };

        $('[data-fund-type]').on('click', syncDisplay);
        $('[data-fund-type="all"]').click();
    })
;
