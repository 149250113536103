class FcSignLegaldocInterstitial implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        docName: '<',
        legalDoc: '<',
        onResetForm: '&',
        onSigningModalClosed: '&?',
    };
    public controller = FcSignLegaldocInterstitialController;
    public templateUrl = 'legaldocs/sign-legaldoc-interstitial.component.html';
}

class FcSignLegaldocInterstitialController implements ng.IComponentController {
    public docName: string;
    public legalDoc: any;
    public onResetForm: () => void;
    public onSigningModalClosed: () => void;

    private isSafari = false;
    private popupWindow: any;

    constructor(
        private $scope: ng.IRootScopeService,
        private $state: any,
        private $uibModal: any,
        private $window: any,
        private FCChoices: any
    ) {}

    public $onInit() {
        // HACK: Noramlly we would copy legalDoc to get correct 1 way data binding
        // but rely on polling in this component's parent to update the legalDocs's
        // status so we want the pass by reference behavior here

        this.isSafari = (
            this.$window.navigator.userAgent.indexOf('Safari') !== -1 &&
            this.$window.navigator.userAgent.indexOf('Chrome') === -1
        );

        if (this.isSafari) {
            this.$scope.$watch(
                () => this.legalDoc.user_has_signed,
                () => {
                    if (this.legalDoc.user_has_signed) {
                        this.onSigningModalClosed();
                    }
                }
            );
        } else {
            this.$uibModal.open({
                animation: false,
                ariaDescribedBy: 'modal-body',
                ariaLabelledBy: 'modal-title',
                component: 'fcSignLegaldocModal',
                resolve: {
                    docName: () => this.docName,
                    getLegaldocStatus: () => this.getLegaldocStatus.bind(this),
                    getSigningUrl: () => this.getSigningUrl.bind(this),
                    onResetForm: () => this.onResetForm.bind(this),
                },
                size: 'lg',
                windowClass: 'complete-investment--sign-legal-doc--modal',
            }).result.then((result: any) => {
                this.onSigningModalClosed();
            });
        }
    }

    private getLegaldocStatus(): string {
        return this.legalDoc.status;
    }

    private getSigningUrl(): string {
        return this.legalDoc.signing_url;
    }

    /* tslint:disable:no-unused-variable */
    private signInPopup() {
        this.popupWindow = this.$window.open(
            this.legalDoc.signing_url,
            'Popup',
            'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=no,width=800,height=600'
        );

        this.$window.docPostSignCallback = (doc: any) => {
            angular.extend(this.legalDoc, doc);
            this.$window.docPostSignCallback = null;
            this.popupWindow.close();
        };
    }
}

angular
    .module('fundersclub.legaldocs')
    .component('fcSignLegaldocInterstitial', new FcSignLegaldocInterstitial());
