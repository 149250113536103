class FcApproveSensitiveSectionAccess implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        approvedApplicationsData: '<',
        deniedApplicationsData: '<',
        pendingApplicationsData: '<',
    };
    public controller = FcApproveSensitiveSectionAccessController;
    public templateUrl = 'funds/approve-sensitive-section-access.component.html';
}

class FcApproveSensitiveSectionAccessController implements ng.IComponentController {
    private approvedApplications: Array<any>;
    private approvedApplicationsData: Array<any>;
    private deniedApplications: Array<any>;
    private deniedApplicationsData: Array<any>;
    private pendingApplications: Array<any>;
    private pendingApplicationsData: Array<any>;
    /* tslint:disable:no-unused-variable */
    private showApprovedApplications: boolean = false;
    /* tslint:disable:no-unused-variable */
    private showDeniedApplications: boolean = false;

    constructor(
        private _: UnderscoreStatic,
        private FundSensitiveWhitelist: any
    ) {}

    public $onInit(): void {
        this.approvedApplications = this.approvedApplicationsData.map(
            (application: any) => new this.FundSensitiveWhitelist(application)
        );
        this.deniedApplications = this.deniedApplicationsData.map(
            (application: any) => new this.FundSensitiveWhitelist(application)
        );
        this.pendingApplications = this.pendingApplicationsData.map(
            (application: any) => new this.FundSensitiveWhitelist(application)
        );
    }

    public onApplicationApproved(application: any) {
        this.pendingApplications = _.reject(this.pendingApplications, (app) => app.id === application.id);
        this.approvedApplications.push(new this.FundSensitiveWhitelist(application));
    }

    public onApplicationDenied(application: any) {
        this.pendingApplications = _.reject(this.pendingApplications, (app) => app.id === application.id);
        this.deniedApplications.push(new this.FundSensitiveWhitelist(application));
    }
}

angular
    .module('fundersclub.funds')
    .component('fcApproveSensitiveSectionAccess', new FcApproveSensitiveSectionAccess());
