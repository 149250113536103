class FcInitiateToi implements ng.IComponentOptions {
    public controller = FcInitiateToiController;
    public templateUrl = 'staff/initiate-toi.component.html';
}

class FcInitiateToiController implements ng.IComponentController {
    private agreementId: any;
    private searching: boolean;
    private startDate: any;
    private submitting: boolean = false;

    constructor (
        private AdminSearchData: any,
        private TOIAgreements: any,
        private FCChoices: any,
        private $http: ng.IHttpService,
        private $httpParamSerializer: ng.IHttpParamSerializer,
        private $window: ng.IWindowService
    ) {}

    public $onInit(): void {
        this.searching = false;
    }

    public isReady(): boolean {
        return this.agreementId && this.startDate && !this.submitting;
    }

    public ipClicked(ip: any) {
        /* tslint:disable:max-line-length */
        if (!confirm('Are you sure you want to initiate a transfer of interest? This will email the transferer above.')) {
            return;
        }

        this.submitting = true;

        this.$http({
            data: this.$httpParamSerializer({
                agreement: this.agreementId,
                start_date: this.startDate,
                to_investor_profile: ip.id,
            }),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            method: 'POST',
            url: this.$window.location.href,
        }).success((data: any) => {
            console.log(data);
            this.$window.location.href = data.next;
        }).catch(() => {
            this.submitting = false;
            alert('Error. Please try again or contact fire@fundersclub.com');
        });
    }

    /* tslint:disable:no-unused-variable */
    private submitSearch(): void {
        this.searching = true;
    }
}

angular
    .module('fundersclub.staff')
    .component('fcInitiateToi', new FcInitiateToi());
