class FcInvestorProfileTaxBoundFormW9 implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        errors: '<',
        investorProfile: '=',
        investorProfileTaxDoc: '=',
        readonlyAll: '<',
        readonlyName: '<',

    };
    public controller = FcInvestorProfileTaxBoundFormW9Controller;
    public templateUrl = 'users/investor-profile-tax-bound-form-w9.component.html';
}

class FcInvestorProfileTaxBoundFormW9Controller implements ng.IComponentController {
    public errors: {[error: string]: Array<any>} = {};
    public investorProfile: IInvestorProfile;
    public investorProfileTaxDoc: IInvestorProfileTaxDoc;
    public readonlyAll: boolean;
    public readonlyName: boolean;

    constructor(private FCChoices: any) {}

}

angular
    .module('fundersclub.users')
    .component('fcInvestorProfileTaxBoundFormW9', new FcInvestorProfileTaxBoundFormW9());
