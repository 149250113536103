// Custom wrapper for ngResource that makes it easier to work with DRF.
// adapted from http://kirkbushell.me/angular-js-using-ng-resource-in-a-more-restful-manner/

interface IFcModelResource<T> extends ng.resource.IResourceClass<any> {
    // TODO(chazeah): Convert these to FcModel once we have an interface defined.
    partialupdate?(FcModel: any): any;
}

let FcModelFactory = function($resource: ng.resource.IResourceService) {
    // Converts dot notataion to object model, e.g.
    // 'profile.bio' becomes {profile: {bio: ''}}
    let deeppick = function(obj: {[key: string]: any}, keepKeys: Array<string>): Object {
        let result: {[key: string]: any} = {};
        for (let i = 0, len = keepKeys.length; i < len; i++) {
            let item = keepKeys[i];
            let [key, ...rest] = item.split('.');
            if (rest.length) {
                result[key] = angular.extend(
                    result[key] || {},
                    deeppick(obj[key], [rest.join('.')])
                );
            } else {
                result[key] = obj[key];
            }
        }
        return result;
    };

    return function(url: string, params: any, methods: any): IFcModelResource<any> {
        // Set RESTful methods.
        let defaults: ng.resource.IActionHash = {
            create: {
                method: 'post',
            },
            // Sometimes we want to force a PATCH call, so we
            // add a $partialupdate function.
            partialupdate: {
                method: 'patch',
            },
            update: {
                method: 'put',
            },
        };
        methods = angular.extend(defaults, methods);
        let fcModelResource = <IFcModelResource<any>> $resource(url, params, methods);

        // Override the $save method with a custom one that offers the following:
        // 1. POST only if we don't have an 'id' field, otherwise DRF is upset
        // 2. PUT to update an existing object in its entirety
        // 3. PATCH for a partial update. The partial updates are useful for pages
        //    with multiple forms updating parts of the same model, otherwise we'll have
        //    funky behaviour.
        fcModelResource.prototype.$save = function(props: Array<string>) {
            // Should do partial update? (i.e. PATCH)
            if (props) {
                // Create temp obj with keys we will be updating
                let temp = new fcModelResource();
                angular.extend(temp, deeppick(this, ['id', ...props]));

                // Merge the result back into original object on success.
                // Creates closure to ensure callbacks maintain proper context.
                return temp.$partialupdate().then((function(that: any) {
                    return (response: any) => {
                        return angular.extend(that, response);
                    };
                })(this));
            // Do a full update/create (i.e. PUT/POST).
            } else if (this.id) {
                return this.$update();
            } else {
                return this.$create();
            }
        };
        return fcModelResource;
    };
};

angular
    .module('fundersclub.models')
    .factory('fcModel', FcModelFactory);
