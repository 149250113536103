do ->

    # Form responsible for displaying and editing corporate actions.
    fcCorporateActionEdit = (FCChoices, CorporateAction, FCCorporateActionsData) ->
        thisScope =
            'action': '=fcCorporateActionEdit'
            'edit_form_enabled': '=editFormEnabled'
            'expandCallback': '&onExpand'
            'collapseCallback': '&onCollapse'
            'deleteCallback': '&onDelete'

        thisController = ->
            vm = @
            vm.errors = {}
            vm.edit_form_expanded = false
            vm.add_investor_form_expanded = false
            vm.initial_action_data = angular.copy(vm.action)

            # Get choices for dropdowns/add investors widget.
            vm.choices = FCChoices
            vm.allInvestors = FCCorporateActionsData.all_investors

            vm.onStartupInvestorCreated = (startupInvestor) ->
                vm.allInvestors.push(startupInvestor)
                vm.action.round_investors.push(startupInvestor)
                vm.form.$setDirty()

            vm.onStartupInvestorRemoved = (startupInvestor) ->
                index = _.findIndex(vm.action.round_investors, (investor) ->
                    investor.id == startupInvestor.id
                )
                vm.action.round_investors.splice(index, 1)
                vm.form.$setDirty()

            vm.onStartupInvestorSelected = (startupInvestor) ->
                existingInvestor = vm.action.round_investors.filter((investor) ->
                    investor.id == startupInvestor.id
                )
                if not existingInvestor.length
                    vm.action.round_investors.push(startupInvestor)

            vm.onStartupInvestorUpdated = (startupInvestor, updateAllInvestors) ->
                index = _.findIndex(vm.action.round_investors, (investor) ->
                    investor.id == startupInvestor.id
                )
                startupInvestor.is_lead = vm.action.round_investors[index].is_lead
                vm.action.round_investors[index] = startupInvestor

                if updateAllInvestors
                    index = _.findIndex(vm.allInvestors, (investor) ->
                        investor.id == startupInvestor.id
                    )
                    vm.allInvestors[index] = startupInvestor

            # Expand the editing form for this action.
            vm.expandEditForm = (expand_form) ->
                currently_expanded = vm.edit_form_expanded
                becoming_expanded = if typeof expand_form is 'boolean' then expand_form else !currently_expanded

                # Don't allow user to collapse form unless they've dealt with their
                # changes (e.g. Save or Cancel).
                if not becoming_expanded and not vm.form.$pristine then return

                # If a expansion callback was provided, fire it off.
                vm.edit_form_expanded = becoming_expanded
                if becoming_expanded then vm.expandCallback() else vm.collapseCallback()

            if vm.initial_action_data.expand_after_render
                delete vm.initial_action_data.expand_after_render
                vm.expandEditForm()

            # Save
            vm.save = ->
                vm.errors = {}
                vm.action.lead_investors = vm.action.round_investors
                    .filter((investor) -> investor.is_lead)
                    .map((investor) -> investor.id)
                vm.action.$save([
                    'label',
                    'security_type',
                    'currency',
                    'valuation_cap',
                    'discount',
                    'pre_money_valuation',
                    'post_money_valuation',
                    'round_size',
                    'event_date',
                    'description',
                    'round_investors',
                    'lead_investors',
                ])
                    .then (response) ->
                        angular.copy(response, vm.initial_action_data)
                    .catch (response) ->
                        angular.extend(vm.errors, response.data)
                    .finally () ->
                        vm.fcSave.saveAttemptFinished(vm.errors)

            # Cancel
            vm.cancel = ->
                # Reset form data.
                angular.copy(vm.initial_action_data, vm.action)
                vm.form.$setPristine()
                vm.errors = {}
                vm.expandEditForm(false)

            # Delete corporate action.
            vm.deleteAction = ($event) ->
                if vm.action.current_round_funds.length
                    alert 'This corporate action is set as the current round of a fund and cannot be deleted'
                    return

                remove = confirm('Are you sure you want to delete this corporate action?')
                if remove
                    vm.action.$delete()
                        .then ->
                            vm.deleteCallback()

            # End thisController
            return

        thisDirective =
            templateUrl: 'companies/corporate-action-edit.directive.html'
            restrict: 'A'
            scope: thisScope
            bindToController: true
            controllerAs: 'cac'
            controller: thisController
        return thisDirective

    angular
        .module 'fundersclub.companies'
        .directive 'fcCorporateActionEdit', fcCorporateActionEdit
