do ->

    # List of updates for a given fund.
    FundWireRequestsController = (FCFundData, FCWireAccountsData, WireRequest) ->
        vm = @
        vm.wireRequests = (new WireRequest(wireRequest) for wireRequest in FCFundData.wireRequests)
        vm.expanded_child_id = null
        vm.start_expanded_child_id = Number(FCWireAccountsData.start_expanded or null)

        # Add a new item to the list.
        vm.itemCreated = (item) ->
            vm.wireRequests.unshift(item)

        # Remove specified child from list.
        vm.removeChild = (child_id) ->
            vm.expanded_child_id = null
            child_index = _.findIndex(vm.wireRequests, (child) -> child.id == child_id)
            vm.wireRequests.splice(child_index, 1)

        # End FundWireRequestsController
        return

    angular
        .module 'fundersclub.funds'
        .controller 'FundWireRequestsController', FundWireRequestsController
