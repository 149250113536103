do ->
    FCToastManager = (UnseenToast, ngToast, FCAccomplishmentPromosManager) ->
        FCToastManager =
            checkForToasts: (opts) ->
                opts = opts or {}
                UnseenToast.query (toasts) ->
                    for toast in toasts
                        toast_el = $('<fc-toast/>')
                            .attr('body', toast.body)
                            .attr('kind', toast.toast_kind)
                            .attr('extra', JSON.stringify(toast.extra_data))

                        ngToast.create
                            content: toast_el
                            compileContent: true

                        unless opts.venturePointsAlreadyAdded
                            FCAccomplishmentPromosManager.pointsEarned(toast.extra_data.points_earned)

            createGenericToast: (body) ->
                toast_el = $('<fc-toast/>')
                    .attr('body', body)
                    .attr('kind', 'generic-toast')

                ngToast.create
                    content: toast_el
                    compileContent: true

        FCToastManager

    FCToastManagerController = (FCToastManager) ->
        FCToastManager.checkForToasts({'venturePointsAlreadyAdded': true})

    toastConfig = (ngToastProvider) ->
        ngToastProvider.configure
            additionalClasses: 'fc-toast'


    angular
        .module 'fundersclub'
        .factory 'FCToastManager', FCToastManager
        .controller 'FCToastManagerController', FCToastManagerController
        .config toastConfig
