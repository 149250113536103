class FcSimResults implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        cashflows: '<',
        grossIrr: '<',
        grossMultiple: '<',
    };
    public controller = FcSimResultsController;
    public templateUrl = 'strategy-simulator/sim-results.component.html';
}

class FcSimResultsController implements ng.IComponentController {
    public cashflows: Array<any> = [];
    public grossIrr: number;
    public grossMultiple: number;

    public downloadCashflows(): void {
        console.log('Downloading cashflows');
        // Create a CSV file with the cashflows for the following columns: date, value, fund_name,
        // company_name, investment_name, description
        let csvContent: string = 'data:text/csv;charset=utf-8,';

        csvContent += 'Date,Value,Fund,Company,Investment,Description\n';
        this.cashflows.forEach(
            (cashflow: any) => {
                csvContent += `${cashflow.date},${cashflow.value},"${cashflow.fund_name}",` +
                    `"${cashflow.company_name}","${cashflow.investment_name}","${cashflow.description}"\n`;
            }
        );

        let encodedUri: string = encodeURI(csvContent);
        let link: HTMLAnchorElement = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'cashflows.csv');
        document.body.appendChild(link);
        link.click();
    }
}

angular
    .module('fundersclub.strategy-simulator')
    .component('fcSimResults', new FcSimResults());
