class FcAllInvestmentsViewInvestmentModal implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        modalInstance: '<',
        resolve: '<',
    };
    public controller = FcAllInvestmentsViewInvestmentModalController;
    public templateUrl = 'users/all-investments-view-investment-modal.component.html';
}

class FcAllInvestmentsViewInvestmentModalController implements ng.IComponentController {
    public modalInstance: ng.ui.bootstrap.IModalServiceInstance;
    public resolve: {
        investment_details_url: any,
    };

    private historyChartConfig: any;
    private investment: any;
    private loading: boolean = true;
    private showChart: boolean = false;

    constructor(
        private $http: ng.IHttpService,
        private $timeout: ng.ITimeoutService
    ) {
    }

    public $onInit(): void {
        this.$http.get(this.resolve.investment_details_url).then((response: any) => {
            this.investment = response.data;

            if (this.investment.history_graph_data) {
                this.historyChartConfig = {
                    chart: {
                        marginRight: 0,
                        panKey: 'shift',
                        panning: false,
                        spacingRight: 0,
                        zoomType: 'x',
                    },
                    plotOptions: {
                        series: {
                            marker: {
                                enabled: false,
                            },
                            step: 'left',
                        },
                    },
                    series: [
                        {
                            color: '#2E7EC5',
                            lineWidth: 2,
                            marker: {
                                enabled: false,
                            },
                            name: 'Invested',
                            states: {
                                hover: {
                                    enabled: true,
                                    lineWidthPlus: 0,
                                },
                            },
                            zIndex: 2,
                        },
                        {
                            color: '#2DCD8A',
                            data: this.investment.history_graph_data,
                            fillOpacity: 0.2,
                            marker: {
                                enabled: false,
                            },
                            name: 'Estimated Value',
                            states: {
                                hover: {
                                    enabled: true,
                                    lineWidthPlus: 0,
                                },
                            },
                            type: 'area',
                            zIndex: 1,
                        },
                        {
                            color: '#1D5A40',
                            data: this.investment.distributions_graph_data,
                            fillOpacity: 0.2,
                            marker: {
                                enabled: false,
                            },
                            name: 'Distributions',
                            showInLegend: this.investment.distributions_graph_data.length > 0,
                            type: 'area',
                            visible: this.investment.distributions_graph_data.length > 0,
                            zIndex: 3,
                        },
                    ],
                    title: {
                        text: null,
                    },
                    tooltip: {
                        backgroundColor: 'rgba(255,255,255,0.95)',
                        borderColor: '#CED7DF',
                        borderRadius: 5,
                        borderWidth: 1,
                        crosshairs: true,
                        headerFormat: '<span style="font-size: 10px; line-height: 20px;">{point.key}</span><br/>',
                        padding: 15,
                        shadow: false,
                        shared: true,
                        valueDecimals: 0,
                        valuePrefix: '$',
                        xDateFormat: '%b %e, %Y',
                        zIndex: 10,
                    },
                    xAxis: {
                        crosshair: {
                            color: '#E4EAEE',
                        },
                        endOnTick: false,
                        gridLineColor: '#E4EAEE',
                        lineColor: '#E4EAEE',
                        maxPadding: 0,
                        minPadding: 0,
                        startOnTick: false,
                        tickColor: '#E4EAEE',
                        title: {
                            text: null,
                        },
                        type: 'datetime',
                        useHtml: true,
                    },
                    yAxis: {
                        gridLineColor: '#E4EAEE',
                        labels: {
                            formatter: function() {
                                let value = this.axis.defaultLabelFormatter.call(this);
                                if (/^[0-9]{4}$/.test(value)) {
                                    value = Highcharts.numberFormat(this.value, 0);
                                }
                                return '$' + value;
                            },
                            style: {
                                color: '#333B61',
                            },
                        },
                        lineColor: '#E4EAEE',
                        min: 0,
                        minRange: this.investment.invested,
                        plotLines: [
                            {
                                color: '#2E7EC5',
                                value: this.investment.invested,
                                width: 2,
                                zIndex: 3,
                            },
                        ],
                        tickColor: '#E4EAEE',
                        title: {
                            text: null,
                        },
                    },
                };
            }

            this.loading = false;
        });
    }

    /* tslint:disable:no-unused-variable */
    private onHistoryTabSelected() {
        this.$timeout(() => {
            this.historyChartConfig.getChartObj().reflow();
            this.showChart = true;
        }, 0);
    }

    /* tslint:disable:no-unused-variable */
    private resetExpandedOutgoingInvestments() {
        this.investment.outgoing_investments_grouped_by_company.forEach((oi: any) => {
            oi.expanded = false;
        });
    }
}

angular
    .module('fundersclub.users')
    .component('fcAllInvestmentsViewInvestmentModal', new FcAllInvestmentsViewInvestmentModal());
