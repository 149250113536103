let fcIcpSelectRefundAccount = () => {
    let thisScope = {
        'investment': '=investment',
    };
    let thisController = function($state: any, $stateParams: any, FCChoices: any) {

        let vm = this;
        vm.confirmingCharge = false;
        vm.choices = FCChoices;
        vm.wireAccount = {};
        console.log('xxxx', vm.investment);

        vm.submit = function() {
            vm.submitting = true;
            vm.investment.selectWireRefundAccount(vm.wireAccount.id).then((response: any) => {
                vm.investment.refund_wire_account = response.data;
                $state.go('icp');
            });
        };
    };
    let thisDirective = {
        bindToController: true,
        controller: thisController,
        controllerAs: 'srac',
        restrict: 'E',
        scope: thisScope,
        templateUrl: 'icp/icp-select-refund-account.directive.html',
    };
    return thisDirective;
};
angular
    .module('fundersclub.icp')
    .directive('fcIcpSelectRefundAccount', fcIcpSelectRefundAccount);
