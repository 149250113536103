do ->
    SignupController = (SignupLead, SignupData, $window) ->
        vm = @
        vm.directive = null
        vm.signupLead = new SignupLead(SignupData.lead)
        vm.step = vm.signupLead.current_step
        vm.finalizing = false

        vm.save = ->
            if not vm.directive?.validate? then return

            # Show error messages if not ready to save.
            if not vm.directive.validate()
                vm.directive.showErrorMessages = true

            # Otherwise, advance form and save data.
            else
                vm.directive.showErrorMessages = false
                # In order to optimize the speed and feel of the signup flow,
                # backend validation is not taken into account until the final
                # step
                if vm.step < 4
                    vm.signupLead.$save vm.directive.directiveFields
                    vm.step++
                else
                    vm.finalizing = true
                    vm.signupLead.finalize()
                        .then (response) ->
                            # Account created! Go to page specified by the server.
                            $window.location = response.data['next']
                        .catch (response) ->
                            for field, error of response.data['errors']
                                vm.form[field]?.$setValidity(field, false)
                                vm.directive.errors[field] = error
                            vm.directive.showErrorMessages = true
                            vm.finalizing = false

        vm.back = ->
            if vm.step == 0
                $window.history.back()
            else
                vm.step--

        vm.register = (directive) ->
            vm.directive = directive
            vm.form

        # Expose the directive's highlight function if available.
        vm.highlightContinueButton = ->
            if not vm.directive?.highlightContinueButton then return false
            return vm.directive.highlightContinueButton()

        return


    angular
        .module 'fundersclub.signup'
        .controller 'SignupController', SignupController
