class FcSimGraph implements ng.IComponentOptions {
    public bindings: { [binding: string]: string } = {
        cashflows: '<',
    };
    public controller = FcSimGraphController;
    public templateUrl = 'strategy-simulator/sim-graph.component.html';
}

class FcSimGraphController implements ng.IComponentController {
    public cashflows: Array<any> = [];
    public visibility: any = {
        companies: false,
        funds: true,
        legend: false,
        total: true,
    };
    private highchartsConfig: any;

    public $onChanges(changes: any) {
        if (changes.cashflows) {
            this.cashflows = changes.cashflows.currentValue;
            this.loadChart();
        }
    }

    public loadChart() {

        let allSeries: Array<any> = [];
        let allCompanies: any = {};
        let allFunds: any = {};

        let startDate = new Date(this.cashflows[0].date);
        let endDate = new Date(this.cashflows[this.cashflows.length - 1].date);

        if (this.cashflows[this.cashflows.length - 1].date === 'None') {
            // Look for the last cashflow with a date
            for (let i = this.cashflows.length - 1; i >= 0; i--) {
                if (this.cashflows[i].date !== 'None') {
                    endDate = new Date(this.cashflows[i].date);
                    break;
                }
            }
        }

        // Set end date to 5% after the last cashflow to make the chart look better
        endDate.setTime(endDate.getTime() +  (endDate.getTime() - startDate.getTime()) * 0.05);

        // ================== COMPANY / FUND CASHFLOWS ==================
        // Group cashflows by company and fund
        for (let i = 0; i < this.cashflows.length; i++) {
            let cashflow = this.cashflows[i];
            let company = cashflow.company_name;
            let fund = cashflow.fund_name;

            if (!allCompanies[company]) {
                allCompanies[company] = [];
            }

            if (!allFunds[fund]) {
                allFunds[fund] = [];
            }

            allCompanies[company].push([cashflow.date, cashflow.value]);
            allFunds[fund].push([cashflow.date, cashflow.value]);
        }

        // Create series for each company in the cashflows
        for (const company of Object.keys(allCompanies)) {
            allSeries.push({
                data: this.cumulativeTimeSeries(allCompanies[company], startDate, endDate),
                lineWidth: 1,
                name: company,
                opacity: 0.7,
                visible: this.visibility.companies,
            });
        }

        // Create series for each fund in the cashflows
        for (const fund of Object.keys(allFunds)) {
            allSeries.push({
                data: this.cumulativeTimeSeries(allFunds[fund], startDate, endDate),
                lineWidth: 3,
                name: fund,
                opacity: 0.6,
                visible: this.visibility.funds,
            });
        }

        // Create series for the total cashflows
        allSeries.push({
            data: this.cumulativeTimeSeries(this.cashflows.map((cf) => [cf.date, cf.value]), startDate, endDate),
            lineWidth: 5,
            name: 'Total',
            visible: this.visibility.total,
        });

        this.highchartsConfig = {
            chart: {
                marginRight: 0,
                panKey: 'shift',
                panning: true,
                spacingRight: 0,
                zoomType: 'x',
            },
            legend: {
                enabled: this.visibility.legend,
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false,
                    },
                    step: 'left',
                },
            },
            responsive: {
                rules: [{
                    chartOptions: {
                        chart: {
                            height: 550,
                        },
                    },
                    condition: {
                        maxWidth: 600,
                    },

                }],
            },
            series: allSeries,
            title: {
                text: null,
            },
            tooltip: {
                backgroundColor: 'rgba(255,255,255,0.95)',
                borderColor: '#CED7DF',
                borderRadius: 5,
                borderWidth: 1,
                crosshairs: true,
                headerFormat: '<span style="font-size: 10px; line-height: 20px;">{point.key}</span><br/>',
                padding: 15,
                shadow: false,
                // shared: true,
                valueDecimals: 0,
                valuePrefix: '$',
                xDateFormat: '%b %e, %Y',
            },
            xAxis: {
                crosshair: {
                    color: '#E4EAEE',
                },
                endOnTick: false,
                gridLineColor: '#E4EAEE',
                lineColor: '#E4EAEE',
                maxPadding: 0,
                minPadding: 0,
                startOnTick: false,
                tickColor: '#E4EAEE',
                title: {
                    text: null,
                },
                type: 'datetime',
                useHtml: true,
            },
            yAxis: {
                gridLineColor: '#E4EAEE',
                labels: {
                    formatter: function () {
                        let value = this.axis.defaultLabelFormatter.call(this);
                        if (/^[0-9]{4}$/.test(value)) {
                            value = Highcharts.numberFormat(this.value, 0);
                        }
                        return '$' + value;
                    },
                    style: {
                        color: '#333B61',
                    },
                },
                lineColor: '#E4EAEE',
                tickColor: '#E4EAEE',
                title: {
                    text: null,
                },
            },
        };
    }

    private cumulativeTimeSeries(series: Array<any>, d1: Date, d2?: Date): Array<any> {
        let stretchedSeries: Array<any> = [];
        let currentDate = new Date(d1.getTime());
        let currentTotal = 0;

        if (!d2) {
            d2 = new Date();
        }

        for (let i = 0; i < series.length; i++) {
            let cashflow = series[i];
            if (cashflow[0] === 'None') {
                continue;
            }
            let cashflowDate = new Date(cashflow[0]);
            cashflowDate.setHours(0, 0, 0, 0); // Reset time component of cashflowDate

            while (currentDate.getTime() < cashflowDate.getTime()) {
                stretchedSeries.push([currentDate.getTime(), currentTotal]);
                currentDate.setDate(currentDate.getDate() + 7);
            }
            currentTotal += cashflow[1];

            let lastElement = stretchedSeries[stretchedSeries.length - 1];

            if (lastElement && new Date(lastElement[0]).getTime() >= cashflowDate.getTime()) {
                lastElement[1] = currentTotal;
            } else {
                stretchedSeries.push([cashflowDate.getTime(), currentTotal]);
                currentDate.setDate(currentDate.getDate() + 7);
            }
        }

        while (currentDate < d2) {
            stretchedSeries.push([currentDate.getTime(), currentTotal]);
            currentDate.setDate(currentDate.getDate() + 7);
        }

        return stretchedSeries;
    }
}

angular
    .module('fundersclub.strategy-simulator')
    .component('fcSimGraph', new FcSimGraph());
