class FcIndividualIdentityVerification implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        embeddedInPage: '<',
        identityDocuments: '<',
        investorProfile: '<',
        onIdentityDocumentsUpdated: '&',
        onInvestorProfileUpdated: '&',
        onSaved: '&',
        onUserProfileUpdated: '&',
        userProfile: '<',
    };
    public controller = FcIndividualIdentityVerificationController;
    public templateUrl = 'users/individual-identity-verification.component.html';
}

class FcIndividualIdentityVerificationController implements ng.IComponentController {
    public identityDocuments: Array<any>;
    public investorProfile: IInvestorProfile;
    public onIdentityDocumentsUpdated: (data: {identityDocuments: Array<any>}) => void;
    public onInvestorProfileUpdated: (data: {investorProfile: any}) => void;
    public onSaved: () => void;
    public onUserProfileUpdated: (data: {userProfile: any}) => void;
    public userProfile: any;

    private errors = {};
    private fcSave: any;
    private form: any;
    private isDatePickerPopupOpen = false;
    private newDocumentErrors = {};

    constructor(
        private $state: any,
        private InvestorProfile: any,
        private moment: moment.MomentStatic
    ) {}

    public $onInit() {
        // Need to copy objects to get correct 1 way data binding
        this.identityDocuments = angular.copy(this.identityDocuments);
        this.investorProfile = angular.copy(this.investorProfile);
        this.userProfile = angular.copy(this.userProfile);
        this.userProfile.dob = this.moment(this.userProfile.dob).toDate();
    }

    /* tslint:disable:no-unused-variable */
    private onDocumentAdded() {
        this.form.$setDirty();
    }

    /* tslint:disable:no-unused-variable */
    private onDocumentRemoved() {
        this.form.$setDirty();
    }

    /* tslint:disable:no-unused-variable */
    private save() {
        this.errors = {};
        this.newDocumentErrors = {};

        const documentsToSave = this.identityDocuments.filter(
            (doc: any) => !doc.removed
        );
        const dob = this.moment(this.userProfile.dob).format('YYYY-MM-DD');

        this.investorProfile
            .verifyIdentity(
                documentsToSave,
                {
                    'citizenship_country': this.userProfile.citizenship_country || '',
                    'dob': dob,
                    'phone_number': this.investorProfile.phone_number || '',
                }
            )
            .then((response: any) => {
                angular.copy(documentsToSave, this.identityDocuments);
                this.onInvestorProfileUpdated({
                    investorProfile: new this.InvestorProfile(response.data),
                });
                this.onIdentityDocumentsUpdated({identityDocuments: this.identityDocuments});
                this.onUserProfileUpdated({userProfile: this.userProfile});
                this.onSaved();
            })
            .catch((response: any) => {
                if (response.data.includes('RequestDataTooBig')) {
                    this.errors = {
                        'identityDocuments': 'The total size of the uploaded documents is too large.',
                    };
                }
                angular.extend(this.errors, response.data);
                angular.extend(this.newDocumentErrors, response.data);
            }).finally(() => {
                this.fcSave.saveAttemptFinished(this.errors);
            });
    }

    /* tslint:disable:no-unused-variable */
    private openDatePicker() {
        this.isDatePickerPopupOpen = true;
    }
}

angular
    .module('fundersclub.users')
    .component('fcIndividualIdentityVerification', new FcIndividualIdentityVerification());
