do ->

    # List of updates for a given company.
    InvestorUpdatesListController = (InvestorUpdate, FCUpdatesData) ->
        vm = @
        vm.company = FCUpdatesData.company
        vm.acquired_companies = FCUpdatesData.acquired_companies || []
        vm.updates = (new InvestorUpdate(item) for item in FCUpdatesData.updates)
        vm.expanded_child_id = null

        # Remove specified child from list.
        vm.removeChild = (child_id) ->
            vm.expanded_child_id = null
            child_index = _.findIndex(vm.updates, (child) -> child.id == child_id)
            vm.updates.splice(child_index, 1)

        # New update
        vm.new_update = ->
            new_update = new InvestorUpdate
                company: vm.company.url
                timestamp: (new Date).toISOString()
                update_title: 'New update'

            new_update.$create()
                .then ->
                    # Expand new update immediately.
                    new_update.expand_after_render = true
                    vm.updates.unshift new_update

        # End InvestorUpdatesListController
        return


    angular
        .module 'fundersclub.companies'
        .controller 'InvestorUpdatesListController', InvestorUpdatesListController
