class FcInvestorProfileTaxBoundForm implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        errors: '<',
        hideTaxStatus: '<?',
        investorProfile: '=',
        investorProfileTaxDoc: '=',
        readonlyName: '<',
    };
    public controller = FcInvestorProfileTaxBoundFormController;
    public templateUrl = 'users/investor-profile-tax-bound-form.component.html';
}

class FcInvestorProfileTaxBoundFormController implements ng.IComponentController {
    public errors: {[error: string]: Array<any>} = {};
    public hideTaxStatus: boolean;
    public investorProfile: IInvestorProfile;
    public investorProfileTaxDoc: IInvestorProfileTaxDoc;
    public readonlyName: boolean;

    constructor(private FCChoices: any) {}
}

angular
    .module('fundersclub.users')
    .component('fcInvestorProfileTaxBoundForm', new FcInvestorProfileTaxBoundForm());
