interface IEarlyReservation extends ng.resource.IResource<IEarlyReservation> {
    fund_info: any;
    investment_amount: number;
    investor_profile: string;
}

let EarlyReservation = function(fcModel: ng.resource.IResourceService) {
    let EarlyReservationModel: IFcModelResource<IEarlyReservation> = fcModel(
        '/api/earlyreservations/:id/',
        { id: '@id' },
        {}
    );
    return EarlyReservationModel;
};

angular
    .module('fundersclub.models')
    .factory('EarlyReservation', EarlyReservation);
