do ->

    fcSignupInterest = (SignupData) ->
        thisScope =
            'signupLead': '=lead'
            'register': '&registerCallback'

        thisController = ->
            vm = @
            vm.form = vm.register({'directive': vm})
            vm.showErrorMessages = false
            vm.sectionToShow = ''
            vm.companiesFunded = SignupData.companiesFunded
            vm.numMembers = SignupData.numMembers

            vm.directiveFields = [
                'interest_diversify',
                'interest_engaged',
                'interest_insider_access',
                'interest_online_investing',
            ]

            # User does not have to fill out anything here.
            vm.validate = ->
                # To indicate user has interacted with the form,
                # set all "interest" fields to false if they're
                # undefined.
                vm.signupLead[field] = false for field in vm.directiveFields when vm.signupLead[field] is null
                return true

            # Although the user can always click continue, we want to teach
            # them that the continue button turns green when "ready". As such,
            # turn the button green after they've made their first selection.
            vm.highlightContinueButton = ->
                anySelected = false
                for field_name in vm.directiveFields
                    if vm.signupLead[field_name] == true
                        anySelected = true
                        break
                return anySelected

            vm.toggleSection = (fieldName) ->
                newValue = !vm.signupLead[fieldName]
                vm.signupLead[fieldName] = newValue

                # If newly selected field, highlight it.
                vm.sectionToShow = if newValue then fieldName else ''

            return

        thisDirective =
            bindToController: true
            controller: thisController
            controllerAs: 'isuc'
            replace: true
            restrict: 'E'
            scope: thisScope
            templateUrl: 'signup/steps/interest.directive.html'

        thisDirective


    angular
        .module 'fundersclub.signup'
        .directive 'fcSignupInterest', fcSignupInterest
