do ->

    Company = ($http, fcModel, transformFormRequestToFormData) ->
        Company = fcModel '/api/companies/:id/', {
            id: '@id'
        }, {
            partialupdate:
                method: 'PATCH'
                transformRequest: transformFormRequestToFormData
                headers:
                    'Content-type': undefined
        }

        Company.inviteEntrepreneur = (companySlug, founderData) ->
            $http.post("/companies/#{companySlug}/onboard-founder/", founderData)

        Company.prototype.isUsBasedOnCountry = ->
            return @country == 'US'

        Company.prototype.markFounderOnboarded = ->
            $http.post(@urls.mark_founder_onboarded)

        Company.prototype.updateMailingAddress = ->
            $http.post(@urls.update_mailing_address, {
                'city': @city,
                'company_phone_number': @company_phone_number,
                'country': @country,
                'state': @state,
                'street_address': @street_address,
                'street_address_2': @street_address_2,
                'zip_code': @zip_code,
            })

        Company.prototype.updateUpdatesSettings = ->
            $http.post(@urls.update_updates_settings, {
                'allow_full_updates': @allow_full_updates,
                'exclude_from_updates': @exclude_from_updates,
                'investor_updates_setting': @investor_updates_setting,
            })

        Company


    CompanyTeamMember = (fcModel, transformFormRequestToFormData) ->
        CompanyTeamMember = fcModel '/api/companyteammembers/:id/', {
            id: '@id'
        }, {
            create:
                method: 'POST'
                transformRequest: transformFormRequestToFormData
                headers:
                    'Content-type': undefined

            partialupdate:
                method: 'PATCH'
                transformRequest: transformFormRequestToFormData
                headers:
                    'Content-type': undefined

            update:
                method: 'PUT'
                transformRequest: transformFormRequestToFormData
                headers:
                    'Content-type': undefined
        }

        CompanyTeamMember


    CompanyNewsItem = (fcModel) ->
        CompanyNewsItem = fcModel '/api/companynewsitems/:id/',
            id: '@id'

        CompanyNewsItem


    CompanyAssetTracking = (fcModel, $http) ->
        CompanyAssetTracking = fcModel '/api/companyassettracking/:id/',
            id: '@id'


        CompanyAssetTracking.prototype.getCorpActions = ->
            $http.get(@urls.get_corporate_actions)

        CompanyAssetTracking.prototype.corpActionsIsUserActionNeeded = ->
            return not @corp_actions_reviewed

        CompanyAssetTracking


    CompanySavedContact = (fcModel, $http) ->
        CompanySavedContact = fcModel '/api/companies/:id/list_contacts/', {
            id: '@company'
        }, {
            create:
                method: 'POST'
                url: '/api/companies/:id/add_contact/'
            delete:
                method: 'POST'
                url: '/api/companies/:id/remove_contact/'
        }

        CompanySavedContact.listContacts = (companyID) ->
            $http.get "/api/companies/#{companyID}/list_contacts"

        CompanySavedContact


    InvestorUpdate = (fcModel, $http) ->
        InvestorUpdate = fcModel '/api/investorupdates/:id/',
            id: '@id'

        InvestorUpdate.prototype.mark_read = ->
            $http.post "#{@company}mark_update_read/", {
                update_id: @id,
            }

        InvestorUpdate


    InvestorUpdateFile = (fcModel, transformFormRequestToFormData) ->
        InvestorUpdateFile = fcModel '/api/investorupdatefiles/:id/', {
            id: '@id'
        }, {
            create:
                method: 'POST'
                transformRequest: transformFormRequestToFormData
                headers:
                    'Content-type': undefined

            update:
                method: 'PUT'
                transformRequest: transformFormRequestToFormData
                headers:
                    'Content-type': undefined
        }

        InvestorUpdateFile


    CompanyOwner = (fcModel) ->
        CompanyOwner = fcModel '/api/companies/:company_id/owners/',
            company_id: '@company_id'

        CompanyOwner


    CorporateAction = (fcModel) ->
        CorporateAction = fcModel '/api/corporateactions/:id/', {
            id: '@id'
        }

        CorporateAction.prototype.is_investment_round = ->
            @label in @investment_round_labels

        CorporateAction.prototype.is_liquidation_event = ->
            @label in @liquidation_event_labels

        CorporateAction.prototype.is_debt_round = ->
            @security_type in @debt_round_security_types

        CorporateAction.prototype.is_equity_round = ->
            @security_type in @equity_round_security_types

        CorporateAction


    KeyPerformanceIndicator = (fcModel) ->
        KeyPerformanceIndicator = fcModel '/api/keyperformanceindicators/:id/', {
            id: '@id'
        }

        KeyPerformanceIndicator


    NetworkSearch = (fcModel) ->
        NetworkSearch = fcModel '/api/searchsocialgraph/', {}, {
            get:
                transformResponse: (data, headersGetter) ->
                    {'results': angular.fromJson(data)}
        }

        NetworkSearch.prototype.$search = ->
            NetworkSearch.get({
                'funds': @funds
                'page': @page
                'q': @q
                'scopes': @getScopes()
            })

        NetworkSearch.prototype.getScopes = ->
            scopes = []
            for scope, active of @scopes
                if active
                    scopes.push(scope)
            scopes.join(',')

        NetworkSearch

    sharedMethods =
        'baseDescription': ->
            if @isStaff()
                return "Staff"
            else if @isFounder()
                return "Founder"
            else if @isInvestor()
                return "Backed your company"
            else if @isMember()
                return "FundersClub Member"

        'fullName': ->
            "#{@first_name} #{@last_name}"

        'getIntroText': (sender) ->
            if sender.founder_role
                introText = "I am the #{sender.founder_role} at #{sender.founder_company}"
            else if sender.founder_company
                introText = "I'm at #{sender.founder_company}"
            else
                introText = "I'm at FundersClub"

        'isNetwork': ->
            return @result_type == 'Network'

        'isFounder': ->
            return @result_type == 'Founder'

        'isInvestor': ->
            return @result_type == 'Investor'

        'isMember': ->
            return @result_type == 'Member'

        'isStaff': ->
            return @result_type == 'Staff'

        'getPositionDisplay': (position) ->
            if not position
                return ''
            return "#{position.title}, #{position.company}"


    NetworkSearchResult = (fcModel) ->
        NetworkSearchResult = fcModel '', {}, {}

        NetworkSearchResult.prototype.defaultMessage = (sender) ->
            introText = @getIntroText(sender)
            if sender.is_staff
                return """
                    Hi #{@first_name},

                    My name is #{sender.first_name}.  I am a #{sender.title} at FundersClub (https://www.fundersclub.com). I found your profile through our Network Search tool and would love to get your help.

                    ## Your ask ##

                    Best,
                    #{sender.first_name}
                """
            else if @isStaff() or (@isInvestor() and @user.only_single_asset_investor)
                return """
                    #{@first_name},

                    About #{sender.company_name}: #{sender.company_description}.

                    ### Your ask ###

                    Best,
                    #{sender.first_name}
                """
            else if @isInvestor() and not @user.only_single_asset_investor
                return """
                    #{@first_name},

                    #{introText}.  You invested in our company through FundersClub's #{@user.multi_asset_fund_funded_name} fund.

                    About #{sender.company_name}: #{sender.company_description}.

                    ### Your ask ###

                    Best,
                    #{sender.first_name}
                """
            else
                return """
                    #{@first_name},

                    #{introText}#{if sender.founder_company then ', a company funded by FundersClub' else ''}.

                    About #{sender.company_name}: #{sender.company_description}.

                    ### Your ask ###

                    Best,
                    #{sender.first_name}
                """

        _.extend(NetworkSearchResult.prototype, sharedMethods)

        NetworkSearchResult


    NetworkSearchResultConnection = (fcModel) ->
        NetworkSearchResultConnection = fcModel '', {}, {}

        NetworkSearchResultConnection.prototype.defaultMessage = (sender, result) ->
            introText = @getIntroText(sender)
            if sender.is_staff
                return """
                    Hi #{@first_name},

                    My name is #{sender.first_name}.  I am a #{sender.title} at FundersClub (https://www.fundersclub.com).  I was using the FundersClub Network Search tool and saw you were connected to #{result.first_name} #{result.last_name} (#{result.linkedin_profile_url}). Can you make an introduction?

                    ## Your ask ##

                    Best,
                    #{sender.first_name}
                """
            else if @isStaff()
                return """
                    Hi #{@first_name},

                    My name is #{sender.first_name}. #{introText}#{if sender.founder_company then ', a FundersClub funded company' else ''}.  I was using the FundersClub Network Search tool and saw you were connected to #{result.first_name} #{result.last_name} (#{result.linkedin_profile_url}). Can you make an introduction?

                    Best,
                    #{sender.first_name}
                """
            else if @isInvestor()
                return """
                    Hi #{@first_name},

                    My name is #{sender.first_name}. #{introText}#{if sender.founder_company then ', a FundersClub funded company' else ''}.  I was using the FundersClub Network Search tool and saw you were connected to #{result.first_name} #{result.last_name} (#{result.linkedin_profile_url}).  As an investor in our company, can you make an introduction?

                    Best,
                    #{sender.first_name}
                """
            else
                return """
                    Hi #{@first_name},

                    My name is #{sender.first_name}. #{introText}#{if sender.founder_company then ', a FundersClub funded company' else ''}.  I was using the FundersClub Network Search tool and saw you were connected to #{result.first_name} #{result.last_name} (#{result.linkedin_profile_url}). Can you make an introduction?

                    Best,
                    #{sender.first_name}
                """

        _.extend(NetworkSearchResultConnection.prototype, sharedMethods)

        NetworkSearchResultConnection


    # Get module and add factories.
    angular
        .module 'fundersclub.models'
        .factory 'Company', Company
        .factory 'CompanyNewsItem', CompanyNewsItem
        .factory 'CompanyOwner', CompanyOwner
        .factory 'CompanySavedContact', CompanySavedContact
        .factory 'CompanyTeamMember', CompanyTeamMember
        .factory 'CompanyAssetTracking', CompanyAssetTracking
        .factory 'CorporateAction', CorporateAction
        .factory 'InvestorUpdate', InvestorUpdate
        .factory 'InvestorUpdateFile', InvestorUpdateFile
        .factory 'KeyPerformanceIndicator', KeyPerformanceIndicator
        .factory 'NetworkSearch', NetworkSearch
        .factory 'NetworkSearchResult', NetworkSearchResult
        .factory 'NetworkSearchResultConnection', NetworkSearchResultConnection
