do ->

    # Converts a given request body to be form encoded.
    transformRequestAsFormPost = ->
        serializeData = (data) ->
            if not angular.isObject data
                return data.toString()

            buffer = []
            for own name, value of data
                buffer.push(encodeURIComponent(name) + '=' + encodeURIComponent(if value then value else ''))
            buffer.join('&').replace(/%20/g, '+')

        transformRequest = (data, getHeaders) ->
            serializeData(data)

        transformRequest


    # Converts a given form request to FormData
    # (needed for file uploads)
    transformFormRequestToFormData = ->
        transformRequest = (data, options) ->
            if not data
                return data

            json_fields = options?.json_fields || []
            array_fields = options?.array_fields || []

            fd = new FormData()
            angular.forEach(data, (value, key) ->
                if (value instanceof FileList)
                    if (value.length == 1)
                        fd.append(key, value[0])
                    else
                        angular.forEach(value, (file, index) ->
                            fd.append(key + '_' + index, file)
                        )
                else if key in array_fields
                    angular.forEach(value, (item, index) ->
                        fd.append(key, item)
                    )
                else
                    if key in json_fields
                        value = angular.toJson(value)
                    fd.append(key, value)
            )
            return fd

        transformRequest


    apiUserIdHttpRequestInterceptor = ($injector, $window) ->
        return {
            request: (config) ->
                # Only add our header for requests going to our origin
                if config.url[0] == '/' or config.url.indexOf("/#{$window.location.host}/") != -1
                    try
                        FCGlobals = $injector.get('FCGlobals')
                        if FCGlobals.OVERRIDE_API_USER_ID
                            config.headers['Fc-Override-Api-User-Id'] = FCGlobals.OVERRIDE_API_USER_ID
                    catch
                        # ignore

                config
        }

    # Setup module.
    angular
        .module 'fundersclub.api'
        .factory 'transformRequestAsFormPost', transformRequestAsFormPost
        .factory 'transformFormRequestToFormData', transformFormRequestToFormData
        .factory 'apiUserIdHttpRequestInterceptor', apiUserIdHttpRequestInterceptor
