do ->

    # Form responsible for creating a new priced round.
    fcNewPricedRoundForm = (FCManageInvestmentsData, Company, Partnership) ->
        thisScope =
            'closeWithCancel': '&onCancel'
            'closeWithSave': '&onSave'
            'companyId': '@'
            'partnershipId': '@'

        thisController = ->
            vm = @
            vm.errors = {}
            vm.dataLoaded = false
            vm.choices = FCManageInvestmentsData.choices
            vm.partnership = new Partnership({id: vm.partnershipId})
            vm.selectedAssets = {}

            # Do API call to load required form data.
            vm.partnership.$get().then ->
                vm.partnership.new_priced_round_details_for_company(vm.companyId)
                    .then (response) ->
                        vm.bindCompanyData(response.data)
                    .catch (response) ->
                        # It's hard to know if there's something better to do here...
                        alert 'This form failed to load. Please refresh the page and try again'
                        console.log response

            vm.saveForm = ->
                params =
                    target_assets: (asset_id for asset_id, selected of vm.selectedAssets when selected)
                    effective_date: vm.company.effective_date
                    target_corporate_action: vm.company.target_corporate_action
                    price_per_share: vm.company.price_per_share
                    currency: vm.company.currency
                vm.partnership.execute_new_priced_round(params)
                    .then (e) ->
                        vm.closeWithSave()
                    .catch (response) ->
                        angular.extend(vm.errors, response.data)
                    .finally ->
                        vm.fcSave.saveAttemptFinished(vm.errors)

            vm.refreshCorporateActions = ->
                vm.partnership.new_priced_round_details_for_company(vm.companyId)
                    .then (response) ->
                        vm.bindCompanyData(response.data)
                    .catch (response) ->
                        alert 'Failed to refresh list.'
                        console.log response

            vm.bindCompanyData = (data) ->
                vm.company = data
                vm.dataLoaded = true

                # Select the first corp action if there's only one.
                if vm.company.corporate_actions.length == 1
                    vm.company.target_corporate_action = vm.company.corporate_actions[0].id

            vm.cancelForm = ->
                vm.closeWithCancel()

            vm.assetHasActiveMarkdown = (asset) ->
                if not @company.effective_date
                    return null

                effective_date = moment(@company.effective_date, 'YYYY-MM-DD')
                for val_event in asset.valuation_events
                    if val_event.event_type != 'internal-markdown' and val_event.event_type != 'external-markdown'
                        continue

                    val_event_start = moment(val_event.effective_date, 'YYYY-MM-DD')
                    if val_event_start.isSameOrBefore(effective_date)
                        if not val_event.no_longer_effective_date
                            return true

                        val_event_end = moment(val_event.no_longer_effective_date, 'YYYY-MM-DD')
                        if effective_date.isBefore(val_event_end)
                            return true

                return false

            return

        thisDirective =
            templateUrl: 'investment-assets/priced-round-add.directive.html'
            restrict: 'A'
            scope: thisScope
            bindToController: true
            controllerAs: 'fnprf'
            controller: thisController
        return thisDirective


    angular
        .module 'fundersclub.assets'
        .directive 'fcNewPricedRoundForm', fcNewPricedRoundForm
