# textAngular uses $sanitize internally to scrub its contents as you type them in.  However
# they do not support iframes, and there is no way to allow this (no optional allowed tags).
# We shouldn't rely on client side scrubbing for this sort of stuff anyway, so we simply provide
# a noop version of $sanitize for textAngular to use so that iframes are correctly shown/saved.
do ->

    $fakeSanitizeProvider = ->
        vm = @
        vm.$get = ->
            (html) ->
                html

        # End $fakeSanitizeProvider
        return

    angular
        .module 'ngSanitize', []
        .provider '$sanitize', $fakeSanitizeProvider
