class FcInvestorProfileComplete implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        investorProfile: '<',
        userProfile: '<',
    };
    public controller = FcInvestInvestorProfileComplete;
    public templateUrl = 'users/investor-profile-complete.component.html';
}

class FcInvestInvestorProfileComplete implements ng.IComponentController {
    public investorProfile: IInvestorProfile;
    public userProfile: any;

    private identityDocumentModal: any;
    private prepareTaxDocsModal: any;
    private taxDocs: any;

    constructor(
        private _: UnderscoreStatic,
        private $interval: any,
        private $uibModal: ng.ui.bootstrap.IModalService,
        private InvestorProfile: any,
        private SignableLegalDocument: any,
        private UserProfile: IUserProfileClass,
        private FCChoices: any
    ) {}

    public $onInit() {
        this.investorProfile = angular.copy(this.investorProfile);
        this.userProfile = angular.copy(this.userProfile);
        if (this.investorProfile.tax_docs) {
            this.investorProfile.tax_docs.pollingInterval = 10;
        }
        this.taxDocs = new this.SignableLegalDocument(this.investorProfile.tax_docs);
        this.taxDocs.pollForUpdates();
    }

    /* tslint:disable:no-unused-variable */
    private edit() {
        this.$uibModal
            .open({
                animation: false,
                backdrop: false,
                component: this.taxDocs.user_has_signed ?
                    'FcInvestorProfileResignTaxFormModal' :
                    'FcInvestorProfileEditModal',
                resolve: {
                    investorProfile: () => this.investorProfile,
                    onUpdated: () => this.onInvestorProfileUpdated.bind(this),
                    updateReason: () => this.FCChoices.UpdateReason.UserEditProfile,
                },
                windowClass: 'FullTakeoverModal',
            });
    }

    /* tslint:disable:no-unused-variable */
    private identityTypeDisplay() {
        if (this.investorProfile.is_individual) {
            return 'identity';
        } else if (this.investorProfile.is_entity) {
            return 'entity';
        }
    }

    private onIdentityVerifcationSaved() {
        this.identityDocumentModal.close();
    }

    private onInvestorProfileUpdated(investorProfile: IInvestorProfile) {
        this.investorProfile = investorProfile;
    }

    /* tslint:disable:no-unused-variable */
    private onResetTaxDocs() {
        return this.investorProfile.abandonTaxDocs().then(() => {
            this.taxDocs.id = null;
            this.taxDocs.status = null;
            this.taxDocs.signing_url = null;
        });
    }

    private onTaxDocGenerated(taxDoc: any) {
        this.taxDocs = taxDoc;
        this.taxDocs.pollForUpdates();
        this.prepareTaxDocsModal.close();
        this.signTaxDocs();
    }

    private onUserProfileUpdated(userProfile: any) {
        this.userProfile = userProfile;
    }

    /* tslint:disable:no-unused-variable */
    private prepareTaxDocs() {
        this.prepareTaxDocsModal = this.$uibModal
            .open({
                animation: false,
                backdrop: false,
                component: 'FcPrepareTaxDocsModal',
                resolve: {
                    investorProfile: () => this.investorProfile,
                    onTaxDocGenerated: () => this.onTaxDocGenerated.bind(this),
                },
                windowClass: 'FullTakeoverModal',
            });
    }

    private signTaxDocs() {
        this.$uibModal
            .open({
                animation: false,
                backdrop: false,
                component: 'FcSignLegaldocInterstitialModal',
                resolve: {
                    docName: () => 'Tax Document',
                    legalDoc: () => this.taxDocs,
                    onResetForm: () => this.onResetTaxDocs.bind(this),
                },
                windowClass: 'FullTakeoverModal',
            });
    }

    /* tslint:disable:no-unused-variable */
    private fillEntityAccreditation() {
        this.$uibModal
            .open({
                animation: false,
                backdrop: false,
                component: 'FcFillEntityAccreditationModal',
                resolve: {
                    investorProfile: () => this.investorProfile,
                    onEntityAccreditationQuestionnaireSubmitted: () => () => {
                        this.investorProfile.accreditation_questionnaire_completed = true;
                    },
                },
                windowClass: 'FullTakeoverModal',
            });
    }

    /* tslint:disable:no-unused-variable */
    private verifyIdentity() {
        let resolve: any;

        if (this.investorProfile.is_entity) {
            resolve = {
                identityDocuments: () => this.investorProfile.identity_documents,
                investorProfile: () => this.investorProfile,
                onIdentityDocumentsUpdated: () => angular.noop,
                onInvestorProfileUpdated: () => this.onInvestorProfileUpdated.bind(this),
                onSaved: () => this.onIdentityVerifcationSaved.bind(this),
            };
        } else {
            resolve = {
                identityDocuments: () => this.investorProfile.identity_documents,
                investorProfile: () => this.investorProfile,
                onIdentityDocumentsUpdated: () => angular.noop,
                onInvestorProfileUpdated: () => this.onInvestorProfileUpdated.bind(this),
                onSaved: () => this.onIdentityVerifcationSaved.bind(this),
                onUserProfileUpdated: () => this.onUserProfileUpdated.bind(this),
                userProfile: () => this.userProfile,
            };
        }

        this.identityDocumentModal = this.$uibModal
            .open({
                animation: false,
                backdrop: false,
                component: 'FcIdentityVerificationModal',
                resolve: resolve,
                windowClass: 'FullTakeoverModal',
            });
    }
}

angular
    .module('fundersclub.users')
    .component('fcInvestorProfileComplete', new FcInvestorProfileComplete());
