class FcPrepareTaxDocs implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        investorProfile: '<',
        onTaxDocGenerated: '&',
    };
    public controller = FcPrepareTaxDocsController;
    public templateUrl = 'legaldocs/prepare-tax-docs.component.html';
}

class FcPrepareTaxDocsController implements ng.IComponentController {
    public investorProfile: IInvestorProfile;
    public onTaxDocGenerated: (data: {taxDoc: any}) => void;

    /* tslint:disable:no-unused-variable */
    private fcSave: any;

    constructor(private SignableLegalDocument: any) {}

    public $onInit() {
        // Need to copy objects to get correct 1 way data binding
        this.investorProfile = angular.copy(this.investorProfile);
    }

    /* tslint:disable:no-unused-variable */
    private onInvestorProfileSaved() {
        this.investorProfile.generateTaxDocs().then((response: any) => {
            this.onTaxDocGenerated({taxDoc: new this.SignableLegalDocument(response.data)});
        });
    };
}

angular
.module('fundersclub.legaldocs')
    .component('fcPrepareTaxDocs', new FcPrepareTaxDocs());
