do ->

    PartnershipInvestmentHighlightsController = (FCInvestmentHighlightsData, Partnership) ->
        vm = @

        vm.partnership = new Partnership(FCInvestmentHighlightsData.partnership)
        vm.investmentHighlights = FCInvestmentHighlightsData.investment_highlights

        vm.dragControlListeners =
            orderChanged: (event) -> vm.form.$setDirty()

        vm.save = ->
            companiesOnInvestmentHighlights = []
            companiesNotOnInvestmentHighlights = []
            for company in vm.investmentHighlights
                if company.selected
                    companiesOnInvestmentHighlights.push company
                else
                    companiesNotOnInvestmentHighlights.push company

            companiesNotOnInvestmentHighlights.sort (company1, company2) ->
                company1.company_name.toUpperCase().localeCompare(company2.company_name.toUpperCase())
            vm.partnership.companies_on_investment_highlights = companiesOnInvestmentHighlights
                .map((company) -> company.id)
                .toString()

            # The partnership model gets form encoded.  For a native javascript array, this converts the
            # array to a comma separated string.  Aka [1,2] -> "1,2".  However we are storing this as a JSON
            # field on the server side which expects an array looking string (eg '[1,2]'), so just manually
            # adding back in the brackets
            vm.partnership.companies_on_investment_highlights = (
                "[#{vm.partnership.companies_on_investment_highlights}]"
            )
            vm.partnership
                .$save(['companies_on_investment_highlights'])
                .then (response) ->
                    vm.form.$setPristine()
                    vm.investmentHighlights = companiesOnInvestmentHighlights
                        .concat(companiesNotOnInvestmentHighlights)

        return

    angular
        .module 'fundersclub.partnerships'
        .controller 'PartnershipInvestmentHighlightsController', PartnershipInvestmentHighlightsController
