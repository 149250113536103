do ->

    # Form responsible for displaying and editing fund screenshots.
    fcFundScreenshotEdit = ->
        thisScope =
            'screenshot': '=?screenshot'
            'edit_form_enabled': '=editFormEnabled'
            'createdCallback': '&onCreate'
            'expandCallback': '&onExpand'
            'collapseCallback': '&onCollapse'
            'deleteCallback': '&onDelete'

        thisController = ($http, $scope, FCGlobals, FCFundData, FundScreenshot) ->
            vm = @
            vm.errors = {}
            vm.edit_form_expanded = false
            vm.media_url = FCGlobals.MEDIA_URL

            screenshot_template =
                fund_profile: FCFundData.fund_profile.url
                caption: ''
                picture: null
            if not vm.screenshot
                vm.screenshot = new FundScreenshot(angular.copy(screenshot_template))
            vm.initial_screenshot_data = angular.copy(vm.screenshot)

            if vm.screenshot.picture
                vm.picture_display = vm.media_url + vm.screenshot.picture
            else
                vm.picture_display = ''

            # Expand the editing form for this fund screenshot.
            vm.expandEditForm = (expand_form) ->
                currently_expanded = vm.edit_form_expanded
                becoming_expanded = if typeof expand_form is 'boolean' then expand_form else !currently_expanded

                # Don't allow user to collapse form unless they've dealt with their
                # changes (e.g. Save or Cancel).
                if not becoming_expanded and not vm.form.$pristine then return

                # If a expansion callback was provided, fire it off.
                vm.edit_form_expanded = becoming_expanded
                if becoming_expanded then vm.expandCallback() else vm.collapseCallback()

            # Handle image upload
            vm.image_uploaded = ($files) ->
                if not $files or not $files.length then return
                file = $files[0]
                if not file or not file.size then return

                vm.errors.picture = null
                vm.picture_display = ''

                fileReader = new FileReader
                fileReader.onload = ->
                    $scope.$apply ->
                        vm.picture_display = fileReader.result

                fileReader.readAsDataURL(file)
                vm.screenshot.picture = file
                vm.form.$setDirty()

            # Save
            vm.save = ->
                if (
                    vm.form.$invalid or
                    not vm.screenshot.picture
                )
                    vm.form.$setDirty()
                    vm.hasError = true
                    return false

                adding = not vm.screenshot.id
                vm.errors = {}
                vm.screenshot.$save()
                    # unsaved-warning-form monitors the form pristine/dirty state
                    .then (response) ->
                        if adding
                            vm.createdCallback({'item': response})
                            vm.screenshot = new FundScreenshot(angular.copy(screenshot_template))
                            vm.picture_display = ''
                            vm.expandEditForm(false)
                        else
                            angular.copy(response, vm.initial_screenshot_data)
                    .catch (response) ->
                        angular.extend(vm.errors, response.data)
                    .finally () ->
                        vm.fcSave.saveAttemptFinished(vm.errors)

            # Cancel
            vm.cancel = ->
                # Reset form data.
                angular.copy(vm.initial_screenshot_data, vm.screenshot)
                if vm.screenshot.picture
                    vm.picture_display = vm.media_url + vm.screenshot.picture
                vm.form.$setPristine()
                vm.errors = {}
                vm.expandEditForm(false)

            # Delete
            vm.deleteFundScreenshot = ->
                remove = confirm('Are you sure you want to delete this screenshot?')
                if not remove
                    return

                vm.screenshot.$delete()
                    .then ->
                        vm.deleteCallback()

            return

        thisDirective =
            templateUrl: 'funds/screenshot-edit.directive.html'
            restrict: 'A'
            require: '^form'
            scope: thisScope
            bindToController: true
            controllerAs: 'efsc'
            controller: thisController
            replace: true
        return thisDirective

    angular
        .module 'fundersclub.funds'
        .directive 'fcFundScreenshotEdit', fcFundScreenshotEdit
