class FcPrepareTaxDocsModal implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        modalInstance: '<',
        resolve: '<',
    };
    public controller = FcPrepareTaxDocsModalController;
    public templateUrl = 'legaldocs/prepare-tax-docs-modal.component.html';
}

class FcPrepareTaxDocsModalController implements ng.IComponentController {
    public modalInstance: ng.ui.bootstrap.IModalServiceInstance;
    public resolve: {
        investorProfile: IInvestorProfile;
        onTaxDocGenerated: (data: {taxDoc: any}) => void;
    };

    public $onInit() {
        this.resolve.investorProfile = angular.copy(this.resolve.investorProfile);
    }
}

angular
    .module('fundersclub.legaldocs')
    .component('fcPrepareTaxDocsModal', new FcPrepareTaxDocsModal());
