do ->

    # Form responsible for displaying and editing fund questions.
    fcFundQuestionEdit = ->
        thisScope =
            'question': '=fcFundQuestionEdit'
            'fund': '=fcFundQuestionEditFund'

        thisController = ->
            vm = @
            vm.errors = {}

            # Save
            vm.save = ->
                vm.errors = {}
                vm.question.$save()
                    .catch (response) ->
                        angular.extend(vm.errors, response.data)
                    .finally () ->
                        vm.fcSave.saveAttemptFinished(vm.errors)

            return

        thisDirective =
            templateUrl: 'funds/question-edit.directive.html'
            restrict: 'A'
            scope: thisScope
            bindToController: true
            controllerAs: 'fqc'
            controller: thisController
        return thisDirective

    angular
        .module 'fundersclub.funds'
        .directive 'fcFundQuestionEdit', fcFundQuestionEdit
