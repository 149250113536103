class FcCompanyUpdatesSettingsBoundForm implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        company: '=',
        errors: '<',
    };
    public controller = FcCompanyUpdatesSettingsBoundFormController;
    public templateUrl = 'companies/updates-settings-bound-form.component.html';
}

class FcCompanyUpdatesSettingsBoundFormController implements ng.IComponentController {
    public company: any;
    public errors: {[error: string]: Array<any>} = {};

    constructor(private FCChoices: any) {}
}

angular
    .module('fundersclub.companies')
    .component('fcCompanyUpdatesSettingsBoundForm', new FcCompanyUpdatesSettingsBoundForm());
