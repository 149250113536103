do ->
    CompanyOnboardingController = (
        $attrs,
        Company,
        CompanyOwner,
        FCCompanyData,
        FCCompanyOwnerData
    ) ->
        vm = @

        vm.founders = _.map(FCCompanyOwnerData, (owner) ->
            new CompanyOwner(owner)
        )

        emptyFounderTemplate =
            'first_name': ''
            'last_name': ''
            'work_email': ''

        vm.newFounder = _.clone emptyFounderTemplate
        vm.show_add_founder_form = false
        vm.adding_founder = false
        vm.newFounderErrors = []

        # Toggle visibility of "Add another founder" form.
        vm.toggleAddFounderForm = ->
            vm.show_add_founder_form = !vm.show_add_founder_form
            # Reset form.
            vm.newFounder = _.clone emptyFounderTemplate
            vm.adding_founder = false

        # Make API to actually add founder.
        vm.addFounder = ->
            vm.adding_founder = true
            vm.newFounderErrors = {}

            newfounder = new CompanyOwner(vm.newFounder)
            newFounder.email = newfounder.work_email.toLowerCase()
            Company.inviteEntrepreneur(FCCompanyData.company_slug, vm.newFounder)
                .then (response) ->
                    #vm.founders.push(newfounder)
                    vm.founders.push(vm.newFounder)
                    vm.toggleAddFounderForm()
                .catch (response) ->
                    angular.extend(vm.newFounderErrors, response.data)
                    vm.adding_founder = false

        # End CompanyOnboardingController
        return


    angular
        .module 'fundersclub.companies'
        .controller 'CompanyOnboardingController', CompanyOnboardingController
