class FcPartnershipFollow implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        following: '<',
        partnershipId: '<',
    };
    public controller = FcPartnershipFollowController;
    public templateUrl = 'partnerships/follow.component.html';
}

class FcPartnershipFollowController implements ng.IComponentController {
    public following: boolean;
    public partnershipId: number;

    private partnership: any;

    constructor(private Partnership: any) {}

    public $onInit(): void {
        this.partnership = new this.Partnership({id: this.partnershipId});
    }

    /* tslint:disable:no-unused-variable */
    private toggleFollowing() {
        if (this.following) {
            this.partnership.unfollow();
        } else {
            this.partnership.follow();
        }

        this.following = !this.following;
    }
}

angular
    .module('fundersclub.publicPages')
    .component('fcPartnershipFollow', new FcPartnershipFollow());
