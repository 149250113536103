do ->

    fcCompanyNetworkSearchResultSendMessageController = (
        $timeout,
        $uibModalInstance,
        FCToastManager,
        IntroductionRequest,
        result,
        senderInfo,
        searchQuery
    ) ->
        vm = @
        vm.result = result
        vm.senderInfo = senderInfo
        vm.message = vm.result.defaultMessage(vm.senderInfo)

        vm.close = ->
            $uibModalInstance.close(vm.result)

        vm.sendMessage = ->
            vm.sending = true
            introductionRequest = new IntroductionRequest
                'contents': vm.message
                'message_type': 'message'
                'recipient': vm.result.user.id
                'search_query': searchQuery

            introductionRequest
                .$create()
                .then (response) ->
                    FCToastManager.createGenericToast("Your message was sent to #{result.fullName()}")
                    vm.form.$setPristine()
                    vm.result.most_recent_introduction_request = response
                    $uibModalInstance.close(vm.result)
                .finally ->
                    vm.sending = false

        return

    fcCompanyNetworkSearchResultRequestIntroController = (
        $scope,
        $timeout,
        $uibModalInstance,
        FCToastManager,
        IntroductionRequest,
        result,
        senderInfo,
        searchQuery
    ) ->
        vm = @
        vm.result = result
        vm.previousConnection = vm.connection = result.connected_through.slice(0, 1)[0]
        vm.senderInfo = senderInfo
        vm.message = vm.connection.defaultMessage(vm.senderInfo, vm.result)

        vm.close = ->
            $uibModalInstance.close(vm.result)

        vm.sendRequest = ->
            vm.sending = true
            introductionRequest = new IntroductionRequest
                'connection': result.pk
                'contents': vm.message
                'message_type': 'introduction'
                'recipient': vm.connection.id
                'search_query': searchQuery

            introductionRequest
                .$create()
                .then (response) ->
                    FCToastManager.createGenericToast(
                        "Your introduction request to #{result.fullName()} was sent to #{vm.connection.fullName()}."
                    )
                    vm.form.$setPristine()
                    vm.result.most_recent_introduction_request = response
                    $uibModalInstance.close(vm.result)
                .finally ->
                    vm.sending = false


        vm.setMessage = ->
            if vm.form.message.$dirty
                setMessage = confirm "Changing this selection will reset the message area. Please copy your message if you'd like to preserve it."
                if not setMessage
                    $timeout(->
                        $scope.$apply ->
                            vm.connection = vm.previousConnection
                    , 0)
                    return

            vm.message = vm.connection.defaultMessage(vm.senderInfo, vm.result)
            vm.previousConnection = vm.connection
            vm.form.message.$setPristine()

        return

    # Form responsible for displaying and editing fund update items.
    fcCompanyNetworkSearchResult = ($uibModal, $http, NetworkSearchResultConnection, CompanySavedContact) ->
        thisScope =
            'result': '=fcCompanyNetworkSearchResult'
            'senderInfo': '='
            'searchQuery': '='
            'savedContacts': '='

        thisController = ($scope) ->
            vm = @
            vm.connectionTooltip = 'companies/connection.tooltip.html'
            vm.linkedinVerificationTooltip = 'companies/linkedin-verification.tooltip.html'
            vm.descriptionShownLength = 250

            contact = _.findWhere(vm.savedContacts.contacts,
                'contact': vm.result.pk
                'contact_type': vm.result.account_type
            )
            vm.isThisContactSaved = !!contact

            if not contact
                contact =
                    'company': vm.savedContacts.company_id,
                    'contact_type': vm.result.account_type
                    'contact': vm.result.pk

            vm.savedContact = new CompanySavedContact(contact)

            vm.result.connected_through = _.map vm.result.connected_through, (connection) ->
                new NetworkSearchResultConnection(connection)

            vm.showRequestIntroModal = ->
                $uibModal.open(
                    templateUrl: 'companies/request-intro.modal.html'
                    controller: 'fcCompanyNetworkSearchResultRequestIntroController'
                    controllerAs: 'cnsrric'
                    resolve:
                        result: -> vm.result
                        senderInfo: -> vm.senderInfo
                        searchQuery: -> vm.searchQuery
                )
                .result
                .then (result) ->
                    vm.result = result

            vm.showSendMessageModal = ->
                $uibModal.open(
                    templateUrl: 'companies/send-message.modal.html'
                    controller: 'fcCompanyNetworkSearchResultSendMessageController'
                    controllerAs: 'cnsrsmc'
                    resolve:
                        result: -> vm.result
                        senderInfo: -> vm.senderInfo
                        searchQuery: -> vm.searchQuery
                )
                .result
                .then (result) ->
                    vm.result = result

            vm.showCompleteDescription = ->
                vm.descriptionShownLength = vm.result.description.length

            vm.verifyURL = (data) ->
                console.log(data)
                data['linkedin_profile_verified'] = true
                $http.post('/api/searchsocialgraph/verify_url/', data)

            return

        thisDirective =
            templateUrl: 'companies/network-search-result.directive.html'
            restrict: 'A'
            scope: thisScope
            bindToController: true
            controllerAs: 'cnsr'
            controller: thisController
        return thisDirective

    angular
        .module 'fundersclub.companies'
        .directive 'fcCompanyNetworkSearchResult', fcCompanyNetworkSearchResult
        .controller(
            'fcCompanyNetworkSearchResultSendMessageController',
            fcCompanyNetworkSearchResultSendMessageController
        )
        .controller(
            'fcCompanyNetworkSearchResultRequestIntroController',
            fcCompanyNetworkSearchResultRequestIntroController
        )
