class FcFounderOnboard implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        companyData: '<',
        founderData: '<',
        founderHasCompletedSignup: '<',
        foundersData: '<',
        linkedinSignupUrl: '<',
        userData: '<',
    };
    public controller = FcFounderOnboardController;
    public templateUrl = 'founder-onboarding/founder-onboard.component.html';
}

class FcFounderOnboardController implements ng.IComponentController {
    public companyData: any;
    public founderData: any;
    public founderHasCompletedSignup: boolean;
    public foundersData: Array<any>;
    public linkedinSignupUrl: string;
    public userData: any;

    private company: any;
    private founder: any;
    private founders: any;
    private inviteFoundersRouteNextState: string;
    private POST_FUNDING_ROUTES = [
        'founderOnboarding.inviteFounders',
        'founderOnboarding.swagSizes',
        'founderOnboarding.mailingAddress',
        'founderOnboarding.updatesAddress',
        'founderOnboarding.updatesSettings',
    ];
    private PREVIOUS_ROUTING_STATES: any = {
        'founderOnboarding.founderDetails': 'founderOnboarding.userDetails',
        'founderOnboarding.companyDetails': 'founderOnboarding.founderDetails',
        'founderOnboarding.inviteFounders': 'founderOnboarding.companyDetails',
        'founderOnboarding.swagSizes': 'founderOnboarding.inviteFounders',
        'founderOnboarding.mailingAddress': 'founderOnboarding.swagSizes',
        'founderOnboarding.updatesAddress': null,
        'founderOnboarding.updatesSettings': 'founderOnboarding.updatesAddress',
    };
    private GIFT_ROUTES = [
        'founderOnboarding.swagSizes',
        'founderOnboarding.mailingAddress',
    ];
    private user: any;

    constructor(
        private _: UnderscoreStatic,
        private $state: any,
        private $timeout: ng.ITimeoutService,
        private $transitions: any,
        private $window: ng.IWindowService,
        private Company: any,
        private CompanyTeamMember: any,
        private User: any
    ) {}

    public $onInit() {
        this.company = new this.Company(this.companyData);
        this.founder = new this.CompanyTeamMember(this.founderData);
        this.founders = this.foundersData.map((founder: any) => new this.CompanyTeamMember(founder));
        this.user = new this.User(this.userData);

        if (!this.company.was_onboarding_gift_sent) {
            this.inviteFoundersRouteNextState = 'founderOnboarding.swagSizes';
            this.PREVIOUS_ROUTING_STATES['founderOnboarding.updatesAddress'] = 'founderOnboarding.mailingAddress';
        } else {
            this.inviteFoundersRouteNextState = 'founderOnboarding.updatesAddress';
            this.PREVIOUS_ROUTING_STATES['founderOnboarding.updatesAddress'] = 'founderOnboarding.inviteFounders';
        }

        if (!this.founderHasCompletedSignup) {
            this.$state.go('founderOnboarding.completeSignup');
        } else {
            // Need the $timeout to allow angular to finish it's digest cycle
            // and set $state.$current correctly
            this.$timeout(() => {
                if (
                    !this.$state.$current.name ||
                    this.$state.$current.name === 'founderOnboarding.completeSignup'
                ) {
                    this.$state.go('founderOnboarding.userDetails');
                }
            });
        }

        this.$transitions.onBefore({}, (trans: any) => {
            this.$window.scroll(0, 0);
        });

        this.$transitions.onBefore({to: 'founderOnboarding.completeSignup'}, (trans: any) => {
            if (this.founderHasCompletedSignup) {
                return false;
            }
        });

        this.$transitions.onBefore({to: this.POST_FUNDING_ROUTES}, (trans: any) => {
            if (!this.company.funded) {
                return false;
            }
        });

        this.$transitions.onBefore({to: this.GIFT_ROUTES}, (trans: any) => {
            if (this.company.was_onboarding_gift_sent) {
                return false;
            }
        });
    }

    /* tslint:disable:no-unused-variable */
    private goBack() {
        this.$state.go(this.PREVIOUS_ROUTING_STATES[this.$state.$current.name]);
    }

    /* tslint:disable:no-unused-variable */
    private onCompanyUpdated(company: any) {
        this.company = company;
    }

    /* tslint:disable:no-unused-variable */
    private onFounderInvited(founder: any) {
        this.founders.push(founder);
    }

    /* tslint:disable:no-unused-variable */
    private onFounderInListUpdated(founder: any) {
        let index = this._.findIndex(this.founders, (tmpFounder: any) => tmpFounder.id === founder.id);
        this.founders[index] = founder;
    }

    /* tslint:disable:no-unused-variable */
    private onFoundersUpdated(founders: any) {
        this.founders = founders;
    }

    /* tslint:disable:no-unused-variable */
    private onFounderUpdated(founder: any) {
        this.founder = founder;
    }

    /* tslint:disable:no-unused-variable */
    private onUserUpdated(user: any) {
        this.user = user;
    }

    /* tslint:disable:no-unused-variable */
    private showBackButton() {
        return [
            'founderOnboarding.completeSignup',
            'founderOnboarding.userDetails',
        ].indexOf(this.$state.$current.name) === -1;
    }
}

angular
    .module('fundersclub.founderOnboarding')
    .component('fcFounderOnboard', new FcFounderOnboard());
