do ->

    fcSignupAccreditation = ->
        thisScope =
            'signupLead': '=lead'
            'register': '&registerCallback'

        thisController = (SignupData, $filter) ->
            vm = @
            vm.form = vm.register({'directive': vm})
            vm.showErrorMessages = false
            vm.SignupData = SignupData

            vm.sliderFields = [
                'individual_net_worth'
                'individual_income'
                'individual_combined_income'
                'entity_assets'
            ]

            vm.directiveFields = [
                'entity_accredited'
                'entity_assets'
                'entity_location'
                'entity_name'
                'entity_type'
                'individual_combined_income'
                'individual_income'
                'individual_net_worth'
                'is_not_married'
            ]

            vm.sliderOptions = (field, range) -> {
                start: [vm.signupLead[field]],
                connect: 'lower',
                range: range,
                tooltips: true,
                pips: {
                    mode: 'range',
                    density: 100,
                    format: {
                        to: (value) ->
                            $filter('dollarsAbbv')(parseInt(value, 10), '$').replace('.00', '').replace('–', '$0')
                        value: (value) ->
                            value?.replace('M', '000000').replace('K', '000').replace('$', '')
                    }
                },
                format: {
                    to: (value) -> $filter('dollarsAbbv')(parseInt(value, 10), '$')
                    from: (value) ->
                        value?.replace('M', '000000').replace('00K', '00000').replace('$', '')
                },
            }

            vm.individualNetWorthOptions =
                config: vm.sliderOptions 'individual_net_worth',
                    'min': [0, 10000]
                    '25%': [500000, 10000]
                    '50%': [1000000, 10000]
                    '75%': [5000000, 100000]
                    'max': [25000000]

            vm.individualIncomeOptions =
                config: vm.sliderOptions 'individual_income',
                    'min': [0, 1000]
                    '25%': [200000, 10000]
                    '50%': [500000, 10000]
                    '75%': [1000000, 10000]
                    'max': [5000000]

            vm.individualCombinedIncomeOptions =
                config: vm.sliderOptions 'individual_combined_income',
                    'min': [0, 1000]
                    '25%': [200000, 10000]
                    '50%': [500000, 10000]
                    '75%': [1000000, 10000]
                    'max': [5000000]

            vm.entityAssetsOptions =
                config: vm.sliderOptions 'entity_assets',
                    'min': [0, 10000]
                    '25%': [1000000, 10000]
                    '50%': [5000000, 10000]
                    '75%': [10000000, 100000]
                    'max': [25000000]

            vm.showErrorMessage = (fieldName) ->
                return vm.showErrorMessages && vm.form[fieldName]?.$invalid

            vm.validate = ->
                if vm.tab_investing_as_individual()
                    vm.signupLead.individual_net_worth = parseInt(vm.individualNetWorthOptions.config.start, 10) || 0
                    vm.signupLead.individual_income = parseInt(vm.individualIncomeOptions.config.start, 10) || 0
                    vm.signupLead.individual_combined_income = parseInt(vm.individualCombinedIncomeOptions.config.start, 10) || 0

                    if vm.signupLead.individual_net_worth == 0
                        return false
                else
                    vm.signupLead.entity_assets = parseInt(vm.entityAssetsOptions.config.start, 10) || 0

                return vm.form.$valid

            vm.highlightContinueButton = ->
                # Although the user is allowed to submit 0 values, we don't
                # want the button to become green until they're >0.
                rest_of_form_is_valid = vm.validate()
                has_positive_slider_value = false
                if vm.tab_investing_as_individual()
                    has_positive_slider_value = (
                        !!vm.signupLead.individual_net_worth and
                        !!vm.signupLead.individual_income and
                        (if vm.signupLead.is_not_married then true else !!vm.signupLead.individual_combined_income)
                    )
                else
                    has_positive_slider_value = !!vm.signupLead.entity_assets
                return rest_of_form_is_valid and has_positive_slider_value

            # Convenience functions to know what tab we're on.
            vm.tab_investing_as_individual = -> vm.signupLead.entity_type == 'individual'
            vm.tab_investing_as_business = -> vm.signupLead.entity_type == 'business'
            vm.tab_investing_as_firm = -> vm.signupLead.entity_type == 'vcfirm'

            return

        thisDirective =
            bindToController: true
            controller: thisController
            controllerAs: 'usac'
            replace: true
            restrict: 'E'
            scope: thisScope
            templateUrl: 'signup/steps/accreditation.directive.html'

        thisDirective

    angular
        .module 'fundersclub.signup'
        .directive 'fcSignupAccreditation', fcSignupAccreditation
