class FcFounderOnboardUpdatesAddress implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        company: '<',
        nextState: '<',
    };
    public controller = FcFounderOnboardUpdatesAddressController;
    public templateUrl = 'founder-onboarding/updates-address.component.html';
}

class FcFounderOnboardUpdatesAddressController implements ng.IComponentController {
    public company: any;
    public nextState: string;

    private errors: any = {};
    private fcSave: any;

    constructor(private $state: any, private $window: ng.IWindowService, private FCFounderOnboardData: any) {}

    public $onInit() {
        this.company = angular.copy(this.company);

        if (this.company.was_sending_updates_when_onboarding === null) {
            this.company.was_sending_updates_when_onboarding = true;
        }
    }

    /* tslint:disable:no-unused-variable */
    private finish() {
        this.errors = {};
        this.company
            .$save(['was_sending_updates_when_onboarding'])
            .then((companyResponse: any) => {
                if (!this.company.was_sending_updates_when_onboarding || this.FCFounderOnboardData.fcvc_only) {
                    this.company
                        .markFounderOnboarded()
                        .then((response: any) => this.$window.location.href = response.data.url);
                } else {
                    this.$state.go(this.nextState);
                }
            })
            .catch((response: any) => {
                this.fcSave.saveAttemptFinished(this.errors);
                angular.extend(this.errors, response.data);
            });
    }
}

angular
    .module('fundersclub.founderOnboarding')
    .component('fcFounderOnboardUpdatesAddress', new FcFounderOnboardUpdatesAddress());
