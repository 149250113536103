class FcInvestorProfileSignTaxDoc implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        investorProfileData: '<',
        investorProfileTaxDocData: '<',
        returnToUrl: '<',
    };
    public controller = FcInvestorProfileSignTaxDocController;
    public templateUrl = 'users/investor-profile-sign-tax-doc.component.html';
}

class FcInvestorProfileSignTaxDocController implements ng.IComponentController {
    public investorProfileData: any;
    public investorProfileTaxDocData: any;
    public returnToUrl: any;

    public submitting: boolean = false;
    public investorProfile: any;
    public investorProfileTaxDoc: any;
    public errors: any;
    public currentStep: any;
    private pollingInterval: any;

    constructor(
        private $uibModal: any,
        private $window: any,
        private $interval: ng.IIntervalService,
        private $http: ng.IHttpService,
        private $scope: ng.IRootScopeService,
        private FCChoices: any,
        private SignableLegalDocument: any,
        private InvestorProfile: any,
        private InvestorProfileTaxDoc: any,
        private _: UnderscoreStatic
    ) {}

    public $onInit() {
        this.investorProfile = new this.InvestorProfile(this.investorProfileData);
        this.investorProfileTaxDoc = new this.InvestorProfileTaxDoc(this.investorProfileTaxDocData);
        this.currentStep = 'investor-profile-update';

        // For US individual investor profiles, we want to force tax_holding_type to be individual.
        // We need to monitor investorProfile.investor_country since the user might change it.
        if (this.investorProfile.is_individual) {
            this.$scope.$watch(
                () => this.investorProfile.investor_country,
                (newValue: any, oldValue: any) => {
                    if (newValue === this.FCChoices.InvestorCountryChoices.USA) {
                        this.investorProfileTaxDoc.tax_holding_type =
                            this.FCChoices.W9HoldingType.Individual_sole_proprietor;
                    }
                }
            );

            if (this.investorProfile.investor_country === this.FCChoices.InvestorCountryChoices.USA) {
                this.investorProfileTaxDoc.tax_holding_type = this.FCChoices.W9HoldingType.Individual_sole_proprietor;
            }
        } else {
            // For entities, we want to reset tax_holding_type when investor type is changed since the list of
            // possible options is incompatible.
            this.$scope.$watch(
                () => this.investorProfile.investor_country,
                (newValue: any, oldValue: any) => {
                    this.investorProfileTaxDoc.tax_holding_type = '';
                }
            );
        }

        if (this.investorProfileTaxDoc.tax_doc && this.investorProfileTaxDoc.tax_doc.id) {
            this.signDocModal();
        }
    }

    public pollForUpdates() {
        if (this.pollingInterval) {
            return;
        }

        this.pollingInterval = this.$interval(() => {
            this.$http({
                method: 'GET',
                url: this.investorProfileTaxDoc.tax_doc.url,
            }).success((data: any) => {
                if (this.investorProfileTaxDoc.tax_doc != null) {
                    angular.extend(this.investorProfileTaxDoc.tax_doc, data);
                }
                if (!this.investorProfileTaxDoc.tax_doc ||
                    this.investorProfileTaxDoc.tax_doc.status === this.FCChoices.SigningStatus.Signed) {
                    this.$interval.cancel(this.pollingInterval);
                    this.pollingInterval = null;
                }
            });
        }, 1000);
    }

    public saveInvestorProfile() {
        this.submitting = true;

        this.investorProfile
            .$save()
            .then((response: any) => {
                this.investorProfile = new this.InvestorProfile(response);
                this.errors = {};
                this.submitting = false;
                this.currentStep = 'provide-tax-info';
            })
            .catch((response: any) => {
                this.errors = response.data;
                this.submitting = false;
            });
    }

    public saveTaxDoc() {
        this.submitting = true;

        if (this.investorProfile.investor_country === this.FCChoices.InvestorCountryChoices.USA) {
            this.cleanupW9();
        } else if (this.investorProfile.investor_country === this.FCChoices.InvestorCountryChoices.NonUSA) {
            this.cleanupW8();
        } else {
            console.log('invalid investor_country', this.investorProfile.investor_country);
            return;
        }

        this.errors = {};
        this.investorProfileTaxDoc.investor_profile = this.investorProfile;
        this.investorProfileTaxDoc
            .$save()
            .then((response: any) => {
                this.investorProfileTaxDoc = new this.InvestorProfileTaxDoc(response);
                this.errors = {};
                this.submitting = false;
                this.signDocModal();
            })
            .catch((response: any) => {
                this.errors = response.data;
                this.submitting = false;
            });
    }

    public back() {
        this.currentStep = 'investor-profile-update';
    }

    private signDocModal() {
        this.currentStep = 'sign-doc';
        this.pollForUpdates();
        this.$uibModal.open({
            animation: false,
            ariaDescribedBy: 'modal-body',
            ariaLabelledBy: 'modal-title',
            component: 'fcSignLegaldocModal',
            resolve: {
                docName: () => 'IRS Tax Form',
                getLegaldocStatus: () => this.getLegaldocStatus.bind(this),
                getSigningUrl: () => this.getSigningUrl.bind(this),
                onResetForm: () => this.onResetForm.bind(this),
            },
            size: 'lg',
            windowClass: 'complete-investment--sign-legal-doc--modal',
        });
    }
    private getLegaldocStatus(): string {
        return this.investorProfileTaxDoc.tax_doc ? this.investorProfileTaxDoc.tax_doc.status : -1;
    }

    private getSigningUrl(): string {
        return this.investorProfileTaxDoc.tax_doc ? this.investorProfileTaxDoc.tax_doc.signing_url : '';
    }

    private onResetForm(): void {
        return this.investorProfileTaxDoc.abandonDoc()
            .then((response: any) => {
                this.investorProfileTaxDoc.tax_doc = null;
                this.currentStep = 'provide-tax-info';
            });
    }

    private cleanupW9() {
        this.investorProfileTaxDoc.tax_foreign_tax_identifying_number = null;
        this.investorProfileTaxDoc.tax_type_of_income = null;
        this.investorProfileTaxDoc.tax_special_rates_and_conditions = null;
        this.investorProfileTaxDoc.tax_explanation = null;
        this.investorProfileTaxDoc.tax_making_a_treaty_claim = null;
        this.investorProfileTaxDoc.tax_country_of_incorporation_or_organization = null;
        this.investorProfileTaxDoc.tax_international_ssn_check = null;
        this.investorProfileTaxDoc.tax_country_of_citizenship = null;
        this.investorProfileTaxDoc.tax_claim_benefits_checkbox = null;
        this.investorProfileTaxDoc.tax_article_and_paragraph = null;
        this.investorProfileTaxDoc.tax_percentage_rate = null;
        this.investorProfileTaxDoc.tax_type_of_income = null;
        this.investorProfileTaxDoc.tax_explanation = null;
        this.investorProfileTaxDoc.tax_w8ben_fatca_status = null;
        this.investorProfileTaxDoc.tax_us_taxpayer_identification_number = null;
        this.investorProfileTaxDoc.tax_entity_name = null;
        this.investorProfileTaxDoc.tax_beneficial_owner = null;

        if (this.investorProfileTaxDoc.tax_holding_type !== this.FCChoices.W9HoldingType.LLC) {
            this.investorProfileTaxDoc.tax_llc_type = null;
        }
        if (!this.investorProfileTaxDoc.w9ShouldHaveSSN(this.FCChoices.W9HoldingType)) {
            this.investorProfileTaxDoc.tax_ssn = null;
        }
        if (!this.investorProfileTaxDoc.w9ShouldHaveEIN(this.FCChoices.W9HoldingType)) {
            this.investorProfileTaxDoc.tax_ein = null;
        }
        if (this.investorProfileTaxDoc.tax_holding_type !== this.FCChoices.W9HoldingType.Single_member_LLC &&
            this.investorProfileTaxDoc.tax_holding_type !== this.FCChoices.W9HoldingType.Grantor_trust) {
            this.investorProfileTaxDoc.tax_owner_name = null;
        }
        if (this.investorProfileTaxDoc.tax_holding_type !== this.FCChoices.W9HoldingType.Other) {
            this.investorProfileTaxDoc.tax_holding_type_other = null;
        }
    }

    private cleanupW8() {
        if (this.investorProfileTaxDoc.tax_making_a_treaty_claim) {
            this.investorProfileTaxDoc.tax_claim_benefits_checkbox = true;
        }
        this.investorProfileTaxDoc.tax_llc_type = null;
        this.investorProfileTaxDoc.tax_holding_type_other = null;
        this.investorProfileTaxDoc.tax_smllc_ein_ssn_choice = null;
        this.investorProfileTaxDoc.tax_ssn = null;
        this.investorProfileTaxDoc.tax_ein = null;
        this.investorProfileTaxDoc.tax_owner_name = null;
        if (!this.investorProfileTaxDoc.tax_international_ssn_check) {
            this.investorProfileTaxDoc.tax_us_taxpayer_identification_number = null;
        }
        if (!this.investorProfileTaxDoc.tax_claim_benefits_checkbox) {
            this.investorProfileTaxDoc.tax_article_and_paragraph = null;
            this.investorProfileTaxDoc.tax_percentage_rate = null;
            this.investorProfileTaxDoc.tax_type_of_income = null;
            this.investorProfileTaxDoc.tax_explanation = null;
        }
        if (this.investorProfile.is_entity) {
            this.investorProfileTaxDoc.tax_country_of_citizenship = null;
            if (!this.investorProfileTaxDoc.w8beneShouldSeeTreatyClaimQuestion(this.FCChoices.W8BENEHoldingType)) {
                this.investorProfileTaxDoc.tax_making_a_treaty_claim = null;
            }
        } else if (!this.investorProfile.is_entity) {
            this.investorProfileTaxDoc.tax_holding_type = null;
            this.investorProfileTaxDoc.tax_making_a_treaty_claim = null;
            this.investorProfileTaxDoc.tax_entity_name = null;
            this.investorProfileTaxDoc.tax_beneficial_owner = null;
            this.investorProfileTaxDoc.tax_country_of_incorporation_or_organization = null;
            this.investorProfileTaxDoc.tax_w8ben_fatca_status = null;
        }
    }
 }

angular
    .module('fundersclub.users')
    .component('fcInvestorProfileSignTaxDoc', new FcInvestorProfileSignTaxDoc());
