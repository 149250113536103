class FcWithheldInvestableCapital implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        onSaved: '&',
        wic: '<',
    };
    public controller = FcWithheldInvestableCapitalController;
    public templateUrl = 'funds/withheld-investable-capital.component.html';
}

class FcWithheldInvestableCapitalController implements ng.IComponentController {
    public onSaved: () => void;
    public wic: any;

    private errors: Array<any> = [];
    private editing = false;
    private fcVentureMemberChoicesMap: {[id: number]: string} = {};
    private originalWic: any;
    private saving = false;

    constructor(
        private $window: ng.IWindowService,
        private FCChoices: any,
        private FCVentureMemberChoices: any,
        private WithheldInvestableCapital: any
    ) {}

    public $onInit(): void {
        for (let member of this.FCVentureMemberChoices) {
            this.fcVentureMemberChoicesMap[member[0]] = member[1];
        }
        if (this.wic.new) {
            this.editing = true;
            delete this.wic.new;
        }
    }

    public abandon() {
        this.cancel();
        this.wic.status = this.FCChoices.Statuses.Abandoned;
        this.save();
    }

    public cancel() {
        this.errors = [];
        this.editing = false;
        for (let key in this.originalWic) {
            if (this.originalWic.hasOwnProperty(key)) {
                this.wic[key] = this.originalWic[key];
            }
        }
        this.originalWic = undefined;
    }

    public convert() {
        this.save()
            .then(() => {
                this.$window.location.href = this.wic.urls.send_wic_url;
            });
    }

    public delete() {
        this.cancel();
        this.wic.status = this.FCChoices.Statuses.Deleted;
        this.save();
    }

    public edit() {
        this.editing = true;
        this.originalWic = angular.copy(this.wic);
    }

    public save() {
        this.saving = true;
        this.errors = [];
        let wic = new this.WithheldInvestableCapital(this.wic);
        return wic.$update()
            .then((response: any) => {
                this.onSaved();
                this.editing = false;
            }).catch((response: any) => {
                this.errors = response.data;
            }).finally(() => {
                this.saving = false;
            });
    }
}

angular
    .module('fundersclub.funds')
    .component('fcWithheldInvestableCapital', new FcWithheldInvestableCapital());
