declare var Plaid: any;

class FcVerifyBankAccount implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        bankAccount: '<',
        onVerified: '&',
    };
    public controller = FcVerifyBankAccountController;
    public templateUrl = 'payments/verify-bank-account.component.html';
}

class FcVerifyBankAccountController implements ng.IComponentController {
    public bankAccount: any;
    public onVerified: (data: {bankAccount: any}) => void;

    private errors: any = {};
    private fcSave: any;

    constructor(private BankAccount: any, private FCAPITokens: any) {}

    public $onInit() {
        this.bankAccount = angular.copy(this.bankAccount);
    }

    /* tslint:disable:no-unused-variable */
    private verifyAccount() {
        this.errors = {};
        this.bankAccount
            .verify(this.bankAccount.amount_1, this.bankAccount.amount_2)
            // Jankily this endpoint does not return the serialized bank account, so
            // mark the bank account we were given as verified and pass it back
            .then((response: any) => {
                this.bankAccount.verified = true;
                this.onVerified({bankAccount: this.bankAccount});
            })
            .catch((response: any) => angular.extend(this.errors, response.data))
            .finally(() => this.fcSave.saveAttemptFinished(this.bankAccount.errors));
    }

    /* tslint:disable:no-unused-variable */
    private verifyAccountPlaid() {
        this.errors = {};
        this.bankAccount
            .create_verification_link_token()
            .then((response: any) => {
                Plaid.create({
                    apiVersion: 'v2',
                    clientName: 'FundersClub',
                    env: this.FCAPITokens.plaid_client_environment,
                    onSuccess: (publicToken: any) => {
                        this.bankAccount.verify_plaid_microdeposits(publicToken).then(() => {
                            this.onVerified({bankAccount: this.bankAccount});
                        });
                    },
                    product: ['auth'],
                    token: response.data.link_token,
                }).open();
                // this.bankAccount.verified = true;
                // this.onVerified({bankAccount: this.bankAccount});
            })
            .catch((response: any) => angular.extend(this.errors, response.data))
            .finally(() => this.fcSave.saveAttemptFinished(this.bankAccount.errors));
    }

}

angular
    .module('fundersclub.payments')
    .component('fcVerifyBankAccount', new FcVerifyBankAccount());
