do ->

    MyInvestmentsController = ($uibModal) ->
        vm = @

        vm.partnershipChoices = AppData.partnershipChoices
        vm.partnershipIds = AppData.partnershipIds
        vm.activePartnership = vm.partnershipChoices[0]
        vm.numInvestments = AppData.initialNumInvestments

        vm.change = ->
            # All filter
            if vm.activePartnership.value == 0
                vm.numInvestments = vm.partnershipIds.length
                return

            vm.numInvestments = _.filter(vm.partnershipIds, (id) -> id == vm.activePartnership.value).length

        vm.openInvestmentModal = (url) ->
            $uibModal.open({
                animation: false,
                component: 'FcAllInvestmentsViewInvestmentModal',
                resolve: {
                    investment_details_url: () => url,
                },
                windowClass: 'FullTakeoverModal FullTakeoverModal--narrow',
            })

        # End MyInvestmentsController
        return


    angular
        .module 'fundersclub.users'
        .controller 'MyInvestmentsController', MyInvestmentsController
