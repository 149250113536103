class FcDistributionConfirmModal implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        modalInstance: '<',
        resolve: '<',
    };
    public controller = FcDistributionConfirmModalController;
    public templateUrl = 'funds/distribution-confirm-modal.component.html';
}

class FcDistributionConfirmModalController implements ng.IComponentController {
    public modalInstance: ng.ui.bootstrap.IModalServiceInstance;
    public resolve: {
        onCancel: any,
        onConfirm: any,
    };

    private confirming: Boolean = false;

    public $onInit() {
        return;
    }

    public confirm() {
        this.confirming = true;
        this.resolve.onConfirm();
        this.modalInstance.dismiss();
    }

    public close() {
        this.resolve.onCancel();
        this.modalInstance.dismiss();
    }

}

angular
    .module('fundersclub.users')
    .component('fcDistributionConfirmModal', new FcDistributionConfirmModal());
