class FcReserveInvestorProfile implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        errors: '<',
        investorProfile: '<',
        onSelected: '&',
        onUpdated: '&',
        qcNeeded: '<',
    };
    public controller = FcReserveInvestorProfileController;
    public templateUrl = 'invest/investor-profile.component.html';
}

class FcReserveInvestorProfileController implements ng.IComponentController {
    public errors: {[error: string]: Array<string>};
    public investorProfile: IInvestorProfile;
    public onSelected: (data: {investorProfile: IInvestorProfile}) => void;
    public onUpdated: (data: {investorProfile: IInvestorProfile}) => void;
    public qcNeeded: boolean;

    constructor(private $uibModal: ng.ui.bootstrap.IModalService) {}

    /* tslint:disable:no-unused-variable */
    private changeInvestorProfile(): void {
        let modal: any = this.$uibModal
            .open({
                animation: false,
                backdrop: false,
                component: 'FcChangeInvestorProfile',
                resolve: {
                    defaultSelection: () => this.investorProfile,
                    onSelected: () => this._onSelected.bind(this),
                    onUpdated: () => this._onUpdated.bind(this),
                },
                windowClass: 'FullTakeoverModal',
            });
    }

    /* tslint:disable:no-unused-variable */
    private editInvestorProfile(): void {
        this.$uibModal
            .open({
                animation: false,
                backdrop: false,
                component: 'FcInvestorProfileEditModal',
                resolve: {
                    investorProfile: () => this.investorProfile,
                    onUpdated: () => this._onUpdated.bind(this),
                    triggerValidateOnOpen: true,
                },
                windowClass: 'FullTakeoverModal',
            });
    }

    /* tslint:disable:no-unused-variable */
    private verifyQC(): void {
        this.$uibModal
            .open({
                animation: false,
                backdrop: false,
                component: 'FcInvestorProfileVerifyQcModal',
                resolve: {
                    investorProfile: () => this.investorProfile,
                    onUpdated: () => this._onUpdated.bind(this),
                    triggerValidateOnOpen: true,
                },
                windowClass: 'FullTakeoverModal',
            });
    }

    private _onSelected(selectedInvestorProfile: IInvestorProfile): void {
        this.onSelected({investorProfile: selectedInvestorProfile});
    }

    private _onUpdated(updatedInvestorProfile: IInvestorProfile): void {
        this.onUpdated({investorProfile: updatedInvestorProfile});
    }
}

angular
    .module('fundersclub.invest')
    .component('fcReserveInvestorProfile', new FcReserveInvestorProfile());
