class FcInvestorProfileResignTaxForm implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        inModal: '<',
        objData: '<',
        onDone: '&',
    };
    public controller = FcInvestorProfileResignTaxFormController;
    public templateUrl = 'users/investor-profile-resign-tax-form.component.html';
}

class FcInvestorProfileResignTaxFormController implements ng.IComponentController {
    public inModal: boolean;
    public k1DateUncertainty: boolean;
    public objData: any;
    public onDone: any;
    public submitting: boolean = false;

    private obj: any;
    private errors: any;
    private pollingInterval: any;

    constructor(
        private $uibModal: any,
        private $window: any,
        private $interval: ng.IIntervalService,
        private $http: ng.IHttpService,
        private FCChoices: any,
        private SignableLegalDocument: any,
        private InvestorProfileTaxDocUpdate: any,
        private _: UnderscoreStatic
    ) {}

    public $onInit() {
        this.obj = new this.InvestorProfileTaxDocUpdate(this.objData);
        this.pollForUpdates();

        const start = new Date((this.obj.current_tax_year + 1).toString() + '-01-15');
        const end = new Date((this.obj.current_tax_year + 1).toString() + '-04-15');
        const today = new Date();
        this.k1DateUncertainty = (start <= today) && (end >= today);
    }

    public pollForUpdates() {
        if (this.pollingInterval) {
            return;
        }

        if (this.obj.tax_doc) {
            this.pollingInterval = this.$interval(() => {
                this.$http({
                    method: 'GET',
                    url: this.obj.url,
                }).success((data: any) => {
                    angular.extend(this.obj, data);
                    if (!this.obj.tax_doc || this.obj.tax_doc.status === this.FCChoices.SigningStatus.Signed) {
                        this.$interval.cancel(this.pollingInterval);
                        this.pollingInterval = null;
                        if (this.onDone) {
                            this.onDone({obj: this.obj});
                        }
                    }
                });
            }, 1000);
        }
    }

    public submitInvestorDetails() {
        this.submitting = true;
        this.errors = {};
        this.obj.$save()
            .then((response: any) => {
                angular.extend(this.obj, response.data);
                if (!this.obj.done_at) {
                    this.signTaxDoc();
                } else if (this.onDone) {
                    this.onDone({obj: this.obj});
                }
                this.submitting = false;
            })
            .catch((response: any) => {
                angular.extend(this.errors, response.data);
                this.submitting = false;
            });
    }

    // Needs to be kept in sync with need_to_sign_tax_form
    public needToSignTaxForm() {
        if (this.obj.investor_profile.is_ira) {
            return false;
        }

        // If the user is just editing their profile, they don't necessarily have to sign
        if ((this.obj.update_reason === this.FCChoices.UpdateReason.UserEditProfile) ||
            (this.obj.update_reason === this.FCChoices.UpdateReason.K1Prep)) {
            return (
                this.obj.full_name !== this.obj.orig_full_name ||
                this.obj.investor_country !== this.obj.orig_investor_country ||
                this.obj.state !== this.obj.orig_state ||
                this.obj.country !== this.obj.orig_country ||
                this.obj.legal_country !== this.obj.orig_legal_country ||
                this.obj.legal_state !== this.obj.orig_legal_state
            );
        }

        // Otherwise we assume they do
        return true;
    }

    public signTaxDoc() {
        this.pollForUpdates();
        /* this.$uibModal.open({
            animation: false,
            ariaDescribedBy: 'modal-body',
            ariaLabelledBy: 'modal-title',
            component: 'fcInvestorProfileResignTaxFormSignTaxDocModal',
            resolve: {
                getInvestorProfileResignTaxForm: () => {
                    return () => { return this.obj; };
                },
            },
            size: 'lg',
            windowClass: 'complete-investment--sign-legal-doc--modal',
        }).result.then((result: any) => {
            console.log('modal closed');
        });*/
        this.$uibModal.open({
            animation: false,
            ariaDescribedBy: 'modal-body',
            ariaLabelledBy: 'modal-title',
            component: 'fcSignLegaldocModal',
            resolve: {
                docName: () => 'IRS Tax Form',
                getLegaldocStatus: () => this.getLegaldocStatus.bind(this),
                getSigningUrl: () => this.getSigningUrl.bind(this),
                onResetForm: () => this.onResetForm.bind(this),
            },
            size: 'lg',
            windowClass: 'complete-investment--sign-legal-doc--modal',
        });
    }

    public isUsBasedOnCountry() {
        return this.obj.country === 'US';
    }

    private getLegaldocStatus(): string {
        return this.obj.tax_doc ? this.obj.tax_doc.status : -1;
    }

    private getSigningUrl(): string {
        return this.obj.tax_doc ? this.obj.tax_doc.signing_url : '';
    }

    private onResetForm(): void {
        return this.obj.abandonTaxDoc()
            .then((response: any) => {
                angular.extend(this.obj, response.data);
            });
    }
 }

angular
    .module('fundersclub.users')
    .component('fcInvestorProfileResignTaxForm', new FcInvestorProfileResignTaxForm());
