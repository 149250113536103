class FcFounderOnboardFounderDetails implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        company: '<',
        founder: '<',
        nextState: '<',
        onUpdated: '&',
    };
    public controller = FcFounderOnboardFounderDetailsController;
    public templateUrl = 'founder-onboarding/founder-details.component.html';
}

class FcFounderOnboardFounderDetailsController implements ng.IComponentController {
    public company: any;
    public founder: any;
    public nextState: string;
    public onUpdated: (data: {founder: any}) => void;

    private errors: any = {};
    private fcSave: any;
    private pictureDisplay: string;

    constructor(
        private $state: any,
        private $uibModal: any,
        private CompanyTeamMember: any,
        private FCChoices: any,
        private FCFounderOnboardData: any,
        private FCGlobals: any
    ) {}

    public $onInit() {
        this.founder = angular.copy(this.founder);

        if (this.founder.picture) {
            this.pictureDisplay = this.FCGlobals.MEDIA_URL + this.founder.picture;
        } else {
            this.pictureDisplay = this.FCFounderOnboardData.urls.DEFAULT_FOUNDER_PICTURE;
        }
    }

    /* tslint:disable:no-unused-variable */
    private cropImage($files: Array<any>) {
        this.$uibModal.open({
            animation: false,
            backdrop: false,
            component: 'FcImageCropperModal',
            resolve: {
                files: () => $files,
                onCropped: () => this.onImageCropped.bind(this),
            },
            windowClass: 'FullTakeoverModal',
        });
    }

    /* tslint:disable:no-unused-variable */
    private next() {
        this.errors = {};
        const fieldsToSave = [
            'founder_type',
            'picture',
            'title',
        ];

        if (this.company.funded) {
            fieldsToSave.push('bio');
        }

        this.founder
            .$save(fieldsToSave)
            .then((response: any) => {
                this.onUpdated({founder: new this.CompanyTeamMember(response)});
                this.$state.go(this.nextState);
            })
            .catch((response: any) => angular.extend(this.errors, response.data))
            .finally(() => this.fcSave.saveAttemptFinished(this.errors));
    }

    private onImageCropped(croppedImage: any, croppedImageDisplay: any) {
        this.founder.picture = croppedImage;
        this.pictureDisplay = croppedImageDisplay;
    }

    /* tslint:disable:no-unused-variable */
    private removePicture(): void {
        this.pictureDisplay = this.FCFounderOnboardData.urls.DEFAULT_FOUNDER_PICTURE;
        this.founder.picture = '';
    }
}

angular
    .module('fundersclub.founderOnboarding')
    .component('fcFounderOnboardFounderDetails', new FcFounderOnboardFounderDetails());
