do ->

    EditPartnershipDetailsController = ($uibModal, FCChoices, FCGlobals, Partnership, FCPartnershipData) ->
        vm = @

        default_logo = FCPartnershipData.urls.logo_default_image
        vm.errors = {}

        vm.partnershipTypeChoices = FCChoices.PartnershipTypeChoices

        vm.partnership = new Partnership(FCPartnershipData.partnership)
        vm.partnership.logo_display = FCGlobals.MEDIA_URL + vm.partnership.logo

        vm.onImageCropped = (croppedImage, croppedImageDisplay) ->
            vm.partnership.logo = croppedImage
            vm.partnership.logo_display = croppedImageDisplay
            vm.form.$setDirty()

        vm.cropImage = ($files) ->
            $uibModal.open({
                animation: false,
                backdrop: false,
                component: 'FcImageCropperModal',
                resolve: {
                    files: -> $files,
                    onCropped: -> vm.onImageCropped,
                },
                windowClass: 'FullTakeoverModal',
            })

        vm.removeLogo = ->
            vm.partnership.logo_display = default_logo
            vm.partnership.logo = ''

        # Save
        vm.save = ->
            vm.errors = {}
            vm.partnership.$save([
                'name',
                'blurb',
                'url',
                'logo',
                'type',
                'flag_aca',
                'flag_nvca',
                'num_exits',
                'publicly_visible',
                'hide_from_directory',
            ])
                # unsaved-warning-form monitors the form pristine/dirty state
                .catch (response) ->
                    angular.extend(vm.errors, response.data)
                .finally ->
                    vm.fcSave.saveAttemptFinished(vm.errors)

        # End EditPartnershipDetailsController
        return


    EditPartnershipBackgroundController = (Partnership, FCPartnershipData) ->
        vm = @
        vm.partnership = new Partnership(FCPartnershipData.partnership)

        # Save
        vm.save = ->
            vm.errors = {}
            vm.partnership.$save([
                'description',
                'video_embed_url',
            ])
                # unsaved-warning-form monitors the form pristine/dirty state
                .catch (response) ->
                    angular.extend(vm.errors, response.data)
                .finally ->
                    vm.fcSave.saveAttemptFinished(vm.errors)

        # End EditPartnershipBackgroundController
        return


    EditPartnershipInvestmentTargetsController = ($http, Partnership, FCPartnershipData) ->
        vm = @
        vm.partnership = new Partnership(FCPartnershipData.partnership)
        vm.errors = {}
        vm.newLocation = ''
        vm.newMarket = ''
        vm.newStage = ''

        # Properties for each of the metadata sections.
        sections =
            location:
                newFieldName: 'newLocation'
                partnershipProperty: 'target_investments_locations'
                newItemKey: 'location'
            market:
                newFieldName: 'newMarket'
                partnershipProperty: 'target_investments_markets'
                newItemKey: 'market'
            stage:
                newFieldName: 'newStage'
                partnershipProperty: 'target_investments_stages'
                newItemKey: 'stage'

        vm.removeTargetInvestmentItem = (targetSection, indexToRemove) ->
            section = sections[targetSection]
            if not section
                return

            $http({
                method: 'delete'
                url: vm.partnership[section.partnershipProperty][indexToRemove].url
            }).then ->
                vm.partnership[section.partnershipProperty]?.splice(indexToRemove, 1)

        vm.addTargetInvestmentItem = (targetSection) ->
            section = sections[targetSection]
            if not section or not vm[section.newFieldName]
                return

            itemToAdd = {
                'partnership': vm.partnership.api_url
            }
            itemToAdd[section.newItemKey] = vm[section.newFieldName]

            $http({
                method: 'POST'
                url: vm.partnership.urls[section.partnershipProperty]
                data: itemToAdd
            }).then (response) ->
                vm.partnership[section.partnershipProperty]?.push(response.data)
                vm[section.newFieldName] = ''

        # End EditPartnershipInvestmentTargetsController
        return



    angular
        .module 'fundersclub.partnerships'
        .controller 'EditPartnershipDetailsController', EditPartnershipDetailsController
        .controller 'EditPartnershipBackgroundController', EditPartnershipBackgroundController
        .controller 'EditPartnershipInvestmentTargetsController', EditPartnershipInvestmentTargetsController
