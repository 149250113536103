class FcFounderOnboardInviteFounderModal implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        modalInstance: '<',
        resolve: '<',
    };
    public controller = FcFounderOnboardInviteFounderModalController;
    public templateUrl = 'founder-onboarding/invite-founder-modal.component.html';
}

class FcFounderOnboardInviteFounderModalController implements ng.IComponentController {
    public modalInstance: ng.ui.bootstrap.IModalServiceInstance;
    public resolve: {
        company: any,
        onInvited: (founder: any) => void,
    };

    private errors: any = {};
    private fcSave: any;
    private founder: any;

    constructor(private Company: any, private CompanyTeamMember: any, private FCChoices: any) {}

    public $onInit() {
        this.founder = new this.CompanyTeamMember({
            company: this.resolve.company.url,
            founder_type: '',
            title: '',
        });
    }

    /* tslint:disable:no-unused-variable */
    private invite() {
        this.errors = {};

        // We dont normally require an email address when adding a founder, but we need
        // one here to invite them, so lets do some client side validation
        if (!this.founder.work_email) {
            this.errors.work_email = ['This field is required'];
            this.fcSave.saveAttemptFinished(this.errors);
            return;
        }

        this.founder
            .$save()
            .then((founderResponse: any) => {
                let inviteData = {
                    email: this.founder.work_email.toLowerCase(),
                    first_name: this.founder.first_name,
                    founder_id: this.founder.id,
                    last_name: this.founder.last_name,
                };
                this.Company
                    .inviteEntrepreneur(this.resolve.company.company_slug, inviteData)
                    .then(() => {
                        this.resolve.onInvited(new this.CompanyTeamMember(founderResponse));
                        this.modalInstance.close();
                    })
                    .catch((response: any) => this.errors.invite_errors = [response.message])
                    .finally(() => this.fcSave.saveAttemptFinished(this.errors));
            })
            .catch((response: any) => {
                this.fcSave.saveAttemptFinished(this.errors);
                angular.extend(this.errors, response.data);
            });
    }
}

angular
    .module('fundersclub.users')
    .component('fcFounderOnboardInviteFounderModal', new FcFounderOnboardInviteFounderModal());
