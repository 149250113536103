class FcLeaveFeedback implements ng.IComponentOptions {
    public controller = FcLeaveFeedbackController;
    public templateUrl = 'support/leave-feedback.component.html';
}

class FcLeaveFeedbackController implements ng.IComponentController {
    private feedback: any;
    private alreadySavedFeedback: boolean = false;
    private errors: {[error: string]: Array<string>} = {};
    private fcSave: any;

    constructor (private CSATFeedback: any,
                 private FCLeaveFeedback: any,
                 private FCCSATFeedback: any,
                 private FCChoices: any) {}

    public $onInit() {
        this.feedback = new this.CSATFeedback(this.FCCSATFeedback);
        if (this.FCLeaveFeedback.saved) {
            this.alreadySavedFeedback = true;
        }
    }

    /* tslint:disable:no-unused-variable */
    private submitFeedback() {
        this.errors = {};
        this.feedback.$update()
            .catch((response: any) => {
                angular.extend(this.errors, response.data);
            }).finally(() => {
                this.fcSave.saveAttemptFinished(this.errors);
            });
    }
}

angular
    .module('fundersclub.support')
    .component('fcLeaveFeedback', new FcLeaveFeedback());
