do ->
    fcGmailContactsHelper = ($q, $http) ->
        class GmailContactsHelper
            constructor: (@clientId, @apiKey, @scopes) ->
                @scopes = @scopes or 'https://www.google.com/m8/feeds'

            getContacts: ->
                convertFeedEntriesToContacts = (entries) ->
                    results = []
                    for entry in entries
                        try
                            name = entry.title.$t
                            if not name
                                continue
                            results.push
                                email: entry.gd$email[0].address
                                name: name
                        catch error
                    results

                deferred = $q.defer()
                if @contacts
                    deferred.resolve(@contacts)
                else
                    @authenticate().then (accessToken) =>
                        contactUrl = """
                            https://www.google.com/m8/feeds/contacts/default/full/
                            ?access_token=#{accessToken}&max-results=1000&alt=json&callback=?
                        """
                        $
                            .getJSON(contactUrl)
                            .then (result) =>
                                @contacts = convertFeedEntriesToContacts(result.feed.entry)
                                deferred.resolve(@contacts)

                deferred.promise

            authenticate: =>
                deferred = $q.defer()
                if @accessToken
                    deferred.resolve(@accessToken)
                else
                    gapi.client.setApiKey(@apiKey)
                    authData =
                        client_id: @clientId
                        scope: @scopes
                        immediate: false

                    gapi.auth.authorize authData, (result) =>
                        if result and not result.error
                            @accessToken = result.access_token
                            deferred.resolve(@accessToken)
                        else
                            deferred.reject(result)

                deferred.promise

    angular
        .module 'fundersclub'
        .service 'fcGmailContactsHelper', fcGmailContactsHelper
