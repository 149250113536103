class FcInvestorProfileBoundForm implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        errors: '<',
        hideTaxStatus: '<?',
        investorProfile: '=',
        readonlyName: '<',
    };
    public controller = FcInvestorProfileBoundFormController;
    public templateUrl = 'users/investor-profile-bound-form.component.html';
}

class FcInvestorProfileBoundFormController implements ng.IComponentController {
    public errors: {[error: string]: Array<any>} = {};
    public hideTaxStatus: boolean;
    public investorProfile: IInvestorProfile;
    public readonlyName: boolean;

    constructor(private FCChoices: any) {}
}

angular
    .module('fundersclub.users')
    .component('fcInvestorProfileBoundForm', new FcInvestorProfileBoundForm());
