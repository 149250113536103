class FcExtDistDetails implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        extDistDetailsData: '<',
    };
    public controller = FcExtDistDetailsController;
    public templateUrl = 'funds/ext-dist-details.component.html';
}

class FcExtDistDetailsController implements ng.IComponentController {
    public extDistDetailsData: any;
    public submitting: boolean = false;

    private extDistDetails: any;
    private errors: any;
    private pollingInterval: any;
    private wireAddDirective: any;

    constructor(
        private $uibModal: any,
        private $window: any,
        private $interval: ng.IIntervalService,
        private $http: ng.IHttpService,
        private fcPaymentsManager: any,
        private FCChoices: any,
        private SignableLegalDocument: any,
        private ExtDistDetails: any,
        private _: UnderscoreStatic
    ) {}

    public $onInit() {
        this.extDistDetails = new this.ExtDistDetails(this.extDistDetailsData);
        this.pollForUpdates();
    }

    public pollForUpdates() {
        if (this.pollingInterval) {
            return;
        }

        if (this.extDistDetails.tax_doc_status === this.FCChoices.SigningStatus.RequiresSignature) {
            this.pollingInterval = this.$interval(() => {
                this.$http({
                    method: 'GET',
                    url: this.extDistDetails.url,
                }).success((data: any) => {
                    angular.extend(this.extDistDetails, data);
                    if (this.extDistDetails.tax_doc_status === this.FCChoices.SigningStatus.Signed) {
                        this.$interval.cancel(this.pollingInterval);
                        this.pollingInterval = null;
                    }
                });
            }, 1000);
        }
    }

    public submitInvestorDetails() {
        this.submitting = true;
        this.errors = {};
        this.extDistDetails.submitInvestorDetails()
            .then((response: any) => {
                angular.extend(this.extDistDetails, response.data);
                this.signTaxDoc();
            })
            .catch((response: any) => {
                angular.extend(this.errors, response.data);
            })
            .finally(() => {
                this.submitting = false;
            });
    }

    public signTaxDoc() {
        this.pollForUpdates();
        this.$uibModal.open({
            animation: false,
            ariaDescribedBy: 'modal-body',
            ariaLabelledBy: 'modal-title',
            component: 'fcExtDistDetailsSignTaxDocModal',
            resolve: {
                getExtDistDetails: () => {
                    return () => { return this.extDistDetails; };
                },
            },
            size: 'lg',
            windowClass: 'complete-investment--sign-legal-doc--modal',
        }).result.then((result: any) => {
            console.log('modal closed');
        });
    }

    public bindWireAdd(instance: any) {
        this.wireAddDirective = instance;
        this.wireAddDirective.wireAccount.recipient_name = this.extDistDetails.investor_name;
    }

    public wireAccountCreationError() {
        this.submitting = false;
    }

    public submitWireDetails() {
        this.submitting = true;
        this.wireAddDirective.add({
            customPostHook: (wireAccountId: any) => {
                this.extDistDetails.associateWireAccount(wireAccountId)
                    .then((response: any) => {
                        angular.extend(this.extDistDetails, response.data);
                        this.$window.scrollTo(0, 0);
                    })
                    .catch((response: any) => {
                        angular.extend(this.wireAddDirective.errors, response.data);
                        this.$window.document.getElementById('wire-account-form').scrollIntoView();
                    })
                    .finally(() => {
                        this.submitting = false;
                    });
            },
        });
    }

    public isUsBasedOnCountry() {
        return this.extDistDetails.country === 'US';
    }

    public submitMailAddr() {
        this.submitting = true;
        this.errors = {};
        this.extDistDetails.submitMailAddr()
            .then((response: any) => {
                angular.extend(this.extDistDetails, response.data);
                this.$window.scrollTo(0, 0);
            })
            .catch((response: any) => {
                angular.extend(this.errors, response.data);
            })
            .finally(() => {
                this.submitting = false;
            });
    }
 }

angular
    .module('fundersclub.funds')
    .component('fcExtDistDetails', new FcExtDistDetails());
