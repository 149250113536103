do ->

    # A small display hint we add to forms to remind the user the current
    # for has changes which haven't been saved.
    fcUnsavedChangesWarning = ->
        thisControllerAs = 'ucw'

        # This directive's link function.
        thisLink = (scope, element, attrs, formCtrl) ->
            scope[thisControllerAs].formCtrl = formCtrl

        # This directive's controller function.
        thisController = ($scope) ->
            vm = @
            vm.has_unsaved_changes = ->
                return !vm.formCtrl.$pristine
            return

        thisDirective =
            restrict: 'A'
            require: '^form'
            link: thisLink
            bindToController: true
            controllerAs: thisControllerAs
            controller: thisController
            replace: true
            templateUrl: 'forms/unsaved-changes.directive.html'
        return thisDirective


    angular
        .module 'fundersclub.forms'
        .directive 'fcUnsavedChangesWarning', fcUnsavedChangesWarning
