do ->

    # Form responsible for creating new fund update items.
    fcFundUpdateItemAdd = (FundUpdateItem, $timeout) ->
        thisScope =
            'add_form_enabled': '=addFormEnabled'
            'update_items': '=updateItems'
            'fund_profile_url': '@fcFundUpdateItemAdd'
            'addCallback': '&onAdd'
            'expandCallback': '&onExpand'
            'collapseCallback': '&onCollapse'

        thisController = ->
            vm = @
            vm.errors = {}
            vm.add_form_expanded = false

            update_item_template =
                fund_profile: vm.fund_profile_url
                contents: ''
            vm.update_item = angular.copy(update_item_template)

            # Expand the form for adding a update item.
            vm.expandAddForm = ->
                vm.add_form_expanded = !vm.add_form_expanded
                if vm.add_form_expanded
                    vm.expandCallback()
                else
                    vm.collapseCallback()
                    vm.resetForm()

            # Add
            vm.add = ->
                vm.errors = {}
                update_item = new FundUpdateItem(vm.update_item)
                update_item.$save()
                    # unsaved-warning-form monitors the form pristine/dirty state
                    .then (response) ->
                        vm.update_item = angular.copy(update_item_template)
                        vm.expandAddForm()
                        vm.addCallback({'updateItem': new FundUpdateItem(response)})
                    .catch (response) ->
                        angular.extend(vm.errors, response.data)
                    .finally ->
                        vm.fcSave.saveAttemptFinished(vm.errors)

            # Resets the form state.
            vm.resetForm = ->
                # Clear form feedback, clear out input values.
                vm.errors = {}
                vm.update_item = angular.copy(update_item_template)
                vm.form.$setPristine()

            # Cancel
            vm.cancel = ->
                vm.expandAddForm()
                vm.resetForm()

            return

        thisDirective =
            templateUrl: 'funds/update-item-add.directive.html'
            restrict: 'A'
            scope: thisScope
            bindToController: true
            controllerAs: 'afuic'
            controller: thisController
        return thisDirective

    angular
        .module 'fundersclub.funds'
        .directive 'fcFundUpdateItemAdd', fcFundUpdateItemAdd
