class FcFundDistribution implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        distributionData: '<',
    };
    public controller = FcFundDistributionController;
    public templateUrl = 'funds/distribution.component.html';
}

class FcFundDistributionController implements ng.IComponentController {
    public distributionData: any;

    private bankAccount: any;
    private distribution: any;
    private errors: any = {};
    private fcSave: any;
    private resendingVerificationCode = false;
    private selectedWireAccount: any = null;
    private selectedBankAccountId: any = null;
    private visibleWithdrawDistributions: Array<number> = [];

    constructor(
        private $timeout: any,
        private BankAccount: any,
        private FCChoices: any,
        private FundDistributionRecipient: any,
        private $uibModal: any
    ) {}

    public $onInit() {
        this.distribution = new this.FundDistributionRecipient(this.distributionData);
        this.bankAccount = new this.BankAccount(this.distribution.bank_account);
        this.distribution.reinvestment_amount = this.distribution.amount_net_of_withholding;

        if (this.distribution.is_ira) {
            this.distribution.method = this.FCChoices.FundDistributionRecipientMethod.IRA;
        } else if (!this.distribution.can_claim_via_wire) {
            this.distribution.method = this.FCChoices.FundDistributionRecipientMethod.ACH;
        }
    }

    /* tslint:disable:no-unused-variable */
    private showWithdrawDistribution(distributionId: number) {
        if (this.visibleWithdrawDistributions.indexOf(distributionId) === -1) {
            this.visibleWithdrawDistributions.push(distributionId);
        }
    }

    private confirmClaim() {
        if (this.distribution.method === this.FCChoices.FundDistributionRecipientMethod.SplitInvestment ||
            this.distribution.method === this.FCChoices.FundDistributionRecipientMethod.AnotherInvestment
        ) {
            this.$uibModal.open({
                component: 'FcDistributionConfirmModal',
                resolve: {
                    onCancel: () => () => this.fcSave.saving = false,
                    onConfirm: () => () => this.claim(),
                },
            });
        } else {
            this.claim();
        }
    }

    /* tslint:disable:no-unused-variable */
    private claim() {
        this.errors = {};

        if (this.selectedWireAccount) {
            this.distribution.wire_account = this.selectedWireAccount.id;
        }

        // Bank account select/add form uses -1 for unselected bank account id
        if (this.selectedBankAccountId > 0) {
            this.distribution.bank_account = this.selectedBankAccountId;
        }

        // Reload page if this is a split distribution -
        // In order to show that the current distribution was split into two distributions
        let reloadPage = (this.distribution.method === this.FCChoices.FundDistributionRecipientMethod.SplitInvestment);

        this.distribution
            .$save()
            .then((response: any) => {
                if (reloadPage) {
                    window.location.reload();
                }

                this.distribution = new this.FundDistributionRecipient(response);
                this.bankAccount = new this.BankAccount(this.distribution.bank_account);

                let distributionIndex = this.visibleWithdrawDistributions.indexOf(this.distribution.id);
                if (distributionIndex !== -1) {
                    this.visibleWithdrawDistributions.splice(distributionIndex, 1);
                }
            })
            .catch((response: any) => angular.extend(this.errors, response.data))
            .finally(() => this.fcSave.saveAttemptFinished(this.errors));
    }

    /* tslint:disable:no-unused-variable */
    private onBankAccountVerified(bankAccount: any) {
        // No need to do a whole trip to the server to update the one bit of state we care about
        this.distribution.needs_bank_account_verification = false;
    }

    /* tslint:disable:no-unused-variable */
    private resendVerificationCode() {
        this.resendingVerificationCode = true;
        this.distribution
            .resendVerificationCode()
            .then((response: any) => {
                this.resendingVerificationCode = false;
                this.distribution.sent_verification_code_recently = (
                    response.data.sent_verification_code_recently
                );
            });
    }
}

angular
    .module('fundersclub.funds')
    .component('fcFundDistribution', new FcFundDistribution());
