do ->

    fcNewsfeedItem = ->
        thisScope =
            'newsfeed_item': '=fcNewsfeedItem'

        thisController = ->
            vm = @
            vm.momentFrom = moment(vm.newsfeed_item.created_at).fromNow()
            return

        thisDirective =
            templateUrl: 'newsfeed/newsfeed-item.directive.html'
            restrict: 'A'
            scope: thisScope
            bindToController: true
            controllerAs: 'nfic'
            controller: thisController
            replace: true
        return thisDirective



    angular
        .module 'fundersclub.newsfeed'
        .directive 'fcNewsfeedItem', fcNewsfeedItem
