class FcInvestInvestorProfiles implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        defaultSelection: '<',
        investorProfiles: '<',
        onAdded: '&',
        onSelected: '&',
        onUpdated: '&',
    };
    public controller = FcInvestInvestorProfilesController;
    public templateUrl = 'invest/investor-profiles.component.html';
}

class FcInvestInvestorProfilesController implements ng.IComponentController {
    public defaultSelection: any;
    public investorProfiles: Array<IInvestorProfile>;
    public onAdded: (data: {investorProfile: IInvestorProfile}) => void;
    public onSelected: (data: {investorProfileId: number}) => void;
    public onUpdated: (data: {investorProfile: IInvestorProfile}) => void;

    private expandedProfileId: number = null;
    /* tslint:disable:no-unused-variable */
    private NEW_INVESTOR_PROFILE_ID: number = 0;
    private selectedProfileId: number = null;

    constructor(private $timeout: ng.ITimeoutService) {}

    public $onInit(): void {
        this.selectedProfileId = this.defaultSelection.id;
    }

    public $postLink(): void {
        // Show add form by default if we dont have an investor profile selected
        if (!this.selectedProfileId) {
            this.selectProfile(this.NEW_INVESTOR_PROFILE_ID);
            this.expandedProfileId = this.NEW_INVESTOR_PROFILE_ID;
        }
    }

    /* tslint:disable:no-unused-variable */
    private isFormExpanded(investorProfileId: number) {
        return this.expandedProfileId === investorProfileId;
    }

    /* tslint:disable:no-unused-variable */
    private isSelected(investorProfileId: number) {
        return this.selectedProfileId === investorProfileId;
    }

    /* tslint:disable:no-unused-variable */
    private _onAdded(investorProfile: IInvestorProfile) {
        // Show the saved message for a second, close form,
        // then report up the chain.
        this.$timeout(() => {
            this.expandedProfileId = null;
            this.$timeout(() => {
                this.onAdded({investorProfile: investorProfile});
                this.selectProfile(investorProfile.id);
            }, 400);
        }, 1000);
    }

    /* tslint:disable:no-unused-variable */
    private onCanceled() {
        this.expandedProfileId = null;

        // Unselect add form if it was canceled
        if (this.selectedProfileId === this.NEW_INVESTOR_PROFILE_ID) {
            this.selectedProfileId = null;
        }
    }

    /* tslint:disable:no-unused-variable */
    private onSaved(investorProfile: IInvestorProfile) {
        // Show the saved message for a second, close form,
        // then report up the chain.
        this.$timeout(() => {
            this.toggleForm(investorProfile.id);
            this.$timeout(() => {
                this.onUpdated({investorProfile: investorProfile});
            }, 400);
        }, 1000);
    }

    private selectProfile(investorProfileId: number) {
        this.selectedProfileId = investorProfileId;
        if (this.expandedProfileId !== investorProfileId) {
            // Collapse the profile that was open.
            this.expandedProfileId = null;
        }
        this.onSelected({investorProfileId: investorProfileId});
    }

    /* tslint:disable:no-unused-variable */
    private toggleForm(investorProfileId: number) {
        if (this.expandedProfileId === investorProfileId) {
            // Collapse current profile.
            this.expandedProfileId = null;
        } else {
            this.expandedProfileId = investorProfileId;
        }
        this.selectProfile(investorProfileId);
    }
}

angular
    .module('fundersclub.users')
    .component('fcInvestInvestorProfiles', new FcInvestInvestorProfiles());
