class FcImageCropperModal implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        modalInstance: '<',
        resolve: '<',
    };
    public controller = FcImageCropperModalController;
    public templateUrl = 'forms/image-cropper-modal.component.html';
}

class FcImageCropperModalController implements ng.IComponentController {
    public modalInstance: ng.ui.bootstrap.IModalServiceInstance;
    public resolve: {
        files: Array<any>,
        onCropped: any,
    };

    private cropper: any;
    private imagePath: string;
    private previousZoom: number;
    private stepsNeededToFitImage: number;
    private zoom: number;
    private ZOOM_STEP = 0.05;

    constructor(
        private $element: JQuery,
        private $scope: ng.IRootScopeService,
        private $timeout: ng.ITimeoutService
    ) {}

    public $onInit() {
        if (!this.resolve.files) {
            this.modalInstance.dismiss();
            return;
        }

        let file = this.resolve.files[0];
        if (!file || !file.size) {
            this.modalInstance.dismiss();
            return;
        }

        let fileReader = new FileReader();
        fileReader.onload = () => {
            this.imagePath = fileReader.result;
            let image = new Image();
            image.onload = () => {
                this.$timeout(() => {
                    let imageElement = this.$element.find('img') as any;
                    this.cropper = imageElement.cropper({
                        aspectRatio: 1,
                        cropBoxMovable: false,
                        cropBoxResizable: false,
                        dragCrop: false,
                        guides: false,
                        minCropBoxHeight: 200,
                        mouseWheelZoom: false,
                        strict: false,
                    });
                    this.zoom = this.previousZoom = this.stepsNeededToFitImage = this.getStepsNeededToFitImage(
                        image.width,
                        image.height
                    );
                }, 500);
            };
            image.src = fileReader.result;
        };
        fileReader.readAsDataURL(file);
    }

    private dataURItoBlob(dataURI: string): Blob {
        let byteString = atob(dataURI.split(',')[1]);
        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        byteString.split('').map((_, index) => {
            ia[index] = byteString.charCodeAt(index);
        });
        return new Blob([ab], {
            type: 'image/jpeg',
        });
    }

    /* tslint:disable:no-unused-variable */
    private finishCropping() {
        let croppedImage = this.cropper.cropper('getCroppedCanvas', {
            height: 400,
            width: 400,
        });
        let dataURI: any = croppedImage.toDataURL();
        this.resolve.onCropped(this.dataURItoBlob(dataURI), dataURI);
        this.modalInstance.dismiss();
    }

    private getStepsNeededToFitImage(width: number, height: number): number {
        let smallerSide = Math.min(width, height);
        let largerSide = Math.max(width, height);
        let ratio = largerSide / smallerSide;

        // If ratio is 1 we technically dont need any zooming to fit the picture, but lets give
        // 2 steps of zoom so it doesnt feel weird
        if (ratio === 1) {
            return 2;
        }

        // Math.log is the natural logarithm.  We need log base 10.  To get this do:
        // Math.log(x) / Math.log(10)
        let numerator = Math.log(ratio) / Math.log(10);
        let denominator = (Math.log(1 - this.ZOOM_STEP) / Math.log(10)) * -1;
        return Math.ceil(numerator / denominator);
    }

    /* tslint:disable:no-unused-variable */
    private onZoomChanged() {
        let difference = this.zoom - this.previousZoom;
        this.cropper.cropper('zoom', (this.ZOOM_STEP * difference));
        this.previousZoom = this.zoom;
    }
}

angular
    .module('fundersclub.users')
    .component('fcImageCropperModal', new FcImageCropperModal());
