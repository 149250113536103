class FcInvestorProfileAdd implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        onAdded: '&',
        onCanceled: '&',
    };
    public controller = FcInvestorProfileAddController;
    public templateUrl = 'users/investor-profile-add.component.html';
}

class FcInvestorProfileAddController implements ng.IComponentController {
    public onAdded: (investorProfile: {investorProfile: IInvestorProfile}) => void;
    public onCanceled: () => void;

    private entityInvestorProfile: IInvestorProfile;
    private profileType: string;
    private userIsProxyOnly: boolean = false;

    constructor(
        private FCChoices: any,
        private InvestorProfile: IInvestorProfile,
        private UserProfile: IUserProfileClass
    ) {}

    public $onInit(): void {
        this.UserProfile.isCurrentUserProxyOnly().then((response: any) => {
            this.profileType = this.FCChoices.InvestorTypeChoices.Entity;
            this.entityInvestorProfile = new this.InvestorProfile({
                country: '',
                investor_type: this.FCChoices.InvestorTypeChoices.Entity,
                state: '',
            });
            this.userIsProxyOnly = response.data.is_proxy_only;
        });
    }

    /* tslint:disable:no-unused-variable */
    private _onAdded(investorProfile: IInvestorProfile): void {
        this.onAdded({investorProfile: investorProfile});
    }
}

angular
    .module('fundersclub.users')
    .component('fcInvestorProfileAdd', new FcInvestorProfileAdd());
