do ->

    # Building block for code of conduct's "default answer or custom answer" form.
    fcCodeOfConductQuestion = ->
        thisScope =
            fcModel: '='
            fcErrors: '='

        thisController = ($scope) ->
            vm = @

            # Set initial state.
            vm.provide_custom_answer = vm.fcModel?.length > 0

            return

        thisDirective =
            restrict: 'E'
            transclude: true
            bindToController: true
            controllerAs: 'fdoct'
            controller: thisController
            scope: thisScope
            templateUrl: 'funds/code-of-conduct-question.directive.html'
            replace: true
        return thisDirective


    angular
        .module 'fundersclub.funds'
        .directive 'fcCodeOfConductQuestion', fcCodeOfConductQuestion
