do ->
    # Renders a display value for a Django choices field
    fcChoicesDisplay = ->
        thisFilter = (input, choices, fallback) ->
            for choice in choices
                if choice[0] == input
                    return choice[1]
            return fallback

        thisFilter

    angular
        .module 'fundersclub.forms'
        .filter 'fcChoicesDisplay', fcChoicesDisplay
