class FcToiTransfer implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        curUserIsTransferee: '<',
        curUserIsTransferer: '<',
        toiData: '<',
    };
    public controller = FcToiTransferController;
    public templateUrl = 'users/toi-transfer.component.html';
}

class FcToiTransferController implements ng.IComponentController {
    public curUserIsTransferee: boolean;
    public curUserIsTransferer: boolean;
    public toiData: any;
    public percentAmounts: any = {};

    private errors: any = {};
    private resettingPercents: boolean = false;
    private selectedAction: string = 'all-100';
    private submitting: boolean = false;
    private pollingInterval: any;

    constructor(
        private $filter: ng.IFilterService,
        private $http: ng.IHttpService,
        private $interval: ng.IIntervalService,
        private $uibModal: any,
        private $window: ng.IWindowService,
        private FCChoices: any
    ) {
    }

    public $onInit(): void {
        this.pollForUpdates();

        for (let item of this.toiData.items) {
            if (!item.is_eligible_for_transfer) {
                this.percentAmounts[item.id] = 0;
            }
        }
    }

    public pollForUpdates() {
        if (this.pollingInterval) {
            return;
        }

        if ((this.toiData.status === this.FCChoices.TOIStatus.TransfererSignatureNeeded) ||
            (this.toiData.status === this.FCChoices.TOIStatus.TransfereeSignatureNeeded)) {
            this.pollingInterval = this.$interval(() => {
                this.$http({
                    data: {
                        action: 'poll',
                    },
                    method: 'POST',
                    url: this.$window.location.href,
                }).success((data: any) => {
                    angular.extend(this.toiData, data);
                });
            }, 1000);
        }
    }

    public onActionApply(): void {
        switch (this.selectedAction) {
            case 'all-100':
                for (let item of this.toiData.items) {
                    this.percentAmounts[item.id] = 100;
                }
                break;

            case 'all-50':
                for (let item of this.toiData.items) {
                    this.percentAmounts[item.id] = 50;
                }
                break;

            case 'all-0':
                for (let item of this.toiData.items) {
                    this.percentAmounts[item.id] = 0;
                }
                break;

            case 'all-clear':
                for (let item of this.toiData.items) {
                    this.percentAmounts[item.id] = undefined;
                }
                break;

            default:
                break;
        }

        for (let item of this.toiData.items) {
            if (!item.is_eligible_for_transfer) {
                this.percentAmounts[item.id] = 0;
            }
        }
    }

    public hasMultipleInvestmentsInLlc(): boolean {
        for (let item of this.toiData.items) {
            if (item.investments.length > 1) {
                return true;
            }
        }
        return false;
    }

    public calcTransferAmount(itemId: number): any {
        const percents = this.percentAmounts[itemId];
        const item: any = this.$filter('filter')(this.toiData.items, {'id': itemId})[0];
        if (!angular.isNumber(percents) || percents < 0 || percents > 100) {
            return '–';
        }
        return (<any> this.$filter('dollars'))(item.total_original_amount * percents / 100);
    }

    public submitPercents(): void {
        this.submitting = true;
        this.errors = {};

        this.$http({
            data: {
                action: 'set-percents',
                percents: this.percentAmounts,
            },
            method: 'POST',
            url: this.$window.location.href,
        }).success((data: any) => {
            this.toiData = data;
            this.submitting = false;
            this.$window.scrollTo(0, 0);
        }).catch((resp: any) => {
            this.submitting = false;
            this.errors = resp.data;
        });

    }

    public resetPercents(): void {
        this.resettingPercents = true;
        this.submitting = true;
        this.errors = {};

        this.$http({
            data: {
                action: 'reset-percents',
            },
            method: 'POST',
            url: this.$window.location.href,
        }).success((data: any) => {
            this.toiData = data;
            this.resettingPercents = false;
            this.submitting = false;
            this.$window.scrollTo(0, 0);
        }).catch((resp: any) => {
            this.resettingPercents = false;
            this.submitting = false;
            this.errors = resp.data;
        });
    }

    public confirmPercents(): void {
        this.submitting = true;
        this.errors = {};

        this.$http({
            data: {
                action: 'confirm-percents',
            },
            method: 'POST',
            url: this.$window.location.href,
        }).success((data: any) => {
            this.toiData = data;
            this.submitting = false;
            this.$window.scrollTo(0, 0);
            this.pollForUpdates();
        }).catch((resp: any) => {
            this.submitting = false;
            this.errors = resp.data;
        });
    }

    public signDoc(toiItem: any): void {
        this.$uibModal.open({
            animation: false,
            ariaDescribedBy: 'modal-body',
            ariaLabelledBy: 'modal-title',
            component: 'fcToiSignDocModal',
            resolve: {
                getToiItem: () => {
                    return () => { return this.$filter('filter')(this.toiData.items, {'id': toiItem.id}, true)[0]; };
                },
            },
            size: 'lg',
            windowClass: 'complete-investment--sign-legal-doc--modal',
        }).result.then((result: any) => {
            console.log('modal closed');
        });
    }

    public canSign(toiItem: any): boolean {
        if ((toiItem.doc_status !== this.FCChoices.DocStatus.NeedTransfererSig) &&
            (toiItem.doc_status !== this.FCChoices.DocStatus.NeedTransfereeSig)) {
            return false;
        }

        if ((this.toiData.status === this.FCChoices.TOIStatus.TransfererSignatureNeeded) &&
            (toiItem.doc_status === this.FCChoices.DocStatus.NeedTransfererSig)) {
            return true;
        }

        if (this.toiData.status === this.FCChoices.TOIStatus.TransfereeSignatureNeeded) {
            if (this.curUserIsTransferee) {
                return toiItem.doc_status === this.FCChoices.DocStatus.NeedTransfereeSig;
            }
        }

        return false;
    }

    public isSigned(toiItem: any): boolean {
        if (toiItem.doc_status === this.FCChoices.DocStatus.NeedManagerSig) {
            return true;
        }

        if (toiItem.doc_status === this.FCChoices.DocStatus.DocNotNeeded) {
            return false;
        }

        if (this.toiData.status === this.FCChoices.TOIStatus.TransfererSignatureNeeded) {
            return toiItem.doc_status === this.FCChoices.DocStatus.NeedTransfereeSig;
        }

        if (this.toiData.status === this.FCChoices.TOIStatus.TransfereeSignatureNeeded) {
            if (this.curUserIsTransferee) {
                return toiItem.doc_status === this.FCChoices.DocStatus.NeedManagerSig;
            }
            return true;
        }

        return false;
    }
}

angular
    .module('fundersclub.users')
    .component('fcToiTransfer', new FcToiTransfer());
