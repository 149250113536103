do ->

    # Form responsible for converting a set of debt assets into equity assets.
    fcPositionClosedForm = (Company, Partnership) ->
        thisScope =
            'closeWithCancel': '&onCancel'
            'closeWithSave': '&onSave'
            'companyId': '@'
            'partnershipId': '@'

        thisController = ->
            vm = @
            vm.errors = {}
            vm.dataLoaded = false
            vm.partnership = new Partnership({id: vm.partnershipId})
            # Do API call to load required form data.
            vm.partnership.$get().then ->
                vm.partnership.position_closed_get(vm.companyId)
                    .then (response) ->
                        vm.company = response.data
                        vm.dataLoaded = true
                    .catch (response) ->
                        # It's hard to know if there's something better to do here...
                        alert 'This form failed to load. Please refresh the page and try again'

            vm.saveForm = ->
                params = vm.company
                vm.partnership.position_closed_post(params, vm.companyId)
                    .then (e) ->
                        vm.closeWithSave()
                    .catch (response) ->
                        if response.status == 500
                            alert 'This form failed to load. Please refresh the page and try again'
                        angular.extend(vm.errors, response.data.detail)
                    .finally ->
                        vm.fcSave.saveAttemptFinished(vm.errors)

            vm.cancelForm = ->
                vm.closeWithCancel()

            return

        thisDirective =
            templateUrl: 'investment-assets/position-closed.directive.html'
            restrict: 'A'
            scope: thisScope
            bindToController: true
            controllerAs: 'fcdf'
            controller: thisController

        return thisDirective


    angular
        .module 'fundersclub.assets'
        .directive 'fcDebtRepaidForm', fcPositionClosedForm
