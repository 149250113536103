class FcAllInvestments implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        assetsDate: '<',
        assetsPublishedDate: '<',
        investmentYears: '<',
        nextAssetsDate: '<',
        nextAssetsPublishedDate: '<',
        partnerships: '<',
        previousAssetsDate: '<',
        secondPreviousAssetsDate: '<',
    };
    public controller = FcAllInvestmentsController;
    public templateUrl = 'users/all-investments.component.html';
}

class FcAllInvestmentsController implements ng.IComponentController {
    public ALL_YEARS = 'All';

    public assetsDate: string;
    public investmentYears: Array<number | string>;
    public partnerships: Array<any>;
    public previousAssetsDate: string;
    public secondPreviousAssetsDate: string;

    private cache: any = {};
    private highchartsConfig: any;
    private investments: any;
    private loading: boolean = true;
    private reverseSort: boolean;
    private selectedPartnership: any;
    private selectedYear: number | string;
    private sort: string = '';
    private summary: any;

    constructor(
        private $window: any,
        private $http: ng.IHttpService,
        private $uibModal: ng.ui.bootstrap.IModalService
    ) {
        this.selectedYear = this.ALL_YEARS;

        // http://api.highcharts.com/highcharts/lang :(
        this.$window.Highcharts.setOptions({
            lang: {
                thousandsSep: ',',
            },
        });
    }

    public $onChanges(changes: any) {
        if (changes.partnerships && changes.partnerships.isFirstChange() && changes.partnerships.currentValue.length) {
            this.selectedPartnership = changes.partnerships.currentValue[0];
            this.loadInvestments();
        }
    }

    /* tslint:disable:no-unused-variable */
    public loadInvestments() {
        /* See if we have the data we need cached, and if not load it */
        const key = this.selectedPartnership.id.toString() + '-' + this.selectedYear;

        if (!this.cache.hasOwnProperty(key)) {
            this.loading = true;
            this.$http.get(
                this.selectedPartnership.urls.all_investments_data + '?year=' + this.selectedYear
            ).then((response: any) => {
                this.cache[key] = response.data;
                this.loading = false;

                for (let investment of this.cache[key].investments) {
                    if (investment.status === 'pending') {
                        if (this.selectedPartnership.is_fundersclub) {
                            investment.invested_for_display = 0;
                        }
                        investment.net_value_without_markdowns_for_display = 0;
                        investment.multiple_without_markdowns_for_display = 0;
                    } else {
                        investment.invested_for_display = investment.invested;
                        investment.net_value_without_markdowns_for_display = investment.net_value_without_markdowns;
                        investment.multiple_without_markdowns_for_display = investment.multiple_without_markdowns;
                    }
                }

                this.loadInvestments();
            });

            return;
        }

        const data = this.cache[key];
        this.summary = data.summary;
        this.investments = data.investments;
        this.reverseSort = false;
        this.sort = '';

        this.highchartsConfig = {
            chart: {
                marginRight: 0,
                panKey: 'shift',
                panning: true,
                spacingRight: 0,
                zoomType: 'x',
            },
            plotOptions: {
                series: {
                    marker: {
                       enabled: false,
                    },
                    step: 'left',
                },
            },
            responsive: {
                rules: [{
                    chartOptions: {
                        chart: {
                            height: 250,
                        },
                    },
                    condition: {
                        maxWidth: 600,
                    },

                }],
            },
            series: [
                {
                    color: '#2E7EC5',
                    data: data.graph.invested_per_week,
                    lineWidth: 2,
                    marker: {
                        enabled: false,
                    },
                    name: 'Invested',
                    states: {
                        hover: {
                              enabled: true,
                              lineWidthPlus: 0,
                        },
                    },
                    zIndex: 3,
                }, {
                    color: '#2DCD8A',
                    data: data.graph.value_per_week,
                    fillOpacity: 0.2,
                    marker: {
                        enabled: false,
                    },
                    name: 'Estimated Value',
                    states: {
                        hover: {
                              enabled: true,
                              lineWidthPlus: 0,
                        },
                    },
                    type: 'area',
                    zIndex: 1,
                },
                {
                    color: '#1D5A40',
                    data: data.graph.distributions,
                    fillOpacity: 0.2,
                    marker: {
                        enabled: false,
                    },
                    name: 'Distributions',
                    showInLegend: data.graph.distributions.length > 0,
                    type: 'area',
                    visible: data.graph.distributions.length > 0,
                    zIndex: 2,
                },
            ],
            title: {
                text: null,
            },
            tooltip: {
                backgroundColor: 'rgba(255,255,255,0.95)',
                borderColor: '#CED7DF',
                borderRadius: 5,
                borderWidth: 1,
                crosshairs: true,
                headerFormat: '<span style="font-size: 10px; line-height: 20px;">{point.key}</span><br/>',
                padding: 15,
                shadow: false,
                shared: true,
                valueDecimals: 0,
                valuePrefix: '$',
                xDateFormat: '%b %e, %Y',
            },
            xAxis: {
                crosshair: {
                    color: '#E4EAEE',
                },
                endOnTick: false,
                gridLineColor: '#E4EAEE',
                lineColor: '#E4EAEE',
                maxPadding: 0,
                minPadding: 0,
                startOnTick: false,
                tickColor: '#E4EAEE',
                title: {
                    text: null,
                },
                type: 'datetime',
                useHtml: true,
            },
            yAxis: {
                gridLineColor: '#E4EAEE',
                labels: {
                    formatter: function() {
                        let value = this.axis.defaultLabelFormatter.call(this);
                        if (/^[0-9]{4}$/.test(value)) {
                            value = Highcharts.numberFormat(this.value, 0);
                        }
                        return '$' + value;
                    },
                    style: {
                        color: '#333B61',
                    },
                },
                lineColor: '#E4EAEE',
                tickColor: '#E4EAEE',
                title: {
                    text: null,
                },
            },
        };
    }

    /* tslint:disable:no-unused-variable */
    public onInvestmentClicked(investment: any) {
        if (investment.status === 'pending' || investment.status === 'incomplete') {
            return;
        }

        let modal: any = this.$uibModal
            .open({
                animation: false,
                component: 'FcAllInvestmentsViewInvestmentModal',
                resolve: {
                    investment_details_url: () => investment.my_investments_details_url,
                },
                windowClass: 'FullTakeoverModal FullTakeoverModal--narrow',
            });
    }

    /* tslint:disable:no-unused-variable */
    private resort(sort: string): void {
        if (this.sort === sort) {
            if (this.reverseSort) {
                this.reverseSort = false;
                this.sort = '';
            } else {
                this.reverseSort = !this.reverseSort;
            }
        } else {
            this.sort = sort;
            this.reverseSort = false;
        }
    }
}

angular
    .module('fundersclub.users')
    .component('fcAllInvestments', new FcAllInvestments());
