class FcReserveInvestmentAmount implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        errors: '<',
        fundHasMaximum: '<',
        investmentAmount: '<',
        isFundOutOfSpots: '<',
        isFundOversubscribed: '<',
        isProrataFundingPeriod: '<',
        onUpdated: '&',
        prorataAmount: '<',
        reserveRange: '<',
        waitlistedAmount: '<',
    };
    public controller = FcReserveInvestmentAmountController;
    public templateUrl = 'invest/investment-amount.component.html';
}

class FcReserveInvestmentAmountController implements ng.IComponentController {
    public errors: Array<string>;
    public investmentAmount: number;
    public isFundOutOfSpots: boolean;
    public isFundOversubscribed: boolean;
    public isProrataFundingPeriod: boolean;
    public onUpdated: (investmentAmount: number) => void;
    public prorataAmount: number;
    public reserveRange: {[amount: string]: number};
    public waitlistedAmount: number;

    private inputmaskOptions: any;

    public $onInit() {
        this.inputmaskOptions = {
            autoGroup: true,
            digits: 2,
            groupSeparator: ',',
            radixPoint: '.',
            rightAlign: false,
        };
    }
}

angular
    .module('fundersclub.invest')
    .component('fcReserveInvestmentAmount', new FcReserveInvestmentAmount());
