class FcFounderOnboardMailingAddress implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        company: '<',
        nextState: '<',
        onUpdated: '&',
    };
    public controller = FcFounderOnboardMailingAddressController;
    public templateUrl = 'founder-onboarding/mailing-address.component.html';
}

class FcFounderOnboardMailingAddressController implements ng.IComponentController {
    public company: any;
    public nextState: string;
    public onUpdated: (data: {company: any}) => void;

    private errors: any = {};
    private fcSave: any;

    constructor(private $state: any, private Company: any) {}

    public $onInit() {
        this.company = angular.copy(this.company);
    }

    /* tslint:disable:no-unused-variable */
    private next() {
        this.errors = {};
        this.company
            .updateMailingAddress()
            .then((response: any) => {
                this.onUpdated({company: new this.Company(response.data)});
                this.$state.go(this.nextState);
            })
            .catch((response: any) => angular.extend(this.errors, response.data))
            .finally(() => this.fcSave.saveAttemptFinished(this.errors));
    }
}

angular
    .module('fundersclub.founderOnboarding')
    .component('fcFounderOnboardMailingAddress', new FcFounderOnboardMailingAddress());
