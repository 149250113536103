class FcReserveInvestment implements ng.IComponentOptions {
    public controller = FcReserveInvestmentController;
    public templateUrl = 'invest/reserve-investment.component.html';
}

class FcReserveInvestmentController implements ng.IComponentController {
    private bankAccount: any;
    private errors: {[error: string]: Array<string>} = {};
    private investment: IInvestment;
    private investorProfile: IInvestorProfile;
    private saveDistributionsAmount: ng.IPromise<any>;
    private saveInvestmentAmount: ng.IPromise<any>;
    private saveInvestmentCreditAmount: ng.IPromise<any>;

    constructor(
        private $intercom: any,
        private $timeout: ng.ITimeoutService,
        private $uibModal: ng.ui.bootstrap.IModalService,
        private $window: ng.IWindowService,
        private BankAccount: any,
        private FCReserveInvestmentData: any,
        private FCChoices: any,
        private Investment: IFcModelResource<IInvestment>,
        private InvestorProfile: IFcModelResource<IInvestorProfile>,
        private Raven: RavenStatic
    ) {}

    public $onInit(): void {
        this.bankAccount = new this.BankAccount(this.FCReserveInvestmentData.bank_account);
        this.investment = new this.Investment(this.FCReserveInvestmentData.investment);
        this.investorProfile = new this.InvestorProfile(
            this.FCReserveInvestmentData.investor_profile
        );

        this.investment.distributions_amount = parseFloat(<any> this.investment.distributions_amount);
        this.investment.investor_profile = this.investorProfile.url;
        this.investment.reservation_bank_account = this.bankAccount.url;
        this.investment.use_distributions  = !!this.investment.distributions_amount;
        this.investment.use_investment_credit = !!this.investment.investment_credit_amount;
    }

    /* tslint:disable:no-unused-variable */
    private additionalProrataInterest() {
        if (!this.investment.fund.is_in_followon_early_access_state) {
            return 0;
        }

        return Math.max(
            this.investmentAmountWithCredit() - this.investment.prorata_amount,
            0
        );
    }

    /* tslint:disable:no-unused-variable */
    private cancelInvestment(): ng.IPromise<any> {
        let cancelPromise = this.investment.cancel();
        cancelPromise.then((response: any) => {
            let investment = new this.Investment(response.data);
            let modal: any = this.$uibModal
                .open({
                    animation: false,
                    backdrop: false,
                    component: 'FcInvestmentDeletionReasons',
                    resolve: {
                        investment: () => investment,
                    },
                    windowClass: 'FullTakeoverModal FullTakeoverModal--extraTopMargin',
                });
        });

        return cancelPromise;
    }

    /* tslint:disable:no-unused-variable */
    private invest(): ng.IPromise<any> {
        this.errors = {};
        let investPromise = this.investment.reserve();
        investPromise.then((response: any) => {
            this.$window.location.href = response.data.url;
        }).catch((response: any) => {
            // Server returns well-formed objects.
            if (angular.isObject(response.data)) {
                angular.extend(this.errors, response.data);
                return;
            }

            // We need to handle other errors explicitly.
            let ravenMessage = '';
            let errorMessage = '';
            switch (response.status) {
                case 404:
                    ravenMessage = 'Investment failed: Reserve API call returned 404';
                    errorMessage = `
                        We could not complete your investment at this time.
                        Please refresh this page and try again. (Error 404)
                    `;
                    break;
                case 500:
                    ravenMessage = 'Investment failed: Reserve API call returned 500';
                    errorMessage = `
                        We were unable to process your investment at this time.
                        Our engineering team has been notified.
                        Please try again in a few moments. (Error 500)
                    `;
                    break;
                // If the request times out, $http returns a -1 status code.
                case -1:
                    ravenMessage = 'Investment failed: Reserve API call timed out';
                    errorMessage = `
                        We could not complete your investment at this time.
                        Please ensure that you are currently online and try again.
                        (Error -1)
                    `;
                    break;
                default:
                    ravenMessage = 'Investment failed: Reserve API call failed';
                    errorMessage = `
                        We could not complete your investment at this time.
                        Please refresh this page and try again.
                    `;
            }

            angular.extend(this.errors, {
                'non_field_errors': [errorMessage],
            });
            this.Raven.captureMessage(ravenMessage, {
                extra: {
                    bankAccount: this.bankAccount,
                    investment: this.investment,
                    investorProfile: this.investorProfile,
                    response: response,
                    targetUrl: `/api/investments/${this.investment.id}/reserve/`,
                },
            });
        });

        return investPromise;
    }

    private investmentAmountWithCredit() {
        let amount = this.investment.investment_amount;
        if (this.investment.use_investment_credit) {
            amount += this.investment.usable_investment_credit_amount;
        }

        return amount;
    }

    /* tslint:disable:no-unused-variable */
    private isOnlinePayment() {
        return this.investment.reservation_payment_method === this.FCChoices.ReservationPaymentMethods.Online;
    }

    /* tslint:disable:no-unused-variable */
    private onInvestmentAmountUpdated(investmentAmount: string) {
        this.investment.investment_amount = parseFloat(investmentAmount.replace(/,/g, ''));

        if (this.saveInvestmentAmount) {
            this.$timeout.cancel(this.saveInvestmentAmount);
        }

        this.saveInvestmentAmount = this.$timeout(() => {
            this.investment.backgroundSave(['investment_amount'], this.errors);
        }, 500);
    }

    /* tslint:disable:no-unused-variable */
    private onInvestorProfileUpdated(investorProfile: IInvestorProfile) {
        this.investorProfile = investorProfile;
    }

    /* tslint:disable:no-unused-variable */
    private onInvestorProfileSelected(investorProfile: IInvestorProfile) {
        this.investorProfile = investorProfile;
        this.investment.investor_profile = this.investorProfile.url;
        this.investment.backgroundSave(['investor_profile'], this.errors);
    }

    private onPaymentMethodSelected(
        paymentMethodData: {bankAccount: any, paymentMethod: string}
    ) {
        this.investment.reservation_bank_account = (
            paymentMethodData.bankAccount ? paymentMethodData.bankAccount.url : null
        );
        this.investment.reservation_payment_method = paymentMethodData.paymentMethod;
        this.investment.backgroundSave(['reservation_bank_account', 'reservation_payment_method'], this.errors);
    }

    /* tslint:disable:no-unused-variable */
    private onUseDistributionsUpdated(useDistributions: boolean) {
        const defaultDistAmount = Math.min(this.investment.investment_amount,
                                           this.investment.usable_distributions_amount);
        if (useDistributions && defaultDistAmount && this.investment.distributions_amount === 0) {
            this.investment.distributions_amount = defaultDistAmount;
        }
        this.investment.distributions_amount = (
            useDistributions ?
            this.investment.distributions_amount : 0
        );
        this.investment.use_distributions = useDistributions;

        if (this.saveDistributionsAmount) {
            this.$timeout.cancel(this.saveDistributionsAmount);
        }

        this.saveDistributionsAmount = this.$timeout(() => {
            this.investment.backgroundSave(['distributions_amount'], this.errors);
        }, 500);
    }

    /* tslint:disable:no-unused-variable */
    private onUseInvestmentCreditUpdated(useInvestmentCredit: boolean) {
        this.investment.investment_credit_amount = (
            useInvestmentCredit ?
            this.investment.usable_investment_credit_amount : 0
        );
        this.investment.use_investment_credit = useInvestmentCredit;

        if (this.saveInvestmentCreditAmount) {
            this.$timeout.cancel(this.saveInvestmentCreditAmount);
        }

        this.saveInvestmentCreditAmount = this.$timeout(() => {
            this.investment.backgroundSave(['investment_credit_amount'], this.errors);
        }, 500);
    }

    /* tslint:disable:no-unused-variable */
    private reservedAmount() {
        let amount = this.investmentAmountWithCredit() - this.waitlistedAmount();

        if (this.investment.fund.is_in_followon_early_access_state) {
            return Math.min(amount, this.investment.prorata_amount);
        }

        return amount;
    }

    /* tslint:disable:no-unused-variable */
    private waitlistedAmount() {
        if (
            this.investment.fund.is_in_followon_early_access_state ||
            (this.investment.fund.has_spots_left && !this.investment.fund.has_maximum)
        ) {
            return 0;
        }

        let amount = this.investmentAmountWithCredit() || 0;

        // If out of spots, entire investment is on waitlist.
        if (!this.investment.fund.has_spots_left) {
            return amount;
        // Otherwise, only portion that currently won't fit in fund is
        // waitlisted (which could be entire amount).
        } else {
            return Math.max(
                0,
                amount - this.investment.fund.amount_under_oversubscribed_without_minimum
            );
        }
    }

    private noPaymentMethodNeeded(): boolean {
        return this.investment.distributions_amount >= this.investment.investment_amount;
    }
}

angular
    .module('fundersclub.invest')
    .component('fcReserveInvestment', new FcReserveInvestment());
