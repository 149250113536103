class FcKpiEdit implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        editFormEnabled: '<',
        editFormExpanded: '<',
        kpi: '<',
        kpiLabels: '<',
        onCollapse: '&',
        onDeleted: '&',
        onExpand: '&',
        onSaved: '&',
    };
    public controller = FcKpiEditController;
    public templateUrl = 'companies/kpi-edit.component.html';
}

class FcKpiEditController implements ng.IComponentController {
    public editFormEnabled: boolean;
    public editFormExpanded: boolean;
    public kpi: any;
    public kpiLabels: Array<string>;
    public onCollapse: () => void;
    public onDeleted: (data: {kpiId: number}) => void;
    public onExpand: () => void;
    public onSaved: (data: {newKpi: any}) => void;

    /* tslint:disable:no-unused-variable */
    private datepickerOptions: any = {
        datepickerMode: 'year',
        minMode: 'year',
    };
    private editableDataPoints: Array<any>;
    private errors = {};
    private fcSave: any;
    private form: any;
    private initialKpi: any;
    /* tslint:disable:no-unused-variable */
    private isDatepickerPopupOpen: boolean;
    private periodSettings: any = {};

    constructor(
        private FCChoices: any,
        private KeyPerformanceIndicator: any,
        private moment: moment.MomentStatic
    ) {}

    public $onInit() {
        this.kpi = new this.KeyPerformanceIndicator(this.kpi);
        this.initialKpi = angular.copy(this.kpi);

        this.periodSettings = {
            [this.FCChoices.Periods.Weekly]: {
                dateFormat: 'MM/DD/YYYY',
                startDataInputType: 'date',
                timeTypeToAdd: 'weeks',
            },
            [this.FCChoices.Periods.Monthly]: {
                dateFormat: 'MM/YYYY',
                startDataInputType: 'month',
                timeTypeToAdd: 'months',
            },
            [this.FCChoices.Periods.Quarterly]: {
                dateFormat: '[Q]Q YYYY',
                startDataInputType: 'month',
                timeTypeToAdd: 'quarters',
            },
            [this.FCChoices.Periods.Yearly]: {
                dateFormat: 'YYYY',
                startDataInputType: null,
                timeTypeToAdd: 'years',
            },
            [this.FCChoices.Periods.Snapshot]: {
                dateFormat: 'MM/DD/YYYY',
                startDataInputType: 'date',
                timeTypeToAdd: null,
            },
        };

        if (!this.kpi.data_points.length) {
            this.kpi.data_points.push(0);
        }

        this.buildEditableDataPoints();
    }

    /* tslint:disable:no-unused-variable */
    private addEditablePastDataPoint() {
        this.editableDataPoints.unshift({
            annotation: '',
            disabled: false,
            value: 0,
        });
        this.form.$setDirty();

        let newStartDate = this
            .moment(this.kpi.start_date)
            .subtract(1, this.periodSettings[this.kpi.period].timeTypeToAdd);
        this.kpi.start_date = newStartDate.format('YYYY-MM-DD');
    }

    /* tslint:disable:no-unused-variable */
    private addEditableFutureDataPoint() {
        this.editableDataPoints.push({
            annotation: '',
            disabled: false,
            value: 0,
        });
        this.form.$setDirty();
    }

    private buildEditableDataPoints() {
        this.editableDataPoints = this.kpi.data_points.map((dataPoint: number) => {
            return {
                annotation: '',
                disabled: dataPoint === null,
                value: dataPoint,
            };
        });

        for (let i = 0; i < Math.min(this.editableDataPoints.length, this.kpi.annotations.length); i++) {
            this.editableDataPoints[i].annotation = this.kpi.annotations[i];
        }
    }

    private calculateCAGR() {
        if (this.editableDataPoints.length <= 1) {
            return 'N/A';
        }

        let firstDataPoint = parseFloat(this.editableDataPoints[0].value);
        let lastDataPoint = parseFloat(this.editableDataPoints[this.editableDataPoints.length - 1].value);
        let cagr: number = (
            Math.pow((lastDataPoint / firstDataPoint), (1 / (this.editableDataPoints.length - 1))) - 1
        ) * 100;
        return `${cagr.toFixed(2)}%`;
    }

    /* tslint:disable:no-unused-variable */
    private calculateDataPointDate($index: number) {
        let startDate: any = this.kpi.start_date;

        return this
            .moment(startDate)
            .add($index, this.periodSettings[this.kpi.period].timeTypeToAdd)
            .format(this.periodSettings[this.kpi.period].dateFormat);
    }

    /* tslint:disable:no-unused-variable */
    private canEditDataPoints() {
        return this.kpi.period && this.kpi.start_date && this.kpi.unit;
    }

    private delete() {
        if (confirm('Are you sure you want to delete this kpi?')) {
            this.kpi.$delete().then(() => this.onDeleted({kpiId: this.kpi.id}));
        }
    }

    /* tslint:disable:no-unused-variable */
    private expandEditForm(expandForm: boolean) {
        // Expand the editing form for this asset.
        let currentlyExpanded: boolean = this.editFormExpanded;
        let becomingExpanded: boolean;
        if (typeof expandForm === 'boolean') {
            becomingExpanded = expandForm;
        } else {
            becomingExpanded = !currentlyExpanded;
        }

        // Don't allow user to collapse form unless they've dealt with their
        // changes (e.g. Save or Cancel).
        if (!becomingExpanded && !this.form.$pristine) {
            return;
        }

        // If a expansion callback was provided, fire it off.
        this.editFormExpanded = becomingExpanded;
        if (becomingExpanded) {
            this.onExpand();
        } else {
            this.kpi.showUsedInFundSaveWarning = false;
            this.onCollapse();
        }
    }

    /* tslint:disable:no-unused-variable */
    private onDataPasted($event: any, $index: number) {
        let text: string = $event.originalEvent.clipboardData.getData('text');
        let data: Array<any> = text.split('\n').map((row: string) => {
            return row.split('\t');
        });
        data = data.concat.apply([], data);
        data = data.map((dataPoint: string) => {
            if (!dataPoint) {
                return {
                    annotation: '',
                    disabled: true,
                    value: null,
                };
            }
            return {
                annotation: '',
                disabled: false,
                value: Number(dataPoint.replace(/[$,%\s€]/g, '')),
            };
        });
        Array.prototype.splice.apply(this.editableDataPoints, [$index, data.length].concat(data));
        this.form.$setDirty();
        $event.preventDefault();
    }

    /* tslint:disable:no-unused-variable */
    private removeLastEditableDataPoint() {
        this.editableDataPoints.pop();
        this.form.$setDirty();
    }

    /* tslint:disable:no-unused-variable */
    private removeFirstEditableDataPoint() {
        this.editableDataPoints.shift();

        let newStartDate = this
            .moment(this.kpi.start_date)
            .add(1, this.periodSettings[this.kpi.period].timeTypeToAdd);
        this.kpi.start_date = newStartDate.format('YYYY-MM-DD');

        this.form.$setDirty();
    }

    private save() {
        this.errors = {};
        this.kpi.annotations  = this.editableDataPoints.map((dataPoint: any) => {
            return dataPoint.annotation;
        });
        this.kpi.data_points = this.editableDataPoints.map((dataPoint: any) => {
            return dataPoint.value;
        });
        this.kpi
            .$save()
            .then((response: any) => {
                let newKpi = new this.KeyPerformanceIndicator(response);
                newKpi.showUsedInFundSaveWarning = true;
                this.initialKpi = angular.copy(newKpi);
                this.onSaved({newKpi: newKpi});
            })
            .catch((response: any) => {
                angular.extend(this.errors, response.data);
            })
            .finally(() => this.fcSave.saveAttemptFinished(this.errors));
    }

    /* tslint:disable:no-unused-variable */
    private cancel() {
        // Reset form data.
        angular.copy(this.initialKpi, this.kpi);
        this.form.$setPristine();
        this.errors = {};
        this.expandEditForm(false);
    }

    /* tslint:disable:no-unused-variable */
    private setEditableDataPointNull($index: number) {
        this.editableDataPoints[$index].annotation = '';
        this.editableDataPoints[$index].value = null;
    }
}

angular
    .module('fundersclub.companies')
    .component('fcKpiEdit', new FcKpiEdit());
