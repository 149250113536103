class FcSimSelectFunds implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        onSelect: '&',
    };
    public controller = FcSimSelectFundsController;
    public templateUrl = 'strategy-simulator/sim-select-funds.component.html';
}

class FcSimSelectFundsController implements ng.IComponentController {
    public fundPresets: any = {
        'All Funds': '',
        'FCVC Funds': 'FCVC',
        'Opportunity Funds': 'Opportunity',
        'Accelerate FC Funds': 'Accelerate',
        'Multi-company funds': 'multi-company',
        'Single-company funds': 'single-company',
    };
    public visibleFundResults: Array<any> = [];
    public loading: boolean = false;
    public onSelect: (data: {selectedFunds: Array<any>}) => void;
    public selectedFunds: Array<any> = [];
    public years: Array<number> = [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024];
    public year: number = null;

    private FundQuery: string = '';
    private FundResults: Array<any> = [];
    private fundSearchFn: any;

    constructor (
        private $http: ng.IHttpService
    ) {}

    public fundSearch(): void {
        if (this.FundQuery) {
            this.fundSearchFn(this.FundQuery, this.year);
        }
    };

    public refreshVisibleFundResults(): void {
        this.visibleFundResults = this.FundResults.filter((n: any) => this.selectedFunds.indexOf(n) === -1);
        this.onSelect({'selectedFunds': this.selectedFunds});
    }

    public $onInit(): void {

        this.fundSearchFn = _.debounce(
            (query: string, year: number, callback?: any) => {
                this.loading = true;

                // Filter the fields to only include the ones we need, and only include funds that are closed
                let queryParams = 'fields=id,fund_display_name,fund_legal_name,start_date&status=c';
                if (year) {
                    queryParams += '&year=' + year;
                }
                queryParams += '&search=' + query;
                this.$http.get(
                '/api/funds/?' + queryParams
                ).then(
                    (data: any) => {
                        this.FundResults = data.data;
                        this.refreshVisibleFundResults();
                        this.loading = false;
                        if (callback) {
                            callback(data.data);
                        }
                    }
                );
            }, 300
        );
    }

    public addFund(fund: any) {
        this.selectedFunds.push(fund);
        this.refreshVisibleFundResults();
    }

    public removeFund(fund: any) {
        this.selectedFunds.splice(this.selectedFunds.indexOf(fund), 1);
        this.refreshVisibleFundResults();
    }

    public clearSelectedFunds() {
        this.selectedFunds = [];
        this.refreshVisibleFundResults();
    }

    public selectAll() {
        this.selectedFunds = this.FundResults;
        this.refreshVisibleFundResults();
    }

    public setPreset(preset: string) {
        this.selectedFunds = [];
        this.FundQuery = preset;
        this.fundSearchFn(preset, this.year, () => {
            this.selectedFunds = this.FundResults;
            this.refreshVisibleFundResults();
        });
    }
}

angular
    .module('fundersclub.strategy-simulator')
    .component('fcSimSelectFunds', new FcSimSelectFunds());
