let fcCompleteInvestmentFundOptIns = ($state: any, FundOptInInvestment: any) => {
    let thisScope = {
        'fund': '=fund',
        'investment': '=investment',
        'optInInvestments': '=optInInvestments',
    };
    let thisController = function($scope: any) {
        let vm = this;
        vm.errors = null;

        if (FundOptInInvestment.allConfirmed(vm.optInInvestments)) {
            $state.go('icp');
        }

        vm.requestedAmount = vm.investment.investment_amount;
        vm.saving = false;
        vm.shouldCancelInvestment = false;
        vm.showChangeInvestmentForm = false;

        $scope.$watch(
            function(scope: any) {
                return vm.shouldCancelInvestment;
            },
            function(newShouldCancelInvestment: any) {
                if (newShouldCancelInvestment) {
                    vm.requestedAmount = 0;
                }
            }
        );

        vm.save = function() {
            vm.saving = true;
            vm.errors = null;
            let optInInvestmentIds = vm.optInInvestments.map(function(optInInvestent: any) {
                return optInInvestent.id;
            });
            FundOptInInvestment
                .batchUpdate(optInInvestmentIds, vm.requestedAmount, vm.shouldCancelInvestment)
                .then(function(response: any) {
                    vm.saving = false;
                    const optInInvestments = Array.prototype.map.call(
                        response.data,
                        function(optInInvestment: any) {
                            return new FundOptInInvestment(optInInvestment);
                        }
                    );
                    angular.copy(optInInvestments, vm.optInInvestments);
                    $state.go('icp');
                })
                .catch(function(response: any) {
                    vm.errors = response.data;
                    vm.saving = false;
                });
        };
    };
    let thisDirective = {
        bindToController: true,
        controller: thisController,
        controllerAs: 'cifoic',
        restrict: 'E',
        scope: thisScope,
        templateUrl: 'icp/fund-opt-ins.directive.html',
    };
    return thisDirective;
};

angular
    .module('fundersclub.icp')
    .directive('fcCompleteInvestmentFundOptIns', fcCompleteInvestmentFundOptIns);
