class FcManageProceeds implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        valuationEventData: '<',
    };
    public controller = FcManageProceedsController;
    public templateUrl = 'investment-assets/proceeds-manage.component.html';
}

class FcManageProceedsController implements ng.IComponentController {
    public valuationEventData: any;

    private valuationEvent: any;
    private adding: boolean = false;

    constructor (private _: UnderscoreStatic, private ValuationEvent: any, private $window: ng.IWindowService) {}

    public $onInit() {
        this.valuationEvent = new this.ValuationEvent(this.valuationEventData);
    }

    /* tslint:disable:no-unused-variable */
    private addProceed() {
        this.adding = true;
        this.valuationEvent
            .addProceed()
            .then((response: any) => this.valuationEvent.proceeds.push(response.data))
            .finally(() => this.adding = false);
    }

    /* tslint:disable:no-unused-variable */
    private onProceedDeleted(proceed: any) {
        let index: number = this._.findIndex(
            this.valuationEvent.proceeds,
            (tmpProceed: any) => proceed.id === tmpProceed.id
        );
        this.valuationEvent.proceeds.splice(index, 1);
    }

    /* tslint:disable:no-unused-variable */
    private onProceedSaved(proceed: any) {
        let index: number = this._.findIndex(
            this.valuationEvent.proceeds,
            (tmpProceed: any) => proceed.id === tmpProceed.id
        );
        this.valuationEvent.proceeds[index] = proceed;
    }

    /* tslint:disable:no-unused-variable */
    private totalExpectedProceeds(): number {
        return this.valuationEvent.proceeds.reduce((prev: number, curr: any) => {
            return prev + ((curr.asset_expected && curr.asset_expected.initial_value) || 0);
        }, 0);
    }

    /* tslint:disable:no-unused-variable */
    private totalReceivedProceeds(): number {
        return this.valuationEvent.proceeds.reduce((prev: number, curr: any) => {
            return prev + ((curr.asset_received && curr.asset_received.initial_value) || 0);
        }, 0);
    }

    /* tslint:disable:no-unused-variable */
    private createJEs() {
        let win = this.$window.open(this.valuationEvent.urls.create_ops_journal_entries, '_blank');
        win.focus();

        // Assume this actually happened
        this.valuationEvent.ops_journal_entries_created = true;
    }
}

angular
    .module('fundersclub.assets')
    .component('fcManageProceeds', new FcManageProceeds());
