do ->

    MobileNavigationController = ->
        vm = @
        vm.isOpen = false
        vm.moreMenuVisible = false

        vm.toggled = (open) ->
            if not open
                vm.moreMenuVisible = false

        vm.toggleMobileSubNav = ($event) ->
            # Prevent Bootstrap's default dropdown menu handler from firing.
            $event.stopPropagation()
            vm.moreMenuVisible = !vm.moreMenuVisible

        # End MobileNavigationController
        return


    UserDropdownController = ->
        vm = @
        vm.isOpen = false

        vm.toggleMobileSubNav = ($event) ->
            # Prevent Bootstrap's default dropdown menu handler from firing.
            $event.stopPropagation()
            vm.moreMenuVisible = !vm.moreMenuVisible

        # End MobileNavigationController
        return

    angular
        .module 'fundersclub.layout'
        .controller 'MobileNavigationController', MobileNavigationController
        .controller 'UserDropdownController', UserDropdownController
