class FcAdminSearch implements ng.IComponentOptions {
    public controller = FcAdminSearchController;
    public templateUrl = 'internal/admin-search.component.html';
}

class FcAdminSearchController implements ng.IComponentController {
    private advancedSearchPopoverIsOpen: boolean;
    private masqueradeData: any;
    private searchQuery: string;
    private searching: boolean;
    private visibleResultTypes: any;

    constructor (
        private $window: any,
        private AdminSearchData: any,
        private AdminSearchResults: any,
        private FCChoices: any,
        private MasqueradeData: any
    ) {}

    public $onInit(): void {
        this.masqueradeData = this.MasqueradeData;
        this.advancedSearchPopoverIsOpen = false;
        this.searching = false;

        this.visibleResultTypes = {
            'users': true,
            'founders': true,
            'companies': true,
            'funds': true,
        };
    }

    /* tslint:disable:no-unused-variable */
    private advancedSearchSetQuery(newContent: string): void {
        this.searchQuery = newContent;
        this.advancedSearchPopoverIsOpen = false;
        this.submitSearch();
    }

    /* tslint:disable:no-unused-variable */
    private submitSearch(): void {
        this.searching = true;
    }

    /* tslint:disable:no-unused-variable */
    private usersToDisplay(): Array<any> {
        return this.masqueradeData.users;
    }
}

angular
    .module('fundersclub.internal')
    .component('fcAdminSearch', new FcAdminSearch());
