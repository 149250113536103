do ->

    MemberInviteController = ($scope, FCInviteMembersData, UserInvite, FCToastManager) ->
        vm = @

        default_invite =
            can_send_reminder: true
            status: 'Pending'

        vm.members_to_invite = []
        vm.invites = FCInviteMembersData.invites
        vm.invited_emails = vm.invites.map (invite) -> invite.invited_email

        vm.gmailContactsAdded = (contacts) ->
            new_members_to_invite = []
            for contact in contacts
                member_to_invite = angular.copy default_invite
                member_to_invite.email = contact.email

                name_parts = contact.name.split(' ')
                member_to_invite.first_name = name_parts[0]
                member_to_invite.last_name = name_parts.slice(1).join(' ')
                new_members_to_invite.push member_to_invite

                contact.invited_email = contact.email
                vm.invites.push contact
                vm.invited_emails.push contact.email

            UserInvite.bulk_invite new_members_to_invite
                .then -> FCToastManager.checkForToasts()

        vm.resendInvite = (invite) ->
            userinvite = new UserInvite(invite)
            invite.resending = true
            userinvite.$remind()
                .then ->
                    invite.can_send_reminder = false
                    invite.reminder_sent = true
                .finally -> invite.resending = false

        return

    angular
        .module 'fundersclub.users'
        .controller 'MemberInviteController', MemberInviteController
