// Hardcoded data is used in the six year retrospective.

// The industries in which we've invested.
FC.retrospective6IndustryData = function (dataType: any) {
    // dataType options are:
    //   'industry','product', 'customer', 'model'
    let dataTypes = {
        'industry': [{
            color: '#4C9CE5',
            label: 'Legal',
            value: 2,
        }, {
            color: '#EB3D31',
            label: 'Other',
            value: 2,
        }, {
            color: '#28D177',
            label: 'Data Centers',
            value: 2,
        }, {
            color: '#C70D91',
            label: 'Material Science',
            value: 2,
        }, {
            color: '#FF9800',
            label: 'Music',
            value: 2,
        }, {
            color: '#4C9CE5',
            label: 'Video',
            value: 4,
        }, {
            color: '#EB3D31',
            label: 'Gaming',
            value: 3,
        }, {
            color: '#28D177',
            label: 'Information Security',
            value: 4,
        }, {
            color: '#C70D91',
            label: 'Social Media',
            value: 5,
        }, {
            color: '#FF9800',
            label: 'Ad Tech',
            value: 5,
        }, {
            color: '#4C9CE5',
            label: 'Real Estate',
            value: 7,
        }, {
            color: '#EB3D31',
            label: 'Manufacturing',
            value: 8,
        }, {
            color: '#28D177',
            label: 'Other',
            value: 11,
        }, {
            color: '#C70D91',
            label: 'Consumer Electronics',
            value: 12,
        }, {
            color: '#FF9800',
            label: 'Personal Care & Services',
            value: 10,
        }, {
            color: '#4C9CE5',
            label: 'Education',
            value: 14,
        }, {
            color: '#EB3D31',
            label: 'Transportation & Logistics',
            value: 13,
        }, {
            color: '#28D177',
            label: 'Communications',
            value: 14,
        }, {
            color: '#C70D91',
            label: 'Food & Agriculture',
            value: 16,
        }, {
            color: '#FF9800',
            label: 'Retail',
            value: 17,
        }, {
            color: '#4C9CE5',
            label: 'Human Resources',
            value: 19,
        }, {
            color: '#EB3D31',
            label: 'Health & Medicine',
            value: 25,
        }, {
            color: '#28D177',
            label: 'Financial Technology',
            value: 35,
        }, {
            color: '#C70D91',
            label: 'Information Technology',
            value: 34,
        }],
        'customer': [{
            color: '#2FCC90',
            label: 'Enterprise',
            value: 128,
        }, {
            color: '#4C9CE5',
            label: 'Consumer',
            value: 137,
        }],
        'product': [{
            color: '#EB3D31',
            label: 'Hardware',
            value: 37,
        }, {
            color: '#FF9800',
            label: 'Software',
            value: 229,
        }],
        'model': [{
            color: '#2FCC90',
            label: 'Advertising',
            value: 5,
        }, {
            color: '#C70D91',
            label: 'Licensing',
            value: 6,
        }, {
            color: '#4C9CE5',
            label: 'Peer to Peer',
            value: 7,
        }, {
            color: '#EB3D31',
            label: 'Data',
            value: 12,
        }, {
            color: '#FF9800',
            label: 'Transaction Processing',
            value: 19,
        }, {
            color: '#2FCC90',
            label: 'Subscription',
            value: 83,
        }, {
            color: '#4C9CE5',
            label: 'Commerce',
            value: 126,
        }],
    };

    return (<any> dataTypes)[dataType];
};
