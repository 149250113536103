class FcSensitiveSectionApplication implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        application: '<',
        onApplicationApproved: '&',
        onApplicationDenied: '&',
    };
    public controller = FcSensitiveSectionApplicationController;
    public templateUrl = 'funds/sensitive-section-application.component.html';
}

class FcSensitiveSectionApplicationController implements ng.IComponentController {
    public application: any;
    public onApplicationApproved: (data: {application: any}) => void;
    public onApplicationDenied: (data: {application: any}) => void;

    /* tslint:disable:no-unused-variable */
    private showDetails: boolean = false;

    constructor(private FCChoices: any) {}

    /* tslint:disable:no-unused-variable */
    private approve(): void {
        this.application.status = this.FCChoices.Statuses.Approved;
        this.application
            .$save(['status'])
            .then((response: any) => this.onApplicationApproved({application: this.application}));
    }

    /* tslint:disable:no-unused-variable */
    private deny(): void {
        this.application.status = this.FCChoices.Statuses.Denied;
        this.application
            .$save(['status'])
            .then((response: any) => this.onApplicationDenied({application: this.application}));
    }
}

angular
    .module('fundersclub.funds')
    .component('fcSensitiveSectionApplication', new FcSensitiveSectionApplication());
