do ->
    fcPublicProfileUrlEdit = (UserProfile, FCUserData, FCToastManager, FCAccomplishmentPromosManager) ->
        thisScope =
            'profile': '=fcPublicProfileUrlEdit'
            'user': '=fcUserProfile'

        thisController = ->
            vm = @
            vm.profile = vm.profile

            # Bindables.
            vm.errors = {}
            vm.saving = false
            vm.show_slug_form = false

            # Toggle visibility of "Set slug" form.
            vm.toggleSlugForm = ->
                if vm.profile.slug_can_change
                    vm.profile.slug = ''
                    vm.errors = {}
                    vm.show_slug_form = !vm.show_slug_form

            vm.save = ->
                vm.errors = {}
                vm.saving = true
                vm.profile.$save ['slug']
                    .then ->
                        vm.show_slug_form = false
                        FCToastManager.checkForToasts()
                        FCAccomplishmentPromosManager.promoCompleted('claim-personal-url')
                    .catch (response) ->
                        angular.extend(vm.errors, response.data)
                    .finally ->
                        vm.saving = false

            # End UserProfileUrlController
            return

        thisDirective =
            restrict: 'A'
            require: '^form'
            scope: thisScope
            templateUrl: 'users/public-profile-url-edit.directive.html'
            controllerAs: 'ppue'
            bindToController: true
            controller: thisController
            replace: true

        return thisDirective

    angular
        .module 'fundersclub.users'
        .directive 'fcPublicProfileUrlEdit', fcPublicProfileUrlEdit
