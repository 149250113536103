class FcCompleteInvestment implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        fundData: '<',
        individualIdentityDocumentsData: '<',
        individualInvestorProfileData: '<',
        investmentData: '<',
        investorProfileData: '<',
        investorProfileIdentityDocumentsData: '<',
        optInInvestmentsData: '<',
        paymentData: '<',
        scrollToData: '<',
        subscriptionAgreementData: '<',
        taxDocsData: '<',
        userprofileData: '<',
    };
    public controller = FcCompleteInvestmentController;
    public templateUrl = 'icp/complete.component.html';
}

class FcCompleteInvestmentController implements ng.IComponentController {
    public fundData: any;
    public individualIdentityDocumentsData: Array<any>;
    public individualInvestorProfileData: IInvestorProfile;
    public investmentData: IInvestment;
    public investorProfileData: IInvestorProfile;
    public investorProfileIdentityDocumentsData: Array<any>;
    public optInInvestmentsData: Array<any>;
    public paymentData: any;
    public scrollToData: any = null;
    public subscriptionAgreementData: any;
    public taxDocsData: any;
    public userprofileData: any;

    private saveDistributionsAmount: ng.IPromise<any>;
    private bankAccountToVerify: any;
    private fund: any;
    private individualIdentityDocuments: Array<any>;
    private individualInvestorProfile: IInvestorProfile;
    private investment: IInvestment;
    private investorProfile: IInvestorProfile;
    private investorProfileIdentityDocuments: Array<any>;
    private optInInvestments: Array<any>;
    private payment: any;
    private scrollTo: any = null;
    private subscriptionAgreement: any;
    private taxDocs: any;
    private transactionForBankAccountToVerify: any;
    private userProfile: any;
    private errors: {[error: string]: Array<string>} = {};

    constructor(
        private _: UnderscoreStatic,
        private $intercom: any,
        private $interval: any,
        private $state: any,
        private $timeout: any,
        private $transitions: any,
        private $window: any,
        private FCChoices: any,
        private BankAccount: any,
        private Fund: any,
        private FundOptInInvestment: any,
        private InvestorIdentityDocument: any,
        private Investment: any,
        private InvestorProfile: any,
        private Payment: any,
        private Transaction: any,
        private UserProfile: any,
        private SignableLegalDocument: any
    ) {}

    public $onInit() {
        this.fund = new this.Fund(this.fundData);
        this.individualIdentityDocuments = Array.prototype.map.call(
            this.individualIdentityDocumentsData,
            (document: any) => {
                return new this.InvestorIdentityDocument(document);
            }
        );
        this.individualInvestorProfile = new this.InvestorProfile(
            this.individualInvestorProfileData
        );
        this.investment = new this.Investment(this.investmentData);
        this.investorProfile = new this.InvestorProfile(
            this.investorProfileData
        );
        this.investorProfileIdentityDocuments = Array.prototype.map.call(
            this.investorProfileIdentityDocumentsData,
            (document: any) => {
                return new this.InvestorIdentityDocument(document);
            }
        );
        this.optInInvestments = Array.prototype.map.call(
            this.optInInvestmentsData,
            (optInInvestment: any) => {
                return new this.FundOptInInvestment(optInInvestment);
            }
        );
        this.payment = new this.Payment(this.paymentData);
        this.userProfile = new this.UserProfile(this.userprofileData);

        if (!this._.isEmpty(this.subscriptionAgreementData)) {
            this.subscriptionAgreement = new this.SignableLegalDocument(
                this.subscriptionAgreementData
            );
        } else {
            // subscription doc not generated
            this.subscriptionAgreement = new this.SignableLegalDocument({
                id: null,
                status: null,
            });
        }
        this.subscriptionAgreement.pollForUpdates();

        if (!_.isEmpty(this.taxDocsData)) {
            this.taxDocs = new this.SignableLegalDocument(
                this.taxDocsData
            );
        } else {
            // tax docs not generated
            this.taxDocs = new this.SignableLegalDocument({
                id: null,
                status: null,
            });
        }
        this.taxDocs.pollForUpdates();

        // Need the $timeout to allow angular to finish it's digest cycle
        // and set $state.$current correctly
        this.$timeout(() => {
            if (!this.$state.$current.name) {
                this.$state.go('icp');
            }
        });

        // Want to capture the scroll position everytime we leave
        // all-steps so we can scroll to it when we return
        this.$transitions.onBefore({from: 'icp'}, (trans: any) => {
            this.scrollTo = this.$window.scrollY;
            // If already scrolled enough to hide header, don't re-show
            // it upon jumping into step. 72 is a magic number; just enough
            // to hide the navigation header.
            const newScroll = (this.$window.scrollY > 72) ? 72 : 0;
            this.$window.scrollTo(0, newScroll);
        });

        this.$transitions.onBefore({to: 'icp.verifyBankAccount'}, (trans: any) => {
            let transactionId = Number(trans.params().transactionId);
            let transaction: any = this._.find(this.payment.transactions, (tmpTransaction: any) => {
                return transactionId === tmpTransaction.id;
            });

            if (transaction.their_bank_account.verified) {
                return false;
            }

            this.bankAccountToVerify = new this.BankAccount(transaction.their_bank_account);
            this.transactionForBankAccountToVerify = new this.Transaction(transaction);
        });

        this.$transitions.onBefore({to: 'icp.entityAccreditation'}, (trans: any) => {
            if (this.investorProfile.accredited && this.investment.questionnaire_completed) {
                return false;
            }
        });

        this.$transitions.onBefore({to: 'icp.entityIdentityVerification'}, (trans: any) => {
            if (!this.investorProfile.is_entity || this.investorProfile.identity_verified) {
                return false;
            }
        });

        this.$transitions.onBefore({to: 'icp.individualIdentityVerification'}, (trans: any) => {
            if (this.individualInvestorProfile.identity_verified) {
                return false;
            }
        });

        this.$transitions.onBefore({to: 'icp.prepareTaxDocs'}, (trans: any) => {
            if (this.taxDocs.id !== null) {
                return false;
            }
        });

        this.$transitions.onBefore({to: 'icp.prepareSubscriptionAgreement'}, (trans: any) => {
            if (this.subscriptionAgreement.id !== null) {
                return false;
            }
        });

        this.$transitions.onBefore({to: 'icp.signTaxDocs'}, (trans: any) => {
            if (
                this.taxDocs.status === this.FCChoices.SigningStatus.RequiresCountersignature ||
                this.taxDocs.status === this.FCChoices.SigningStatus.Signed
            ) {
                return false;
            }
        });

        this.$transitions.onBefore({to: 'icp.signSubscriptionAgreement'}, (trans: any) => {
            if (
                this.subscriptionAgreement.status === this.FCChoices.SigningStatus.RequiresCountersignature ||
                this.subscriptionAgreement.status === this.FCChoices.SigningStatus.Signed
            ) {
                return false;
            }
        });
    }

    /* tslint:disable:no-unused-variable */
    private additionalProrataInterest() {
        if (!this.investment.fund.is_in_followon_early_access_state) {
            return 0;
        }

        return this.investment.initial_additional_prorata_interest;
    }

    private onBankAccountVerified(bankAccount: any) {
        this.transactionForBankAccountToVerify
            .$get()
            .then((response: any) => {
                let index = this._.findIndex(this.payment.transactions, (transaction: any) => {
                    return response.id === transaction.id;
                });
                this.payment.transactions[index] = response;
                this.$state.go('icp');
            });
    }

    /* tslint:disable:no-unused-variable */
    private onEntityAccreditationQuestionnaireSubmitted() {
        this.$state.go('icp');
    }

    /* tslint:disable:no-unused-variable */
    private onIdentityDocumentsUpdated(identityDocuments: any) {
        this.investorProfileIdentityDocuments = identityDocuments;
    }

    /* tslint:disable:no-unused-variable */
    private onIdentityVerificationSaved() {
        this.$state.go('icp');
    }

    /* tslint:disable:no-unused-variable */
    private onIndividualIdentityDocumentsUpdated(individualIdentityDocuments: any) {
        this.individualIdentityDocuments = individualIdentityDocuments;
    }

    /* tslint:disable:no-unused-variable */
    private onIndividualInvestorProfileUpdated(individualInvestorProfile: any) {
        this.individualInvestorProfile = individualInvestorProfile;
    }

    /* tslint:disable:no-unused-variable */
    private onInvestmentUpdated(investment: any) {
        this.investment = investment;
    }

    /* tslint:disable:no-unused-variable */
    private onInvestorProfileUpdated(investorProfile: any) {
        this.investorProfile = investorProfile;
    }

    private onLegaldocSigningModalClosed() {
        this.$state.go('icp');
    }

    /* tslint:disable:no-unused-variable */
    private onResetSubscriptionAgreement() {
        return this.investment.abandonSubscriptionAgreement().then(() => {
            this.subscriptionAgreement.id = null;
            this.subscriptionAgreement.status = null;
            this.subscriptionAgreement.signing_url = null;
        });
    };

    /* tslint:disable:no-unused-variable */
    private onResetTaxDocs() {
        return this.investorProfile.abandonTaxDocs().then(() => {
            this.taxDocs.id = null;
            this.taxDocs.status = null;
            this.taxDocs.signing_url = null;
        });
    }

    /* tslint:disable:no-unused-variable */
    private onSubscriptionAgreementGenerated(subscriptionAgreement: any) {
        this.subscriptionAgreement = subscriptionAgreement;
        this.subscriptionAgreement.pollForUpdates();
        this.$state.go('icp.signSubscriptionAgreement');
    }

    /* tslint:disable:no-unused-variable */
    private onTaxDocGenerated(taxDoc: any) {
        this.taxDocs = taxDoc;
        this.taxDocs.pollForUpdates();
        this.$state.go('icp.signTaxDocs');
    }

    /* tslint:disable:no-unused-variable */
    private onUserProfileUpdated(userProfile: any) {
        this.userProfile = userProfile;
    }

    /* tslint:disable:no-unused-variable */
    private reservedAmount() {
        if (this.investment.is_waitlisted) {
            return 0;
        }

        return this.investment.investment_amount;
    }

    /* tslint:disable:no-unused-variable */
    private showBackToHomeLink () {
        return this.$state.$current.name !== 'icp';
    }

    private onUseDistributionsUpdated(useDistributions: boolean) {
        if (Boolean(useDistributions) === Boolean(this.investment.use_distributions)) {
            return;
        }
        this.investment.distributions_amount = (
            useDistributions ?
            Math.min(this.investment.investment_amount, this.investment.usable_distributions_amount) : 0
        );
        this.investment.use_distributions = useDistributions;

        if (this.saveDistributionsAmount) {
            this.$timeout.cancel(this.saveDistributionsAmount);
        }

        this.saveDistributionsAmount = this.$timeout(() => {
            this.investment.backgroundSave(['distributions_amount'], this.errors, () => {
                window.location.reload();
            });
        }, 200);
    }

    /* tslint:disable:no-unused-variable */
    private showIntercom() {
        this.$intercom.showNewMessage();
    }

    /* tslint:disable:no-unused-variable */
    private waitlistedAmount() {
        if (this.investment.is_waitlisted) {
            return this.investment.investment_amount;
        }

        return (
            this.investment.initial_waitlisted_amount -
            this.investment.waitlisted_amount_accepted
        );
    }
}

angular
    .module('fundersclub.icp')
    .component('fcCompleteInvestment', new FcCompleteInvestment());
