const NO_COUNTRY_CODE_SELECTED = '-';
const VALID_PHONE_NUMBER_REGEX = /^[0-9]{4,12}$/g;

class FcTextMessagePreferences implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        areTextMessagesEnabled: '<',
        path: '@',
        phoneCountryCode: '@',
        phoneNumber: '@',
    };
    public controller = FcTextMessagePreferencesController;
    public templateUrl = 'support/text-message-preferences.component.html';
}

class FcTextMessagePreferencesController implements ng.IComponentController {
    public areTextMessagesEnabled: boolean;
    public path: string;
    public phoneCountryCode: string;
    public phoneNumber: string;
    public selectedCountryCode: {name: string, value: string, disabled ?: string};

    // Only countries that are not subject to general US embargo. Frequently used ones can be moved to the top.
    public countryCodes: Array<{name: string, value: string, disabled ?: string}> = [
        { name: 'Please choose country code', value: '-'},
        { name: 'USA / Canada (+1)', value: '1'},
        { name: 'UK (+44)', value: '44'},
        { disabled: 'disabled', name: 'Other Countries', value: ''},
        { name: 'Algeria (+213)', value: '213'},
        { name: 'Andorra (+376)', value: '376'},
        { name: 'Angola (+244)', value: '244'},
        { name: 'Anguilla (+1264)', value: '1264'},
        { name: 'Antigua & Barbuda (+1268)', value: '1268'},
        { name: 'Argentina (+54)', value: '54'},
        { name: 'Armenia (+374)', value: '374'},
        { name: 'Aruba (+297)', value: '297'},
        { name: 'Australia (+61)', value: '61'},
        { name: 'Austria (+43)', value: '43'},
        { name: 'Azerbaijan (+994)', value: '994'},
        { name: 'Bahamas (+1242)', value: '1242'},
        { name: 'Bahrain (+973)', value: '973'},
        { name: 'Bangladesh (+880)', value: '880'},
        { name: 'Barbados (+1246)', value: '1246'},
        { name: 'Belarus (+375)', value: '375'},
        { name: 'Belgium (+32)', value: '32'},
        { name: 'Belize (+501)', value: '501'},
        { name: 'Benin (+229)', value: '229'},
        { name: 'Bermuda (+1441)', value: '1441'},
        { name: 'Bhutan (+975)', value: '975'},
        { name: 'Bolivia (+591)', value: '591'},
        { name: 'Bosnia Herzegovina (+387)', value: '387'},
        { name: 'Botswana (+267)', value: '267'},
        { name: 'Brazil (+55)', value: '55'},
        { name: 'Brunei (+673)', value: '673'},
        { name: 'Bulgaria (+359)', value: '359'},
        { name: 'Burkina Faso (+226)', value: '226'},
        { name: 'Burundi (+257)', value: '257'},
        { name: 'Cambodia (+855)', value: '855'},
        { name: 'Cameroon (+237)', value: '237'},
        { name: 'Cape Verde Islands (+238)', value: '238'},
        { name: 'Cayman Islands (+1345)', value: '1345'},
        { name: 'Central African Republic (+236)', value: '236'},
        { name: 'Chile (+56)', value: '56'},
        { name: 'China (+86)', value: '86'},
        { name: 'Colombia (+57)', value: '57'},
        { name: 'Comoros (+269)', value: '269'},
        { name: 'Congo (+242)', value: '242'},
        { name: 'Cook Islands (+682)', value: '682'},
        { name: 'Costa Rica (+506)', value: '506'},
        { name: 'Croatia (+385)', value: '385'},
        { name: 'Cyprus - North (+90)', value: '90'},
        { name: 'Cyprus - South (+357)', value: '357'},
        { name: 'Czech Republic (+420)', value: '420'},
        { name: 'Denmark (+45)', value: '45'},
        { name: 'Djibouti (+253)', value: '253'},
        { name: 'Dominica (+1809)', value: '1809'},
        { name: 'Dominican Republic (+1809)', value: '1809'},
        { name: 'Ecuador (+593)', value: '593'},
        { name: 'Egypt (+20)', value: '20'},
        { name: 'El Salvador (+503)', value: '503'},
        { name: 'Equatorial Guinea (+240)', value: '240'},
        { name: 'Eritrea (+291)', value: '291'},
        { name: 'Estonia (+372)', value: '372'},
        { name: 'Ethiopia (+251)', value: '251'},
        { name: 'Falkland Islands (+500)', value: '500'},
        { name: 'Faroe Islands (+298)', value: '298'},
        { name: 'Fiji (+679)', value: '679'},
        { name: 'Finland (+358)', value: '358'},
        { name: 'France (+33)', value: '33'},
        { name: 'French Guiana (+594)', value: '594'},
        { name: 'French Polynesia (+689)', value: '689'},
        { name: 'Gabon (+241)', value: '241'},
        { name: 'Gambia (+220)', value: '220'},
        { name: 'Georgia (+7880)', value: '7880'},
        { name: 'Germany (+49)', value: '49'},
        { name: 'Ghana (+233)', value: '233'},
        { name: 'Gibraltar (+350)', value: '350'},
        { name: 'Greece (+30)', value: '30'},
        { name: 'Greenland (+299)', value: '299'},
        { name: 'Grenada (+1473)', value: '1473'},
        { name: 'Guadeloupe (+590)', value: '590'},
        { name: 'Guam (+671)', value: '671'},
        { name: 'Guatemala (+502)', value: '502'},
        { name: 'Guinea (+224)', value: '224'},
        { name: 'Guinea - Bissau (+245)', value: '245'},
        { name: 'Guyana (+592)', value: '592'},
        { name: 'Haiti (+509)', value: '509'},
        { name: 'Honduras (+504)', value: '504'},
        { name: 'Hong Kong (+852)', value: '852'},
        { name: 'Hungary (+36)', value: '36'},
        { name: 'Iceland (+354)', value: '354'},
        { name: 'India (+91)', value: '91'},
        { name: 'Indonesia (+62)', value: '62'},
        { name: 'Iraq (+964)', value: '964'},
        { name: 'Ireland (+353)', value: '353'},
        { name: 'Israel (+972)', value: '972'},
        { name: 'Italy (+39)', value: '39'},
        { name: 'Jamaica (+1876)', value: '1876'},
        { name: 'Japan (+81)', value: '81'},
        { name: 'Jordan (+962)', value: '962'},
        { name: 'Kazakhstan (+7)', value: '7'},
        { name: 'Kenya (+254)', value: '254'},
        { name: 'Kiribati (+686)', value: '686'},
        { name: 'Korea - South (+82)', value: '82'},
        { name: 'Kuwait (+965)', value: '965'},
        { name: 'Kyrgyzstan (+996)', value: '996'},
        { name: 'Laos (+856)', value: '856'},
        { name: 'Latvia (+371)', value: '371'},
        { name: 'Lebanon (+961)', value: '961'},
        { name: 'Lesotho (+266)', value: '266'},
        { name: 'Liberia (+231)', value: '231'},
        { name: 'Libya (+218)', value: '218'},
        { name: 'Liechtenstein (+417)', value: '417'},
        { name: 'Lithuania (+370)', value: '370'},
        { name: 'Luxembourg (+352)', value: '352'},
        { name: 'Macao (+853)', value: '853'},
        { name: 'Macedonia (+389)', value: '389'},
        { name: 'Madagascar (+261)', value: '261'},
        { name: 'Malawi (+265)', value: '265'},
        { name: 'Malaysia (+60)', value: '60'},
        { name: 'Maldives (+960)', value: '960'},
        { name: 'Mali (+223)', value: '223'},
        { name: 'Malta (+356)', value: '356'},
        { name: 'Marshall Islands (+692)', value: '692'},
        { name: 'Martinique (+596)', value: '596'},
        { name: 'Mauritania (+222)', value: '222'},
        { name: 'Mayotte (+269)', value: '269'},
        { name: 'Mexico (+52)', value: '52'},
        { name: 'Micronesia (+691)', value: '691'},
        { name: 'Moldova (+373)', value: '373'},
        { name: 'Monaco (+377)', value: '377'},
        { name: 'Mongolia (+976)', value: '976'},
        { name: 'Montserrat (+1664)', value: '1664'},
        { name: 'Morocco (+212)', value: '212'},
        { name: 'Mozambique (+258)', value: '258'},
        { name: 'Myanmar (+95)', value: '95'},
        { name: 'Namibia (+264)', value: '264'},
        { name: 'Nauru (+674)', value: '674'},
        { name: 'Nepal (+977)', value: '977'},
        { name: 'Netherlands (+31)', value: '31'},
        { name: 'New Caledonia (+687)', value: '687'},
        { name: 'New Zealand (+64)', value: '64'},
        { name: 'Nicaragua (+505)', value: '505'},
        { name: 'Niger (+227)', value: '227'},
        { name: 'Nigeria (+234)', value: '234'},
        { name: 'Niue (+683)', value: '683'},
        { name: 'Norfolk Islands (+672)', value: '672'},
        { name: 'Northern Marianas (+670)', value: '670'},
        { name: 'Norway (+47)', value: '47'},
        { name: 'Oman (+968)', value: '968'},
        { name: 'Pakistan (+92)', value: '92'},
        { name: 'Palau (+680)', value: '680'},
        { name: 'Panama (+507)', value: '507'},
        { name: 'Papua New Guinea (+675)', value: '675'},
        { name: 'Paraguay (+595)', value: '595'},
        { name: 'Peru (+51)', value: '51'},
        { name: 'Philippines (+63)', value: '63'},
        { name: 'Poland (+48)', value: '48'},
        { name: 'Portugal (+351)', value: '351'},
        { name: 'Puerto Rico (+1787)', value: '1787'},
        { name: 'Qatar (+974)', value: '974'},
        { name: 'Reunion (+262)', value: '262'},
        { name: 'Romania (+40)', value: '40'},
        { name: 'Russia (+7)', value: '7'},
        { name: 'Rwanda (+250)', value: '250'},
        { name: 'San Marino (+378)', value: '378'},
        { name: 'Sao Tome & Principe (+239)', value: '239'},
        { name: 'Saudi Arabia (+966)', value: '966'},
        { name: 'Senegal (+221)', value: '221'},
        { name: 'Serbia (+381)', value: '381'},
        { name: 'Seychelles (+248)', value: '248'},
        { name: 'Sierra Leone (+232)', value: '232'},
        { name: 'Singapore (+65)', value: '65'},
        { name: 'Slovak Republic (+421)', value: '421'},
        { name: 'Slovenia (+386)', value: '386'},
        { name: 'Solomon Islands (+677)', value: '677'},
        { name: 'Somalia (+252)', value: '252'},
        { name: 'South Africa (+27)', value: '27'},
        { name: 'Spain (+34)', value: '34'},
        { name: 'Sri Lanka (+94)', value: '94'},
        { name: 'St. Helena (+290)', value: '290'},
        { name: 'St. Kitts (+1869)', value: '1869'},
        { name: 'St. Lucia (+1758)', value: '1758'},
        { name: 'Suriname (+597)', value: '597'},
        { name: 'Sudan (+249)', value: '249'},
        { name: 'Swaziland (+268)', value: '268'},
        { name: 'Sweden (+46)', value: '46'},
        { name: 'Switzerland (+41)', value: '41'},
        { name: 'Taiwan (+886)', value: '886'},
        { name: 'Tajikistan (+992)', value: '992'},
        { name: 'Thailand (+66)', value: '66'},
        { name: 'Togo (+228)', value: '228'},
        { name: 'Tonga (+676)', value: '676'},
        { name: 'Trinidad & Tobago (+1868)', value: '1868'},
        { name: 'Tunisia (+216)', value: '216'},
        { name: 'Turkey (+90)', value: '90'},
        { name: 'Turkmenistan (+993)', value: '993'},
        { name: 'Turks & Caicos Islands (+1649)', value: '1649'},
        { name: 'Tuvalu (+688)', value: '688'},
        { name: 'Uganda (+256)', value: '256'},
        { name: 'Ukraine (+380)', value: '380'},
        { name: 'United Arab Emirates (+971)', value: '971'},
        { name: 'Uruguay (+598)', value: '598'},
        { name: 'Uzbekistan (+998)', value: '998'},
        { name: 'Vanuatu (+678)', value: '678'},
        { name: 'Vatican City (+379)', value: '379'},
        { name: 'Venezuela (+58)', value: '58'},
        { name: 'Vietnam (+84)', value: '84'},
        { name: 'Wallis & Futuna (+681)', value: '681'},
        { name: 'Yemen (North)(+969)', value: '969'},
        { name: 'Yemen (South)(+967)', value: '967'},
        { name: 'Zambia (+260)', value: '260'},
    ];

    constructor () {
        this.selectedCountryCode = this.countryCodes.filter(el => el.value === this.phoneCountryCode)[0];
    }

    public checkIfCountryCodeIsNotChosen() {
        return !this.selectedCountryCode.value.localeCompare(NO_COUNTRY_CODE_SELECTED);
    }

    public getPhoneAllowance() {
        if (this.selectedCountryCode.value === NO_COUNTRY_CODE_SELECTED) {
            return false;
        }
        return this.areTextMessagesEnabled;
    }

    public validatePhoneNumber() {
        return this.phoneNumber.match(VALID_PHONE_NUMBER_REGEX) === null;
    }
}

angular
    .module('fundersclub.support')
    .component('fcTextMessagePreferences', new FcTextMessagePreferences());
