do ->

    # Form responsible for displaying and editing company founders.
    fcCompanyFounderEdit = (
        $http,
        $uibModal,
        Company,
        CompanyTeamMember,
        FCChoices,
        FCCompanyData,
        FCCompanyFounderData,
        FCGlobals
    ) ->
        thisScope =
            'founder': '=?founder'
            'company_url': '@companyUrl'
            'founders': '=?founders'
            'edit_form_enabled': '=editFormEnabled'
            'expandCallback': '&onExpand'
            'collapseCallback': '&onCollapse'
            'deleteCallback': '&onDelete'

        thisController = ->
            vm = @
            vm.errors = {}
            vm.edit_form_expanded = false
            vm.inviting_team_member = false
            vm.founder_type_choices = FCChoices.CompanyFounderChoices
            vm.title_choices = FCChoices.CompanyTeamMemberTitleChoices
            vm.default_picture = FCCompanyFounderData.urls.DEFAULT_PICTURE
            vm.media_url = FCGlobals.MEDIA_URL

            founder_template =
                company: vm.company_url
                bio: ''
                title: ''
                founder_type: ''

            if not vm.founder
                vm.founder = new CompanyTeamMember(angular.copy(founder_template))

            vm.initial_founder_data = angular.copy(vm.founder)
            vm.save_text = if vm.founder.id then 'Save' else 'Add'
            vm.saved_text = if vm.founder.id then 'Saved' else 'Pending'

            if vm.founder.picture
                vm.picture_display = vm.media_url + vm.founder.picture
            else
                vm.picture_display = vm.default_picture

            if not (_.find vm.title_choices.choices, (choice) -> choice[0] == vm.founder.title)
                vm.other_title = vm.founder.title
                vm.founder.title = 'Other'
                vm.initial_founder_data.title = 'Other'

            vm.onImageCropped = (croppedImage, croppedImageDisplay) ->
                vm.founder.picture = croppedImage
                vm.picture_display = croppedImageDisplay
                vm.form.$setDirty()

            vm.cropImage = ($files) ->
                $uibModal.open({
                    animation: false,
                    backdrop: false,
                    component: 'FcImageCropperModal',
                    resolve: {
                        files: -> $files,
                        onCropped: -> vm.onImageCropped,
                    },
                    windowClass: 'FullTakeoverModal',
                })

            vm.showAlternateTitleTextbox = ->
                vm.founder.title == 'Other'

            vm.notAlreadyInvited = ->
                not vm.founder.user and vm.founder.work_email and vm.form.$pristine

            vm.removeLogo = ->
                vm.picture_display = vm.default_picture
                vm.founder.picture = ''

            vm.inviteEntrepreneur = ($event) ->
                # So the card is not expanded when someone goes to invite an entrepeneur
                $event.stopPropagation()

                message = '''
                    You are about to invite this person to join FundersClub.
                    They'll also be granted the ability to manage this company.
                '''
                if not confirm(message)
                    return

                vm.inviting_team_member = true
                data =
                    'email': vm.founder.work_email.toLowerCase()
                    'first_name': vm.founder.first_name
                    'founder_id': vm.founder.id
                    'last_name': vm.founder.last_name

                if !vm.notAlreadyInvited()
                    data['resend'] = true

                Company.inviteEntrepreneur(FCCompanyData.company.company_slug, data)
                    .then (response) ->
                        vm.founder.user = response.data.user
                        vm.initial_founder_data.user = response.data.user
                        alert response.data.message
                    .catch (response) ->
                        alert response.data.message
                    .finally ->
                        vm.inviting_team_member = false

            # Expand the editing form for this company founder.
            vm.expandEditForm = (expand_form) ->
                currently_expanded = vm.edit_form_expanded
                becoming_expanded = if typeof expand_form is 'boolean' then expand_form else !currently_expanded

                # Don't allow user to collapse form unless they've dealt with their
                # changes (e.g. Save or Cancel).
                if not becoming_expanded and not vm.form.$pristine then return

                # If a expansion callback was provided, fire it off.
                vm.edit_form_expanded = becoming_expanded
                if becoming_expanded then vm.expandCallback() else vm.collapseCallback()

            vm.add = (response) ->
                vm.founders.unshift(response)
                vm.founder = new CompanyTeamMember(angular.copy(founder_template))
                vm.picture_display = vm.default_picture
                vm.form.$pristine = true
                vm.expandEditForm(false)

            # Save
            vm.save = ->
                adding = not vm.founder.id
                sentOtherTitle = vm.showAlternateTitleTextbox()

                # Send custom job title to server if provided.
                if vm.founder.title == 'Other' and vm.other_title
                    vm.founder.title = vm.other_title

                vm.errors = {}
                vm.founder.$save()
                    # unsaved-warning-form monitors the form pristine/dirty state
                    .then (response) ->
                        if sentOtherTitle
                            vm.founder.title = 'Other'
                        if adding
                            vm.add(response)
                        else
                            angular.copy(response, vm.initial_founder_data)
                    .catch (response) ->
                        angular.extend(vm.errors, response.data)
                    .finally () ->
                        vm.fcSave.saveAttemptFinished(vm.errors)

            # Cancel
            vm.cancel = ->
                # Reset form data.
                angular.copy(vm.initial_founder_data, vm.founder)
                vm.form.$setPristine()
                vm.expandEditForm(false)

            # Delete
            vm.deleteCompanyFounder = ->
                remove = confirm('Are you sure you want to delete this founder?')
                if not remove
                    return

                vm.founder.$delete()
                    .then ->
                        vm.deleteCallback()

            return

        thisDirective =
            templateUrl: 'companies/founder-edit.directive.html'
            restrict: 'A'
            require: '^form'
            scope: thisScope
            bindToController: true
            controllerAs: 'cfe'
            controller: thisController
            replace: true
        return thisDirective

    angular
        .module 'fundersclub.companies'
        .directive 'fcCompanyFounderEdit', fcCompanyFounderEdit
