do ->
    BankAccount = (fcModel, $http, $injector, $q) ->
        BankAccount = fcModel '/api/bankaccounts/:id/',
            id: '@id'

        BankAccount.prototype.reset_verification = ->
            $http
                method: 'POST'
                url: @urls.reset_verification
            .then =>
                @enable_reset_verification = false
                @verification_failed = false

        BankAccount.prototype.verify = (amount_1, amount_2) ->
            $http
                method: 'POST'
                url: @urls.verify
                data:
                    'amount_1': amount_1
                    'amount_2': amount_2
            .then =>
                @verified = true

        BankAccount.prototype.create_verification_link_token = ->
            $http
                method: 'POST'
                url: @urls.create_verification_link_token

        BankAccount.prototype.verify_plaid_microdeposits = (token) ->
            $http
                method: 'POST'
                data:
                    'token': token
                url: @urls.verify_plaid_microdeposits
            .then (response) =>
                # These fields are updated by the verification call
                @account_number = response.data.account_number
                @routing_number = response.data.routing_number
                @verified = response.data.verified
                @account_type = response.data.account_type

        BankAccount.list = ->
            # Sometimes the server provides data to populate the
            # clientside models without needing to make any async
            # calls. If that cache doesn't exist, make API call.
            getAccounts = null
            try
                FCBankAccountsData = $injector.get 'FCBankAccountsData'
                getAccounts = $q (resolve, reject) ->
                    resolve
                        data: FCBankAccountsData.accounts
            catch
                getAccounts = $http.get "/api/bankaccounts/"

            getAccounts.then (response) ->
                return (new BankAccount(a) for a in response.data)

        BankAccount


    Payment = (_, fcModel, $http) ->
        Payment = fcModel '/api/payments/:id/',
            id: '@id'

        Payment.prototype.switchToOnlinePayment = (bankAccountId) ->
            $http
                method: 'POST'
                url: @urls.new_switch_to_online_payment
                data:
                    bank_account: bankAccountId

        Payment.prototype.resetOnlinePayment = (bankAccountId) ->
            $http
                method: 'POST'
                url: @urls.reset_online_payment

        Payment.prototype.confirmDistributionReinvestment = () ->
            $http
                method: 'POST'
                url: @urls.confirm_distribution_reinvestment

        Payment.prototype.nonInvestmentCreditOrDistributionsTransactions = ->
            if not @transactions
                return []

            return _.filter(
                @transactions,
                (transaction) -> !transaction.is_investment_credit && !transaction.is_distributions_reinvestment && !transaction.is_refund
            )

        Payment


    Transaction = (fcModel, $http) ->
        Transaction = fcModel '/api/transactions/:id/',
            id: '@id'

        Transaction.prototype.confirmCharge = ->
            $http
                method: 'POST'
                url: @urls.confirm_charge

        Transaction


    WireAccount = (fcModel) ->
        WireAccount = fcModel '/api/wireaccounts/:id/',
            id: '@id'
        WireAccount

    BrokerageAccount = (fcModel, $http) ->
        BrokerageAccount = fcModel '/api/brokerageaccounts/:id/',
            id: '@id'

        BrokerageAccount.prototype.selectForDistribution = (account, distributionId, confirmationCode) ->
            $http
                method: 'POST'
                url: account.url + 'select_for_distribution/'
                data:
                    distribution_id: distributionId,
                    confirmation_code: confirmationCode

        BrokerageAccount

    angular
        .module 'fundersclub.models'
        .factory 'BankAccount', BankAccount
        .factory 'Payment', Payment
        .factory 'Transaction', Transaction
        .factory 'WireAccount', WireAccount
        .factory 'BrokerageAccount', BrokerageAccount
