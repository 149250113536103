do ->

    # Handles rendering the section containing the investments in a company
    fcCompanyInvestmentTile = () ->
        nameOfController = 'fcit'

        thisController = ($scope, $element, $attrs, $compile, Partnership) ->
            vm = @
            vm.visibleForm = null
            vm.companyId = $attrs['companyId']
            vm.valuationEvents = []
            vm.assetDataVersion = 0
            vm.partnershipId = $attrs['partnershipId']
            vm.partnership = new Partnership({id: $attrs['partnershipId']})

            # Need this to ensure that the injected DOM is formatting correctly.


            # Get options for dropdown.
            vm.showActionDropdown = ->
                vm.valuationEvents = [{
                    'name': 'Loading actions...'
                    'cssClass': 'loading-placeholder'
                    'directive': '#'
                }]

                # Get available actions for this company.
                vm.partnership.$get().then ->
                    vm.partnership.available_actions_for_company(vm.companyId)
                        .then (response) ->
                            vm.valuationEvents = response.data
                        .catch (response) ->
                            # It's hard to know if there's something better to do here...
                            alert 'Internal failure. Please refresh the page and try again'
                            console.log response

                return

            vm.showForm = (directive) ->
                if not directive
                    return

                # If already showing a form, destroy it.
                if vm.visibleForm
                    vm.visibleForm.remove()

                # Inject specified directive.
                vm.visibleForm = $compile(
                    "<div class='inline-form-container'
                        #{directive}
                        company-id='#{vm.companyId}'
                        partnership-id='#{vm.partnershipId}'
                        on-save='#{nameOfController}.hideForm(true)'
                        on-cancel='#{nameOfController}.hideForm(false)'></div>"
                )($scope)
                $element.append(vm.visibleForm)
                return

            vm.hideForm = (refreshData) ->
                vm.visibleForm.remove()
                if refreshData
                    # Bump the asset version to trigger an update within the valuation tiles.
                    vm.assetDataVersion += 1

            return

        thisDirective =
            restrict: 'A'
            scope: true
            controllerAs: nameOfController
            controller: thisController
        return thisDirective

    angular
        .module 'fundersclub.assets'
        .directive 'fcCompanyInvestmentTile', fcCompanyInvestmentTile

