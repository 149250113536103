do ->

    FundMgmtFeesScheduleController = (FundClosing, MgmtFeePayment, FCMgmtFeesScheduleData, isStaff) ->
        vm = @
        vm.fund_closings = (new FundClosing(closing) for closing in FCMgmtFeesScheduleData.fund_closings)
        vm.is_staff = isStaff == true

        # Helper method to prepare data for display
        vm.prepare_data = ->
            for closing in vm.fund_closings
                closing.mgmt_fee_payments = (new MgmtFeePayment(payment) for payment in closing.mgmt_fee_payments)

            # Distinguish between closings that have MgmtFeePayments generated for them and those that don't
            vm.closings_with_payments = (closing for closing in vm.fund_closings when closing.mgmt_fee_payments.length > 0)
            vm.closings_without_payments = (closing for closing in vm.fund_closings when closing.mgmt_fee_payments.length == 0)
            vm.amount_as_of_today = 0.0

            # Get the current quarter and year
            date = new Date()
            cur_quarter = parseInt(date.getMonth() / 3) + 1
            cur_year = date.getFullYear()

            if vm.closings_with_payments.length
                [first_closing, ..., last_closing] = vm.closings_with_payments

                # Get the first and last year/quarter we're charging management fees
                start_year = first_closing.mgmt_fee_payments[0].year
                start_quarter = first_closing.mgmt_fee_payments[0].quarter
                end_year = last_closing.mgmt_fee_payments[last_closing.mgmt_fee_payments.length - 1].year
                end_quarter = last_closing.mgmt_fee_payments[last_closing.mgmt_fee_payments.length - 1].quarter

                # Generate table per category
                vm.tables = []
                for category in ['internal', 'external']
                    category_had_payment = false

                    # Go over all the quarters
                    active_year = start_year
                    active_quarter = start_quarter
                    reached_cur_quarter = false
                    rows = []
                    while true
                        payments = []
                        for closing in vm.closings_with_payments
                            # See if we can get a value for this year/quarter
                            payment = (p for p in closing.mgmt_fee_payments when p.year == active_year and p.quarter == active_quarter and p.category == category)[0]
                            payments.push(payment)

                            # If we did it means we're charging a management fee for this closing in this year/quarter
                            if payment
                                category_had_payment = true
                                active_quarter_is_cur = payment.year == cur_year and payment.quarter == cur_quarter
                                payment.view_state =
                                    # show actions for old, incomplete payments.
                                    show_actions: (
                                        ((payment.year < cur_year) or (payment.year == cur_year and payment.quarter <= cur_quarter)) and
                                        not payment.is_paid and
                                        not payment.payment_in_progress and
                                        not payment.payment_failed
                                    )
                                    change_amount: false

                                if not reached_cur_quarter
                                    vm.amount_as_of_today += parseFloat(payment.amount)

                        rows.push
                            title: "#{active_year} Q#{active_quarter}"
                            payments: payments

                        if active_year == end_year and active_quarter == end_quarter
                            break

                        if active_quarter_is_cur
                            reached_cur_quarter = true

                        # Move to next quarter
                        active_quarter += 1
                        if active_quarter == 5
                            active_quarter = 1
                            active_year += 1

                    # Calculate the Totals row
                    totals = (0.0 for n in [0 .. vm.closings_with_payments.length - 1])
                    for row in rows
                        for i in [0 .. vm.closings_with_payments.length - 1]
                            if row.payments[i]
                                totals[i] += parseFloat(row.payments[i].amount)

                    if category_had_payment
                        vm.tables.push
                            category: category
                            rows: rows
                            totals: totals

            # Generate Management Fee buttons
            vm.generate_mgmt_fee_payments = (closing) ->
                closing.saving = true
                closing.generate_mgmt_fee_payments()
                    .then ->
                        vm.prepare_data()
                        closing.saving = false

            # Pay online action
            vm.payment_pay_online = (payment) ->
                if not FCMgmtFeesScheduleData.fund_has_post_funding_bank_account
                    alert 'You must add a Post-Fundraising Bank Account to use this feature.'
                    return false

                payment.view_state.show_actions = false
                payment.view_state.saving = true
                payment.view_state.errors = {}

                payment.pay_online()
                    .then ->
                        payment.view_state.show_actions = false
                        payment.view_state.saving = false
                    .catch (response) ->
                        angular.extend(payment.view_state.errors, response.data)
                        payment.view_state.show_actions = true
                        payment.view_state.saving = false

                return false

            # Pay offline action
            vm.payment_pay_offline = (payment) ->
                payment.view_state.show_actions = false
                payment.view_state.saving = true
                payment.view_state.errors = {}

                payment.pay_offline()
                    .then ->
                        payment.view_state.show_actions = false
                        payment.view_state.saving = false
                    .catch (response) ->
                        angular.extend(payment.view_state.errors, response.data)
                        payment.view_state.show_actions = true
                        payment.view_state.saving = false

                return false

            # Change amount action
            vm.payment_change_amount = (payment) ->
                payment.view_state.show_actions = false
                payment.view_state.change_amount = true
                payment.view_state.new_amount = payment.amount
                payment.view_state.errors = {}

                return false

            vm.payment_change_amount_submit = (payment) ->
                payment.view_state.change_amount = false
                payment.view_state.saving = true

                orig_amount = payment.amount
                payment.amount = payment.view_state.new_amount
                payment.$save(['amount'])
                    .then (response) ->
                        payment.view_state.show_actions = true
                        payment.view_state.saving = false
                        vm.prepare_data()
                    .catch (response) ->
                        angular.extend(payment.view_state.errors, response.data)
                        payment.view_state.change_amount = true
                        payment.view_state.saving = false
                        payment.amount = orig_amount

                return false

        vm.prepare_data()
        return


    angular
        .module 'fundersclub.funds'
        .controller 'FundMgmtFeesScheduleController', FundMgmtFeesScheduleController
