do ->

    # Form responsible for displaying and editing company news items.
    fcCompanyNewsItemEdit = ->
        thisScope =
            'news_item': '=fcCompanyNewsItemEdit'
            'edit_form_enabled': '=editFormEnabled'
            'expandCallback': '&onExpand'
            'collapseCallback': '&onCollapse'
            'deleteCallback': '&onDelete'

        thisController = ->
            vm = @
            vm.errors = {}
            vm.edit_form_expanded = false
            vm.initial_news_item_data = angular.copy(vm.news_item)

            # Expand the editing form for this news item.
            vm.expandEditForm = (expand_form) ->
                currently_expanded = vm.edit_form_expanded
                becoming_expanded = if typeof expand_form is 'boolean' then expand_form else !currently_expanded

                # Don't allow user to collapse form unless they've dealt with their
                # changes (e.g. Save or Cancel).
                if not becoming_expanded and not vm.form.$pristine then return

                # If a expansion callback was provided, fire it off.
                vm.edit_form_expanded = becoming_expanded
                if becoming_expanded then vm.expandCallback() else vm.collapseCallback()

            # Save
            vm.save = ->
                vm.errors = {}
                vm.news_item.$save()
                    # unsaved-warning-form monitors the form pristine/dirty state
                    .then (response) ->
                        angular.copy(response, vm.initial_news_item_data)
                    .catch (response) ->
                        angular.extend(vm.errors, response.data)
                    .finally () ->
                        vm.fcSave.saveAttemptFinished(vm.errors)

            # Cancel
            vm.cancel = ->
                # Reset form data.
                angular.copy(vm.initial_news_item_data, vm.news_item)
                vm.form.$setPristine()
                vm.errors = {}
                vm.expandEditForm(false)

            # Delete
            vm.deleteNewsItem = ->
                remove = confirm('Are you sure you want to delete this news item?')
                if remove
                    vm.news_item.$delete()
                        .then ->
                            vm.deleteCallback()

            return

        thisDirective =
            templateUrl: 'companies/news-and-press-edit.directive.html'
            restrict: 'A'
            scope: thisScope
            bindToController: true
            controllerAs: 'nic'
            controller: thisController
        return thisDirective

    angular
        .module 'fundersclub.companies'
        .directive 'fcCompanyNewsItemEdit', fcCompanyNewsItemEdit
