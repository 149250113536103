class FcCompanyAssetTrackingSecForms implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        batchDate: '<',
        secForms: '<',
    };
    public controller = FcCompanyAssetTrackingSecFormsController;
    public templateUrl = 'investment-assets/company-asset-tracking-sec-forms.component.html';
}

class FcCompanyAssetTrackingSecFormsController implements ng.IComponentController {
    public batchDate: any;
    public secForms: any;

    /* tslint:disable:no-unused-variable */
    private getMainSecurityType(types: Array<string>) {
        if (types.indexOf('Equity') !== -1 && types.indexOf('Debt') !== -1) {
            return 'Equity, Debt';
        } else if (types.indexOf('Equity') !== -1) {
            return 'Equity';
        } else if (types.indexOf('Debt') !== -1) {
            return 'Debt';
        }
        return 'Other';
    }

    /* tslint:disable:no-unused-variable */
    private getSecurityTypePopoverHTML(types: Array<string>, otherTypeDescription: string) {
        let html = '<ul>';
        types.forEach((t) => {
            if (t === 'Other') {
                html += `<li>${t}: ${otherTypeDescription}</li>`;
            } else {
                html += `<li>${t}</li>`;
            }
        });
        html += '</ul>';
        return html;
    }

    /* tslint:disable:no-unused-variable */
    private isSecurityTypePopupAvailable(types: Array<string>) {
        /* Popup should be available only if there are other types than equity and debt */
        return types.filter((el) => el !== 'Equity' && el !== 'Debt').length > 0;
    }
}

angular
    .module('fundersclub.assets')
    .component('fcCompanyAssetTrackingSecForms', new FcCompanyAssetTrackingSecForms());
