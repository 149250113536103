class FcSocialAccountConnector implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        alreadyConnected: '<',
        connectUrl: '@',
        disconnectUrl: '@',
        expired: '@',
        provider: '@',
        providerName: '@',
        reconnectUrl: '@',
        socialId: '<',
        staffOnly: '@',
    };
    public controller = FcSocialAccountConnectorController;
    public templateUrl = 'users/social-account-connector.component.html';
}

class FcSocialAccountConnectorController implements ng.IComponentController {
    public alreadyConnected: boolean;
    public connectUrl: string;
    public disconnectUrl: string;
    public expired: boolean;
    public provider: string;
    public providerName: string;
    public reconnectUrl: string;
    public socialId: number;
    public staffOnly: boolean;

    private disconnecting = false;
    private errors: Array<any> = [];

    constructor(private fcSocialAccountManager: any) {}

    /* tslint:disable:no-unused-variable */
    private disconnect() {
        this.errors = [];
        this.disconnecting = true;
        this.fcSocialAccountManager.disconnect(this.disconnectUrl, this.socialId)
            .then((response: any) => this.socialId = 0)
            .catch((response: any) => {
                let errors: Array<any> = Object.keys(response.data).map((field: string) => {
                    return response.data[field][0];
                });
                angular.extend(this.errors, errors);
            })
            .finally(() => this.disconnecting = false);
    }
}

angular
    .module('fundersclub.users')
    .component('fcSocialAccountConnector', new FcSocialAccountConnector());
