let fcSecondarySellForm = (Company: any, Partnership: any) => {
    let thisScope = {
        'closeWithCancel': '&onCancel',
        'closeWithSave': '&onSave',
        'companyId': '@',
        'partnershipId': '@',
    };
    let thisController = function() {
        let vm = this;
        vm.allSoldCheckbox = false;
        vm.dataLoaded = false;
        vm.errors = {};
        vm.partnership = new Partnership({id: vm.partnershipId});
        vm.selectedAsset = null;

        // Do API call to load required form data.
        vm.partnership.$get().then(() => {
            vm.partnership.secondary_sell_details_for_company(vm.companyId)
                .then((response: any) => {
                    vm.bindCompanyData(response.data);
                })
                .catch((response: any) => {
                    // It's hard to know if there's something better to do here...
                    alert('This form failed to load. Please refresh the page and try again');
                    console.log(response);
                });
        });

        vm.saveForm = () => {
            let params = {
                company_id: vm.company.id,
                company_shares_sold_to: vm.company.company_shares_sold_to ? vm.company.company_shares_sold_to.id : '',
                effective_date: vm.company.effective_date,
                number_of_shares: vm.company.number_of_shares,
                target_asset: vm.selectedAsset ? vm.selectedAsset.id : null,
            };
            vm.errors = {};
            vm.partnership.execute_secondary_sell(params)
                .then(() => {
                    vm.closeWithSave();
                })
                .catch((response: any) => {
                    angular.extend(vm.errors, response.data);
                })
                .finally(() => {
                    vm.fcSave.saveAttemptFinished(vm.errors);
                });
        };

        vm.bindCompanyData = (data: any) => {
            vm.company = data;
            vm.dataLoaded = true;
        };

        vm.companyAdded = (company: any) => {
            vm.company.all_companies.push(company);
            vm.company.company_shares_sold_to = company;
        };

        vm.cancelForm = () => vm.closeWithCancel();

        vm.allSoldChanged = () => {
            if (vm.allSoldCheckbox) {
                if (vm.selectedAsset) {
                    vm.company.number_of_shares = vm.selectedAsset.effective_number_of_shares;
                } else {
                    vm.allSoldCheckbox = false;
                }
            }
        };
    };
    let thisDirective = {
        bindToController: true,
        controller: thisController,
        controllerAs: 'fssf',
        restrict: 'A',
        scope: thisScope,
        templateUrl: 'investment-assets/secondary-sell.directive.html',
    };
    return thisDirective;
};

angular
    .module('fundersclub.assets')
    .directive('fcSecondarySellForm', fcSecondarySellForm);
