class FcRealEstate implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        alreadySubmitted: '<',
        imgGraph: '<',
        imgHero: '<',
        imgHeroBg: '<',
        imgWordmark: '<',
        investorFirstName: '<',
        returnLink: '<',
    };
    public controller = FcRealEstateController;
    public templateUrl = 'users/real-estate.component.html';
}

class FcRealEstateController implements ng.IComponentController {
    public alreadySubmitted: boolean;
    public errors: any = {};
    public imgGraph: string;
    public imgHero: string;
    public imgHeroBg: string;
    public imgWordmark: string;
    public investorFirstName: string;
    public model: any = {};
    public returnLink: string;
    public step: number = 1;
    public submitting: boolean = false;
    public whatsTheDiff: boolean;

    constructor(
        private $http: ng.IHttpService,
        private $httpParamSerializer: ng.IHttpParamSerializer,
        private $window: ng.IWindowService
    ) {}

    public $onInit() {
        if (this.alreadySubmitted) {
            this.step = 5;
        }
    }

    public next(): void {
        this.errors = {};

        switch (this.step) {
            // How would you like to invest in real estate?
            case 2:
                if (!this.model.invest_in_hand_picked && !this.model.invest_in_managed) {
                    this.errors.investIn = 'Select at least one.';
                }
                break;

            // What type of investment offerings are you interested in?
            case 3:
                if (!this.model.invest_in_debt_offerings && !this.model.invest_in_equity_offerings) {
                    this.errors.investIn = 'Select at least one.';
                }
                break;

            // Amount
            case 4:
                if (!angular.isNumber(this.model.amount)) {
                    this.errors.amount = 'Enter an estimated amount.';
                }
                if (angular.isNumber(this.model.amount) && this.model.amount <= 0) {
                    this.errors.amount = 'Enter an amount greater than $0.';
                }
                break;

            default:
                break;
        }
        if (!angular.equals(this.errors, {})) {
            return;
        }

        // See if we need to submit the form
        if (this.step === 4) {
            this.submitting = true;
            this.$http({
                data: this.$httpParamSerializer(this.model),
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                method: 'POST',
                url: this.$window.location.href,
            }).success(() => {
                this.step++;
            }).catch(() => {
                alert('Error sending form, please try again.');
                this.submitting = false;
            });

            return;
        }

        this.scrollTop();
        this.step++;
        this.whatsTheDiff = false;
    }

    public prev(): void {
        this.scrollTop();
        this.step--;
        this.whatsTheDiff = false;
    }

    private scrollTop(): void {
        this.$window.scroll(0, 0);
    }
}

angular
    .module('fundersclub.users')
    .component('fcRealEstate', new FcRealEstate());
