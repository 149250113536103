let LearnController = function(
    $document: any,
    $scope: any,
    $window: any
) {
    let vm = this;
    vm.chapterNavOpen = false;
    vm.docHeight = $document.height();
    vm.footerHeight = document.getElementsByTagName('footer')[0].offsetHeight;
    vm.winHeight = $window.innerHeight;

    vm.scrollMax = vm.docHeight - vm.winHeight - vm.footerHeight;
    vm.scrollValue = $window.pageYOffset;

    vm.toggleChapterNav = () => {
        vm.chapterNavOpen = !vm.chapterNavOpen;
    };

    angular.element($window).bind('scroll', function() {
        $scope.$apply(() => {
            vm.scrollValue = $window.pageYOffset;
        });
    });
};

angular.module('fundersclub.learn').controller('LearnController', LearnController);
