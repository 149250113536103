let FC: any;

FC.newReturnsIrrChart = function () {
    let years = 10;
    let startingInvestment = 100000;
    /* tslint:disable:no-string-literal */
    let FCIrr: any = (<any> window)['FCIrr'];
    /* tslint:enable:no-string-literal */
    let sAndP500Ten = 0.067;
    /* tslint:disable:no-unused-variable */
    let russellTen = 0.073;
    let sAndP500All = 0.091;
    let russellAll = 0.076;
    /* tslint:enable:no-unused-variable */
    let yearlyArray: Array<number>;
    let fcData: any;
    let otherData: any;

    let $: any = jQuery;

    $(document).ready(function () {
        yearlyArray = _.range(years);

        let createDataSets = function (array: Array<any>, datasetIrr: any, startInvestment: number) {
            let dataset: any;
            let amount = startInvestment;
            let irr = datasetIrr;

            let start = years - 1;
            let ascending = start <= 0;

            for (let i of _.range(years - 1, ascending ? 1 : -1, ascending ? 1 : -1)) {
                let x = 1 + irr;
                let y = amount * x;
                let z = y;
                array[i] = Math.round(z);
                amount = z;
            }
            return dataset = array.reverse();
        };

        fcData = createDataSets(yearlyArray, FCIrr, startingInvestment).slice();
        otherData = createDataSets(yearlyArray, sAndP500Ten, startingInvestment).slice();

        let updateGraph = function () {
            let newAmount = $('#amount-input').val();
            newAmount = parseFloat(newAmount);
            let newIrr = $('#irr-input').val();
            newIrr = parseFloat(newIrr);
            newIrr = newIrr / 100;
            fcData = createDataSets(yearlyArray, newIrr, newAmount).slice();
            otherData = createDataSets(yearlyArray, sAndP500Ten, newAmount).slice();
            let chart = $('#irr-calculator').highcharts();
            chart.series[0].setData(fcData);
            return chart.series[1].setData(otherData);
        };

        $('#irr-input').change(() => updateGraph());
        return $('#amount-input').change(() => updateGraph());
    });

    return $(function () {
        let highestValue = 0;

        return $('#irr-calculator').highcharts({
            chart: {
                backgroundColor: 'transparent',
                marginLeft: 20,
                marginRight: 70,
                plotBackgroundColor: '#ffffff',
                spacingBottom: 20,
                spacingTop: 20,
                type: 'column',
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: false,
            },
            legend: {
                enabled: false,
            },
            plotOptions: {
                column: {
                    borderWidth: 0,
                    groupPadding: 0.1,
                    pointPadding: 0,
                },
            },
            series: [{
                color: '#184368',
                data: fcData,
                name: 'IRR',
            }, {
                color: '#3694E8',
                data: otherData,
                name: 'Reference',
            }],
            title: {
                text: null,
            },
            tooltip: {
                enabled: false,
                pointFormat: '<b>{point.y}</b><br/>',
                useHTML: true,
                valuePrefix: '$',
            },
            xAxis: {
                categories: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
                gridLineColor: '#EFF3F6',
                gridLineWidth: 1,
                labels: {
                    formatter: function () {
                        return this.value + 'y';
                    },
                    style: {
                        color: '#757575',
                        fontSize: '12px',
                    },
                },
                lineColor: '#EFF3F6',
                tickColor: '#EFF3F6',
                tickLength: 0,
            },
            yAxis: {
                gridLineColor: '#EEEEEE',
                labels: {
                    formatter: function ()  {
                        let { value } = this;
                        let last = this.isLast;
                        if (last) {
                            highestValue = value;
                        }

                        if (value >= 1000000) {
                            value = value / 1000000;
                            value = `${ value }M`;
                        } else {
                            value = value / 1000;
                            value = `${ value }K`;
                        }
                        return `$${ value }`;
                    },
                    style: {
                        color: '#757575',
                        fontSize: '12px',
                    },
                    useHTML: true,
                },
                min: 0,
                opposite: true,
                title: {
                    text: null,
                },
            },
        });
    });
};

FC.newReturnsOurReturnsCharts = function () {
    let baseOptions = {
        chart: {
            height: 300,
        },

        legend: {
            align: 'center',
            itemStyle: {
                color: '#323B43',
                fontSize: '14px',
            },
            margin: 30,
            verticalAlign: 'bottom',
        },

        plotOptions: {
            dataLabels: {
                shape: 'circle',
            },
            series: {
                cursor: 'pointer',
                lineWidth: 3,
                marker: {
                    enabled: true,
                    symbol: 'circle',
                },
                states: {
                    hover: {
                        lineWidth: 3,
                    },
                },
                tooltip: {
                    dateTimeLabelFormats: {
                        day: '%B %Y',
                    },
                },
            },
        },

        responsive: {
            rules: [{
                chartOptions: {
                    chart: {
                        spacingTop: 30,
                    },
                    yAxis: {
                        title: {
                            text: '',
                        },
                    },
                },
                condition: {
                    maxWidth: 520,
                },
            }, {
                chartOptions: {
                    yAxis: {
                        title: {
                            align: 'middle',
                            rotation: 270,
                            style: {
                                color: '#323B43',
                                fontSize: '16px',
                                fontWeight: '700',
                            },
                            x: -10,
                            y: 0,
                        },
                    },
                },
                condition: {
                    minWidth: 521,
                },
            }],
        },

        title: {
            text: '',
        },

        tooltip: {
            backgroundColor: 'RGBA(255,255,255,0.95)',
            borderColor: '#CED7DF',
            crosshairs: true,
            footerFormat: '</table>',
            headerFormat: (
                '<div style="margin-bottom:4px; font-size:10px">{point.key}</div><table style="color: #323B43;">'
            ),
            padding: 13,
            shadow: false,
            shared: true,
            useHTML: true,
        },

        xAxis: {
            labels: {
                step: 1,
            },
            tickInterval: 24 * 3600 * 1000 * 365,
            tickWidth: 0,
            type: 'datetime',
        },

        yAxis: {
            labels: {
                formatter: function () {
                    return `${ this.value }%`;
                },
            },
            title: {
                style: {
                    color: '#323B43',
                    fontSize: '16px',
                    fontWeight: '700',
                },
                x: -10,
            },
        },
    };

    const tooltipPointFormatIRR = (
        '<tr><td style="line-height:18px"><span style="color:{point.color}">\u25CF</span> <b>{series.name}' +
        '</b>&nbsp;</td><td style="text-align: ' +
        'right">&nbsp;{point.y}% Net IRR</td></tr>'
    );

    const tooltipPointFormatMultiple = (
        '<tr><td style="line-height:18px"><span style="color:{point.color}">\u25CF</span> <b>{series.name}' +
        '</b>&nbsp;</td><td style="text-align: ' +
        'right">&nbsp;{point.y}x</td></tr>'
    );

    const yAxisTitleIRR = 'Net IRR';
    const yAxisTitleMultiple = 'Net Multiple';

    const yAxisLabelsIRR = function() {
        return `${this.value}%`;
    };
    const yAxisLabelsMultiple = function() {
        return `${this.value}x`;
    };

    let returns2012IRROptions = $.extend(true, {}, baseOptions);
    returns2012IRROptions.tooltip.pointFormat = tooltipPointFormatIRR;
    returns2012IRROptions.yAxis.labels.formatter = yAxisLabelsIRR;
    returns2012IRROptions.yAxis.title.text = yAxisTitleIRR;
    returns2012IRROptions.series = [{
        color: '#78838C',
        data: [{
            x: Date.UTC(2012, 0, 1),
            y: 0,
        }, {
            x: Date.UTC(2014, 0, 25),
            y: -4,
        }, {
            x: Date.UTC(2014, 6, 25),
            y: 4,
        }, {
            x: Date.UTC(2015, 0, 20),
            y: 12,
        }, {
            x: Date.UTC(2015, 5, 19),
            y: 20,
        }, {
            x: Date.UTC(2015, 11, 31),
            y: 17,
        }, {
            x: Date.UTC(2016, 2, 31),
            y: 14,
        }, {
            x: Date.UTC(2016, 5, 30),
            y: 13,
        }, {
            x: Date.UTC(2016, 8, 30),
            y: 12,
        }, {
            x: Date.UTC(2016, 11, 31),
            y: 11,
        }, {
            x: Date.UTC(2017, 2, 31),
            y: 11,
        }, {
            x: Date.UTC(2017, 5, 30),
            y: 12,
        }, {
            x: Date.UTC(2017, 8, 30),
            y: 12,
        }, {
            x: Date.UTC(2017, 11, 31),
            y: 13,
        }],
        index: 1,
        name: 'Median VC**',
        zIndex: 1,
    }, {
        color: '#3694e8',
        data: [{
            x: Date.UTC(2012, 0, 1),
            y: 0,
        }, {
            x: Date.UTC(2014, 0, 25),
            y: 46,
        }, {
            x: Date.UTC(2014, 6, 25),
            y: 35,
        }, {
            x: Date.UTC(2015, 0, 20),
            y: 59,
        }, {
            x: Date.UTC(2015, 5, 19),
            y: 47,
        }, {
            x: Date.UTC(2015, 11, 31),
            y: 46,
        }, {
            x: Date.UTC(2016, 2, 31),
            y: 42,
        }, {
            x: Date.UTC(2016, 5, 30),
            y: 28,
        }, {
            x: Date.UTC(2016, 8, 30),
            y: 26,
        }, {
            x: Date.UTC(2016, 11, 31),
            y: 24,
        }, {
            x: Date.UTC(2017, 2, 31),
            y: 22,
        }, {
            x: Date.UTC(2017, 5, 30),
            y: 21,
        }, {
            x: Date.UTC(2017, 8, 30),
            y: 44,
        }, {
            x: Date.UTC(2017, 11, 31),
            y: 41,
        }, {
            x: Date.UTC(2018, 2, 31),
            y: 37,
        }, {
            x: Date.UTC(2018, 5, 30),
            y: 38,
        }, {
            x: Date.UTC(2018, 8, 30),
            y: 36,
        }, {
            x: Date.UTC(2018, 11, 31),
            y: 69,
        }, {
            x: Date.UTC(2019, 2, 31),
            y: 65,
        }, {
            x: Date.UTC(2019, 5, 30),
            y: 62,
        }, {
            x: Date.UTC(2019, 8, 30),
            y: 59,
        }, {
            x: Date.UTC(2019, 11, 31),
            y: 56,
        }, {
            x: Date.UTC(2020, 2, 31),
            y: 53,
        }, {
            x: Date.UTC(2020, 5, 30),
            y: 51,
        }, {
            x: Date.UTC(2020, 8, 30),
            y: 49,
        }],
        index: 0,
        name: 'FundersClub*',
        zIndex: 10,
    }];

    let returns2012MultipleOptions = $.extend(true, {}, baseOptions);
    returns2012MultipleOptions.tooltip.pointFormat = tooltipPointFormatMultiple;
    returns2012MultipleOptions.yAxis.labels.formatter = yAxisLabelsMultiple;
    returns2012MultipleOptions.yAxis.title.text = yAxisTitleMultiple;
    returns2012MultipleOptions.series = [{
        color: '#78838C',
        data: [{
            x: Date.UTC(2012, 0, 1),
            y: 1.0,
        }, {
            x: Date.UTC(2014, 0, 25),
            y: 0.97,
        }, {
            x: Date.UTC(2014, 6, 25),
            y: 1.03,
        }, {
            x: Date.UTC(2015, 0, 20),
            y: 1.13,
        }, {
            x: Date.UTC(2015, 5, 19),
            y: 1.24,
        }, {
            x: Date.UTC(2015, 11, 31),
            y: 1.32,
        }, {
            x: Date.UTC(2016, 2, 31),
            y: 1.24,
        }, {
            x: Date.UTC(2016, 5, 30),
            y: 1.26,
        }, {
            x: Date.UTC(2016, 8, 30),
            y: 1.25,
        }, {
            x: Date.UTC(2016, 11, 31),
            y: 1.30,
        }, {
            x: Date.UTC(2017, 2, 31),
            y: 1.28,
        }, {
            x: Date.UTC(2017, 5, 30),
            y: 1.34,
        }, {
            x: Date.UTC(2017, 8, 30),
            y: 1.38,
        }, {
            x: Date.UTC(2017, 11, 31),
            y: 1.47,
        }],
        index: 1,
        name: 'Median VC**',
        zIndex: 1,
    }, {
        color: '#3694E8',
        data: [{
            x: Date.UTC(2012, 0, 1),
            y: 1.0,
        }, {
            x: Date.UTC(2014, 0, 25),
            y: 1.44,
        }, {
            x: Date.UTC(2014, 6, 25),
            y: 1.55,
        }, {
            x: Date.UTC(2015, 0, 20),
            y: 2.44,
        }, {
            x: Date.UTC(2015, 5, 19),
            y: 2.44,
        }, {
            x: Date.UTC(2015, 11, 31),
            y: 2.98,
        }, {
            x: Date.UTC(2016, 2, 31),
            y: 3.00,
        }, {
            x: Date.UTC(2016, 5, 30),
            y: 2.26,
        }, {
            x: Date.UTC(2016, 8, 30),
            y: 2.26,
        }, {
            x: Date.UTC(2016, 11, 31),
            y: 2.22,
        }, {
            x: Date.UTC(2017, 2, 31),
            y: 2.22,
        }, {
            x: Date.UTC(2017, 5, 30),
            y: 2.21,
        }, {
            x: Date.UTC(2017, 8, 30),
            y: 5.25,
        }, {
            x: Date.UTC(2017, 11, 31),
            y: 5.21,
        }, {
            x: Date.UTC(2018, 2, 31),
            y: 5.21,
        }, {
            x: Date.UTC(2018, 5, 30),
            y: 6.16,
        }, {
            x: Date.UTC(2018, 8, 30),
            y: 6.21,
        }, {
            x: Date.UTC(2018, 11, 31),
            y: 21.96,
        }, {
            x: Date.UTC(2019, 2, 31),
            y: 21.96,
        }, {
            x: Date.UTC(2019, 5, 30),
            y: 21.96,
        }, {
            x: Date.UTC(2019, 8, 30),
            y: 21.95,
        }, {
            x: Date.UTC(2019, 11, 31),
            y: 21.95,
        }, {
             x: Date.UTC(2020, 2, 31),
             y: 21.81,
        }, {
            x: Date.UTC(2020, 5, 30),
             y: 21.81,
        }, {
            x: Date.UTC(2020, 8, 30),
            y: 21.81,
        }],
        index: 0,
        name: 'FundersClub*',
        zIndex: 10,
    }];

    let returns2013IRROptions = $.extend(true, {}, baseOptions);
    returns2013IRROptions.tooltip.pointFormat = tooltipPointFormatIRR;
    returns2013IRROptions.yAxis.labels.formatter = yAxisLabelsIRR;
    returns2013IRROptions.yAxis.title.text = yAxisTitleIRR;
    returns2013IRROptions.series = [{
        color: '#78838C',
        data: [{
            x: Date.UTC(2013, 0, 1),
            y: 0,
        }, {
            x: Date.UTC(2015, 0, 20),
            y: -9,
        }, {
            x: Date.UTC(2015, 5, 19),
            y: 4,
        }, {
            x: Date.UTC(2015, 11, 31),
            y: 5,
        }, {
            x: Date.UTC(2016, 2, 31),
            y: 7,
        }, {
            x: Date.UTC(2016, 5, 30),
            y: 7,
        }, {
            x: Date.UTC(2016, 8, 30),
            y: 9,
        }, {
            x: Date.UTC(2016, 11, 31),
            y: 9,
        }, {
            x: Date.UTC(2017, 2, 31),
            y: 10,
        }, {
            x: Date.UTC(2017, 5, 30),
            y: 13,
        }, {
            x: Date.UTC(2017, 8, 30),
            y: 12,
        }, {
            x: Date.UTC(2017, 11, 31),
            y: 13,
        }],
        index: 1,
        name: 'Median VC**',
        zIndex: 1,
    }, {
        color: '#3694E8',
        data: [{
            x: Date.UTC(2013, 0, 1),
            y: 0,
        }, {
            x: Date.UTC(2015, 0, 20),
            y: 106,
        }, {
            x: Date.UTC(2015, 5, 19),
            y: 84,
        }, {
            x: Date.UTC(2015, 11, 31),
            y: 63,
        }, {
            x: Date.UTC(2016, 2, 31),
            y: 50,
        }, {
            x: Date.UTC(2016, 5, 30),
            y: 45,
        }, {
            x: Date.UTC(2016, 8, 30),
            y: 43,
        }, {
            x: Date.UTC(2016, 11, 31),
            y: 39,
        }, {
            x: Date.UTC(2017, 2, 31),
            y: 41,
        }, {
            x: Date.UTC(2017, 5, 30),
            y: 39,
        }, {
            x: Date.UTC(2017, 8, 30),
            y: 42,
        }, {
            x: Date.UTC(2017, 11, 31),
            y: 41,
        }, {
            x: Date.UTC(2018, 2, 31),
            y: 40,
        }, {
            x: Date.UTC(2018, 5, 30),
            y: 38,
        }, {
            x: Date.UTC(2018, 8, 30),
            y: 35,
        }, {
            x: Date.UTC(2018, 11, 31),
            y: 50,
        }, {
            x: Date.UTC(2019, 2, 31),
            y: 47,
        }, {
            x: Date.UTC(2019, 5, 30),
            y: 46,
        }, {
            x: Date.UTC(2019, 8, 30),
            y: 43,
        }, {
            x: Date.UTC(2019, 11, 31),
            y: 42,
        }, {
            x: Date.UTC(2020, 2, 31),
            y: 40,
        }, {
            x: Date.UTC(2020, 5, 30),
            y: 42,
        }, {
            x: Date.UTC(2020, 8, 30),
            y: 40,
        }],
        index: 0,
        name: 'FundersClub*',
        zIndex: 10,
    }];

    let returns2013MultipleOptions = $.extend(true, {}, baseOptions);
    returns2013MultipleOptions.tooltip.pointFormat = tooltipPointFormatMultiple;
    returns2013MultipleOptions.yAxis.labels.formatter = yAxisLabelsMultiple;
    returns2013MultipleOptions.yAxis.title.text = yAxisTitleMultiple;
    returns2013MultipleOptions.series = [{
        color: '#78838C',
        data: [{
            x: Date.UTC(2013, 0, 1),
            y: 1.0,
        }, {
            x: Date.UTC(2015, 0, 20),
            y: 0.95,
        }, {
            x: Date.UTC(2015, 5, 19),
            y: 1.04,
        }, {
            x: Date.UTC(2015, 11, 31),
            y: 1.07,
        }, {
            x: Date.UTC(2016, 2, 31),
            y: 1.08,
        }, {
            x: Date.UTC(2016, 5, 30),
            y: 1.09,
        }, {
            x: Date.UTC(2016, 8, 30),
            y: 1.14,
        }, {
            x: Date.UTC(2016, 11, 31),
            y: 1.13,
        }, {
            x: Date.UTC(2017, 2, 31),
            y: 1.18,
        }, {
            x: Date.UTC(2017, 5, 30),
            y: 1.26,
        }, {
            x: Date.UTC(2017, 8, 30),
            y: 1.28,
        }, {
            x: Date.UTC(2017, 11, 31),
            y: 1.34,
        }],
        index: 1,
        name: 'Median VC**',
        zIndex: 1,
    }, {
        color: '#3694E8',
        data: [{
            x: Date.UTC(2013, 0, 1),
            y: 1.0,
        }, {
            x: Date.UTC(2015, 0, 20),
            y: 3.13,
        }, {
            x: Date.UTC(2015, 5, 19),
            y: 3.32,
        }, {
            x: Date.UTC(2015, 11, 31),
            y: 3.36,
        }, {
            x: Date.UTC(2016, 2, 31),
            y: 2.96,
        }, {
            x: Date.UTC(2016, 5, 30),
            y: 2.99,
        }, {
            x: Date.UTC(2016, 8, 30),
            y: 3.09,
        }, {
            x: Date.UTC(2016, 11, 31),
            y: 3.08,
        }, {
            x: Date.UTC(2017, 2, 31),
            y: 3.47,
        }, {
            x: Date.UTC(2017, 5, 30),
            y: 3.6,
        }, {
            x: Date.UTC(2017, 8, 30),
            y: 4.24,
        }, {
            x: Date.UTC(2017, 11, 31),
            y: 4.41,
        }, {
            x: Date.UTC(2018, 2, 31),
            y: 4.65,
        }, {
            x: Date.UTC(2018, 5, 30),
            y: 4.73,
        }, {
            x: Date.UTC(2018, 8, 30),
            y: 4.77,
        }, {
            x: Date.UTC(2018, 11, 31),
            y: 8.8,
        }, {
            x: Date.UTC(2019, 2, 31),
            y: 8.76,
        }, {
            x: Date.UTC(2019, 5, 30),
            y: 9.02,
        }, {
            x: Date.UTC(2019, 8, 30),
            y: 8.95,
        }, {
            x: Date.UTC(2019, 11, 31),
            y: 9.1,
        }, {
             x: Date.UTC(2020, 2, 31),
             y: 8.98,
        }, {
             x: Date.UTC(2020, 5, 30),
             y: 10.72,
        }, {
            x: Date.UTC(2020, 8, 30),
            y: 11.02,
        }],
        index: 0,
        name: 'FundersClub*',
        zIndex: 10,
    }];

    let returns2014IRROptions = $.extend(true, {}, baseOptions);
    returns2014IRROptions.tooltip.pointFormat = tooltipPointFormatIRR;
    returns2014IRROptions.yAxis.labels.formatter = yAxisLabelsIRR;
    returns2014IRROptions.yAxis.title.text = yAxisTitleIRR;
    returns2014IRROptions.series = [{
        color: '#78838C',
        data: [{
            x: Date.UTC(2014, 0, 1),
            y: 0,
        }, {
            x: Date.UTC(2015, 11, 31),
            y: 1,
        }, {
            x: Date.UTC(2016, 2, 31),
            y: 1,
        }, {
            x: Date.UTC(2016, 5, 30),
            y: 2,
        }, {
            x: Date.UTC(2016, 8, 30),
            y: 3,
        }, {
            x: Date.UTC(2016, 11, 31),
            y: 6,
        }, {
            x: Date.UTC(2017, 2, 31),
            y: 7,
        }, {
            x: Date.UTC(2017, 5, 30),
            y: 8,
        }, {
            x: Date.UTC(2017, 8, 30),
            y: 10,
        }, {
            x: Date.UTC(2017, 11, 31),
            y: 10,
        }],
        index: 1,
        name: 'Median VC**',
        zIndex: 1,
    }, {
        color: '#3694E8',
        data: [{
            x: Date.UTC(2014, 0, 1),
            y: 0,
        }, {
            x: Date.UTC(2015, 11, 31),
            y: 8,
        }, {
            x: Date.UTC(2016, 2, 31),
            y: 6,
        }, {
            x: Date.UTC(2016, 5, 30),
            y: 3,
        }, {
            x: Date.UTC(2016, 8, 30),
            y: 5,
        }, {
            x: Date.UTC(2016, 11, 31),
            y: 4,
        }, {
            x: Date.UTC(2017, 2, 31),
            y: 5,
        }, {
            x: Date.UTC(2017, 5, 30),
            y: 8,
        }, {
            x: Date.UTC(2017, 8, 30),
            y: 8,
        }, {
            x: Date.UTC(2017, 11, 31),
            y: 9,
        }, {
            x: Date.UTC(2018, 2, 31),
            y: 8,
        }, {
            x: Date.UTC(2018, 5, 30),
            y: 8,
        }, {
            x: Date.UTC(2018, 8, 30),
            y: 9,
        }, {
            x: Date.UTC(2018, 11, 31),
            y: 9,
        }, {
            x: Date.UTC(2019, 2, 31),
            y: 10,
        }, {
            x: Date.UTC(2019, 5, 30),
            y: 12,
        }, {
            x: Date.UTC(2019, 8, 30),
            y: 12,
        }, {
            x: Date.UTC(2019, 11, 31),
            y: 15,
        }, {
             x: Date.UTC(2020, 2, 31),
             y: 13,
        }, {
             x: Date.UTC(2020, 5, 30),
             y: 13,
        }, {
            x: Date.UTC(2020, 8, 30),
            y: 13,
        }],
        index: 0,
        name: 'FundersClub*',
        zIndex: 10,
    }];

    let returns2014MultipleOptions = $.extend(true, {}, baseOptions);
    returns2014MultipleOptions.tooltip.pointFormat = tooltipPointFormatMultiple;
    returns2014MultipleOptions.yAxis.labels.formatter = yAxisLabelsMultiple;
    returns2014MultipleOptions.yAxis.title.text = yAxisTitleMultiple;
    returns2014MultipleOptions.series = [{
        color: '#78838C',
        data: [{
            x: Date.UTC(2014, 0, 1),
            y: 1.0,
        }, {
            x: Date.UTC(2015, 11, 31),
            y: 1.01,
        }, {
            x: Date.UTC(2016, 2, 31),
            y: 1.01,
        }, {
            x: Date.UTC(2016, 5, 30),
            y: 1.02,
        }, {
            x: Date.UTC(2016, 8, 30),
            y: 1.04,
        }, {
            x: Date.UTC(2016, 11, 31),
            y: 1.07,
        }, {
            x: Date.UTC(2017, 2, 31),
            y: 1.11,
        }, {
            x: Date.UTC(2017, 5, 30),
            y: 1.13,
        }, {
            x: Date.UTC(2017, 8, 30),
            y: 1.18,
        }, {
            x: Date.UTC(2017, 11, 31),
            y: 1.21,
        }],
        index: 1,
        name: 'Median VC**',
        zIndex: 1,
    }, {
        color: '#3694E8',
        data: [{
            x: Date.UTC(2014, 0, 1),
            y: 1.0,
        }, {
            x: Date.UTC(2015, 11, 31),
            y: 1.11,
        }, {
            x: Date.UTC(2016, 2, 31),
            y: 1.11,
        }, {
            x: Date.UTC(2016, 5, 30),
            y: 1.06,
        }, {
            x: Date.UTC(2016, 8, 30),
            y: 1.11,
        }, {
            x: Date.UTC(2016, 11, 31),
            y: 1.09,
        }, {
            x: Date.UTC(2017, 2, 31),
            y: 1.13,
        }, {
            x: Date.UTC(2017, 5, 30),
            y: 1.25,
        }, {
            x: Date.UTC(2017, 8, 30),
            y: 1.27,
        }, {
            x: Date.UTC(2017, 11, 31),
            y: 1.33,
        }, {
            x: Date.UTC(2018, 2, 31),
            y: 1.31,
        }, {
            x: Date.UTC(2018, 5, 30),
            y: 1.34,
        }, {
            x: Date.UTC(2018, 8, 30),
            y: 1.43,
        }, {
            x: Date.UTC(2018, 11, 31),
            y: 1.46,
        }, {
            x: Date.UTC(2019, 2, 31),
            y: 1.52,
        }, {
            x: Date.UTC(2019, 5, 30),
            y: 1.67,
        }, {
            x: Date.UTC(2019, 8, 30),
            y: 1.76,
        }, {
            x: Date.UTC(2019, 11, 31),
            y: 2.01,
        }, {
            x: Date.UTC(2020, 2, 31),
            y: 1.96,
        }, {
            x: Date.UTC(2020, 5, 30),
            y: 1.95,
        }, {
            x: Date.UTC(2020, 8, 30),
            y: 2.07,
        }],
        index: 0,
        name: 'FundersClub*',
        zIndex: 10,
    }];

    let returns2015IRROptions = $.extend(true, {}, baseOptions);
    returns2015IRROptions.tooltip.pointFormat = tooltipPointFormatIRR;
    returns2015IRROptions.yAxis.labels.formatter = yAxisLabelsIRR;
    returns2015IRROptions.yAxis.title.text = yAxisTitleIRR;
    returns2015IRROptions.series = [{
        color: '#78838C',
        data: [{
            x: Date.UTC(2015, 0, 1),
            y: 0,
        }, {
            x: Date.UTC(2016, 11, 31),
            y: -7,
        }, {
            x: Date.UTC(2017, 2, 31),
            y: -3,
        }, {
            x: Date.UTC(2017, 5, 30),
            y: 2,
        }, {
            x: Date.UTC(2017, 8, 30),
            y: 3,
        }, {
            x: Date.UTC(2017, 11, 31),
            y: 7,
        }],
        index: 1,
        name: 'Median VC**',
        zIndex: 1,
    }, {
        color: '#3694E8',
        data: [{
            x: Date.UTC(2015, 0, 1),
            y: 0,
        }, {
            x: Date.UTC(2016, 11, 31),
            y: -12,
        }, {
            x: Date.UTC(2017, 2, 31),
            y: -6,
        }, {
            x: Date.UTC(2017, 5, 30),
            y: -1,
        }, {
            x: Date.UTC(2017, 8, 30),
            y: -2,
        }, {
            x: Date.UTC(2017, 11, 31),
            y: -1,
        }, {
            x: Date.UTC(2018, 2, 31),
            y: -1,
        }, {
            x: Date.UTC(2018, 5, 30),
            y: 1,
        }, {
            x: Date.UTC(2018, 8, 30),
            y: 9,
        }, {
            x: Date.UTC(2018, 9, 31),
            y: 9,
        }, {
            x: Date.UTC(2019, 2, 31),
            y: 12,
        }, {
            x: Date.UTC(2019, 5, 30),
            y: 12,
        }, {
            x: Date.UTC(2019, 8, 30),
            y: 15,
        }, {
            x: Date.UTC(2019, 11, 31),
            y: 16,
        }, {
            x: Date.UTC(2020, 2, 31),
            y: 14,
        }, {
            x: Date.UTC(2020, 5, 30),
            y: 13,
        }, {
            x: Date.UTC(2020, 8, 30),
            y: 15,
        }],
        index: 0,
        name: 'FundersClub*',
        zIndex: 10,
    }];

    let returns2015MultipleOptions = $.extend(true, {}, baseOptions);
    returns2015MultipleOptions.tooltip.pointFormat = tooltipPointFormatMultiple;
    returns2015MultipleOptions.yAxis.labels.formatter = yAxisLabelsMultiple;
    returns2015MultipleOptions.yAxis.title.text = yAxisTitleMultiple;
    returns2015MultipleOptions.series = [{
        color: '#78838C',
        data: [{
            x: Date.UTC(2015, 0, 1),
            y: 1.0,
        }, {
            x: Date.UTC(2016, 11, 31),
            y: 0.94,
        }, {
            x: Date.UTC(2017, 2, 31),
            y: 0.97,
        }, {
            x: Date.UTC(2017, 5, 30),
            y: 1.01,
        }, {
            x: Date.UTC(2017, 8, 30),
            y: 1.03,
        }, {
            x: Date.UTC(2017, 11, 31),
            y: 1.10,
        }],
        index: 1,
        name: 'Median VC**',
        zIndex: 1,
    }, {
        color: '#3694E8',
        data: [{
            x: Date.UTC(2015, 0, 1),
            y: 1.0,
        }, {
            x: Date.UTC(2016, 11, 31),
            y: 0.82,
        }, {
            x: Date.UTC(2017, 2, 31),
            y: 0.9,
        }, {
            x: Date.UTC(2017, 5, 30),
            y: 0.97,
        }, {
            x: Date.UTC(2017, 8, 30),
            y: 0.96,
        }, {
            x: Date.UTC(2017, 11, 31),
            y: 0.97,
        }, {
            x: Date.UTC(2018, 2, 31),
            y: 0.97,
        }, {
            x: Date.UTC(2018, 5, 30),
            y: 1.02,
        }, {
            x: Date.UTC(2018, 8, 30),
            y: 1.31,
        }, {
            x: Date.UTC(2018, 11, 31),
            y: 1.31,
        }, {
            x: Date.UTC(2019, 2, 31),
            y: 1.47,
        }, {
            x: Date.UTC(2019, 5, 30),
            y: 1.52,
        }, {
            x: Date.UTC(2019, 8, 30),
            y: 1.75,
        }, {
            x: Date.UTC(2019, 11, 31),
            y: 1.83,
        }, {
            x: Date.UTC(2020, 2, 31),
            y: 1.78,
        }, {
            x: Date.UTC(2020, 5, 30),
            y: 1.79,
        }, {
            x: Date.UTC(2020, 8, 30),
            y: 2.02,
        }],
        index: 0,
        name: 'FundersClub*',
        zIndex: 10,
    }];

    let returns2016IRROptions = $.extend(true, {}, baseOptions);
    returns2016IRROptions.tooltip.pointFormat = tooltipPointFormatIRR;
    returns2016IRROptions.yAxis.labels.formatter = yAxisLabelsIRR;
    returns2016IRROptions.yAxis.title.text = yAxisTitleIRR;
    returns2016IRROptions.series = [{
        color: '#78838C',
        data: [{
            x: Date.UTC(2016, 0, 1),
            y: 0,
        }, {
            x: Date.UTC(2017, 11, 31),
            y: -1,
        }],
        index: 1,
        name: 'Median VC**',
        zIndex: 1,
    }, {
        color: '#3694E8',
        data: [{
            x: Date.UTC(2016, 0, 1),
            y: 0,
        }, {
            x: Date.UTC(2017, 11, 31),
            y: 18,
        }, {
            x: Date.UTC(2018, 2, 31),
            y: 20,
        }, {
            x: Date.UTC(2018, 5, 30),
            y: 24,
        }, {
            x: Date.UTC(2018, 8, 30),
            y: 30,
        }, {
            x: Date.UTC(2018, 11, 31),
            y: 29,
        }, {
            x: Date.UTC(2019, 2, 31),
            y: 34,
        }, {
            x: Date.UTC(2019, 5, 30),
            y: 39,
        }, {
            x: Date.UTC(2019, 8, 30),
            y: 38,
        }, {
            x: Date.UTC(2019, 11, 31),
            y: 39,
        }, {
             x: Date.UTC(2020, 2, 31),
             y: 36,
        }, {
             x: Date.UTC(2020, 5, 30),
             y: 35,
        }, {
            x: Date.UTC(2020, 8, 30),
            y: 37,
        }],
        index: 0,
        name: 'FundersClub*',
        zIndex: 10,
    }];

    let returns2016MultipleOptions = $.extend(true, {}, baseOptions);
    returns2016MultipleOptions.tooltip.pointFormat = tooltipPointFormatMultiple;
    returns2016MultipleOptions.yAxis.labels.formatter = yAxisLabelsMultiple;
    returns2016MultipleOptions.yAxis.title.text = yAxisTitleMultiple;
    returns2016MultipleOptions.series = [{
        color: '#78838C',
        data: [{
            x: Date.UTC(2016, 0, 1),
            y: 1.0,
        }, {
            x: Date.UTC(2017, 11, 31),
            y: 0.99,
        }],
        index: 1,
        name: 'Median VC**',
        zIndex: 1,
    }, {
        color: '#3694E8',
        data: [{
            x: Date.UTC(2016, 0, 1),
            y: 1.0,
        }, {
            x: Date.UTC(2017, 11, 31),
            y: 1.29,
        }, {
            x: Date.UTC(2018, 2, 31),
            y: 1.38,
        }, {
            x: Date.UTC(2018, 5, 30),
            y: 1.53,
        }, {
            x: Date.UTC(2018, 8, 30),
            y: 1.8,
        }, {
            x: Date.UTC(2018, 11, 31),
            y: 1.88,
        }, {
            x: Date.UTC(2019, 2, 31),
            y: 2.21,
        }, {
            x: Date.UTC(2019, 5, 30),
            y: 2.62,
        }, {
            x: Date.UTC(2019, 8, 30),
            y: 2.79,
        }, {
            x: Date.UTC(2019, 11, 31),
            y: 3.02,
        }, {
            x: Date.UTC(2020, 2, 31),
            y: 3.03,
        }, {
            x: Date.UTC(2020, 5, 30),
            y: 3.17,
        }, {
            x: Date.UTC(2020, 8, 30),
            y: 3.58,
        }],
        index: 0,
        name: 'FundersClub*',
        zIndex: 10,
    }];

    let returns2017IRROptions = $.extend(true, {}, baseOptions);
    returns2017IRROptions.tooltip.pointFormat = tooltipPointFormatIRR;
    returns2017IRROptions.yAxis.labels.formatter = yAxisLabelsIRR;
    returns2017IRROptions.yAxis.title.text = yAxisTitleIRR;
    returns2017IRROptions.series = [{
        color: '#3694E8',
        data: [{
            x: Date.UTC(2017, 0, 1),
            y: 0,
        }, {
            x: Date.UTC(2018, 11, 31),
            y: 27,
        }, {
            x: Date.UTC(2019, 2, 31),
            y: 26,
        }, {
            x: Date.UTC(2019, 5, 30),
            y: 26,
        }, {
            x: Date.UTC(2019, 8, 30),
            y: 23,
        }, {
            x: Date.UTC(2019, 11, 31),
            y: 26,
        }, {
            x: Date.UTC(2020, 2, 31),
            y: 24,
        }, {
            x: Date.UTC(2020, 5, 30),
            y: 27,
        }, {
            x: Date.UTC(2020, 8, 30),
            y: 26,
        }],
        index: 0,
        name: 'FundersClub*',
        zIndex: 10,
    }];

    let returns2017MultipleOptions = $.extend(true, {}, baseOptions);
    returns2017MultipleOptions.tooltip.pointFormat = tooltipPointFormatMultiple;
    returns2017MultipleOptions.yAxis.labels.formatter = yAxisLabelsMultiple;
    returns2017MultipleOptions.yAxis.title.text = yAxisTitleMultiple;
    returns2017MultipleOptions.series = [{
        color: '#3694E8',
        data: [{
            x: Date.UTC(2017, 0, 1),
            y: 1.0,
        }, {
            x: Date.UTC(2018, 11, 31),
            y: 1.46,
        }, {
            x: Date.UTC(2019, 2, 31),
            y: 1.54,
        }, {
            x: Date.UTC(2019, 5, 30),
            y: 1.62,
        }, {
            x: Date.UTC(2019, 8, 30),
            y: 1.63,
        }, {
            x: Date.UTC(2019, 11, 31),
            y: 1.82,
        }, {
            x: Date.UTC(2020, 2, 31),
            y: 1.81,
        }, {
            x: Date.UTC(2020, 5, 30),
            y: 2.08,
        }, {
            x: Date.UTC(2020, 8, 30),
            y: 2.15,
        }],
        index: 0,
        name: 'FundersClub*',
        zIndex: 10,
    }];

    let returnsChartsOptions = {
        '2012-irr-returns': {
            options: returns2012IRROptions,
            rendered: false,
        },
        '2013-irr-returns': {
            options: returns2013IRROptions,
            rendered: false,
        },
        '2014-irr-returns': {
            options: returns2014IRROptions,
            rendered: false,
        },
        '2015-irr-returns': {
            options: returns2015IRROptions,
            rendered: false,
        },
        '2016-irr-returns': {
            options: returns2016IRROptions,
            rendered: false,
        },
        '2017-irr-returns': {
            options: returns2017IRROptions,
            rendered: false,
        },
        '2012-multiple-returns': {
            options: returns2012MultipleOptions,
            rendered: false,
        },
        '2013-multiple-returns': {
            options: returns2013MultipleOptions,
            rendered: true,
        },
        '2014-multiple-returns': {
            options: returns2014MultipleOptions,
            rendered: false,
        },
        '2015-multiple-returns': {
            options: returns2015MultipleOptions,
            rendered: false,
        },
        '2016-multiple-returns': {
            options: returns2016MultipleOptions,
            rendered: false,
        },
        '2017-multiple-returns': {
            options: returns2017MultipleOptions,
            rendered: false,
        },
    };

    return $(function () {
        (<any> window).Highcharts.chart('2013-multiple-returns', returns2013MultipleOptions);
        return $('[data-returns-chart-id]').on('click', function () {
            let id = $(this).attr('data-returns-chart-id');
            if (!(<any> returnsChartsOptions)[id].rendered) {
                (<any> window).Highcharts.chart(id, (<any> returnsChartsOptions)[id].options);
                return (<any> returnsChartsOptions)[id].rendered = true;
            }
        });
    });
};
