class FcStartupInvestorEdit implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        onCanceled: '&?',
        onCreated: '&?',
        onSaved: '&?',
        startupInvestorData: '<?',
    };
    public controller = FcStartupInvestorEditController;
    public templateUrl = 'funds/startup-investor-edit.component.html';
}

class FcStartupInvestorEditController implements ng.IComponentController {
    public startupInvestorData: any;
    public onCreated: (startupInvestor: any) => void;
    public onSaved: (startupInvestor: any) => void;

    private defaultLogo: string;
    private errors: {[error: string]: string} = {};
    private fcSave: any;
    private form: any;
    private startupInvestor: any;
    private logoDisplay: string;

    constructor(
        private $uibModal: ng.ui.bootstrap.IModalService,
        private FCChoices: any,
        private FCGlobals: any,
        private FCStartupInvestorData: any,
        private StartupInvestor: any
    ) {}

    public $onInit(): void {
        this.defaultLogo = this.FCStartupInvestorData.urls.DEFAULT_LOGO;
        this.startupInvestorData = this.startupInvestorData || {};
        this.startupInvestor = new this.StartupInvestor(this.startupInvestorData);

        if (this.startupInvestor.logo) {
            this.logoDisplay = this.FCGlobals.MEDIA_URL + this.startupInvestor.logo;
        } else {
            this.logoDisplay = this.defaultLogo;
        }
    }

    public $postLink(): void {
        // This form is nested inside of another form.  Don't want editing
        // on this form to affect the dirty/pristine state of the parent form
        this.form.$$parentForm = {
            $removeControl: angular.noop,
            $setDirty: angular.noop,
            $setValidity: angular.noop,
        };
    }

    /* tslint:disable:no-unused-variable */
    private cropImage($files: Array<any>) {
        this.$uibModal.open({
            animation: false,
            backdrop: false,
            component: 'FcImageCropperModal',
            resolve: {
                files: () => $files,
                onCropped: () => this.onImageCropped.bind(this),
            },
            windowClass: 'FullTakeoverModal',
        });
    }

    private onImageCropped(croppedImage: any, croppedImageDisplay: any) {
        this.startupInvestor.logo = croppedImage;
        this.logoDisplay = croppedImageDisplay;
        this.form.$setDirty();
    }

    /* tslint:disable:no-unused-variable */
    private removeLogo(): void {
        this.logoDisplay = this.defaultLogo;
        this.startupInvestor.logo = '';
    }

    /* tslint:disable:no-unused-variable */
    private save(): void {
        this.errors = {};

        const startupInvestor: any = angular.copy(this.startupInvestor);
        startupInvestor
            .$save()
            .then((response: any) => {
                if (!this.startupInvestor.id) {
                    this.onCreated({startupInvestor: response});
                } else {
                    this.onSaved({startupInvestor: response});
                }
            })
            .catch((response: any) => angular.extend(this.errors, response.data))
            .finally(() => this.fcSave.saveAttemptFinished(this.errors));
    }
}

angular
    .module('fundersclub.funds')
    .component('fcStartupInvestorEdit', new FcStartupInvestorEdit());
