do ->

    fcWireAccountAdd = ($q, fcPaymentsManager, WireAccount, FCWireAccountsData, UserData, $http) ->
        thisScope =
            onCreateCallback: '&onCreateCallback'
            onErrorCallback: '&onErrorCallback'
            onReady: '&onReady'
            isEmbeddedInWireRequestForm: '@isEmbeddedInWireRequestForm'

        thisController = ($scope) ->
            vm = @
            vm.choices = FCWireAccountsData.choices
            vm.isEmbeddedInWireRequestForm = vm.isEmbeddedInWireRequestForm or false
            vm.onErrorCallback = vm.onErrorCallback or (->)
            vm.errors = {}
            vm.wireAccount = {}

            vm.add = (opts) ->
                vm.errors = {}
                opts = opts or {}
                opts.clearForm = opts.clearForm or false

                vm.wireAccount.user_id = UserData.user_id
                vm.wireAccount.user_id_sig = UserData.user_id_sig
                vm.wireAccount.fc_user_name = UserData.fc_user_name
                vm.wireAccount.fc_user_id = UserData.user_id

                fcPaymentsManager.createWireAccount(vm.wireAccount)
                    .then (response) ->
                        if opts.customPostHook
                            opts.customPostHook(response.data.id)
                        else
                            newWireAccount = new WireAccount({wireaccount_id: response.data.id})
                            newWireAccount.$save()
                                .then (response) ->
                                    vm.onCreateCallback({'newWireAccount': newWireAccount})
                                    if opts.clearForm
                                        vm.wireAccount = {}
                                .catch (response) ->
                                    angular.extend(vm.errors, response.data)
                                    vm.onErrorCallback({'errors': vm.errors})
                                .finally ->
                                    vm.fcSave.saveAttemptFinished(vm.errors)
                    .catch (response) ->
                        angular.extend(vm.errors, response.data)
                        vm.onErrorCallback({'errors': vm.errors})
                    .finally ->
                        vm.fcSave.saveAttemptFinished(vm.errors)

            vm.cancel = ->
                vm.errors = {}
                vm.wireAccount = {}

            vm.show_bank_autofill = ->
                return vm.wireAccount['routing_number_or_aba']?.length == 9

            vm.attempt_bank_autofill = ->
                vm.show_bank_autofill_loader = true
                routing_number = vm.wireAccount['routing_number_or_aba']
                api_url = "https://www.routingnumbers.info/api/data.json?rn=#{routing_number}"
                # Note: Unfortunately can't use $http.jsonp here for a dumb reason. Angular
                # autogenerates a callback name similar to jQuery, but Angular's includes
                # periods (they're usually something like: angular.callbacks._0). The
                # routingnumbers.info service we're connecting to doesn't allow periods
                # in its callback names. Boo. jQuery's callbacks don't have periods in them.
                $.ajax
                    url: api_url
                    jsonp: 'callback'
                    dataType: 'jsonp'
                    success: (response) ->
                        # This API doens't use HTTP status codes...
                        if response['code'] != 200 then return

                        vm.wireAccount.bank_name = response['customer_name']
                        vm.wireAccount.bank_street_address1 = response['address']
                        vm.wireAccount.bank_city = response['city']
                        vm.wireAccount.bank_state = response['state']
                        vm.wireAccount.bank_zip_code = response['zip']
                        vm.wireAccount.bank_country = 'US'
                        vm.show_bank_autofill_loader = false

                return

            if vm.isEmbeddedInWireRequestForm
                $scope.$parent.wireAccountDirective = vm

            if vm.onReady
                vm.onReady({'instance': vm})

            return

        thisDirective =
            scope: thisScope
            restrict: 'A'
            templateUrl: 'payments/wire-account-add.directive.html'
            bindToController: true
            controllerAs: 'waac'
            controller: thisController
        return thisDirective

    angular
        .module 'fundersclub.payments'
        .directive 'fcWireAccountAdd', fcWireAccountAdd
