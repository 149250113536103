do ->

    fcSignupPersonal = ->
        thisScope =
            'signupLead': '=lead'
            'register': '&registerCallback'

        thisController = (SignupData) ->
            vm = @
            vm.form = vm.register({'directive': vm})
            vm.showErrorMessages = false
            vm.SignupData = SignupData

            vm.directiveFields = [
                'country',
                'email',
                'employer',
                'employment_status',
                'first_name',
                'industry',
                'job_title',
                'last_name',
                'phone_number',
                'zip_or_postal_code',
            ]
            vm.employment_option = 'employed'
            vm.other_employment_option = ''

            vm.validateZipOrPostalCode = do ->
                return {
                    test: (value) ->
                        if vm.signupLead.country == 'US'
                            return /^[0-9]{5}(?:-[0-9]{4})?$/.test(value)
                        else
                            return true
                }

            vm.showErrorMessage = (fieldName) ->
                if fieldName not in vm.directiveFields then return false
                return vm.showErrorMessages && vm.form[fieldName]?.$invalid

            vm.validate = ->
                vm.signupLead['employment_status'] = (
                    if vm.tab_is_other() then vm.other_employment_option else vm.employment_option
                )

                # Needed to make sure zip/post code validation runs if someone changes country
                vm.form.zip_or_postal_code.$validate()
                return vm.form.$valid

            vm.highlightContinueButton = ->
                return vm.validate()

            # Convenience functions to know what tab we're on.
            vm.tab_is_employed = -> vm.employment_option == 'employed'
            vm.tab_is_self_employed = -> vm.employment_option == 'self-employed'
            vm.tab_is_other = -> vm.employment_option == 'other'

            return

        thisDirective =
            bindToController: true
            controller: thisController
            controllerAs: 'isuc'
            replace: true
            restrict: 'E'
            scope: thisScope
            templateUrl: 'signup/steps/personal.directive.html'

        thisDirective


    angular
        .module 'fundersclub.signup'
        .directive 'fcSignupPersonal', fcSignupPersonal
