do ->
    fcNewsletterSubscriptionModalController = ($http, $timeout, captured_from) ->
        vm = @
        vm.saving = false

        vm.subscribe = ->
            vm.saving = true
            vm.hasError = false

            $http({
                method: 'POST'
                url: '/accounts/public-newsletter-subscription/'
                data: $.param({
                    email: vm.email
                    captured_from: captured_from
                })
                headers:
                    'Content-Type': 'application/x-www-form-urlencoded'
            })
            .then ->
                vm.saving = false
                vm.subscribed = true
            .catch (response) ->
                vm.saving = false
                vm.hasError = true

        return

    # Easy to drop in directive to add newsletter subscription to a page
    fcNewsletterSubscription = ($cookies, $document, $uibModal) ->
        thisScope =
            fcNewsletterSubscriptionCapturedFrom: '='

        thisController = ->
            $document = $document[0]
            fc_shown_newsletter_subscription = $cookies.get('fc_shown_newsletter_subscription')

            if not fc_shown_newsletter_subscription
                vm = @
                vm.modalOpen = false

                $document.onmousemove = (event) ->
                    if event.clientX < 150 and event.clientY < 150 and not vm.modalOpen
                        vm.modalOpen = true
                        $cookies.put 'fc_shown_newsletter_subscription', true, {
                            expires: moment().add(2, 'weeks').toDate()
                        }
                        modalInstance = $uibModal.open({
                            templateUrl: 'shared/newsletter-promo.modal.html'
                            controller: 'fcNewsletterSubscriptionModalController'
                            controllerAs: 'npmc'
                            resolve:
                                captured_from: ->
                                    vm.fcNewsletterSubscriptionCapturedFrom
                            windowClass: 'newsletter-subscribe'
                        })
            return

        thisDirective =
            restrict: 'A'
            templateUrl: 'shared/newsletter-promo.directive.html'
            scope: thisScope
            controller: thisController
            controllerAs: 'ns'
            bindToController: true
        return thisDirective

    angular
        .module 'fundersclub'
        .directive 'fcNewsletterSubscription', fcNewsletterSubscription
        .controller 'fcNewsletterSubscriptionModalController', fcNewsletterSubscriptionModalController
