class FcInvestmentsTable implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        completeCount: '<',
        completeSpotsTaken: '<',
        fundClosingDate: '<',
        fundInvestments: '<',
        fundSpotsTaken: '<?',
    };
    public controller = FcInvestmentsTableController;
    public templateUrl = 'funds/investments-table.component.html';
}

class FcInvestmentsTableController implements ng.IComponentController {
    private closingDate: Date;
    private completeCount: number;
    private completeSpotsTaken: number;
    private filterOptions: Array<any>;
    private fundClosingDate: any;
    private fundInvestments: Array<any>;
    private fundSpotsTaken: number;
    private investments: Array<IInvestment>;
    private selectedFilter: any;

    constructor (
        private $filter: any,
        private $window: any,
        private _: any,
        private Fund: any,
        private Investment: any,
        private InvestorProfile: any
    ) {}

    public $onInit(): void {
        this.closingDate = new Date(this.fundClosingDate);
        this.investments = this.fundInvestments.map((investment: any) => {
            let thisInvestment = new this.Investment(investment);
            thisInvestment.timestamp = Date.parse(thisInvestment.timestamp);
            thisInvestment.complete = thisInvestment.identity_verified &&
                thisInvestment.tax_docs_signed &&
                thisInvestment.legaldoc_signed &&
                thisInvestment.is_30_days_old &&
                thisInvestment.payment_is_settled;
            return thisInvestment;
        });

        let allCount = this.investments.length;
        let incompleteCount = this.fundSpotsTaken - this.completeSpotsTaken;
        this.filterOptions = [
            {
                name: `Show all investments (${allCount} total, ${this.fundSpotsTaken} spots taken)`,
                value: 'all',
            },
            {
                name: `Show only complete (${this.completeCount} total, ${this.completeSpotsTaken} spots taken)`,
                value: 'complete',
            },
            {
                name: `Show only incomplete (${allCount - this.completeCount} total, ${incompleteCount} spots taken)`,
                value: 'incomplete',
            },
        ];
        this.selectedFilter = this.filterOptions[0];
    }

    /* tslint:disable:no-unused-variable */
    private onClickInvestment(investment: IInvestment): void {
        this.$window.location = investment.urls.investment_detail;
    }

    /* tslint:disable:no-unused-variable */
    private getFilteredInvestments(): Array<IInvestment> {
        let filteredInvestments = Array<IInvestment>();

        for (let investment of this.investments) {
            if (this.selectedFilter.value === 'all') {
                filteredInvestments.push(investment);
            } else if (this.selectedFilter.value === 'complete' && investment.complete) {
                filteredInvestments.push(investment);
            } else if (this.selectedFilter.value === 'incomplete' && !investment.complete) {
                filteredInvestments.push(investment);
            }
        }
        return filteredInvestments;
    }
}

angular
    .module('fundersclub.funds')
    .component('fcInvestmentsTable', new FcInvestmentsTable());
