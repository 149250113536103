do ->

    IntroductionRequestsController = (FCIntroductionRequestsData, IntroductionRequest) ->
        vm = @
        vm.introductionRequest = new IntroductionRequest(FCIntroductionRequestsData.introductionRequest)

        # End IntroductionRequestsController
        return


    angular
        .module 'fundersclub.introductionRequests'
        .controller 'IntroductionRequestsController', IntroductionRequestsController
