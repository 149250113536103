do ->

    multiEmail = ->
        EMAIL_REGEXP = /^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9-]+(\.[a-z0-9-]+)*$/i

        isValidEmail = (email) ->
            return EMAIL_REGEXP.test(email.trim())

        validateAll = (ctrl, validatorName, value) ->
            str = value.replace(/,\s*$/, "")
            isValid = str.split(',').every(isValidEmail)
            ctrl.$setValidity(validatorName, isValid)
            return if isValid then str else undefined

        link = (scope, elem, attrs, modelCtrl, filter) ->
            multipleEmailsValidator = (value) ->
                validateAll(modelCtrl, 'multipleEmails', value)

            modelCtrl.$formatters.push multipleEmailsValidator
            modelCtrl.$parsers.push multipleEmailsValidator

        thisDirective =
            restrict: 'A'
            require: 'ngModel'
            link: link
        return thisDirective

    angular
        .module 'fundersclub'
        .directive 'multiEmail', multiEmail

