do ->

    # List of news items for a given company.
    NewsItemsListController = (CompanyNewsItem, fcNewsAndPressData) ->
        vm = @
        vm.news_items = (new CompanyNewsItem(item) for item in fcNewsAndPressData.news_items)
        vm.expanded_child_id = null

        # Remove specified child from list.
        vm.removeChild = (child_id) ->
            vm.expanded_child_id = null
            child_index = _.findIndex(vm.news_items, (child) -> child.id == child_id)
            vm.news_items.splice(child_index, 1)

        # End NewsItemsListController
        return


    angular
        .module 'fundersclub.companies'
        .controller 'NewsItemsListController', NewsItemsListController
