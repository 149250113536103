class FcCompanyAssetTrackingWireSanity implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        viewAssetsUrl: '<',
        wireRequestsData: '<',
    };
    public controller = FcCompanyAssetTrackingWireSanityController;
    public templateUrl = 'investment-assets/company-asset-tracking-wire-sanity.component.html';
}

class FcCompanyAssetTrackingWireSanityController implements ng.IComponentController {
    public viewAssetsUrl: any;
    public wireRequestsData: any;

    constructor(
        private $http: any
    ) {}

    /* tslint:disable:no-unused-variable */
    private done() {
        return this.wireRequestsData.every((data: any) => {
            return data.amounts_match;
        });
    }

    /* tslint:disable:no-unused-variable */
    private markAsOkay(wr: any) {
        wr.saving = true;
        this.$http.post(wr.urls.mark_amount_match).then(() => {
            wr.amounts_match = true;
        });
    }
}

angular
    .module('fundersclub.assets')
    .component('fcCompanyAssetTrackingWireSanity', new FcCompanyAssetTrackingWireSanity());
