do ->

    FundManageCompaniesController = ($http, $window, FCManageCompaniesData) ->
        vm = @
        vm.add_company_form_expanded = false
        vm.create_update = true
        vm.adding = false
        vm.addable_companies = FCManageCompaniesData.addable_companies

        vm.add = ->
            if vm.create_update
                confirm_message = """
                    #{vm.company_to_add.company_name} will be added to this fund and an update will be created notifying investors.
                """
            else
                confirm_message = "#{vm.company_to_add.company_name} will be added to this fund."

            if not confirm(confirm_message)
                return

            vm.adding = true
            $http
                .post(FCManageCompaniesData.addURL, {
                    'company_id': vm.company_to_add.id
                    'create_update': vm.create_update
                })
                .then ->
                    $window.location.reload()


        # End FundManageCompaniesController
        return

    angular
        .module 'fundersclub.funds'
        .controller 'FundManageCompaniesController', FundManageCompaniesController
