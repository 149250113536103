class FcInvestmentExtraLegalDoc implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        fundName: '<',
        investmentExtraLegalDocData: '<',
        legalName: '<',
    };
    public controller = FcInvestmentExtraLegalDocController;
    public templateUrl = 'funds/investment-extra-legal-doc.component.html';
}

class FcInvestmentExtraLegalDocController implements ng.IComponentController {
    public fundName: string;
    public legalName: string;
    public investmentExtraLegalDocData: any;

    private errors: any;
    private generatingLegalDoc: boolean = false;
    private isSafari: boolean;
    private legalDoc: any;
    private popupWindow: any;
    private investmentExtraLegalDoc: any;

    constructor(
        private $uibModal: any,
        private $window: any,
        private FCChoices: any,
        private SignableLegalDocument: any,
        private InvestmentExtraLegalDoc: any,
        private _: UnderscoreStatic
    ) {}

    public $onInit() {
        this.investmentExtraLegalDoc = new this.InvestmentExtraLegalDoc(this.investmentExtraLegalDocData);

        if (!this._.isEmpty(this.investmentExtraLegalDoc.legal_doc)) {
            this.legalDoc = new this.SignableLegalDocument(this.investmentExtraLegalDoc.legal_doc);
        } else {
            // subscription doc not generated
            this.legalDoc = new this.SignableLegalDocument({
                id: null,
                status: null,
            });
        }
        this.legalDoc.pollForUpdates();

        this.isSafari = (
            this.$window.navigator.userAgent.indexOf('Safari') !== -1 &&
            this.$window.navigator.userAgent.indexOf('Chrome') === -1
        );
    }

    public signLegalDoc() {
        if (this.legalDoc.signing_url) {
            if (this.isSafari) {
                this.popupWindow = this.$window.open(
                    this.legalDoc.signing_url,
                    'Popup',
                    'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=no,width=800,height=600'
                );

                this.$window.docPostSignCallback = (doc: any) => {
                    angular.extend(this.legalDoc, doc);
                    this.$window.docPostSignCallback = null;
                    this.popupWindow.close();
                };
            } else {
                this.$uibModal.open({
                    animation: false,
                    ariaDescribedBy: 'modal-body',
                    ariaLabelledBy: 'modal-title',
                    component: 'fcSignLegaldocModal',
                    resolve: {
                        docName: () => this.investmentExtraLegalDoc.batch_name,
                        getLegaldocStatus: () => this.getLegaldocStatus.bind(this),
                        getSigningUrl: () => this.getSigningUrl.bind(this),
                    },
                    size: 'lg',
                    windowClass: 'complete-investment--sign-legal-doc--modal',
                });
            }
        } else {
            this.generatingLegalDoc = true;
            this.errors = {};
            /*
            this.investmentExtraLegalDoc.generateLegalDoc()
                .then((response: any) => {
                    angular.extend(this.investmentExtraLegalDoc, response.data);
                    angular.extend(this.legalDoc, response.data.legal_doc);
                    this.signLegalDoc();
                })
                .catch((response: any) => {
                    angular.extend(this.errors, response.data);
                })
                .finally(() => {
                    this.generatingLegalDoc = false;
                });
            */
        }
    }

    private getLegaldocStatus(): string {
        return this.legalDoc.status;
    }

    private getSigningUrl(): string {
        return this.legalDoc.signing_url;
    }
}

angular
    .module('fundersclub.funds')
    .component('fcInvestmentExtraLegalDoc', new FcInvestmentExtraLegalDoc());
