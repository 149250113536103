do ->

    FundQAController = (Fund, FCFundData, FundQAItem) ->
        vm = @
        vm.expanded_child_id = null
        fund_url = FCFundData.fund.url
        vm.questions = (new FundQAItem(question) for question in FCFundData.questions)

        vm.new_question = ->
            new_question = new FundQAItem
                fund: fund_url
                anonymous: false
                question: 'New question'

            new_question.$create()
                .then ->
                    # Expand new question immediately.
                    new_question.expand_after_render = true
                    vm.questions.unshift new_question

        vm.removeChild = (child_id) ->
            vm.expanded_child_id = null
            child_index = _.findIndex(vm.questions, (child) -> child.id == child_id)
            vm.questions.splice(child_index, 1)

        return



    angular
        .module 'fundersclub.funds'
        .controller 'FundQAController', FundQAController
