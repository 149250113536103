do ->

    fcCompanySelectOrAdd = ->
        thisScope =
            'company': '=company'
            'companies': '=companies'
            'onSelect': '&onSelect'
            'onAdd': '&onAdd'

        thisController = ($uibModal) ->
            vm = @

            vm.showCompanyAddModal = ->
                $uibModal.open
                    animation: false
                    backdrop: false
                    component: 'fcCompanyAdd'
                    windowClass: 'FullTakeoverModal'
                .result
                .then (res) ->
                    vm.onAdd({company: res.company})

            return

        thisDirective =
            templateUrl: 'shared/company-select-or-add.directive.html'
            restrict: 'A'
            scope: thisScope
            bindToController: true
            controllerAs: 'fcsoa'
            controller: thisController

    angular
        .module 'fundersclub'
        .directive 'fcCompanySelectOrAdd', fcCompanySelectOrAdd
