class FcFounderOnboardInviteFounders implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        company: '<',
        founders: '<',
        nextState: '<',
        onInvited: '&',
    };
    public controller = FcFounderOnboardInviteFoundersController;
    public templateUrl = 'founder-onboarding/invite-founders.component.html';
}

class FcFounderOnboardInviteFoundersController implements ng.IComponentController {
    public company: any;
    public founders: Array<any>;
    public nextState: string;
    public onInvited: (data: {founder: any}) => void;

    constructor(
        private $state: any,
        private $uibModal: ng.ui.bootstrap.IModalService,
        private FCFounderOnboardData: any,
        private FCGlobals: any
    ) {}

    public $onInit() {
        this.founders = angular.copy(this.founders);
    }

    /* tslint:disable:no-unused-variable */
    private addFounder() {
        this.$uibModal.open({
            animation: false,
            backdrop: false,
            component: 'FcFounderOnboardInviteFounderModal',
            resolve: {
                company: () => this.company,
                onInvited: () => this.onFounderInvited.bind(this),
            },
            windowClass: 'FullTakeoverModal',
        });
    }

    private onFounderInvited(founder: any) {
        this.founders.push(founder);
        this.onInvited({founder: founder});
    }
}

angular
    .module('fundersclub.founderOnboarding')
    .component('fcFounderOnboardInviteFounders', new FcFounderOnboardInviteFounders());
