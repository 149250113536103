do ->

    fcFundQaItem = ->
        thisScope =
            'item': '=fcFundQaItem'
            'edit_form_enabled': '=editFormEnabled'
            'expandCallback': '&onExpand'
            'collapseCallback': '&onCollapse'
            'deleteCallback': '&onDelete'

        thisController = ->
            vm = @
            vm.errors = {}
            vm.edit_form_expanded = false
            vm.initial_item_data = angular.copy(vm.item)

            # Only want to expose the collaborators checkbox when it isn't checked.
            vm.show_send_to_asker = !vm.item.published_to_contributors
            vm.send_to_asker = true

            # Expand the editing form for this q&a item.
            vm.expandEditForm = (expand_form) ->
                currently_expanded = vm.edit_form_expanded
                becoming_expanded = if typeof expand_form is 'boolean' then expand_form else !currently_expanded

                # Don't allow user to collapse form unless they've dealt with their
                # changes (e.g. Save or Cancel).
                if not becoming_expanded and not vm.form.$pristine then return

                # If a expansion callback was provided, fire it off.
                vm.edit_form_expanded = becoming_expanded
                if becoming_expanded then vm.expandCallback() else vm.collapseCallback()

            if vm.initial_item_data.expand_after_render
                delete vm.initial_item_data.expand_after_render
                vm.expandEditForm()

            vm.save = ->
                vm.errors = {}

                # Set contributors bit if necessary.
                if vm.show_send_to_asker and vm.send_to_asker
                    vm.item.published_to_contributors = true

                vm.item.$save()
                    .then (response) ->
                        # Update display state.
                        vm.show_send_to_asker = !vm.item.published_to_contributors
                        angular.copy(response, vm.initial_item_data)
                    .catch (response) ->
                        angular.extend(vm.errors, response.data)
                    .finally () ->
                        vm.fcSave.saveAttemptFinished(vm.errors)

            # Cancel
            vm.cancel = ->
                # Reset form data.
                angular.copy(vm.initial_item_data, vm.item)
                vm.form.$setPristine()
                vm.errors = {}
                vm.expandEditForm(false)

            # Delete
            vm.delete = ->
                remove = confirm('Are you sure you want to delete this question?')
                if remove
                    if vm.item.id?
                        vm.item.$delete()
                            .then ->
                                vm.deleteCallback()
                    else vm.deleteCallback()

            return

        thisDirective =
            scope: thisScope
            restrict: 'A'
            templateUrl: 'funds/q-and-a-item-edit.directive.html'
            bindToController: true
            controllerAs: 'qac'
            controller: thisController
        return thisDirective



    angular
        .module 'fundersclub.funds'
        .directive 'fcFundQaItem', fcFundQaItem
