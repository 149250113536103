do ->

    # List of news items for a given company.
    CorporateActionListController = (FCCorporateActionsData, CorporateAction, $attrs) ->
        vm = @
        vm.corporate_actions = (new CorporateAction(action) for action in FCCorporateActionsData.actions)
        vm.expanded_child_id = parseInt($attrs.expandedChildId) || null

        for action in vm.corporate_actions
            if vm.expanded_child_id == action.id
                action.expand_after_render = true

        # Remove specified child from list.
        vm.removeChild = (child_id) ->
            vm.expanded_child_id = null
            child_index = _.findIndex(vm.corporate_actions, (child) -> child.id == child_id)
            vm.corporate_actions.splice(child_index, 1)

        # Add new
        vm.new_corporate_action = ->
            new_action = new CorporateAction
                company: FCCorporateActionsData.company_url

            new_action.$create()
                .then (response) ->
                    new_action.expand_after_render = true
                    vm.corporate_actions.unshift(new_action)
                .catch (response) ->
                    alert 'Failed creating new corporate action, please refresh the page and try again'

            return

        # End CorporateActionListController
        return


    angular
        .module 'fundersclub.companies'
        .controller 'CorporateActionListController', CorporateActionListController
