let fcNewsletterSubscribe = () => {
    let thisController = function($http: any, $window: any) {
        let vm = this;
        vm.email = '';
        vm.errors = undefined;
        vm.saving = false;
        vm.subscribed = false;

        vm.subscribe = function() {
            vm.saving = true;
            vm.hasError = false;

            $http({
                data: $.param({
                    captured_from: $window.location.pathname,
                    email: vm.email,
                }),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                method: 'POST',
                url: '/accounts/public-newsletter-subscription/',
            }).then(() => {
                vm.errors = undefined;
                vm.subscribed = true;
            }).catch((response: any) => {
                vm.errors = response.data.email[0];
            }).finally(() => {
                vm.saving = false;
            });
        };
    };

    let thisDirective = {
        bindToController: true,
        controller: thisController,
        controllerAs: 'ns',
        replace: true,
        restrict: 'A',
        templateUrl: 'public-pages/newsletter-signup.directive.html',
    };

    return thisDirective;
};

angular
    .module('fundersclub.publicPages')
    .directive('fcNewsletterSubscribe', fcNewsletterSubscribe);
