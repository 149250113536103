do ->

    fcAccomplishmentPromos = (
        $timeout,
        $uibModal,
        $window,
        FCAccomplishmentPromosData,
        AccomplishmentPromo,
        FCAccomplishmentPromosManager
    ) ->
        thisScope =
            'leaderboardLink': '@leaderboardLink'
            'showNewMemberPromo': '=showNewMemberPromo'

        thisController = ($scope) ->
            vm = @
            vm.isOpen = false
            vm.activeIndex = 0
            vm.isNewMemberPromoOpen = false
            vm.newMemberPromoVenturePoints = FCAccomplishmentPromosData.new_member_promo_venture_points
            vm.points = FCAccomplishmentPromosData.points
            vm.promos = FCAccomplishmentPromosData.promos.map (promo) ->
                new AccomplishmentPromo(promo)
            vm.activePromo = null
            accomplishment_promos_popover_template = 'header/accomplishment-promos.popover.html'

            $scope.$watch(
                (scope) -> vm.isOpen
                (newVal, oldVal) ->
                    if newVal and newVal != oldVal and not vm.showNewMemberPromo
                        vm.trackPromoViewed()
            )

            if vm.showNewMemberPromo
                $timeout(->
                    vm.isNewMemberPromoOpen = true
                    vm.popoverTemplate = 'header/new-member-promo.popover.html'
                    vm.modal = $uibModal.open({template: '<div></div>', backdrop: 'static'})
                , 1000)
            else
                vm.popoverTemplate = accomplishment_promos_popover_template

            FCAccomplishmentPromosManager.onPointsEarned (points) ->
                vm.points += points

            FCAccomplishmentPromosManager.onPromoCompleted (promoType) ->
                childIndex = _.findIndex(vm.promos, (child) -> child.promo_type == promoType)
                # Ensure we found a promo.
                if childIndex == -1 then return

                promo = vm.promos[childIndex]
                vm.points += promo.cta_venture_points_amount
                vm.dismissPromo(childIndex)

            vm.activePromoCTATarget = ->
                if vm.activePromo?.is_off_site
                    return 'blank'
                else
                    return ''

            vm.trackPromoViewed = ->
                if vm.activePromo
                    promoType = vm.activePromo.promo_type
                else
                    promoType = 'empty'

            vm.dismissNewMemberPromo = ->
                vm.isNewMemberPromoOpen = false
                vm.modal.close()
                vm.popoverTemplate = accomplishment_promos_popover_template
                vm.points += vm.newMemberPromoVenturePoints

                # Hack alert! For the initial points allocation, we show
                # a fancy Odometer animation. We don't want to change out
                # which notification menu we're showing until that animation
                # completes. That animation completes in 3s, so we wait about
                # that long before hiding things.
                $timeout(->
                    vm.showNewMemberPromo = false
                , 3050)

            vm.setActivePromo = ->
                if not vm.promos.length
                    vm.activePromo = null
                    return

                if vm.activeIndex > vm.promos.length - 1
                    vm.activeIndex = 0

                vm.activePromo = vm.promos[vm.activeIndex]
                if vm.activeIndex == (vm.promos.length - 1) or not vm.activePromo
                    vm.activeIndex = 0
                else
                    vm.activeIndex += 1

            vm.dismissPromo = (index) ->
                vm.promos.splice(index, 1)
                vm.activeIndex = index
                vm.setActivePromo()

            vm.dismiss = ->
                vm.activePromo
                    .$dismiss()
                    .then ->
                        childIndex = _.findIndex(vm.promos, (child) -> child.id == vm.activePromo.id)
                        vm.dismissPromo(childIndex)

            vm.setActivePromo()
            return

        thisDirective =
            templateUrl: 'header/accomplishment-promos.directive.html'
            restrict: 'A'
            scope: thisScope
            bindToController: true
            controllerAs: 'uvppc'
            controller: thisController
            replace: true
        return thisDirective

    angular
        .module 'fundersclub.header'
        .directive 'fcAccomplishmentPromos', fcAccomplishmentPromos
