do ->

    fcUserNotifications = (FCUserNotificationsData, UserNotification) ->
        thisController = ->
            vm = @
            vm.popoverTemplate = 'header/notifications-list.popover.html'
            vm.notifications = FCUserNotificationsData.notifications.map (notification) ->
                new UserNotification(notification)

            vm.dismiss = ($event, notification) ->
                $event.preventDefault()
                notification
                    .$dismiss()
                    .then ->
                        childIndex = _.findIndex(vm.notifications, (child) -> child.id == notification.id)
                        vm.notifications.splice(childIndex, 1)

            vm.dismissAll = ->
                notificationIds = vm.notifications
                    .filter (notification) -> notification.is_dismissible
                    .map (notification) -> notification.id
                UserNotification
                    .$dismissAll(notificationIds)
                    .then ->
                        vm.notifications = vm.notifications.filter (notification) -> !notification.is_dismissible

            return

        thisDirective =
            templateUrl: 'header/notifications.directive.html'
            restrict: 'A'
            bindToController: true
            controllerAs: 'unc'
            controller: thisController
            replace: true
        return thisDirective

    angular
        .module 'fundersclub.header'
        .directive 'fcUserNotifications', fcUserNotifications
