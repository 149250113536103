class FcChangePaymentMethod implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        modalInstance: '<',
        resolve: '<',
    };
    public controller = FcChangePaymentMethodController;
    public templateUrl = 'invest/change-payment-method.component.html';
}

class FcChangePaymentMethodController implements ng.IComponentController {
    public modalInstance: ng.ui.bootstrap.IModalServiceInstance;
    public resolve: {
        bankAccount: any,
        canPayOffline: boolean,
        canPayOnline: boolean,
        onSelected: any,
        paymentByCheckDisabled: boolean,
        paymentMethod: string,
    };
    private paymentMethod: string;
    private selectedBankAccountId: number = null;
    private selectedBankAccount: any = null;

    constructor(
        private BankAccount: any,
        private FCChoices: any
    ) {}

    public $onInit(): void {
        if (this.resolve.bankAccount) {
            this.selectedBankAccount = this.resolve.bankAccount;
            this.selectedBankAccountId = this.resolve.bankAccount.id;
        }

        this.paymentMethod = this.resolve.paymentMethod;
    }

    /* tslint:disable:no-unused-variable */
    private isSelectDisabled(): boolean {
        return (
            !this.paymentMethod || (
                // If paying online, must have account selected.
                this.isPayingOnline() && (
                    this.selectedBankAccountId === -1 ||
                    !this.selectedBankAccountId
                )
            )
        );
    }

    private isPayingOnline(): boolean {
        return this.paymentMethod === this.FCChoices.ReservationPaymentMethods.Online;
    }

    /* tslint:disable:no-unused-variable */
    private select(): void {
        let bankAccount: any = null;

        if (this.isPayingOnline()) {
            bankAccount = this.selectedBankAccount;
        }

        this.resolve.onSelected({
            bankAccount: bankAccount,
            paymentMethod: this.paymentMethod,
        });
        this.modalInstance.close();
    }
}

angular
    .module('fundersclub.invest')
    .component('fcChangePaymentMethod', new FcChangePaymentMethod());
