do ->

    fcWireRequestEdit = (WireRequest, WireAccount, fcPaymentsManager) ->
        thisScope =
            'wireRequest': '=fcWireRequestEdit'
            'edit_form_enabled': '=editFormEnabled'
            'expandCallback': '&onExpand'
            'collapseCallback': '&onCollapse'
            'deleteCallback': '&onDelete'
            'startExpanded': '=startExpanded'
            'isFcvc': '=isFcvc'

        thisController = ($scope, filterFilter, FCChoices, FCWireAccountsData, moment) ->
            vm = @
            vm.original_wiring_instructions_expanded = false
            vm.FCChoices = FCChoices
            vm.FCWireAccountsData = FCWireAccountsData
            vm.errors = {}
            vm.wireAccount = {}
            vm.onErrorCallback = vm.onErrorCallback or (->)
            vm.moment = moment

            # Expand the editing form for this wire request.
            vm.expandEditForm = (expand_form) ->
                currently_expanded = vm.edit_form_expanded
                becoming_expanded = if typeof expand_form is 'boolean' then expand_form else !currently_expanded

                # Don't allow user to collapse form unless they've dealt with their
                # changes (e.g. Save or Cancel).
                if not becoming_expanded and not vm.form.$pristine then return

                # If a expansion callback was provided, fire it off.
                vm.edit_form_expanded = becoming_expanded
                if becoming_expanded then vm.expandCallback() else vm.collapseCallback()
                if vm.wireRequest.wire_submitted and vm.form
                    vm.form.$setDirty()

            vm.cancel = ->
                # Reset form data.
                if $scope.wireAccountDirective
                    $scope.wireAccountDirective.cancel()
                vm.form.$setPristine()
                vm.errors = {}
                vm.wireAccount = {}
                vm.expandEditForm(false)

            vm.add = (opts) =>
                vm.errors = {}
                opts = opts or {}
                opts.clearForm = opts.clearForm or false

                vm.wireAccount.user_id = vm.FCWireAccountsData.user_id
                vm.wireAccount.user_id_sig = vm.FCWireAccountsData.user_id_sig
                fcPaymentsManager.createWireAccount(vm.wireAccount)
                    .then (response) ->
                        if opts.customPostHook
                            opts.customPostHook(response.data.id)
                        else
                            newWireAccount = new WireAccount({wireaccount_id: response.data.id})
                            newWireAccount.$save()
                                .then (response) ->
                                    vm.wireAccountCreated(newWireAccount)
                                    if opts.clearForm
                                        vm.wireAccount = {}
                                .catch (response) ->
                                    angular.extend(vm.errors, response.data)
                                    vm.wireAccountCreationError(vm.errors)
                                .finally ->
                                    vm.fcSave.saveAttemptFinished(vm.errors)
                    .catch (response) ->
                        angular.extend(vm.errors, response.data)
                        vm.onErrorCallback({'errors': vm.errors})
                    .finally ->
                        vm.fcSave.saveAttemptFinished(vm.errors)

            vm.createWireAccount = ->
                vm.add({clearForm: false})

            vm.deleteWireRequest = ->
                remove = confirm('Are you sure you want to delete this wire request?')
                if not remove
                    return

                vm.wireRequest.$delete()
                    .then ->
                        vm.deleteCallback()

            vm.markApproved = ->
                vm.wireRequest
                    .approve()
                    .then (response) ->
                        vm.wireRequest = new WireRequest(response.data)
                        vm.cancel()

            vm.markClosed = ->
                vm.wireRequest.status = 'request-closed'
                vm.wireRequest
                    .$save(['status'])
                    .then (response) ->
                        vm.wireRequest = new WireRequest(response)
                        vm.cancel()

            vm.wireAccountCreated = (newWireAccount) ->
                vm.errors = {}
                vm.wireRequest.wire_account = newWireAccount.url
                vm.wireRequest
                    .$save([
                        'automatically_submit_wire',
                        'country_of_incorporation',
                        'wire_account',
                        'wiring_amount',
                    ])
                    .then (response) ->
                        vm.wireRequest = new WireRequest(response)
                        vm.cancel()
                    .catch (response) ->
                        angular.extend(vm.errors, response.data)
                        newWireAccount.$delete()
                        vm.wireRequest.wire_account = null
                    .finally ->
                        vm.fcSave.saveAttemptFinished(vm.errors)

            vm.wireAccountCreationError = (errors) ->
                angular.extend(vm.errors, errors)
                vm.fcSave.saveAttemptFinished(vm.errors)

            if vm.startExpanded
                vm.expandEditForm(true)

            return

        thisDirective =
            templateUrl: 'funds/wire-request-edit.directive.html'
            restrict: 'A'
            require: '^form'
            scope: thisScope
            bindToController: true
            controllerAs: 'ewrc'
            controller: thisController
            replace: true
        return thisDirective

    angular
        .module 'fundersclub.funds'
        .directive 'fcWireRequestEdit', fcWireRequestEdit
