do ->

    ChangeInvestmentController = ->
        vm = @

        # End ChangeInvestmentController
        return


    angular
        .module 'fundersclub.users'
        .controller 'ChangeInvestmentController', ChangeInvestmentController
