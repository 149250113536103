do ->
    fcBankAccounts = ($http, FCAPITokens) ->
        thisScope =
            enableAdd: '<?'
            enableDelete: '<?'
            enableSelect: '<?'
            isLoaded: '=?'
            selectedAccount: '=?'
            selectedAccountModel: '=?'

        thisController = ($attrs, $scope, $intercom, filterFilter, BankAccount) ->
            vm = @
            vm.accounts = null
            vm.addFormExpanded = false
            vm.isLoaded = false

            if !vm.selectedAccount
                vm.selectedAccount = null

            BankAccount.list().then (accounts) ->
                vm.accounts = accounts

                if $attrs.initialSelectedAccountId
                    results = filterFilter(vm.accounts, {'id': $attrs.initialSelectedAccountId})
                    if results.length == 1
                        # For now we are storing just the BankAccount id inside selectedAccount
                        # because this directive is integrated with non-angular code. Once the
                        # non-angular code is removed, we should switch back to storing the actual
                        # BankAccount model
                        vm.selectedAccount = results[0].id

                # If we have a selectedAccount when we load the list, it is likely the initial
                # selection. This .sort() hack moves it to the top.
                if vm.selectedAccount
                    vm.accounts.sort((a, b) => a.id != vm.selectedAccount)

                # If we don't have bank accounts, force the 'add' to be selected
                if vm.accounts.length == 0
                    vm.selectedAccount = -1

                # If we've got only one account, select it
                if vm.accounts.length == 1
                    vm.selectedAccount = vm.accounts[0].id

                # We're loaded
                vm.isLoaded = true

            # Since this directive is used by non-Angular code, the ng-value of the radio
            # buttons is set to the bank account IDs instead of the actual bank account
            # model objects. This allows the directive to be used inside a regular <form>.
            # However, Angular code using this directive wants the actual model and not the ID
            # and this little hack achieves that.
            $scope.$watch(
                -> vm.selectedAccount,
                ->
                    if vm.accounts
                        results = filterFilter(vm.accounts, {'id': vm.selectedAccount})
                        if results.length == 1
                            vm.selectedAccountModel = results[0]
            )

            vm.showIntercom = ($event) ->
                $event.stopPropagation()
                $intercom.showNewMessage()

            vm.bankAccountAdded = (bankAccount) ->
                vm.accounts.push(bankAccount)
                vm.selectedAccount = bankAccount.id
                vm.addFormExpanded = false

            vm.delete = (account) ->
                if not vm.enableDelete
                    return

                if account.has_pending_transactions
                    alert "An incomplete investment was made using this bank account.
                        You will only be able to delete this bank account once that
                        investment has been completed."
                    return

                if not confirm('Are you sure you wish to delete this account?')
                    return

                account.$delete()
                    .then (response) ->
                        index = _.findIndex(vm.accounts, (account2) -> account.id == account2.id)
                        vm.accounts.splice(index, 1)
                        if vm.selectedAccount == account.id
                            vm.selectedAccount = null
                    .catch (response) ->
                        alert 'Error deleting account. Please try again'

                return

            vm.reset_verification = (account) ->
                account.resetting = true
                account.errors = {}
                account.reset_verification()
                    .catch (response) ->
                        angular.extend(account.errors, response.data)
                    .finally ->
                        account.resetting = false

            vm.verify_account = (account) ->
                account.verifying = true
                account.errors = {}

                if account.using_plaid_microdeposits
                    account.create_verification_link_token()
                        .then (response) ->
                            Plaid.create
                                apiVersion: 'v2'
                                env: FCAPITokens.plaid_client_environment
                                clientName: 'FundersClub'
                                token: response.data.link_token
                                product: ['auth']
                                onSuccess: (public_token) =>
                                    account.verify_plaid_microdeposits(public_token)
                                        .catch (response) ->
                                            angular.extend(account.errors, response.data)
                                        .finally ->
                                            account.verifying = false
                            .open()
                else
                    account.verify(account.amount_1, account.amount_2)
                        .then ->
                            account.verify_form_expanded = false
                        .catch (response) ->
                            angular.extend(account.errors, response.data)
                        .finally ->
                            account.verifying = false

            return

        thisDirective =
            scope: thisScope
            restrict: 'E'
            templateUrl: (elem, attr) ->
                if attr.useAlternateLayout
                    'payments/icp-bank-accounts.directive.html'
                else
                    'payments/bank-accounts.directive.html'
            bindToController: true
            controllerAs: 'bac'
            controller: thisController
        return thisDirective

    angular
        .module 'fundersclub.payments'
        .directive 'fcBankAccounts', fcBankAccounts
