let fcCompanyAcquisitionForm = (Company: any, Partnership: any) => {
    let thisScope = {
        'closeWithCancel': '&onCancel',
        'closeWithSave': '&onSave',
        'companyId': '@',
        'partnershipId': '@',
    };
    let thisController = function() {
        let vm = this;
        vm.errors = {};
        vm.dataLoaded = false;
        vm.partnership = new Partnership({id: vm.partnershipId});
        vm.selectedAssets = {};

        // Do API call to load required form data.
        vm.partnership.$get().then(() => {
            vm.partnership.acquisition_details_for_company(vm.companyId)
                .then((response: any) => {
                    vm.bindCompanyData(response.data);
                })
                .catch((response: any) => {
                    // It's hard to know if there's something better to do here...
                    alert('This form failed to load. Please refresh the page and try again');
                    console.log(response);
                });
        });

        vm.saveForm = () => {
            let params = {
                acquired_by: vm.company.acquired_by ? vm.company.acquired_by.id : '',
                company_id: vm.company.id,
                effective_date: vm.company.effective_date,
                investor_notification_date: vm.company.investor_notification_date,
            };
            vm.partnership.execute_acquisition(params)
                .then(() => {
                    vm.closeWithSave();
                })
                .catch((response: any) => {
                    angular.extend(vm.errors, response.data);
                })
                .finally(() => {
                    vm.fcSave.saveAttemptFinished(vm.errors);
                });
        };

        vm.bindCompanyData = (data: any) => {
            vm.company = data;
            vm.dataLoaded = true;
        };

        vm.cancelForm = () => vm.closeWithCancel();

        vm.companyAdded = (company: any) => {
            vm.company.all_companies.push(company);
            vm.company.acquired_by = company;
        };

    };
    let thisDirective = {
        bindToController: true,
        controller: thisController,
        controllerAs: 'fcaf',
        restrict: 'A',
        scope: thisScope,
        templateUrl: 'investment-assets/company-acquisition.directive.html',
    };
    return thisDirective;
};

angular
    .module('fundersclub.assets')
    .directive('fcCompanyAcquisitionForm', fcCompanyAcquisitionForm);
