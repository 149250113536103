// Hardcoded data is used in the five year retrospective.

// The countries in which our investors live.
FC.retrospective5CountryData = () => {
    return [
        ['ax', 1],
        ['ar', 6],
        ['au', 15],
        ['at', 1],
        ['bs', 6],
        ['bd', 1],
        ['be', 5],
        ['bz', 1],
        ['br', 1],
        ['io', 1],
        ['vg', 3],
        ['bn', 1],
        ['bg', 1],
        ['ca', 36],
        ['cn', 8],
        ['cy', 2],
        ['cz', 2],
        ['dk', 1],
        ['do', 1],
        ['eg', 1],
        ['ee', 1],
        ['fr', 20],
        ['ge', 1],
        ['de', 24],
        ['jk', 13],
        ['hu', 5],
        ['in', 13],
        ['id', 1],
        ['ie', 1],
        ['il', 7],
        ['it', 6],
        ['jp', 7],
        ['je', 1],
        ['kw', 1],
        ['lu', 4],
        ['mo', 1],
        ['my', 2],
        ['mt', 1],
        ['mu', 2],
        ['mx', 3],
        ['nl', 23],
        ['nz', 7],
        ['no', 4],
        ['pk', 1],
        ['pa', 1],
        ['pe', 2],
        ['ph', 4],
        ['pl', 1],
        ['pt', 2],
        ['qa', 1],
        ['kr', 1],
        ['ro', 1],
        ['ru', 5],
        ['sa', 1],
        ['sg', 23],
        ['si', 1],
        ['za', 1],
        ['es', 15],
        ['lk', 1],
        ['se', 3],
        ['ch', 19],
        ['tw', 1],
        ['th', 1],
        ['tr', 3],
        ['ua', 2],
        ['ae', 12],
        ['gb', 36],
        ['us', 1633],
        ['um', 1],
        ['vi', 1],
    ];
};

// The industries in which we've invested.
FC.retrospective5IndustryData = function (dataType: any) {
    // dataType options are:
    //   'industry','product', 'customer', 'model'
    let dataTypes = {
        'industry': [{
            color: '#4C9CE5',
            label: 'Legal',
            value: 1,
        }, {
            color: '#EB3D31',
            label: 'Other',
            value: 2,
        }, {
            color: '#28D177',
            label: 'Data Centers',
            value: 2,
        }, {
            color: '#C70D91',
            label: 'Material Science',
            value: 2,
        }, {
            color: '#FF9800',
            label: 'Music',
            value: 2,
        }, {
            color: '#4C9CE5',
            label: 'Video',
            value: 3,
        }, {
            color: '#EB3D31',
            label: 'Gaming',
            value: 3,
        }, {
            color: '#28D177',
            label: 'Information Security',
            value: 3,
        }, {
            color: '#C70D91',
            label: 'Social Media',
            value: 4,
        }, {
            color: '#FF9800',
            label: 'Ad Tech',
            value: 6,
        }, {
            color: '#4C9CE5',
            label: 'Real Estate',
            value: 7,
        }, {
            color: '#EB3D31',
            label: 'Manufacturing',
            value: 8,
        }, {
            color: '#28D177',
            label: 'Other',
            value: 11,
        }, {
            color: '#C70D91',
            label: 'Consumer Electronics',
            value: 11,
        }, {
            color: '#FF9800',
            label: 'Personal Care & Services',
            value: 11,
        }, {
            color: '#4C9CE5',
            label: 'Education',
            value: 13,
        }, {
            color: '#EB3D31',
            label: 'Transportation & Logistics',
            value: 13,
        }, {
            color: '#28D177',
            label: 'Communications',
            value: 14,
        }, {
            color: '#C70D91',
            label: 'Food & Agriculture',
            value: 16,
        }, {
            color: '#FF9800',
            label: 'Retail',
            value: 16,
        }, {
            color: '#4C9CE5',
            label: 'Human Resources',
            value: 17,
        }, {
            color: '#EB3D31',
            label: 'Health & Medicine',
            value: 23,
        }, {
            color: '#28D177',
            label: 'Financial Technology',
            value: 32,
        }, {
            color: '#C70D91',
            label: 'Information Technology',
            value: 32,
        }],
        'customer': [{
            color: '#2FCC90',
            label: 'Enterprise',
            value: 114,
        }, {
            color: '#4C9CE5',
            label: 'Consumer',
            value: 137,
        }],
        'product': [{
            color: '#EB3D31',
            label: 'Hardware',
            value: 34,
        }, {
            color: '#FF9800',
            label: 'Software',
            value: 217,
        }],
        'model': [{
            color: '#2FCC90',
            label: 'Advertising',
            value: 5,
        }, {
            color: '#C70D91',
            label: 'Licensing',
            value: 7,
        }, {
            color: '#4C9CE5',
            label: 'Peer to Peer',
            value: 7,
        }, {
            color: '#EB3D31',
            label: 'Data',
            value: 12,
        }, {
            color: '#FF9800',
            label: 'Transaction Processing',
            value: 14,
        }, {
            color: '#2FCC90',
            label: 'Subscription',
            value: 76,
        }, {
            color: '#4C9CE5',
            label: 'Commerce',
            value: 124,
        }],
    };

    return (<any> dataTypes)[dataType];
};
