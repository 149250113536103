interface ICapturedEmailClass extends IFcModelResource<any> {
}

let CapturedEmail = function($http: ng.IHttpService, fcModel: ng.resource.IResourceService) {
    let CapturedEmailModel: ICapturedEmailClass = fcModel(
        '/api/capturedemails/:id/',
        { id: '@id' }
    );

    return CapturedEmailModel;
};

let ColdInboundLead = (fcModel: ng.resource.IResourceService) => {
    return fcModel('/api/coldinboundleads/:id/', {
        id: '@id',
    });
};

angular
    .module('fundersclub.models')
    .factory('CapturedEmail', CapturedEmail)
    .factory('ColdInboundLead', ColdInboundLead);
