class FcIraInvestorProfileAdd implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        isSubForm: '<?',
        onCanceled: '&',
        onCreated: '&',
    };
    public controller = FcIraInvestorProfileAddController;
    public templateUrl = 'users/investor-profile-add-ira.component.html';
}

class FcIraInvestorProfileAddController implements ng.IComponentController {
    public onCanceled: () => void;
    public onCreated: (data: {investorProfile: IInvestorProfile}) => void;

    /* tslint:disable:no-unused-variable */
    private differentMailingAddress: boolean = false;
    private errors: {[error: string]: Array<any>} = {};
    private fcSave: any;
    private investorProfile: IIRAInvestorProfile;

    constructor(
        private FCChoices: any,
        private InvestorProfile: any
    ) {}

    public $onInit() {
        this.investorProfile = new this.InvestorProfile();
    }

    /* tslint:disable:no-unused-variable */
    private create(): void {
        this.errors = {};

        this.investorProfile
            .$save()
            .then((response: any) => {
                this.onCreated({investorProfile: new this.InvestorProfile(response)});
            })
            .catch((response: any) => {
                angular.extend(this.errors, response.data);
            })
            .finally(() => this.fcSave.saveAttemptFinished(this.errors));
    }

    /* tslint:disable:no-unused-variable */
    private cancel(): void {
        this.onCanceled();
    }

}

angular
    .module('fundersclub.users')
    .component('fcIraInvestorProfileAdd', new FcIraInvestorProfileAdd());
