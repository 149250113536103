class FcFundLifecycleManualAudienceCtrlEdit implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        allFunds: '<',
        fundData: '<',
    };

    public controller = FcFundLifecycleManualAudienceCtrlEditController;
    public templateUrl = 'funds/lifecycle-manual-audience-ctrl-edit.component.html';
}

class FcFundLifecycleManualAudienceCtrlEditController implements ng.IComponentController {
    public allFunds: any;
    public fundData: any;
    public form: any;

    private errors: any;
    private fcSave: any;
    private fund: any;

    constructor (
        private _: UnderscoreStatic,
        private $filter: ng.IFilterService,
        private Fund: any,
        private FCChoices: any
    ) {}

    public $onInit(): void {
        this.errors = {};
        this.fund = new this.Fund(this.fundData);
        this.fund.set_blacklist_all_startup_investors = null;
    }

    /* tslint:disable:no-unused-variable */
    private addFundToBucket(bucket: any, fund: any) {
        bucket.funds.push(fund);
    }

    /* tslint:disable:no-unused-variable */
    private removeFundFromBucket(bucket: any, fundToRemove: any) {
        const index = this._.findIndex(bucket.funds, (fund: any) => { return fund.id === fundToRemove.id; });
        console.log(bucket, fundToRemove, index);
        bucket.funds.splice(index, 1);
        this.form.$setDirty();
    }

    /* tslint:disable:no-unused-variable */
    private filteredFunds(search: string): Array<any> {
        let filteredResults: Array<any> = this
            .$filter('filter')(this.allFunds, {fund_display_name: search})
            .concat(this.$filter('filter')(this.allFunds, {slug: search}));

        // Need to remove dupes
        return this._.uniq(filteredResults, false, (fund: any) => fund.id);
    }

    /* tslint:disable:no-unused-variable */
    private toggleBlacklistStartupInvestors($event: any) {
        this.fund.set_blacklist_all_startup_investors = $event.target.checked;
        this.form.$setDirty();
    }

    /* tslint:disable:no-unused-variable */
    private save(): void {
        this.errors = {};
        this.fund.set_manual_lifecycle_buckets = this.fund.current_manual_lifecycle_buckets;

        let fieldsToSave = [
            'set_blacklist_all_startup_investors',
            'set_manual_lifecycle_buckets',
            'end_date',
            'hide_from_browse_page',
            'closing_date',
            'founder_closing_date',
            'ach_off_date',
            'accelerated_closing',
            'founder_emails',
        ];
        fieldsToSave = this._.filter(fieldsToSave, (field) => {
            return this.fund[field] !== null;
        });

        this.fund
            .$save(fieldsToSave)
            .catch((response: any) => angular.extend(this.errors, response.data))
            .finally(() => this.fcSave.saveAttemptFinished(this.errors));
    }

    /* tslint:disable:no-unused-variable */
    private showAddMoreFunds(bucketIdx: number): boolean {
        let lastPrevInvestorsBucketIdx = -1;
        for (let i = 0; i < this.fund.current_manual_lifecycle_buckets.length; ++i) {
            if (this.fund.current_manual_lifecycle_buckets[i].audience ===
                this.FCChoices.FundLifecycleAudience.PreviousInvestors) {
                lastPrevInvestorsBucketIdx = i;
            }
        }

        return (bucketIdx === lastPrevInvestorsBucketIdx &&
                this.fund.current_manual_lifecycle_buckets[bucketIdx].triggered_at);
    }

    /* tslint:disable:no-unused-variable */
    private addMoreFundsClicked(bucketIdx: number): void {
        this.fund.current_manual_lifecycle_buckets.splice(bucketIdx + 1, 0, {
            audience: this.FCChoices.FundLifecycleAudience.PreviousInvestors,
            funds: [],
            send_email: false,
            start_on: null,
        });
    }
}

angular
    .module('fundersclub.funds')
    .component('fcFundLifecycleManualAudienceCtrlEdit', new FcFundLifecycleManualAudienceCtrlEdit());
