class FcEntityIdentityVerification implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        identityDocuments: '<',
        investorProfile: '<',
        onIdentityDocumentsUpdated: '&',
        onInvestorProfileUpdated: '&',
        onSaved: '&',
    };
    public controller = FcEntityIdentityVerificationController;
    public templateUrl = 'users/entity-identity-verification.component.html';
}

class FcEntityIdentityVerificationController implements ng.IComponentController {
    public identityDocuments: Array<any>;
    public investorProfile: IInvestorProfile;
    public onInvestorProfileUpdated: (data: {investorProfile: any}) => void;
    public onIdentityDocumentsUpdated: (data: {identityDocuments: Array<any>}) => void;
    public onSaved: () => void;

    private errors = {};
    private fcSave: any;
    private form: any;
    private newDocumentErrors = {};

    constructor(
        private $state: any,
        private InvestorProfile: any,
        private moment: moment.MomentStatic
    ) {}

    public $onInit() {
        // Need to copy objects to get correct 1 way data binding
        this.identityDocuments = angular.copy(this.identityDocuments);
        this.investorProfile = angular.copy(this.investorProfile);
    }

    /* tslint:disable:no-unused-variable */
    private onDocumentAdded() {
        this.form.$setDirty();
    }

    /* tslint:disable:no-unused-variable */
    private onDocumentRemoved() {
        this.form.$setDirty();
    }

    /* tslint:disable:no-unused-variable */
    private save() {
        this.errors = {};
        this.newDocumentErrors = {};

        const documentsToSave = this.identityDocuments.filter(
            (doc: any) => !doc.removed
        );

        this.investorProfile
            .verifyIdentity(
                documentsToSave,
                {'phone_number': this.investorProfile.phone_number || ''}
            )
            .then((response: any) => {
                angular.copy(documentsToSave, this.identityDocuments);
                this.onInvestorProfileUpdated({
                    investorProfile: new this.InvestorProfile(response.data),
                });
                this.onIdentityDocumentsUpdated({identityDocuments: this.identityDocuments});
                this.onSaved();
            })
            .catch((response: any) => {
                if (response.data.includes('RequestDataTooBig')) {
                    this.errors = {
                        'identityDocuments': 'The total size of the uploaded documents is too large.',
                    };
                }
                angular.extend(this.errors, response.data);
                angular.extend(this.newDocumentErrors, response.data);
            }).finally(() => {
                this.fcSave.saveAttemptFinished(this.errors);
            });
    }
}

angular
    .module('fundersclub.users')
    .component('fcEntityIdentityVerification', new FcEntityIdentityVerification());
