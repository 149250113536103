// Setup investment summary table.
FC.investmentSummary = function($table: any) {
    // Toggle investment details
    $table.on('click', '[data-dropdown]', function(e: any) {
        if ($(e.target).parent().data('fund-closed') !== undefined) {
            return;
        }

        let row = $(e.currentTarget);
        let investmentId = row.attr('data-target');
        let $assets = $(`[data-investment-id="${investmentId}"]`);
        let expandThisRow = $assets.hasClass('collapse');

        // Potentially expand this row.
        if (expandThisRow) {
            row.addClass('expanded');
            $assets.removeClass('collapse');
        } else {
            row.removeClass('expanded');
            $assets.addClass('collapse');
        }
    });

    // Check if there's a fund slug specified in URL – if so, scroll to it
    // and highlight it.
    let hash = window.location.hash.substring(1);
    if (!hash) {
        return;
    }

    let row = $(`[data-fund-slug=\"${hash}\"]`);
    if (!row.length) {
        return;
    }

    let { top } = row.offset();
    $('html, body').animate({ scrollTop: (top - 120) }, 1500);

    row.addClass('expanded');
    let investmentId = row.attr('data-target');
    $(`[data-investment-id="${investmentId}"]`).removeClass('collapse');
    setTimeout(() => row.addClass('linked-to') , 1500);
};

// Company industry breakdown chart.
//
// Some general notes about this implementation:
// - ChartJS does not provide an easy way to display text inside doughnut charts
//   so this has to be done manually (as seen in the render method)
// - When ChartJS shows tooltips it redraws the chart, discarding our inner text.
//   To overcome this we create a new chart type, DoughnutSupportingInnerText,
//   that calls the animation completion callback (which in turns draws our custom
//   text) every time the chart is redrawn
//
declare var Chart: any;

class MyInvestmentsCompaniesBreakdownChart {
    protected colors = [
        '41,128,185', '231,76,60', '26,188,156', '242,121,53', '52,73,94', '46,204,113',
        '155,89,182', '52,152,219', '241,196,15', '127,140,141', '174,168,211', '210,77,87',
        '02,204,153', '242,121,53', '107,185,240', '30,130,76', '134,226,213', '207,0,15',
        '129,207,224', '46,204,113', '103,65,114', '230,126,34', '39,174,96', '41,128,185',
        '243,156,18', '211,84,0', '30,130,76', '134,226,213', '41,128,185', '242,121,53',
        '26,188,156', '231,76,60', '52,73,94', '46,204,113', '155,89,182', '52,152,219',
        '241,196,15', '127,140,141', '174,168,211', '210,77,87',
    ];

    protected options: any;
    protected total: number;
    protected tooltipsByLabel: any;

    constructor(options: any) {
        // Verify we have the options we need
        this.options = options;
        if (!this.options.$el || (this.options.$el.length !== 1)) {
            throw 'MyInvestmentsCompaniesBreakdownChart needs $el of a single element';
        }
        if (!this.options.chartData) {
            throw 'MyInvestmentsCompaniesBreakdownChart needs chart data';
        }

        // Make sure ChartJS have our hacked chart type that forces re-rendering of the inner
        // text after tooltips are displayed
        this.patch_chartjs();

        // Prepare data: count total number of companies, set colors, create a map of label -> tooltip
        this.total = 0;
        this.tooltipsByLabel = {};
        for (let i = 0; i < this.options.chartData.length; i++) {
            let item = this.options.chartData[i];
            this.tooltipsByLabel[item.label] = item.tooltip;
            this.total += item.value;

            let rgbColor = this.colors[i % this.colors.length];
            item.color = `rgba(${rgbColor}, .8)`;
            // Darken each section slightly on hover.
            item.highlight = `rgba(${rgbColor}, 1)`;
        }
    }

    public render(type: any) {
        let $canvas = this.options.$el.find('[data-breakdown-canvas]');
        let ctx = $canvas.get(0).getContext('2d');

        let chartOptions: any = {
            animation: false,
            legendTemplate: (data: any) => this.generate_legend(data),
            onAnimationComplete: () => {
                let canvasWidth = $canvas.width();
                let canvasHeight = $canvas.height();
                let canvasMiddleY = Math.round((canvasHeight / 2));
                let constant = 125; // Arbitrary constant?
                let fontsize = (canvasHeight / constant);

                // Total, potentially formatted (dynamic text)
                let dynText = this.options.formatDynText ? this.options.formatDynText(this.total) : this.total;

                ctx.font         = fontsize.toFixed(2) + 'em bolder proxima-nova, sans-serif';
                ctx.textBaseline = 'middle';
                ctx.fillStyle    = '#000000';
                ctx.textAlign    = 'left';

                // Hack to get an approximation of the height of current font:
                // M is supposedly a letter that has width =~ height
                let dynTextHeight = ctx.measureText('M').width;
                canvasMiddleY -= dynTextHeight / 2;

                ctx.fillText(
                    dynText,
                    Math.round((canvasWidth - ctx.measureText(dynText).width) / 2),
                    canvasMiddleY
                );

                // Static lines
                let staticLine1 = this.options.staticLine1;
                let staticLine2 = this.options.staticLine2;

                ctx.font = Math.max(fontsize - 2.3, 0.9).toString() + 'em proxima-nova, sans-serif';
                ctx.fillStyle = '#3f5765';
                let staticTextHeight = ctx.measureText('M').width;

                ctx.fillText(
                    staticLine1,
                    Math.round((canvasWidth - ctx.measureText(staticLine1).width) / 2),
                    Math.round(canvasMiddleY + dynTextHeight)
                );
                ctx.fillText(
                    staticLine2,
                    Math.round((canvasWidth - ctx.measureText(staticLine2).width) / 2),
                    Math.round(canvasMiddleY + dynTextHeight + staticTextHeight + 10)
                );
            },
            percentageInnerCutout: 60,
            responsive: true,
            segmentShowStroke: false,
        };

        if (this.options.tooltipTemplate) {
            chartOptions.tooltipTemplate = this.options.tooltipTemplate;
        }

        let chart = new Chart(ctx).DoughnutSupportingInnerText(this.options.chartData, chartOptions);

        let legend = chart.generateLegend();
        this.options.$el.find('[data-breakdown-legend]').html('').append(legend);

        // When clicking on a segment in the chart, expand the corresponding legend entry.
        $canvas.click(function(e: any) {
            let clickedSegment = chart.getSegmentsAtEvent(e.originalEvent);
            if (clickedSegment.length !== 1) {
                return;
            }

            ($(`[data-labelled-as='${clickedSegment[0].label}']`) as any).collapse('toggle');
        });
    }

    protected generate_legend(data: any) {
        let $el = $('<ul class="list-unstyled"></ul>');

        for (let i = 0; i < data.segments.length; i++) {
            let segment = data.segments[i];
            let segmentVal = this.options.formatLegendText ?
                this.options.formatLegendText(segment.value) : segment.value;

            let linkId = `legend_entry_${i}`;
            let $colorSquare = $(
                `<div style='background-color: ${segment.fillColor};''></div>`
            );
            let $label = $(
                `<a href='javascript:;' data-toggle='collapse' data-target='\#${linkId}'>${segment.label}</a>`
            );
            let $labelCount = $(
                `<span class='legend-count'>${segmentVal}<i class='fa fa-caret-down'></i></span>`
            );
            $labelCount.appendTo($label);

            let $details = $(
                `<p class='collapse legend-details' id='${linkId}' data-labelled-as='${segment.label}'></p>`
            ).html(this.tooltipsByLabel[segment.label]);
            let $labelSection = $('<div></div>').append($label, $details);

            let $li = $('<li></li>').append($colorSquare, $labelSection);
            $li.appendTo($el);
        }

        return $el;
    }

    protected patch_chartjs() {
        if (Chart.types.DoughnutSupportingInnerText) {
            return;
        }

        Chart.types.Doughnut.extend({
            name: 'DoughnutSupportingInnerText',
            draw() {
                Chart.types.Doughnut.prototype.draw.apply(this, arguments);
                this.options.onAnimationComplete();
            },
        });
    }
}

// Fund investments allocation chart.
class MyInvestmentsInvestmentAllocationChart extends MyInvestmentsCompaniesBreakdownChart {
    public render(type: any) {
        let $canvas = this.options.$el.find('[data-investment-allocation-canvas]');
        let ctx = $canvas.get(0).getContext('2d');

        let chartData = _.map(this.options.chartData, function(item: any) {
            item.value = item[type];
            return item;
        });
        chartData = _.sortBy(chartData, (item: any) => item.value * -1);

        let chartOptions: any = {
            animation: false,
            legendTemplate: (data: any) => this.generate_legend(data),
            onAnimationComplete() {
                let canvasHeight = $canvas.height();
                let constant = 125; // Arbitrary constant?
                let fontsize = (canvasHeight / constant).toFixed(2);

                ctx.font         = fontsize.toString() + 'em bolder proxima-nova, sans-serif';
                ctx.textBaseline = 'middle';
                ctx.fillStyle    = '#000000';
                ctx.textAlign    = 'left';
            },
            responsive: true,
            segmentShowStroke: false,
        };
        if (this.options.tooltipTemplate) {
            chartOptions.tooltipTemplate = this.options.tooltipTemplate;
        }

        let chart = new Chart(ctx).Pie(chartData, chartOptions);

        let legend = chart.generateLegend();
        this.options.$el.find('[data-investment-allocation-legend]').html('').append(legend);
    }

    protected generate_legend(data: any) {
        let $el = $('<ul class="list-unstyled"></ul>');

        for (let i = 0; i < data.segments.length; i++) {
            let segment = data.segments[i];
            let segmentVal = this.options.formatLegendText ?
                this.options.formatLegendText(segment.value) : segment.value;
            let $colorSquare = $(`<div style='background-color: ${segment.fillColor};''></div>`);

            let $tooltip = $(this.tooltipsByLabel[segment.label]);
            $tooltip.append($(`<span class='legend-count'>${segmentVal}</span>`));

            let $li = $('<li></li>').append($colorSquare, $tooltip);
            $li.appendTo($el);
        }

        return $el;
    }

    /* tslint:disable:no-empty */
    protected patch_chartjs() {
    }
}

_.extend(FC, {
   MyInvestmentsCompaniesBreakdownChart,
   MyInvestmentsInvestmentAllocationChart,
});
