class FcTestHellosignModal implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        modalInstance: '<',
        resolve: '<',
    };
    public controller = FcTestHellosignModalController;
    public templateUrl = 'icp/test-hellosign-modal.component.html';
}

declare var HelloSign: any;

class FcTestHellosignModalController implements ng.IComponentController {
    public modalInstance: ng.ui.bootstrap.IModalServiceInstance;
    public resolve: {
        docName: string;
        legalDoc: any;
        onResetForm: () => void;
    };

    constructor(private $stateParams: any, private $state: any, private $intercom: any) {
    }

    public $onInit() {
        if (!this.$stateParams.testUrl) {
            this.modalInstance.close();
        } else {
            this.$intercom.hideLauncher();
            HelloSign.init('c91dd19359a20a88d5c63fd2b7fe6a03');
            console.log('xxxxx', this.$stateParams.testUrl);
            HelloSign.open({
                allowCancel: false,
                container: document.getElementById('myHSContainer'),
                debug: false,
                messageListener: (eventData: any) => {
                    if (eventData.event === 'signature_request_signed') {
                        this.modalInstance.close('signed');
                    }
                    console.log(eventData);
                },
                skipDomainVerification: true,
                url: this.$stateParams.testUrl,
            });
        }
    }

    // Normally we would copy the legalDoc in $onInit but we need to listen for updates
    // from the parent component which is long polling the server for changes

    /* tslint:disable:no-unused-variable */
    private onSigningModalClosed() {
        this.modalInstance.close();
    }
}

angular
    .module('fundersclub.icp')
    .component('fcTestHellosignModal', new FcTestHellosignModal());
