do ->

    # Form responsible for creating a markdown.
    fcMarkdownForm = (FCManageInvestmentsData, Company, Partnership) ->
        thisScope =
            'closeWithCancel': '&onCancel'
            'closeWithSave': '&onSave'
            'companyId': '@'
            'partnershipId': '@'

        thisController = ->
            vm = @
            vm.errors = {}
            vm.dataLoaded = false
            vm.choices = FCManageInvestmentsData.choices
            vm.partnership = new Partnership({id: vm.partnershipId})
            vm.selectedAssets = {}

            # Do API call to load required form data.
            vm.partnership.$get().then ->
                vm.partnership.markdown_details_for_company(vm.companyId)
                    .then (response) ->
                        vm.bindCompanyData(response.data)
                    .catch (response) ->
                        # It's hard to know if there's something better to do here...
                        alert 'This form failed to load. Please refresh the page and try again'
                        console.log response

            vm.getSelectedAssetIds = ->
                return (parseInt(asset_id) for asset_id, selected of vm.selectedAssets when selected)

            vm.debtAssetSelected = ->
                selected = vm.getSelectedAssetIds()
                for asset in vm.company.target_assets
                    if asset.terms.is_debt_investment and selected.indexOf(asset.id) != -1
                        return true
                return false

            vm.equityAssetSelected = ->
                selected = vm.getSelectedAssetIds()
                for asset in vm.company.target_assets
                    if asset.terms.is_equity_investment and selected.indexOf(asset.id) != -1
                        return true
                return false

            vm.saveForm = ->
                vm.errors = {}
                params =
                    target_assets: vm.getSelectedAssetIds()
                    effective_date: vm.company.effective_date
                    price_per_share: vm.company.price_per_share
                    discount_percent: vm.company.discount_percent
                    is_internal: vm.company.is_internal
                    description: vm.company.description
                    currency: vm.company.currency
                vm.partnership.execute_markdown(params)
                    .then (e) ->
                        vm.closeWithSave()
                    .catch (response) ->
                        angular.extend(vm.errors, response.data)
                    .finally ->
                        vm.fcSave.saveAttemptFinished(vm.errors)

            vm.bindCompanyData = (data) ->
                vm.company = data
                vm.dataLoaded = true

            vm.cancelForm = ->
                vm.closeWithCancel()

            return

        thisDirective =
            templateUrl: 'investment-assets/markdown-add.directive.html'
            restrict: 'A'
            scope: thisScope
            bindToController: true
            controllerAs: 'fmf'
            controller: thisController
        return thisDirective


    angular
        .module 'fundersclub.assets'
        .directive 'fcMarkdownForm', fcMarkdownForm
