class FcChangeInvestorProfile implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        modalInstance: '<',
        resolve: '<',
    };
    public controller = FcChangeInvestorProfileController;
    public templateUrl = 'invest/change-investor-profile.component.html';
}

class FcChangeInvestorProfileController implements ng.IComponentController {
    public modalInstance: ng.ui.bootstrap.IModalServiceInstance;
    public resolve: {
        defaultSelection: IInvestorProfile,
        onSelected: any,
        onUpdated: any,
    };
    private investorProfiles: Array<any> = [];

    /* tslint:disable:no-unused-variable */
    private NEW_INVESTOR_PROFILE_ID: number = 0;
    private selectedProfileId: number = null;

    constructor(private InvestorProfile: any) {}

    public $onInit(): void {
        this.selectedProfileId = this.resolve.defaultSelection.id;

        // Populate list of all investor profiles, moving default
        // investor profile to the top.
        this.InvestorProfile.query(
            {filter_for_proxy_only: true},
            (investorProfiles: Array<IInvestorProfile>) => {
                this.investorProfiles = investorProfiles
                    .filter((profile: IInvestorProfile) => {
                        return profile.id !== this.selectedProfileId;
                    })
                    .map((profile: IInvestorProfile) => {
                        return new this.InvestorProfile(profile);
                    });

                if (this.resolve.defaultSelection.id) {
                    this.investorProfiles.unshift(this.resolve.defaultSelection);
                }
            }
        );
    }

    /* tslint:disable:no-unused-variable */
    private onAdded(investorProfile: any): void {
        this.investorProfiles.push(investorProfile);
    }

    /* tslint:disable:no-unused-variable */
    private onUpdated(investorProfile: any): void {
        // Update data in investorProfiles with any new data.
        const index = this.investorProfiles
            .map((profile: any) => {
                return profile.id;
            })
            .indexOf(investorProfile.id);
        this.investorProfiles[index] = investorProfile;

        // If this was the profile passed in as the default,
        // we need to tell our caller that the data was updated.
        if (investorProfile.id === this.resolve.defaultSelection.id) {
            this.resolve.onUpdated(investorProfile);
        }
    }

    /* tslint:disable:no-unused-variable */
    private onSelected(investorProfileId: number): void {
        this.selectedProfileId = investorProfileId;
    }

    /* tslint:disable:no-unused-variable */
    private select(): void {
        // This is where any validation logic on the currently selected
        // options would occur...
        // On no errors: this.fcSave.saveAttemptFinished(this.errors);
        const index = this.investorProfiles
            .map((profile: any) => {
                return profile.id;
            })
            .indexOf(this.selectedProfileId);
        this.resolve.onSelected(this.investorProfiles[index]);
        this.modalInstance.close();
    }

    /* tslint:disable:no-unused-variable */
    private selectDisabled(): boolean {
        return this.selectedProfileId === this.NEW_INVESTOR_PROFILE_ID;
    }
}

angular
    .module('fundersclub.invest')
    .component('fcChangeInvestorProfile', new FcChangeInvestorProfile());
