do ->

    fcPaymentsManager = ($http, FCAPITokens, User, $q) ->
        fcPaymentsManager =
            createBankAccount: (bank_account) ->
                $http
                    method: 'post'
                    url: "#{FCAPITokens.fc_payments_api_root}/bank-accounts/"
                    headers:
                        'Authorization': "Token #{FCAPITokens.fc_payments_client_api_token}"
                    data: bank_account

            createWireAccount: (wire_account) ->
                $http
                    method: 'post'
                    url: "#{FCAPITokens.fc_payments_api_root}/wire-accounts/"
                    headers:
                        'Authorization': "Token #{FCAPITokens.fc_payments_client_api_token}"
                    data: wire_account

            createBrokerageAccount: (brokerage_account) ->
                $http
                    method: 'post'
                    url: "#{FCAPITokens.fc_payments_api_root}/wire-accounts/brokerage-account/"
                    headers:
                        'Authorization': "Token #{FCAPITokens.fc_payments_client_api_token}"
                    data: brokerage_account

            getDTCparticipants: () ->
                $http
                    method: 'get'
                    url: "#{FCAPITokens.fc_payments_api_root}/wire-accounts/dtc-participants/"
                    headers:
                        'Authorization': "Token #{FCAPITokens.fc_payments_client_api_token}"

            getAccountsForPublicToken: (public_token, account_owner) ->
                # First need to retrieve a name to use for account owner.
                $q (resolve, reject) ->
                    User.get({id: 'me'}
                        , (user) ->
                            account_owner = "#{user.first_name} #{user.last_name}"
                            $http(
                                method: 'post'
                                url: "#{FCAPITokens.fc_payments_api_root}/bank-accounts/get-accounts-for-public-token/"
                                headers:
                                    'Authorization': "Token #{FCAPITokens.fc_payments_client_api_token}"
                                data:
                                    public_token: public_token
                                    account_owner: account_owner
                            ).then(
                                (response) ->
                                    resolve response.data
                                , (error) ->
                                    # Payments service failed to retrieve Plaid data.
                                    errors =
                                        non_field_errors: ["""
                                            We were unable to connect to your bank.
                                            We did not save your account credentials.
                                            Please wait a moment, refresh the page,
                                            and try again. If the problem continues,
                                            please <a href="/contact/">
                                            contact us</a>.
                                        """]
                                    reject errors
                                )
                        , (error) ->
                            # Server couldn't find user account for user. Perhaps their
                            # session has expired?
                            errors =
                                non_field_errors: ["""
                                    Unable to add account at this time.
                                    No account information was saved.
                                    Please refresh the page and try again.
                                """]
                            reject errors
                    )

            searchPlaidInstitutions: (query, product) ->
                $q (resolve, reject) ->
                    xhr = new XMLHttpRequest()
                    xhr.open('POST', "#{FCAPITokens.plaid_client_endpoint}/institutions/search")
                    xhr.setRequestHeader('Content-Type', 'application/json')
                    xhr.onload = () =>
                        resolve(JSON.parse(xhr.response))
                    xhr.onerror = () =>
                        reject(xhr.response)
                    xhr.send(JSON.stringify({
                        query: query
                        products: [product]
                        public_key: FCAPITokens.plaid_client_api_token
                    }))

        fcPaymentsManager


    angular
        .module 'fundersclub.payments'
        .factory 'fcPaymentsManager', fcPaymentsManager
