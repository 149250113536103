do ->

    ManageInvestmentsController = ->
        vm = @

        # TODO: Add in-page searching to make navigating investments easier.

        # End ManageInvestmentsController
        return


    angular
        .module 'fundersclub.assets'
        .controller 'ManageInvestmentsController', ManageInvestmentsController
