interface IUserProfileClass extends IFcModelResource<any> {
    isCurrentUserProxyOnly?(): ng.IHttpPromise<any>;
};

let UserProfile = function($http: ng.IHttpService, fcModel: ng.resource.IResourceService) {
    let UserProfileModel: IUserProfileClass = fcModel('/api/userprofiles/:id/', { id: '@id' });

    UserProfileModel.prototype.resendEmailConfirmation = function() {
        return $http.post(this.urls.resend_email_confirmation, {});
    };

    UserProfileModel.prototype.disable_authy = function(token: any) {
        return $http
            .post(this.urls.disable_authy, { token: token })
            .then(() => {
                this.authy_status = 'disabled';
            });
    };

    UserProfileModel.prototype.enable_authy = function(countryCode: any, phoneNum: any) {
        return $http
            .post(this.urls.enable_authy, {
                authy_country_code: countryCode,
                authy_phone_num: phoneNum,
            })
            .then(() => {
                this.authy_status = 'pending';
            });
    };

    UserProfileModel.prototype.validate_authy = function(token: any) {
        return $http
            .post(this.urls.validate_authy, { token: token })
            .then(() => {
                this.authy_status = 'enabled';
            });
    };

    UserProfileModel.prototype.request_authy_sms = function() {
        return $http.post(this.urls.request_authy_sms, {});
    };

    UserProfileModel.isCurrentUserProxyOnly = function() {
        return $http.get('/api/userprofiles/is_current_user_proxy_only/');
    };

    return UserProfileModel;
};

let UserEmailAddress = function(fcModel: ng.resource.IResourceService) {
    const baseUrl: string = '/api/emailaddresses/:id';

    let UserEmailAddressModel = fcModel(baseUrl, { id: '@id' }, {
        send_confirmation: {
            method: 'POST',
            url: `${baseUrl}/send_confirmation/`,
        },
        set_primary: {
            method: 'POST',
            url: `${baseUrl}/set_primary/`,
        },
    });

    return UserEmailAddressModel;
};

let User = function(
    $http: ng.IHttpService,
    fcModel: ng.resource.IResourceService,
    transformRequestAsFormPost: any
) {
    let UserModel = fcModel('/api/users/:id/', { id: '@id' });

    UserModel.prototype.change_password = function(
        oldpassword: string,
        password1: string,
        password2: string
    ) {
        return $http({
            data: {
                oldpassword: oldpassword,
                password1: password1,
                password2: password2,
            },
            headers: {
                'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            },
            method: 'POST',
            transformRequest: transformRequestAsFormPost,
            url: this.urls.change_password,
        });
    };

    UserModel.prototype.companyFounderCompleteEmailSignup = function(companyId: number) {
        return $http.post(this.urls.company_founder_complete_email_signup, {
            company_id: companyId,
            email: this.email,
            password: this.password,
        });
    };

    return UserModel;
};

interface IUserInviteClass extends IFcModelResource<any> {
    bulk_invite?(entries: Array<any>): ng.IHttpPromise<any>;
}

let UserInvite = function($http: ng.IHttpService, fcModel: ng.resource.IResourceService) {
    const baseUrl: string = '/api/invites/:id';

    let UserInviteModel: IUserInviteClass = fcModel(baseUrl, { id: '@id' }, {
        remind: {
            method: 'PATCH',
            url: `${baseUrl}/remind/`,
        },
    });

    UserInviteModel.bulk_invite = function(entries: Array<any>) {
        return $http({
            data: {
                entries: entries,
                source: 'invite-member-form',
            },
            method: 'POST',
            url: '/api/invites/bulk_invite/',
        });
    };

    return UserInviteModel;
};

interface ITaxpayerInfoVerification extends ng.resource.IResource<ITaxpayerInfoVerification> {
    user: number;
    how_preparing_taxes: string;
    tax_software: string;
    other_things_we_can_do: string;
    verified_ids: Array<number>;
}

let TaxpayerInfoVerification = function($http: ng.IHttpService, fcModel: ng.resource.IResourceService) {
    let TaxpayerInfoVerificationModel: IFcModelResource<ITaxpayerInfoVerification> = fcModel(
        '/api/users/:id/verify_taxpayer_info/',
        {id: '@user'},
        {}
    );
    return TaxpayerInfoVerificationModel;
};

interface IInvestorProfile extends ng.resource.IResource<IInvestorProfile> {
    accreditation_questionnaire_completed: boolean;
    accredited: boolean;
    city: string;
    country: string;
    full_name: string;
    id: number;
    identity_documents: Array<any>;
    identity_verified: any;
    investments: Array<any>;
    investor_type: string;
    is_entity: boolean;
    is_individual: boolean;
    is_ira: boolean;
    legal_country: string;
    legal_state: string;
    phone_number: string;
    questionnaire: any;
    state: string;
    street_address_1: string;
    street_address_2: string;
    tax_docs: any;
    url: string;
    urls: any;
    verified_qc: boolean;
    zip_code: string;

    new(fields: {[field: string]: any}): IInvestorProfile;
    abandonTaxDocs(): ng.IHttpPromise<any>;
    generateTaxDocs(): ng.IHttpPromise<any>;
    isUsBasedOnCountry(): boolean;
    verifyIdentity(documents: Array<any>, userProfileData: any): ng.IHttpPromise<any>;
    setupTaxDocUpdate(reason: string): ng.IHttpPromise<any>;
}

interface IIRAInvestorProfile extends IInvestorProfile {
    ira_account_number: string;
    account_type: number;
    first_name: string;
    full_name: string;
    last_name: string;
    middle_name: string;
}

let InvestorProfile = function(
    $http: ng.IHttpService,
    fcModel: ng.resource.IResourceService,
    transformFormRequestToFormData: any
) {
    let InvestorProfileModel: IFcModelResource<IInvestorProfile> = fcModel(
        '/api/investorprofiles/:id/',
        { id: '@id' }
    );

    InvestorProfileModel.prototype.isUsBasedOnCountry = function() {
        return this.country === 'US';
    };

    InvestorProfileModel.prototype.verifyIdentity = function(
        documents: Array<any>,
        userProfileData: any = {}
    ) {
        angular.extend(userProfileData, {
            // Documents that are already saved and we want to keep
            existing_documents: documents
                .filter((doc: any) => Boolean(doc.id))
                .map((doc: any) => doc.id),
            // Documents that have not been saved yet and we want to add
            new_documents: documents
                .filter((doc: any) => !Boolean(doc.id))
                .map((doc: any) => doc.document),
        });

        return $http({
            data: userProfileData,
            headers: {
                'Content-type': undefined,
            },
            method: 'POST',
            transformRequest: function(data) {
                return transformFormRequestToFormData(data, {
                    array_fields: ['new_documents', 'existing_documents'],
                });
            },
            url: this.urls.validate_identity,
        });
    };

    InvestorProfileModel.prototype.abandonTaxDocs = function() {
        return $http.post(this.urls.abandon_tax_docs, {});
    };

    InvestorProfileModel.prototype.generateTaxDocs = function() {
        return $http.post(this.urls.generate_tax_docs, {});
    };

    InvestorProfileModel.prototype.setupTaxDocUpdate = function(updateReason: string) {
        return $http.post(this.urls.setup_tax_doc_update, {update_reason: updateReason});
    };

    return InvestorProfileModel;
};

let InvestorProfileTaxDocUpdate = function(fcModel: ng.resource.IResourceService, $http: ng.IHttpService) {
    let InvestorProfileTaxDocUpdateModel: IFcModelResource<any> = fcModel('/api/investorprofiletaxdocupdates/:id/', {
        id: '@id',
    });

    InvestorProfileTaxDocUpdateModel.prototype.isUsBasedOnCountry = function() {
        return this.country === 'US';
    };

    InvestorProfileTaxDocUpdateModel.prototype.abandonTaxDoc = function() {
        return $http.post(this.urls.abandon_tax_doc, {});
    };

    return InvestorProfileTaxDocUpdateModel;
};

interface IInvestorProfileTaxDoc extends IFcModelResource<any> {
    w9ShouldHaveSSN(W9HoldingType: any): boolean;
    w9ShouldHaveEIN(W9HoldingType: any): boolean;
    w8beneShouldSeeTreatyClaimQuestion(W8BENEHoldingType: any): boolean;
    taxCountryDisplay(): string;
    isUsBasedOnTaxCountry(): boolean;
    abandonDoc(): ng.IHttpPromise<any>;
}

let InvestorProfileTaxDoc = function($http: ng.IHttpService, fcModel: ng.resource.IResourceService) {
    const baseUrl: string = '/api/investorprofiletaxdocs/:id/';

    let InvestorProfileTaxDocModel: IFcModelResource<any> = fcModel(baseUrl, { id: '@id' }, {
    });

    InvestorProfileTaxDocModel.prototype.w9ShouldHaveSSN = function (W9HoldingType: any) {
        if (
            this.tax_holding_type === W9HoldingType.Individual_sole_proprietor ||
            this.tax_holding_type === W9HoldingType.Single_member_LLC ||
            this.tax_holding_type === W9HoldingType.Grantor_trust
        ) {
            return true;
        }
        return false;
    };

    InvestorProfileTaxDocModel.prototype.w9ShouldHaveEIN = function (W9HoldingType: any) {
        if (this.tax_smllc_ein_ssn_choice &&
            (this.tax_holding_type === W9HoldingType.Single_member_LLC ||
             this.tax_holding_type === W9HoldingType.Grantor_trust)
        ) {
            return true;
        } else if (
            this.tax_holding_type === W9HoldingType.C_corporation ||
            this.tax_holding_type === W9HoldingType.S_corporation ||
            this.tax_holding_type === W9HoldingType.Partnership ||
            this.tax_holding_type === W9HoldingType.Trust_estate ||
            this.tax_holding_type === W9HoldingType.LLC ||
            this.tax_holding_type === W9HoldingType.Other
        ) {
            return true;
        }
        return false;
    };

    InvestorProfileTaxDocModel.prototype.w8beneShouldSeeTreatyClaimQuestion = function (W8BENEHoldingType: any) {
        if (
            this.tax_holding_type === W8BENEHoldingType.Disregarded_entity ||
            this.tax_holding_type === W8BENEHoldingType.Partnership ||
            this.tax_holding_type === W8BENEHoldingType.Simple_trust ||
            this.tax_holding_type === W8BENEHoldingType.Grantor_trust
        ) {
            return true;
        }
        return false;
    };

    InvestorProfileTaxDocModel.prototype.taxCountryDisplay = function (CountryChoices: any) {
        if (this.tax_country) {
            return CountryChoices.values[this.tax_country];
        }
        return '';
    };

    InvestorProfileTaxDocModel.prototype.isUsBasedOnTaxCountry = function () {
        return this.tax_country === 'US';
    };

    InvestorProfileTaxDocModel.prototype.abandonDoc = function() {
        return $http.post(this.urls.abandon_doc, {});
    };

    return InvestorProfileTaxDocModel;
};

angular
    .module('fundersclub.models')
    .factory('InvestorProfile', InvestorProfile)
    .factory('InvestorProfileTaxDocUpdate', InvestorProfileTaxDocUpdate)
    .factory('UserProfile', UserProfile)
    .factory('UserEmailAddress', UserEmailAddress)
    .factory('User', User)
    .factory('UserInvite', UserInvite)
    .factory('TaxpayerInfoVerification', TaxpayerInfoVerification)
    .factory('InvestorProfileTaxDoc', InvestorProfileTaxDoc);
