class FcCompanyMailingAddress implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        companyData: '<',
    };
    public controller = FcCompanyMailingAddressController;
    public templateUrl = 'companies/mailing-address.component.html';
}

class FcCompanyMailingAddressController implements ng.IComponentController {
    public companyData: any;

    private company: any;
    private errors: any = {};
    private fcSave: any;

    constructor(private Company: any) {}

    public $onInit() {
        this.company = new this.Company(this.companyData);
    }

    /* tslint:disable:no-unused-variable */
    private save() {
        this.errors = {};
        this.company
            .updateMailingAddress()
            .catch((response: any) => angular.extend(this.errors, response.data))
            .finally(() => this.fcSave.saveAttemptFinished(this.errors));
    }
}

angular
    .module('fundersclub.companies')
    .component('fcCompanyMailingAddress', new FcCompanyMailingAddress());
