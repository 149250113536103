class FcMyPortfolio implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        assetsDate: '<',
        assetsPublishedDate: '<',
        nextAssetsDate: '<',
        nextAssetsPublishedDate: '<',
        partnerships: '<',
    };
    public controller = FcMyPortfolioController;
    public templateUrl = 'users/my-portfolio.component.html';
}

class FcMyPortfolioController implements ng.IComponentController {
    public assetsDate: string;
    public partnerships: Array<any>;

    private cache: any = {};
    private chartConfig: any;
    private companies: any;
    private loading: boolean = true;
    private newsItems: any;
    private reverseSort: boolean;
    private selectedChart: string;
    private selectedPartnership: any;
    private sort: string = '';
    private specialDistsFundsText: string = '';

    constructor(
        private $window: any,
        private $http: ng.IHttpService,
        private $timeout: ng.ITimeoutService,
        private moment: moment.MomentStatic,
        private $uibModal: ng.ui.bootstrap.IModalService
    ) {
    }

    public $onInit() {
        // http://api.highcharts.com/highcharts/lang :(
        this.$window.Highcharts.setOptions({
            lang: {
                thousandsSep: ',',
            },
        });

        this.chartConfig = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
            },
            colors: ['#3694E8', '#00BCD4', '#009688', '#8BC34A', '#CDDC39', '#FFEB3B', '#FFC107',
                    '#FF5722', '#F44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5'],
            plotOptions: {
                pie: {
                    center: ['50%', '50%'],
                    dataLabels: {
                        enabled: false,
                    },
                },
            },
            series: [{
                colorByPoint: true,
                data: [],
                innerSize: '60%',
            }],
            title: {
                text: null,
            },
            tooltip: {
                pointFormat: '<b>Gross Value: ${point.y:,.0f}</b>',
            },
        };
    }

    public $onChanges(changes: any) {
        if (changes.partnerships && changes.partnerships.isFirstChange() && changes.partnerships.currentValue.length) {
            this.selectedPartnership = changes.partnerships.currentValue[0];
            this.loadCompanies();
        }
    }

    /* tslint:disable:no-unused-variable */
    public loadCompanies() {
        /* See if we have the data we need cached, and if not load it */
        const key = this.selectedPartnership.id;

        if (!this.cache.hasOwnProperty(key)) {
            this.loading = true;
            this.$http.get(
                this.selectedPartnership.urls.my_portfolio_data
            ).then((response: any) => {
                this.cache[key] = response.data;
                this.loadCompanies();
            });

            return;
        }

        this.loading = false;

        const data = this.cache[key];
        this.companies = data.companies;
        this.newsItems = data.news_and_press;
        this.specialDistsFundsText = data.special_dists_funds_text;
        this.reverseSort = false;
        this.sort = '';

        this.$timeout(() => {
            this.onChartByCompanyClicked();
        }, 0);
    }

    /* tslint:disable:no-unused-variable */
    public onInvestmentClicked(investment: any) {
        let modal: any = this.$uibModal
            .open({
                animation: false,
                component: 'FcAllInvestmentsViewInvestmentModal',
                resolve: {
                    investment_details_url: () => investment.my_investments_details_url,
                },
                windowClass: 'FullTakeoverModal',
            });
    }

    /* tslint:disable:no-unused-variable */
    private onChartByCompanyClicked() {
        if (!this.selectedPartnership.is_fundersclub || !this.companies.length) {
            return;
        }

        let seriesData = this.companies.map((company: any) => {
            return {
                name: company.name,
                y: company.value,
            };
        });
        seriesData.sort((a: any, b: any) => {
            return b.y - a.y;
        });

        this.chartConfig.getChartObj().series[0].setData(seriesData);
        this.selectedChart = 'by-company';
    }

    /* tslint:disable:no-unused-variable */
    private onChartByIndustryClicked() {
        if (!this.selectedPartnership.is_fundersclub) {
            return;
        }

        let valueByIndustry: any = {};
        for (const company of this.companies) {
            if (!(company.industry in valueByIndustry)) {
                valueByIndustry[company.industry] = 0;
            }
            valueByIndustry[company.industry] += company.value;
        }

        let seriesData: any = [];
        for (const industry of Object.keys(valueByIndustry)) {
            seriesData.push({
                name: industry,
                y: valueByIndustry[industry],
            });
        }
        seriesData.sort((a: any, b: any) => {
            return b.y - a.y;
        });

        this.chartConfig.getChartObj().series[0].setData(seriesData);
        this.selectedChart = 'by-industry';
    }

    /* tslint:disable:no-unused-variable */
    private resort(sort: string): void {
        if (this.sort === sort) {
            if (this.reverseSort) {
                this.reverseSort = false;
                this.sort = '';
            } else {
                this.reverseSort = !this.reverseSort;
            }
        } else {
            this.sort = sort;
            this.reverseSort = false;
        }
    }
}

angular
    .module('fundersclub.users')
    .component('fcMyPortfolio', new FcMyPortfolio());
