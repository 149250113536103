class FcInvestmentAssetEdit implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        asset: '<',
        enableEditChildOfProceed: '<',
        onCanceled: '&',
        onDeleted: '&?',
        onPristineUpdated: '&?',
        onSaved: '&',
        showDeleteButton: '<',
    };
    public controller = FcInvestmentAssetEditController;
    public templateUrl = 'investment-assets/investment-asset-edit.component.html';
}

class FcInvestmentAssetEditController implements ng.IComponentController {
    public asset: any;
    public debtConversionValEvent: any;
    public enableEditChildOfProceed: boolean;
    public onCanceled: () => void;
    public onDeleted: () => void;
    public onPristineUpdated: (data: {pristine: boolean}) => void;
    public onSaved: (data: {asset: any}) => void;
    public showDeleteButton: boolean;
    public parentAsset: any;

    private errors: {[error: string]: any} = {};
    private fcSave: any;
    private form: any;
    private prorataRightsChoices: any;
    private terms: any;
    private loading: boolean = true;

    constructor(
        private _: UnderscoreStatic,
        private $http: ng.IHttpService,
        private $scope: ng.IRootScopeService,
        private FCChoices: any,
        private InvestmentAsset: any,
        private InvestmentTerms: any,
        private moment: moment.MomentStatic,
        private ValuationEvent: any,
        private Upload: any
    ) {}

    public $onInit() {
        // Get term data.
        this.terms = new this.InvestmentTerms(this.asset.terms);

        // If the asset was derived from a debt, locate the debt conversion valuation event
        if (this.asset.is_equity_derived_from_complex_debt) {
            this.parentAsset = new this.InvestmentAsset();
            this.$http.get(this.asset.parent_investment).then((resp: any) => {
                angular.extend(this.parentAsset, resp.data);

                const valEvents = this.parentAsset.valuation_events.filter(
                    (ve: any) => ve.event_type === 'debt-conversion'
                );
                if (valEvents.length !== 1) {
                    alert(`Failed finding debt conversion for asset ${this.asset.id} -
                           please report this to fire@fundersclub.com`);
                    return;
                }
                this.debtConversionValEvent = new this.ValuationEvent(valEvents[0]);

                this.doneLoading();
            });
        } else {
            this.doneLoading();
        }

    }

    private doneLoading() {
        this.loading = false;

        // Get choices for dropdowns.
        this.prorataRightsChoices = [
            {n: '---', v: null},
            {n: 'Yes', v: true},
            {n: 'No', v: false},
        ];

        // Sometimes the parent component needs to know about
        // this form's pristine state
        if (this.onPristineUpdated) {
            this.$scope.$watch(
                () => this.form.$pristine,
                (newValue: any, oldValue: any) => {
                    if (newValue !== oldValue) {
                        this.onPristineUpdated({pristine: newValue});
                    }
                }
            );
        }

        // Upload files when they are added
        this.$scope.$watch(
            () => this.terms.investment_documents,
            (newValues: Array<any>) => {
                if (!newValues) { return; }
                for (let file of newValues) {
                    if (!file || file.$error) { return; }

                    let newDoc: any = {
                        document: file,
                        name: file.name,
                        terms: this.terms.url,
                    };
                    this.terms.documents.push(newDoc);

                    this.Upload
                        .upload({
                            data: newDoc,
                            url: this.terms.urls.documents,
                        })
                        .then((response: any) => {
                            response.config.data.model.uploading = false;
                            angular.extend(response.config.data.model, response.data);
                        });
                }
            }
        );
    }

    /* tslint:disable:no-unused-variable */
    private addUnstructuredValuation() {
        this.terms.unstructured_valuations.push({value: 0});
    }

    /* tslint:disable:no-unused-variable */
    private canEdit() {
        return !this.asset.is_child_of_proceed || this.enableEditChildOfProceed;
    }

    /* tslint:disable:no-unused-variable */
    private deleteValuationEvent(valEvent: any) {
        let remove: boolean = confirm('Are you sure you want to delete this valuation event?');
        if (remove) {
            this.$http.delete(valEvent.url).then(() => {
                this.asset.valuation_events.splice(
                    this.asset.valuation_events.indexOf(valEvent),
                    1
                );

                // Get updated valuation.
                this.InvestmentAsset.get(
                    {id: this.asset.id},
                    (response: any) => {
                        this.onSaved({asset: new this.InvestmentAsset(response)});
                    }
                );
            });
        }
    }

    /* tslint:disable:no-unused-variable */
    private deleteDocument(document: any) {
        document.uploading = true;
        this.$http
            .delete(document.url)
            .then(() => {
                this.terms.documents.splice(this.terms.documents.indexOf(document), 1);
            });
    }

    /* tslint:disable:no-unused-variable */
    private removeUnstructuredValuation(index: number) {
        this.terms.unstructured_valuations.splice(index, 1);
        this.form.$setDirty();
    }

    /* tsline:disable:no-unused-variable */
    private hadStockDist() {
        return this.asset.valuation_events.filter(
            (ve: any) => ve.event_type === 'stock-dist'
        ).length > 0;
    }

    /* tslint:disable:no-unused-variable */
    private save() {
        this.errors = {};

        const saveTerms = () => {
            this.terms
                .$save()
                .then((response: any) => {
                    // Get updated valuation.
                    this.InvestmentAsset.get(
                        {id: this.asset.id},
                        (assetResponse: any) => {
                            this.onSaved({asset: new this.InvestmentAsset(assetResponse)});
                        }
                    );
                })
                .catch((response: any) => {
                    angular.extend(this.errors, response.data);
                })
                .finally(() => this.fcSave.saveAttemptFinished(this.errors));
        };

        if (this.asset.is_equity_derived_from_complex_debt) {
            this.debtConversionValEvent.$save(['total_balance_converted'])
                .then((resp: any) => {
                    angular.extend(this.debtConversionValEvent, resp);
                    saveTerms();
                })
                .catch((resp: any) => {
                    angular.extend(this.errors, resp.data);
                    this.fcSave.saveAttemptFinished(this.errors);
                });
        } else {
            saveTerms();
        }
    }

    /* tslint:disable:no-unused-variable */
    private editValEventNoLongerEffectiveDate(valEvent: any) {
        valEvent.editing_no_longer_effective_date = true;
        valEvent.errors = {};
        valEvent.orig_no_longer_effective_date = valEvent.no_longer_effective_date;
    }

    /* tslint:disable:no-unused-variable */
    private cancelValEventNoLongerEffectiveDate(valEvent: any) {
        valEvent.editing_no_longer_effective_date = false;
        valEvent.no_longer_effective_date = valEvent.orig_no_longer_effective_date;
    }

    /* tslint:disable:no-unused-variable */
    private saveValEventNoLongerEffectiveDate(valEvent: any) {
        let valEventModel = new this.ValuationEvent(valEvent);
        valEvent.saving = true;
        valEvent.errors = {};
        valEventModel.$save(['no_longer_effective_date'])
            .then(() => {
                valEvent.saving = false;
                valEvent.editing_no_longer_effective_date = false;
            })
            .catch((response: any) => {
                valEvent.saving = false;
                angular.extend(valEvent.errors, response.data);
            });
    }

    /* tslint:disable:no-unused-variable */
    private onDelete() {
        if (confirm('Are you sure you wish to delete this asset?')) {
            this.onDeleted();
        }
    }
}

angular
    .module('fundersclub.assets')
    .component('fcInvestmentAssetEdit', new FcInvestmentAssetEdit());
