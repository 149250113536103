class FcFounderOnboardSwagSizes implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        founders: '<',
        nextState: '<',
        onUpdated: '&',
    };
    public controller = FcFounderOnboardSwagSizesController;
    public templateUrl = 'founder-onboarding/swag-sizes.component.html';
}

class FcFounderOnboardSwagSizesController implements ng.IComponentController {
    public founders: Array<any>;
    public nextState: string;
    public onUpdated: (data: {founder: any}) => void;

    constructor(
        private $q: ng.IQService,
        private $state: any,
        private CompanyTeamMember: any,
        private FCChoices: any,
        private FCFounderOnboardData: any,
        private FCGlobals: any
    ) {}

    public $onInit() {
        this.founders = angular.copy(this.founders);
        this.founders = this.founders.filter((founder: any) => founder.is_any_founder);
    }

    /* tslint:disable:no-unused-variable */
    private next() {
        let promises = this.founders.map((founder: any) => {
            let deferred = this.$q.defer();
            founder
                .$save(['tshirt_size'])
                .then((response: any) => {
                    this.onUpdated({founder: new this.CompanyTeamMember(response)});
                    deferred.resolve();
                });
            return deferred.promise;
        });
        this.$q
            .all(promises)
            .then(() => this.$state.go(this.nextState));
    }
}

angular
    .module('fundersclub.founderOnboarding')
    .component('fcFounderOnboardSwagSizes', new FcFounderOnboardSwagSizes());
