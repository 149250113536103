do ->

    fcSelectReferralApplication = (ReferralApplication) ->
        thisScope =
            'referral': '=fcSelectReferralApplication'

        thisController = ->
            vm = @
            vm.referrals = null
            vm.showReferralSelectionForm = !!vm.referral.id

            vm.maybeLoadReferrals = ->
                if vm.showReferralSelectionForm and not vm.referrals
                    ReferralApplication
                        .get()
                        .$promise
                        .then (response) ->
                            vm.referrals = response.referrals

            vm.clearReferral = ->
                vm.referral = {}

            vm.maybeLoadReferrals()

            return

        thisDirective =
            templateUrl: 'funds/select-referral-application.directive.html'
            restrict: 'A'
            scope: thisScope
            require: '^form'
            bindToController: true
            controllerAs: 'srac'
            controller: thisController

    angular
        .module 'fundersclub.funds'
        .directive 'fcSelectReferralApplication', fcSelectReferralApplication
