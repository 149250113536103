let fcRevealMarkdownForm = (Company: any, Partnership: any) => {
    let thisScope = {
        'closeWithCancel': '&onCancel',
        'closeWithSave': '&onSave',
        'companyId': '@',
        'partnershipId': '@',
    };
    let thisController = function() {
        let vm = this;
        vm.errors = {};
        vm.dataLoaded = false;
        vm.partnership = new Partnership({id: vm.partnershipId});
        vm.selectedValuationEvents = {};

        // Do API call to load required form data.
        vm.partnership.$get().then(() => {
            vm.partnership.reveal_markdown_details_for_company(vm.companyId)
                .then((response: any) => {
                    vm.bindCompanyData(response.data);
                })
                .catch((response: any) => {
                    // It's hard to know if there's something better to do here...
                    alert('This form failed to load. Please refresh the page and try again');
                    console.log(response);
                });
        });

        vm.saveForm = () => {
            let selectedValuationEventIds: any = [];
            for (let assetId in vm.selectedValuationEvents) {
                if (vm.selectedValuationEvents[assetId]) {
                    selectedValuationEventIds.push(assetId);
                }
            }
            let params = {
                company_id: vm.company.id,
                target_valuation_events: selectedValuationEventIds,
            };
            vm.partnership.execute_reveal_markdown(params)
                .then(() => {
                    vm.closeWithSave();
                })
                .catch((response: any) => {
                    angular.extend(vm.errors, response.data);
                })
                .finally(() => {
                    vm.fcSave.saveAttemptFinished(vm.errors);
                });
        };

        vm.bindCompanyData = (data: any) => {
            vm.company = data;
            vm.dataLoaded = true;
        };

        vm.cancelForm = () => vm.closeWithCancel();

        vm.selectAll = () => {
            for (let ve of vm.company.target_valuation_events) {
                vm.selectedValuationEvents[ve.id] = true;
            }
            vm.form.$setDirty();
        };
    };
    let thisDirective = {
        bindToController: true,
        controller: thisController,
        controllerAs: 'frmf',
        restrict: 'A',
        scope: thisScope,
        templateUrl: 'investment-assets/markdown-reveal.directive.html',
    };
    return thisDirective;
};

angular
    .module('fundersclub.assets')
    .directive('fcRevealMarkdownForm', fcRevealMarkdownForm);
