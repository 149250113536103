let fcSimpleToastMessage = (FCToastManager: any) => {
    let thisScope = {
        'message': '@message',
    };
    let thisController = function ($scope: any) {
        let vm = this;
        FCToastManager.createGenericToast(vm.message);
    };

    let thisDirective = {
        bindToController: true,
        controller: thisController,
        controllerAs: 'stm',
        restrict: 'E',
        scope: thisScope,
    };

    return thisDirective;
};

angular
    .module('fundersclub')
    .directive('fcSimpleToastMessage', fcSimpleToastMessage);
