do ->

    NewsfeedItem = (fcModel, $http) ->
        NewsfeedItem = fcModel '/api/newsfeeditems/:id/',
            id: '@id'

        NewsfeedItem.getNextItems = (oldest) ->
            $http.get '/api/newsfeeditems/', params: {time: oldest}
        NewsfeedItem


    angular
        .module 'fundersclub.newsfeed'
        .factory 'NewsfeedItem', NewsfeedItem
