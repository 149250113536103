do ->

    # Handles setting a user's profile picture.
    UserAvatarController = (fcUserAvatarManager, FCUserData) ->
        vm = @

        default_avatar = FCUserData.userprofile.urls.avatar_default_image
        vm.avatar_url = FCUserData.urls.AVATAR_IMAGE
        vm.errors = {}
        vm.uploading = false

        vm.set = (file) ->
            if not file or not file.length then return
            vm.uploading = true
            vm.errors = {}

            fcUserAvatarManager.set(file[0])
                .then (response) ->
                    vm.avatar_url = response.data.url
                .catch (response) ->
                    angular.extend(vm.errors, response.data)
                .finally ->
                    vm.uploading = false

        vm.remove = ->
            vm.errors = {}
            fcUserAvatarManager.remove()
                .then (response) ->
                    vm.avatar_url = default_avatar
                .catch (response) ->
                    angular.extend(vm.errors, response.data)

        # End UserAvatarController
        return


    angular
        .module 'fundersclub.users'
        .controller 'UserAvatarController', UserAvatarController
