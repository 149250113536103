class FcSignLegaldocInterstitialModal implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        modalInstance: '<',
        resolve: '<',
    };
    public controller = FcSignLegaldocInterstitialModalController;
    public templateUrl = 'legaldocs/sign-legaldoc-interstitial-modal.component.html';
}

class FcSignLegaldocInterstitialModalController implements ng.IComponentController {
    public modalInstance: ng.ui.bootstrap.IModalServiceInstance;
    public resolve: {
        docName: string;
        legalDoc: any;
        onResetForm: () => void;
    };

    // Normally we would copy the legalDoc in $onInit but we need to listen for updates
    // from the parent component which is long polling the server for changes

    /* tslint:disable:no-unused-variable */
    private onSigningModalClosed() {
        this.modalInstance.close();
    }
}

angular
    .module('fundersclub.users')
    .component('fcSignLegaldocInterstitialModal', new FcSignLegaldocInterstitialModal());
