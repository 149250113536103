class FcInvestmentDeletionReasons implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        modalInstance: '<',
        resolve: '<',
    };
    public controller = FcInvestmentDeletionReasonsController;
    public templateUrl = 'invest/deletion-reasons.component.html';
};

class FcInvestmentDeletionReasonsController implements ng.IComponentController {
    public modalInstance: ng.ui.bootstrap.IModalServiceInstance;
    public resolve: {
        investment: IInvestment,
    };

    private investment: IInvestment;
    private deletionReasons: {[key: string]: boolean} = {};
    private deletionReasonValues: Array<string> = [];
    private deletionReasonCopy: {[key: string]: string} = {};
    private deletionReasonsSaving: {[key: string]: boolean} = {};

    constructor(private FCChoices: any) {}

    public $onInit(): void {
        this.investment = this.resolve.investment;
        this.deletionReasonValues = this.FCChoices.DeletionReasons
            .choices
            .map((deletionReasonChoice: string) => {
                return deletionReasonChoice[0];
            });

        for (let reason of this.deletionReasonValues) {
            this.deletionReasons[reason] = false;
            this.deletionReasonsSaving[reason] = false;
            this.deletionReasonCopy[reason] = (
                this.FCChoices.DeletionReasons.values[reason]
            );
        }
    }

    /* tslint:disable:no-unused-variable */
    private savedeletionReason(deletionReason: string): void {
        this.deletionReasonsSaving[deletionReason] = true;
        this.investment.deletion_reasons = this.
            deletionReasonValues
            .filter((deletionReasonChoice: string) => {
                return this.deletionReasons[deletionReasonChoice];
            });
        this.investment
            .updateDeletionReasons()
            .then((response: any) => {
                this.deletionReasonsSaving[deletionReason] = false;
            });
    }
}

angular
    .module('fundersclub.invest')
    .component('fcInvestmentDeletionReasons', new FcInvestmentDeletionReasons());
