class FcComplianceReverification implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        name: '<',
        noticeText: '<',
    };
    public controller = FcComplianceReverificationController;
    public templateUrl = 'users/compliance-reverification.component.html';
}

class FcComplianceReverificationController implements ng.IComponentController {
    public name: string;
    public noticeText: string;
    public obj: any = {};
    public errors = {};
    public submitting: boolean = false;

    constructor(
        private Upload: any,
        private $window: ng.IWindowService,
        private FCChoices: any
    ) {
    }

    public uploadLicense(files: any, invalidFiles: any) {
        if (!files[0]) {
            return;
        }

        this.obj.us_license_doc = files[0];

    }

    public uploadCitizenshipDoc(idx: number, files: any, invalidFiles: any) {
        if (!files[0]) {
            return;
        }

        this.obj.citizenship_docs[idx] = files[0];
    }

    public numCitizenshipsChanged() {
        const n = parseInt(this.obj.num_citizenships, 10);
        if (!this.obj.citizenships) {
            this.obj.citizenships = new Array(n);
            for (let i = 0; i < n; ++i) {
                this.obj.citizenships[i] = '';
            }
            this.obj.citizenship_docs = new Array(n);
        } else if (this.obj.citizenships.length < n) {
            while (this.obj.citizenships.length !== n) {
                console.log('a', n, this.obj.citizenships.length);
                this.obj.citizenships.push('');
                this.obj.citizenship_docs.push(null);
            }
        } else if (this.obj.citizenships.length > n) {
            while (this.obj.citizenships.length !== n) {
                console.log(this.obj.citizenships.length, n);
                this.obj.citizenships.pop();
                this.obj.citizenship_docs.pop();
            }
        }
    }

    public uploadResidencyDoc(idx: number, files: any, invalidFiles: any) {
        if (!files[0]) {
            return;
        }

        this.obj.residency_docs[idx] = files[0];
    }

    public numResidenciesChanged() {
        const n = parseInt(this.obj.num_residencies, 10);
        if (!this.obj.residencies) {
            this.obj.residencies = new Array(n);
            for (let i = 0; i < n; ++i) {
                this.obj.residencies[i] = '';
            }
            this.obj.residency_docs = new Array(n);
        } else if (this.obj.residencies.length < n) {
            while (this.obj.residencies.length !== n) {
                console.log('a', n, this.obj.residencies.length);
                this.obj.residencies.push('');
                this.obj.residency_docs.push(null);
            }
        } else if (this.obj.residencies.length > n) {
            while (this.obj.residencies.length !== n) {
                console.log(this.obj.residencies.length, n);
                this.obj.residencies.pop();
                this.obj.residency_docs.pop();
            }
        }
    }

    public submit() {
        this.errors = {};
        this.submitting = true;
        this.Upload.upload({
            data: this.obj,
            url: this.$window.location.href,
        }).then((response: any) => {
            this.$window.location.reload();
        }).catch((response: any) => {
            angular.extend(this.errors, response.data);
            this.submitting = false;
        });
    }
}

angular
    .module('fundersclub.users')
    .component('fcComplianceReverification', new FcComplianceReverification());
