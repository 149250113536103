class FcFounderViewUpdates implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        expandedChildId: '<',
    };
    public controller = FcFounderViewUpdatesController;
    public templateUrl = 'companies/founder-view-updates.component.html';
}

class FcFounderViewUpdatesController implements ng.IComponentController {
    private company: any;
    private expandedUpdate: number = null;
    private expandedChildId: any;
    private readUpdates: any;
    private updates: Array<any> = [];

    constructor (
        private Company: any,
        private FCChoices: any,
        private FCCompanyData: any,
        private FCInvestorUpdates: Array<any>,
        private FCReadUpdates: Array<number>,
        private InvestorUpdate: any
    ) {}

    public $onInit(): void {
        this.company =  new this.Company(this.FCCompanyData.company);
        this.readUpdates = this.FCReadUpdates;
        this.expandedChildId = parseInt(this.expandedChildId, 10);

        for (let update of this.FCInvestorUpdates) {
            let updateObj = new this.InvestorUpdate(update);
            this.updates.push(updateObj);

            if (this.expandedChildId === update.id) {
                this.toggleExpandUpdate(updateObj);
            }
        }
    }

    /* tslint:disable:no-unused-variable */
    private isUpdateRead(update: any): boolean {
        return this.readUpdates.indexOf(update.id) > -1;
    }

    /* tslint:disable:no-unused-variable */
    private toggleExpandUpdate(update: any): void {
        if (this.expandedUpdate === update.id) {
            this.expandedUpdate = null;
        } else {
            this.expandedUpdate = update.id;
        }

        if (!this.isUpdateRead(update.id)) {
            this.FCReadUpdates.push(update.id);
            update.mark_read();
        }
    }
}

angular
    .module('fundersclub.companies')
    .component('fcFounderViewUpdates', new FcFounderViewUpdates());
