let fcIdentityDocuments = () => {
    let thisScope = {
        'identityDocuments': '=identityDocuments',
        'investorProfile': '=investorProfile',
        'newDocumentErrors': '=newDocumentErrors',
        'onDocumentAdded': '&onDocumentAdded',
        'onDocumentRemoved': '&onDocumentRemoved',
    };
    let thisController = function(InvestorIdentityDocument: any) {
        let vm = this;
        vm.errors = {};
        vm.hasError = false;
        vm.uploading = false;

        vm.addIdentityDocument = function(files: any, errFiles: any) {
            let identityDocumentFile = files[0];

            if (!identityDocumentFile) {
                return;
            }

            let identityDocument = new InvestorIdentityDocument({
                document: identityDocumentFile,
                name: identityDocumentFile.name,
                profile: vm.investorProfile.url,
            });
            vm.identityDocuments.push(identityDocument);
            vm.onDocumentAdded(identityDocument);
        };

        vm.markIdentityDocumentToBeRemoved = function(document: any) {
            document.removed = !document.removed;
            vm.onDocumentRemoved();
        };
    };
    let thisDirective = {
        bindToController: true,
        controller: thisController,
        controllerAs: 'idc',
        restrict: 'E',
        scope: thisScope,
        templateUrl: 'icp/identity-documents.directive.html',
    };
    return thisDirective;
};

angular
    .module('fundersclub.icp')
    .directive('fcIdentityDocuments', fcIdentityDocuments);
