class FcFundraisingGraphs implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        fundId: '<',
    };
    public controller = FcFundraisingGraphsController;
    public templateUrl = 'funds/fundraising-graphs.component.html';
}

class FcFundraisingGraphsController implements ng.IComponentController {
    public fundId: number;

    private highchartsConfig = {
        chart: {
            type: <string> null,
        },
        plotOptions: {
            column: {
                stacking: 'normal',
            },
            spline: {
                marker: {
                    enabled: false,
                },
            },
        },
        series: <Array<any>> null,
        title: {
            text: <string> null,
        },
        tooltip: {
            footerFormat: '</table>',
            headerFormat: `
                <div style="margin-bottom:4px; font-size:10px">
                    {point.key}
                </div>
                <table style="color: #323B43;">
            `,
            pointFormat: (`
                <tr>
                    <td style="line-height:18px">
                        <b>{series.name}</b>&nbsp;
                    </td>
                    <td style="text-align: right">{point.format_symbol}{point.y}</td>
                </tr>
            `),
            shared: true,
            useHTML: true,
        },
        xAxis: {
            dateTimeLabelFormats: {
                // Label for daily graphs.
                day: '%m/%d',
                // Label for weekly graphs.
                week: '%m/%d',
            },
            plotLines: <Array<any>> null,
            showFirstLabel: false,
            showLastLabel: false,
            startOnTick: true,
            tickInterval: 24 * 3600 * 1000, // 1 day
            tickLength: 5,
            title: {
                text: <string> null,
            },
            type: 'datetime',
        },
        yAxis: {
            labels: {
                align: 'left',
                reserveSpace: false,
                x: 0,
            },
            max: <number> null,
            min: 0,
            offset: 40,
            plotLines: <Array<any>> null,
            showFirstLabel: false,
            title: {
                // Don't need title, data is self-explanatory.
                text: <string> null,
            },
        },
    };
    private data: any = {};
    private fund: any;
    private graphType: string;
    private graphTypes = [{
        chartType: 'spline',
        endpoint: 'get_capital_cumulative',
        key: 'capital_cumulative',
        label: 'Reservations Cumulative',
        showYAxisPlotlines: true,
    }, {
        chartType: 'column',
        endpoint: 'get_capital_incremental',
        key: 'capital_incremental',
        label: 'Reservations Incremental',
        showYAxisPlotlines: false,
    }, {
        chartType: 'spline',
        endpoint: 'get_profile_views_cumulative',
        key: 'profile_views_cumulative',
        label: 'Visitors Cumulative',
        showYAxisPlotlines: false,
    }, {
        chartType: 'column',
        endpoint: 'get_profile_views_incremental',
        key: 'profile_views_incremental',
        label: 'Visitors Incremental',
        showYAxisPlotlines: false,
    }];
    private initialDataLoaded = false;
    private loading = false;
    private period: string;
    private periods = [{
        key: 'daily',
        label: 'Daily',
    }, {
        key: 'weekly',
        label: 'Weekly',
    }];

    constructor(private _: UnderscoreStatic, private $window: any, private Fund: any) {}

    public $onInit() {
        // http://api.highcharts.com/highcharts/lang :(
        this.$window.Highcharts.setOptions({
            lang: {
                thousandsSep: ',',
            },
        });
        this.fund = new this.Fund({id: this.fundId});
        this.graphType = this.graphTypes[0].key;
        this.period = this.periods[0].key;
        this.showGraph();
    }

    private currentGraphKey() {
        return `${this.graphType}-${this.period}`;
    }

    private setGraphData() {
        const data = this.data[this.currentGraphKey()];
        const settings = this._.find(this.graphTypes, (type) => type.key === this.graphType);

        this.highchartsConfig.chart.type = settings.chartType;
        this.highchartsConfig.xAxis.plotLines = data.plotlines.x;

        if (settings.showYAxisPlotlines) {
            this.highchartsConfig.yAxis.plotLines = data.plotlines.y;

            // Make sure both plotline and largest data point appear on graph
            const plotlineMax: number = this._.max(data.plotlines.y.map(
                (plotline: any) => {
                    return plotline.value;
                })
            );
            const seriesMax: number = this._.max(data.graph_data.map((series: any) => {
                return this._.max(series.data.map((dataPoint: any) => dataPoint.y));
            }));
            this.highchartsConfig.yAxis.max = this._.max([plotlineMax, seriesMax]);
        } else {
            this.highchartsConfig.yAxis.plotLines = [];
            this.highchartsConfig.yAxis.max = null;
        }

        this.highchartsConfig.series = data.graph_data;
    }

    private showGraph() {
        this.loading = true;

        if (this.data[this.currentGraphKey()]) {
            this.setGraphData();
            this.loading = false;
        } else {
            const settings = this._.find(this.graphTypes, (type) => type.key === this.graphType);
            this.fund[settings.endpoint](this.period).then((response: any) => {
                this.data[this.currentGraphKey()] = response.data;
                this.setGraphData();
                this.loading = false;
                this.initialDataLoaded = true;
            });
        }
    }
}

angular
    .module('fundersclub.assets')
    .component('fcFundraisingGraphs', new FcFundraisingGraphs());
