do ->

    # List of news items for a given company.
    NewsfeedController = (FCNewsfeedData, NewsfeedItem) ->
        vm = @
        vm.newsfeedItems = (new NewsfeedItem(item) for item in FCNewsfeedData.news_items)
        vm.isCurrentlyLoading = false
        vm.moreItemsCanBeLoaded = true

        vm.getNextPage = ->
            vm.isCurrentlyLoading = true
            oldestItemCreatedAt = _.last(vm.newsfeedItems)?.created_at

            # The list is sorted by timestamp - try and get items older than
            # the last item.
            NewsfeedItem.getNextItems(oldestItemCreatedAt).then (response) ->
                itemWasAdded = false
                for newsfeedItem in response.data.results
                    # Ensure item isn't already in list.
                    itemExists = !!_.find(vm.newsfeedItems, (item) -> (item.id == newsfeedItem.id))
                    if not itemExists
                        vm.newsfeedItems.push(new NewsfeedItem(newsfeedItem))
                        itemWasAdded = true

                # If nothing was added, we don't need to make any more API calls.
                vm.moreItemsCanBeLoaded = itemWasAdded
                vm.isCurrentlyLoading = false

            return

        vm.shouldLoadMoreItems = ->
            return not vm.isCurrentlyLoading and vm.moreItemsCanBeLoaded

        # End NewsfeedController
        return



    angular
        .module 'fundersclub.newsfeed'
        .controller 'NewsfeedController', NewsfeedController
