do ->

    fcIntroductionRequestDecline = ->
        thisScope =
            'introductionRequest': '=fcIntroductionRequestDecline'

        thisController = ->
            vm = @
            # If already have a reason, form has been previously submitted.
            vm.submitted = vm.introductionRequest.declined_reason != ''

            vm.save = ->
                vm.saving = true
                vm.introductionRequest
                    .$save()
                    .finally ->
                        vm.saving = false
                        vm.submitted = true

            return

        thisDirective =
            templateUrl: 'introduction-requests/decline.directive.html'
            restrict: 'A'
            scope: thisScope
            bindToController: true
            controllerAs: 'irdc'
            controller: thisController
            replace: true
        return thisDirective

    angular
        .module 'fundersclub.introductionRequests'
        .directive 'fcIntroductionRequestDecline', fcIntroductionRequestDecline
