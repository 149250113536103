class FcTaxInfoVerification implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        currentTaxYear: '<',
        showVerifyCta: '<',
    };
    public controller = FcTaxInfoVerificationController;
    public templateUrl = 'users/tax-info-verification.component.html';
}

class FcTaxInfoVerificationController implements ng.IComponentController {
    private errors: {[error: string]: Array<string>} = {};
    private fcSave: any;
    private investorProfiles: any;
    private isTaxSoftwareOtherUsed: boolean = false;
    private showVerificationSteps: boolean = false;
    private showVerifyCta: boolean;
    private taxpayerInfoVerification: ITaxpayerInfoVerification;
    private taxSoftwareUsed: any;
    private user: any;
    private verificationCompleted: boolean;

    constructor (
        private $uibModal: ng.ui.bootstrap.IModalService,
        private $window: ng.IWindowService,
        private FCChoices: any,
        private FCUser: any,
        private FCUserInvestorProfiles: any,
        private InvestorProfile: IFcModelResource<IInvestorProfile>,
        private TaxpayerInfoVerification: IFcModelResource<ITaxpayerInfoVerification>,
        private User: any
    ) {}

    public $onInit(): void {
        this.errors = {};
        this.investorProfiles = {};
        this.taxSoftwareUsed = {};
        this.user = new this.User(this.FCUser);
        // Initialize for to correct state.
        this.verificationCompleted = !this.showVerifyCta;

        this.taxpayerInfoVerification = new this.TaxpayerInfoVerification({
            how_preparing_taxes: '',
            other_things_we_can_do: '',
            tax_software: '',
            user: this.user.id,
            verified_ids: [],
        });

        for (let key in this.FCChoices.TaxSoftware.values) {
            if (this.FCChoices.TaxSoftware.choices.hasOwnProperty(key)) {
                this.taxSoftwareUsed[key] = false;
            }
        }

        /* break saved tax software up by tab */
        let softwareChoiceList = this.FCChoices.TaxSoftware.choices.map((choice: string) => { return choice[0]; });
        if (this.taxpayerInfoVerification.tax_software) {
            for (let choice of this.taxpayerInfoVerification.tax_software.split('\t')) {
                if (softwareChoiceList.indexOf(choice) > -1) {
                    this.taxSoftwareUsed[choice] = true;
                } else {
                    this.taxSoftwareUsed.other = choice;
                    this.isTaxSoftwareOtherUsed = true;
                }
            }
        }
        if (!this.taxSoftwareUsed.hasOwnProperty('other')) {
            this.taxSoftwareUsed.other = '';
        }

        this.updateInvestorProfileData(
            this.FCUserInvestorProfiles.map((profile: any) => {
                return new this.InvestorProfile(profile);
            })
        );

        // Auto-expand form if url param specified.
        if (this.$window.location.search.toLowerCase().indexOf('show_verify=true') > -1) {
            this.showVerificationSteps = true;
        }
    }

    private updateInvestorProfileData(investorProfiles: Array<IInvestorProfile>,
                                      verifiedIds: Array<number> = []) {
        this.investorProfiles = {};

        for (let profile of investorProfiles) {
            this.investorProfiles[profile.id] = {
                profile: profile,
                verified: verifiedIds.indexOf(profile.id) > -1,
            };
        }
    }

    /* tslint:disable:no-unused-variable */
    private editInvestorProfile(investorProfileId: number): void {
        let modal: any = this.$uibModal
            .open({
                animation: false,
                backdrop: false,
                component: 'FcInvestorProfileResignTaxFormModal',
                resolve: {
                    investorProfile: () => this.investorProfiles[investorProfileId].profile,
                    onUpdated: () => this._onUpdated.bind(this),
                    updateReason: () => 'k1-prep',
                },
                windowClass: 'FullTakeoverModal',
            });
    }

    private _onUpdated(investorProfile: IInvestorProfile): void {
        this.investorProfiles[investorProfile.id].profile = investorProfile;
    }

    /* tslint:disable:no-unused-variable */
    private updateTaxSoftwareOtherUsed(): void {
        this.isTaxSoftwareOtherUsed = !!this.taxSoftwareUsed.other;
    }

    /* tslint:disable:no-unused-variable */
    private submitVerification(): void {
        this.errors = {};
        this.taxpayerInfoVerification.tax_software = '';
        this.taxpayerInfoVerification.verified_ids = [];

        /* build the string for tax software used */
        this.taxpayerInfoVerification.tax_software = Object.keys(this.taxSoftwareUsed).filter((key: any) => {
            /* ignore other; handle it specially later on */
            return key !== 'other';
        }).map((key: any) => {
            /* get all of the entries except other in taxSoftwareUsed and make an array of them */
            return key;
        }).join('\t');
        if (this.isTaxSoftwareOtherUsed && !!this.taxSoftwareUsed.other && !!this.taxSoftwareUsed.other.trim()) {
            this.taxpayerInfoVerification.tax_software += '\t' + this.taxSoftwareUsed.other;
        }
        this.taxpayerInfoVerification.tax_software = this.taxpayerInfoVerification.tax_software.trim();

        /* collect the investor profiles that have been verified */
        this.taxpayerInfoVerification.verified_ids = Object.keys(this.investorProfiles)
            .filter((id: string) => {
                return this.investorProfiles[id].verified;
            }).map((id: string) => {
                return parseInt(id, 10);
            });

        this.taxpayerInfoVerification.$save()
            .then((response: any) => {
                this.verificationCompleted = true;
            })
            .catch((response: any) => {
                // Edge case: Response may contain a reference to an investor profile
                // which is not present on the page.
                angular.extend(this.errors, response.data);
                this.fcSave.saveAttemptFinished(this.errors);
            });
    }
}

angular
    .module('fundersclub.users')
    .component('fcTaxInfoVerification', new FcTaxInfoVerification());
