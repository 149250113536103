class FcInvestorProfileAddIraBoundForm implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        errors: '<',
        investorProfile: '=',
    };
    public controller = FcInvestorProfileAddIraBoundFormController;
    public templateUrl = 'users/investor-profile-add-ira-bound-form.component.html';
}

class FcInvestorProfileAddIraBoundFormController implements ng.IComponentController {
    public errors: {[error: string]: Array<any>} = {};
    public investorProfile: IIRAInvestorProfile;

    constructor(
        private FCChoices: any
    ) {}

    public $onInit() {
        this.investorProfile.account_type = 1;
        this.investorProfile.country = '';
        this.investorProfile.first_name = '';
        this.investorProfile.investor_type = this.FCChoices.InvestorTypeChoices.IRA;
        this.investorProfile.last_name = '';
        this.investorProfile.middle_name = '';
        this.investorProfile.state = '';
        this.investorProfile.ira_account_number = '';
    }

    public $doCheck() {
        this.investorProfile.full_name = `
            ${this.investorProfile.first_name} ${this.investorProfile.middle_name} ${this.investorProfile.last_name}
        `.trim().replace('  ', ' ');
    }
}

angular
    .module('fundersclub.users')
    .component('fcInvestorProfileAddIraBoundForm', new FcInvestorProfileAddIraBoundForm());
