do ->
    # Note: we use separate controllers and load the user data multiple times
    # since each controller performs a partial update. A partial update results
    # in the entire object being returned from the server, potentially overwriting
    # unsaved changed in a different form

    UserProfileController = (
        AutoInvestPreferences,
        User,
        UserProfile,
        FCUserData,
        FCToastManager,
        FCAccomplishmentPromosManager
    ) ->
        vm = @
        vm.user = new User(FCUserData.user)
        vm.profile = new UserProfile(FCUserData.userprofile)
        vm.autoInvestPreferences = new AutoInvestPreferences(FCUserData.auto_invest_preferences)

        # end of UserProfileController
        return

    # Handles changing a user's password.
    UserChangePasswordController = (User, FCUserData) ->
        vm = @
        vm.user = new User(FCUserData.user)

        # Bindables
        vm.show_change_password_form = false
        vm.show_password_changed_message = false
        vm.errors = {}
        vm.saving = false
        # Leave password inputs blank by default
        vm.oldpassword = ''
        vm.password1 = ''
        vm.password2 = ''

        # Toggle visibility of "Change password" form.
        vm.toggleChangePasswordForm = ->
            vm.oldpassword = ''
            vm.password1 = ''
            vm.password2 = ''
            vm.errors = {}
            vm.show_change_password_form = !vm.show_change_password_form

        vm.save = ->
            vm.errors = {}
            vm.saving = true
            vm.user.change_password vm.oldpassword, vm.password1, vm.password2
                .then ->
                    vm.user.has_usable_password = true
                    vm.show_change_password_form = false
                    vm.show_password_changed_message = true
                .catch (response) ->
                    angular.extend(vm.errors, response.data)
                .finally ->
                    vm.saving = false

        # End UserChangePasswordController
        return


    # Handles adding/editing/removing additional email addresses.
    UserEmailsController = (UserEmailAddress, FCUserData) ->
        vm = @

        # Bindables
        vm.emails = (new UserEmailAddress(email) for email in FCUserData.emails)
        vm.addemail_errors = null
        vm.error = null
        vm.show_add_email_form = false
        vm.email_to_add = ''
        vm.adding_email = false

        # Toggle visibility of "Add additional email" form.
        vm.toggleAddForm = ->
            vm.show_add_email_form = !vm.show_add_email_form
            vm.email_to_add = ''
            vm.addemail_errors = null

        # Adds specified email.
        vm.add = (email) ->
            vm.addemail_errors = null
            vm.adding_email = true
            newemail = new UserEmailAddress
                email: email
            newemail.$save()
                .then ->
                    newemail.$send_confirmation()
                    vm.emails.push newemail
                    vm.email_to_add = ''
                    vm.toggleAddForm()
                .catch (response) ->
                    vm.addemail_errors = response.data.email
                .finally ->
                    vm.adding_email = false

        # Removes specified email.
        vm.delete = (email, index) ->
            vm.error = null
            email.deleting = true
            email.$delete()
                .then ->
                    vm.emails.splice(index, 1)
                .catch (response) ->
                    vm.error = response.data.error
                    email.deleting = false

        # Make specified email the "primary" address for this account.
        vm.make_primary = (email) ->
            vm.error = null
            email.setting_primary = true
            email.$set_primary()
                .then ->
                    # the $set_primary correctly updates the new primary address
                    # but doesn't reset the old one, so lets do it manually
                    ea.primary = false for ea in vm.emails when ea.id isnt email.id
                .catch (response) ->
                    vm.error = response.data.error
                .finally ->
                    email.setting_primary = false

        # Resends a confirmation email for this address.
        vm.send_confirmation = (email) ->
            vm.error = null
            email.sending_confirmation = true
            email.$send_confirmation()
                .catch (response) ->
                    vm.error = response.data.error
                .finally ->
                    email.sending_confirmation = false

        # End of UserEmailsController.
        return


    # Handles two-factor auth setup
    UserAuthyController = (UserProfile, FCUserData) ->
        vm = @
        vm.profile = new UserProfile(FCUserData.userprofile)
        vm.show_setup_form = vm.profile.authy_status == 'pending'
        vm.errors = {}
        vm.saving = false
        vm.token = ''

        vm.toggleSetupForm = ->
            $('.countries-input').addClass('form-control')

            vm.errors = {}
            vm.show_setup_form = !vm.show_setup_form

        vm.cancel = ->
            vm.show_setup_form = false
            vm.errors = {}

            if vm.profile.authy_status == 'pending'
                vm.profile.disable_authy()

        vm.setup = (e) ->
            e.preventDefault()

            vm.errors = {}
            vm.saving = true

            if vm.profile.authy_status == 'disabled'
                vm.profile.enable_authy(
                    $('[name="authy_country_code"]').val(),
                    $('[name="authy_phone_num"]').val()
                )
                    .catch (response) ->
                        angular.extend(vm.errors, response.data)
                    .finally ->
                        vm.saving = false

            else if vm.profile.authy_status == 'pending'
                vm.profile.validate_authy(vm.token)
                    .then ->
                        vm.show_setup_form = false
                    .catch (response) ->
                        angular.extend(vm.errors, response.data)
                    .finally ->
                        vm.saving = false
                        vm.token = ''

            else if vm.profile.authy_status == 'enabled'
                vm.profile.disable_authy(vm.token)
                    .then ->
                        vm.show_setup_form = false
                    .catch (response) ->
                        angular.extend(vm.errors, response.data)
                    .finally ->
                        vm.saving = false
                        vm.token = ''

            return false

        vm.request_sms = ->
            # Don't let them request another SMS.
            if vm.sms_sending or vm.sms_sent
                alert 'A code is already being sent to your phone number.'
                return

            vm.sms_sending = true
            vm.sms_sent = false
            vm.sms_error = false
            vm.profile.request_authy_sms()
                .then (response) ->
                    vm.sms_sent = true
                .catch (response) ->
                    vm.sms_error = true
                .finally ->
                    vm.sms_sending = false
            return

        # End of UserAuthyController
        return


    # Handles slug promo on own public profile page
    UserProfileSlugPromoController = (UserProfile, FCUserData, $window, $element) ->
        vm = @
        vm.profile = new UserProfile(FCUserData.userprofile)
        vm.show_success_message = false

        vm.save = (e) ->
            e.preventDefault()

            vm.errors = {}
            vm.saving = true

            vm.profile.$save ['slug']
                .then (response) ->
                    vm.errors = {}
                    vm.saving = false

                    vm.new_userprofile_url = "fundersclub.com/#{vm.profile.slug}"
                    vm.show_success_message = true
                    ga('send', 'event', 'public-profile', 'view', 'slug-successfully-set', vm.profile.id);
                    $window.history.pushState({}, $window.document.title, "/#{vm.profile.slug}")

                .catch (response) ->
                    angular.extend(vm.errors, response.data)
                    vm.saving = false

            return false

        vm.hide = ->
            $($element).fadeOut 300
            ga('send', 'event', 'public-profile', 'dismiss', 'x-button', vm.profile.id)
            return false

        # End of UserProfileSlugPromoController
        return


    angular
        .module 'fundersclub.users'
        .controller 'UserProfileController', UserProfileController
        .controller 'UserChangePasswordController', UserChangePasswordController
        .controller 'UserEmailsController', UserEmailsController
        .controller 'UserProfileSlugPromoController', UserProfileSlugPromoController
        .controller 'UserAuthyController', UserAuthyController
