do ->
    fcBrokerageInstructions = (BrokerageAccount, FCBrokerageAccountsData, InvestorProfileData, ConfirmationCode) ->
        thisScope =
            selectedAccount: '=?'
            distributionId: '<'
            distributionRecipient: '<'
            enableAdd: '<?'
            enableDelete: '<?'
            enableSelect: '<?'
            investorProfileData: '<'

        thisController = ($attrs, filterFilter) ->
            vm = @
            vm.accounts = _.map(FCBrokerageAccountsData.accounts, (account) -> new BrokerageAccount(account))
            vm.addFormExpanded = false
            vm.investorProfile = InvestorProfileData
            vm.selectedAccount = null

            if vm.distributionRecipient && vm.distributionRecipient.brokerage_account_id
                vm.selectedAccount = vm.accounts.find((a) => a.id == vm.distributionRecipient.brokerage_account_id)
                vm.savedAccount = vm.selectedAccount

            results = filterFilter(vm.accounts, {'id': $attrs.initialSelectedAccountId})

            vm.brokerageAccountAdded = (brokerageAccount) ->
                vm.accounts.push(brokerageAccount)
                vm.selectedAccount = brokerageAccount
                vm.addFormExpanded = false

            vm.confirm = (account) ->
                vm.errors = {}
                account.selectForDistribution(account, vm.distributionId, ConfirmationCode)
                    .then (response) ->
                        vm.savedAccount = vm.selectedAccount
                    .catch (response) ->
                        if response.data.detail
                            angular.extend(vm.errors, [response.data.detail])
                        else
                            angular.extend(vm.errors, ["An error occurred"])
            return

        thisDirective =
            scope: thisScope
            restrict: 'E'
            templateUrl: 'payments/brokerage-instructions.directive.html'
            bindToController: true
            controllerAs: 'bac'
            controller: thisController
        return thisDirective

    angular
        .module 'fundersclub.payments'
        .directive 'fcBrokerageInstructions', fcBrokerageInstructions

