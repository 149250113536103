do ->

    # Form responsible for converting a set of debt assets into equity assets.
    fcDebtConversionForm = (Company, Partnership) ->
        thisScope =
            'closeWithCancel': '&onCancel'
            'closeWithSave': '&onSave'
            'companyId': '@'
            'partnershipId': '@'

        thisController = ->
            vm = @
            vm.errors = {}
            vm.dataLoaded = false
            vm.partnership = new Partnership({id: vm.partnershipId})
            vm.selectedAssets = {}

            # Do API call to load required form data.
            vm.partnership.$get().then ->
                vm.partnership.debt_conversion_details_for_company(vm.companyId)
                    .then (response) ->
                        vm.bindCompanyData(response.data)
                    .catch (response) ->
                        # It's hard to know if there's something better to do here...
                        alert 'This form failed to load. Please refresh the page and try again'
                        console.log response

            vm.saveForm = ->
                params =
                    target_assets: (asset_id for asset_id, selected of vm.selectedAssets when selected)
                    effective_date: vm.company.effective_date
                    target_corporate_action: vm.company.target_corporate_action
                    received_common_stock: vm.company.received_common_stock
                    received_preferred_stock: vm.company.received_preferred_stock
                vm.partnership.execute_debt_conversion(params)
                    .then (e) ->
                        vm.closeWithSave()
                    .catch (response) ->
                        angular.extend(vm.errors, response.data)
                    .finally ->
                        vm.fcSave.saveAttemptFinished(vm.errors)

            vm.refreshCorporateActions = ->
                vm.partnership.debt_conversion_details_for_company(vm.companyId)
                    .then (response) ->
                        vm.bindCompanyData(response.data)
                    .catch (response) ->
                        alert 'Failed to refresh list.'
                        console.log response

            vm.bindCompanyData = (data) ->
                vm.company = data
                vm.dataLoaded = true

                # Select the first corp action if there's only one.
                if vm.company.corporate_actions.length == 1
                    vm.company.target_corporate_action = vm.company.corporate_actions[0].id


            vm.cancelForm = ->
                vm.closeWithCancel()

            return

        thisDirective =
            templateUrl: 'investment-assets/debt-conversion-add.directive.html'
            restrict: 'A'
            scope: thisScope
            bindToController: true
            controllerAs: 'fdcf'
            controller: thisController
        return thisDirective


    angular
        .module 'fundersclub.assets'
        .directive 'fcDebtConversionForm', fcDebtConversionForm
