class FcInvestorProfileResignTaxFormModal implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        modalInstance: '<',
        resolve: '<',
    };
    public controller = FcInvestorProfileResignTaxFormModalController;
    public templateUrl = 'users/investor-profile-resign-tax-form-modal.component.html';
}

class FcInvestorProfileResignTaxFormModalController implements ng.IComponentController {
    public modalInstance: ng.ui.bootstrap.IModalServiceInstance;
    public resolve: {
        investorProfile: IInvestorProfile,
        onUpdated: any,
        triggerValidateOnOpen: boolean,
        updateReason: string,
    };
    public settingUp: boolean = true;
    public objData: any;

    constructor(
        private InvestorProfile: IFcModelResource<IInvestorProfile>
    ) {
    }

    public $onInit() {
        this.resolve.investorProfile.setupTaxDocUpdate(this.resolve.updateReason).then((resp) => {
            this.objData = resp.data;
            this.settingUp = false;
        });
    }

    /* tslint:disable:no-unused-variable */
    private _onSaved(obj: any): void {
        this.resolve.onUpdated(new this.InvestorProfile(obj.investor_profile));
        this.modalInstance.close();
    }
}

angular
    .module('fundersclub.users')
    .component('fcInvestorProfileResignTaxFormModal', new FcInvestorProfileResignTaxFormModal());
