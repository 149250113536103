do ->
    FCAccomplishmentPromosManager = ->
        FCAccomplishmentPromosManager =
            pointsEarned: (points) =>
                @pointsEarnedCallback(points)
            promoCompleted: (promoType) =>
                @dismissCallback(promoType)
            onPointsEarned: (callback) =>
                @pointsEarnedCallback = callback
            onPromoCompleted: (callback) =>
                @dismissCallback = callback

        FCAccomplishmentPromosManager

    angular
        .module 'fundersclub'
        .service 'FCAccomplishmentPromosManager', FCAccomplishmentPromosManager
