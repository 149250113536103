do ->

    fcShareLink = ->
        thisScope =
            'link': '@fcShareLink'
            'tmpCopiedText': '@?copiedText'
            'showIcon': '=?showIcon'
            'trackClickAction': '@?trackClickAction'

        thisController = ($timeout, $scope, $element) ->
            vm = @
            vm.copied = false
            vm.copiedText = vm.tmpCopiedText or 'Copied!'
            if vm.showIcon != false
                vm.showIcon = true
            vm.trackClickAction = vm.trackClickAction or ''

            button = $element.find('.input-group-addon')
            button.on 'click', ->
                $scope.$apply ->
                    vm.copied = true
                $timeout(->
                    vm.copied = false
                , 1000)

            # Highlight contents of email input.
            vm.selectEmail = ($event) ->
                $event.target.select()

            return

        thisDirective =
            templateUrl: 'shared/share-link.directive.html'
            restrict: 'A'
            bindToController: true
            scope: thisScope
            controllerAs: 'slc'
            controller: thisController
        return thisDirective

    angular
        .module 'fundersclub'
        .directive 'fcShareLink', fcShareLink
