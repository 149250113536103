do ->

    fcBulkInvite = ->
        thisScope = {}

        thisController = (FCToastManager, UserInvite) ->
            vm = @
            vm.emails = ''
            vm.numBulkEmailInvites = undefined
            vm.bulkExistingEmails = undefined
            vm.isBulkInviting = false

            vm.bulkInvite = (emails) ->
                vm.numBulkEmailInvites = undefined
                vm.bulkExistingEmails = undefined

                entries = emails.split(',').map  (email) -> {email: email}
                vm.isBulkInviting = true
                UserInvite.bulk_invite(entries)
                    .then (response) ->
                        vm.numBulkEmailInvites = response.data.invited
                        vm.bulkExistingEmails = response.data.existing_emails
                        FCToastManager.checkForToasts()
                        vm.isBulkInviting = false
                        vm.emails = ''
                    .finally (response) ->
                        vm.isBulkInviting = false

            return

        thisDirective =
            bindToController: true
            controller: thisController
            controllerAs: 'bic'
            require: ['multiEmail', 'ngPluralize']
            restrict: 'E'
            #scope: thisScope
            templateUrl: 'shared/bulk-invite.directive.html'
        return thisDirective

    angular
        .module 'fundersclub'
        .directive 'fcBulkInvite', fcBulkInvite
