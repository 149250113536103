class FcCompanyAssetTrackingEditNotes implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        companyAssetTrackingData: '<',
    };
    public controller = FcCompanyAssetTrackingEditNotesController;
    public templateUrl = 'investment-assets/company-asset-tracking-edit-notes.component.html';
}

class FcCompanyAssetTrackingEditNotesController implements ng.IComponentController {
    public companyAssetTrackingData: any;

    private assetTracking: any;
    private errors: {[error: string]: any} = {};
    private fcSave: any;

    constructor(
        private CompanyAssetTracking: any
    ) {}

    public $onInit() {
        this.assetTracking = new this.CompanyAssetTracking(this.companyAssetTrackingData);
    }

    /* tslint:disable:no-unused-variable */
    private save() {
        this.errors = {};
        this.assetTracking
            .$save(['notes'])
            .then((response: any) => {
                // Empty on purpose
            })
            .catch((response: any) => {
                angular.extend(this.errors, response.data);
            })
            .finally(() => this.fcSave.saveAttemptFinished(this.errors));
    }
}

angular
    .module('fundersclub.assets')
    .component('fcCompanyAssetTrackingEditNotes', new FcCompanyAssetTrackingEditNotes());
