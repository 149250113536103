do ->

    Partnership = ($http, fcModel, transformFormRequestToFormData) ->
        baseUrl = '/api/partnerships/:id/'
        params =
            id: '@id'

        methods =
            partialupdate:
                method: 'PATCH'
                transformRequest: transformFormRequestToFormData
                headers:
                    'Content-type': undefined

        Partnership = fcModel baseUrl, params, methods

        # Support custom endpoints.
        Partnership.prototype.available_actions_for_company = (company_id=0) ->
            $http
                method: 'GET'
                url: @urls.available_actions_for_company + "?company_id=#{company_id}"

        Partnership.prototype.debt_conversion_details_for_company = (company_id=0) ->
            $http
                method: 'GET'
                url: @urls.debt_conversion_details_for_company + "?company_id=#{company_id}"

        Partnership.prototype.execute_debt_conversion = (params) ->
            $http
                method: 'PATCH'
                url: @urls.execute_debt_conversion
                data: params

        Partnership.prototype.new_priced_round_details_for_company = (company_id=0) ->
            $http
                method: 'GET'
                url: @urls.new_priced_round_details_for_company + "?company_id=#{company_id}"

        Partnership.prototype.execute_new_priced_round = (params) ->
            $http
                method: 'PATCH'
                url: @urls.execute_new_priced_round
                data: params

        Partnership.prototype.stock_split_details_for_company = (company_id=0) ->
            $http
                method: 'GET'
                url: @urls.stock_split_details_for_company + "?company_id=#{company_id}"

        Partnership.prototype.execute_stock_split = (params) ->
            $http
                method: 'PATCH'
                url: @urls.execute_stock_split
                data: params

        Partnership.prototype.new_cash_asset_details_for_company = (company_id=0) ->
            $http
                method: 'GET'
                url: @urls.new_cash_asset_details_for_company + "?company_id=#{company_id}"

        Partnership.prototype.execute_new_cash_asset = (params) ->
            $http
                method: 'PATCH'
                url: @urls.execute_new_cash_asset
                data: params

        Partnership.prototype.secondary_sell_details_for_company = (company_id=0) ->
            $http
                method: 'GET'
                url: @urls.secondary_sell_details_for_company + "?company_id=#{company_id}"

        Partnership.prototype.execute_secondary_sell = (params) ->
            $http
                method: 'PATCH'
                url: @urls.execute_secondary_sell
                data: params

        Partnership.prototype.crypto_sell_details_for_company = (company_id=0) ->
            $http
                method: 'GET'
                url: @urls.crypto_sell_details_for_company + "?company_id=#{company_id}"

        Partnership.prototype.execute_crypto_sell = (params) ->
            $http
                method: 'PATCH'
                url: @urls.execute_crypto_sell
                data: params

        Partnership.prototype.stock_sell_details_for_company = (company_id=0) ->
            $http
                method: 'GET'
                url: @urls.stock_sell_details_for_company + "?company_id=#{company_id}"

        Partnership.prototype.execute_stock_sell = (params) ->
            $http
                method: 'PATCH'
                url: @urls.execute_stock_sell
                data: params

        Partnership.prototype.ipo_details_for_company = (company_id=0) ->
            $http
                method: 'GET'
                url: @urls.ipo_details_for_company + "?company_id=#{company_id}"

        Partnership.prototype.execute_ipo = (params) ->
            $http
                method: 'PATCH'
                url: @urls.execute_ipo
                data: params

        Partnership.prototype.markdown_details_for_company = (company_id=0) ->
            $http
                method: 'GET'
                url: @urls.markdown_details_for_company + "?company_id=#{company_id}"

        Partnership.prototype.execute_markdown = (params) ->
            $http
                method: 'PATCH'
                url: @urls.execute_markdown
                data: params

        Partnership.prototype.reveal_markdown_details_for_company = (company_id=0) ->
            $http
                method: 'GET'
                url: @urls.reveal_markdown_details_for_company + "?company_id=#{company_id}"

        Partnership.prototype.execute_reveal_markdown = (params) ->
            $http
                method: 'PATCH'
                url: @urls.execute_reveal_markdown
                data: params

        Partnership.prototype.dissolution_details_for_company = (company_id=0) ->
            $http
                method: 'GET'
                url: @urls.dissolution_details_for_company + "?company_id=#{company_id}"

        Partnership.prototype.execute_dissolution = (params) ->
            $http
                method: 'PATCH'
                url: @urls.execute_dissolution
                data: params

        Partnership.prototype.acquisition_details_for_company = (company_id=0) ->
            $http
                method: 'GET'
                url: @urls.acquisition_details_for_company + "?company_id=#{company_id}"

        Partnership.prototype.position_closed_get = (company_id=0) ->
            $http
                method: 'GET'
                url: @urls.position_closed + "?company_id=#{company_id}"

        Partnership.prototype.position_closed_post = (params, company_id=0) ->
            $http
                method: 'POST'
                url: @urls.position_closed + "?company_id=#{company_id}"
                data: params

        Partnership.prototype.execute_acquisition = (params) ->
            $http
                method: 'PATCH'
                url: @urls.execute_acquisition
                data: params

        Partnership.prototype.follow = ->
            $http
                method: 'POST'
                url: "/api/partnerships/#{@id}/follow/"

        Partnership.prototype.unfollow = ->
            $http
                method: 'POST'
                url: "/api/partnerships/#{@id}/unfollow/"

        Partnership.prototype.convert_share_type_get = (company_id=0) ->
            $http
                method: 'GET'
                url: @urls.convert_share_type + "?company_id=#{company_id}"

        Partnership.prototype.convert_share_type_post = (params, company_id=0) ->
            $http
                method: 'POST'
                url: @urls.convert_share_type + "?company_id=#{company_id}"
                data: params

        Partnership


    angular
        .module 'fundersclub.models'
        .factory 'Partnership', Partnership
