do ->
    # Form responsible for displaying and editing fund lifecycle


    fcFundLifecycleEdit = (Fund, FCFundData) ->
        thisScope =
            'fund': '=fcFundLifecycleEdit'

        thisController = ($scope, $attrs) ->
            vm = @

            vm.coinvestorEmailSendDates = FCFundData.coinvestor_email_send_dates
            vm.num_auto_investors = FCFundData.num_auto_investors
            vm.fund = new Fund(FCFundData.fund)
            vm.followon_funds_display = ''

            vm.buildFollowOnData = ->
                if vm.fund.followon_funds
                    vm.followon_funds_display = vm.fund.followon_funds.map((fund) -> fund.name)
                    if not vm.fund.followon_funds_ownership_percentages.length
                        vm.fund.followon_funds_ownership_percentages = vm.fund.followon_funds
                            .map((fund) -> {
                                'name': fund.name
                                'ownership_percentage': 0
                                'slug': fund.slug
                            })

            vm.save = ->
                vm.errors = {}
                vm.warnings = []

                if vm.fund.can_automatically_calculate_prorata_amounts
                    vm.fund.followon_funds_ownership_percentages = []

                fields_to_save = [
                    'early_access_password',
                    'panel_review_start_date',
                    'final_diligence_date',
                    'hide_from_browse_page',
                    'is_followon',
                    'is_existing_member_followon_only',
                    'followon_end_date',
                    'followon_funds_ownership_percentages',
                    'followon_start_date',
                    'send_12h_followon_reminder_email',
                    'send_one_week_existing_member_followon_reminder_email',
                    'create_reservations_for_auto_invest',
                    'early_access_status_date',
                    'early_access_fcvc_email_date',
                    'early_access_panel_email_date',
                    'early_access_watchers_email_date',
                    'start_date',
                    'end_date',
                    'closing_date',
                    'founder_closing_date',
                    'ach_off_date',
                    'first_coinvestors_email_date',
                    'second_coinvestors_email_date',
                    'early_access_password',
                    'send_12h_reminder_email',
                    'preview_start_date',
                    'accelerated_closing',
                ]

                fields_to_save = _.filter(fields_to_save, (elt) ->
                    # We always want create_reservations_for_auto_invest to get sent so that the serializer is forced
                    # to validate it
                    return elt == 'create_reservations_for_auto_invest' or (elt in vm.fund) or vm.fund[elt]? or vm.fund[elt]
                )

                if (
                    ((vm.fund.panel_review_start_date? and vm.fund.panel_review_start_date.length) or (vm.fund.final_diligence_date? and vm.fund.final_diligence_date.length)) and
                    not (vm.fund.panel_review_start_date and vm.fund.final_diligence_date)
                )
                    vm.warnings.push 'If either a Panel Start Date or Panel End Date are set, both should be set.'

                if vm.fund.early_access_panel_email_date? and vm.fund.early_access_watchers_email_date?
                    early_access_panel_dt = moment(vm.fund.early_access_panel_email_date)
                    early_access_watcher_dt = moment(vm.fund.early_access_watchers_email_date)

                    delta = Math.abs(early_access_panel_dt.diff(early_access_watcher_dt, 'hours'))
                    if delta < 12
                        vm.warnings.push 'The Early Access password should be sent to Panel members more than 12 hours before it is sent to Watchers.'

                if vm.fund.ach_off_date? and vm.fund.end_date?
                    if moment(vm.fund.ach_off_date).diff(moment(vm.fund.end_date)) < 0
                        vm.warnings.push 'ACH is set to be disabled before the end of the Live period.'

                if vm.fund.founder_closing_date? and vm.fund.closing_date?
                    if moment(vm.fund.founder_closing_date).diff(moment(vm.fund.closing_date)) < 0
                        vm.warnings.push 'Founder closing date must be later than investor closing date'

                if vm.fund.closing_date? and vm.fund.ach_off_date?
                    if moment(vm.fund.closing_date).diff(moment(vm.fund.ach_off_date), 'days') < -5
                        vm.warnings.push 'ACH should be disabled at least 5 days before the Target first closing.'

                if vm.fund.ach_off_date? and moment(vm.fund.ach_off_date).diff(moment(), 'seconds') < 0
                    vm.warnings.push 'ACH can\'t be set to disable at a point in the past.'

                vm.fund.$save(fields_to_save)
                    .then (response) ->
                        vm.fund.followon_funds = response.followon_funds
                        vm.fund.followon_investors = response.followon_investors
                        vm.buildFollowOnData()
                    .catch (response) ->
                        angular.extend(vm.errors, response.data)
                    .finally () ->
                        vm.fcSave.saveAttemptFinished(vm.errors)

            vm.shouldShowWarnings = ->
                return angular.equals(vm.errors, {}) and if vm.warnings? then vm.warnings.length > 0 else false

            vm.totalFollowonFundsOwnershipPercentages = ->
                _.reduce(
                    vm.fund.followon_funds_ownership_percentages,
                    (total, ownershipData) -> total + ownershipData.ownership_percentage,
                    0
                )

            vm.setPreviewStartToNow = ->
                vm.fund.preview_start_date = moment().format('YYYY-MM-DDTHH:mm')

            vm.setDatesFromPreviewStart = ->
                if not vm.fund.preview_start_date
                    alert 'Cannot set default dates without a Preview Start on date'
                    return

                preview_start = moment(vm.fund.preview_start_date)
                vm.fund.early_access_status_date = preview_start.clone().add('minute', 1).format('YYYY-MM-DDTHH:mm')
                vm.fund.early_access_panel_email_date = preview_start.clone().add('minute', 2).format('YYYY-MM-DDTHH:mm')
                vm.fund.early_access_watchers_email_date = preview_start.clone().add('day', 1).set('hour', 9).set('minute', 0).format('YYYY-MM-DDTHH:mm')
                vm.fund.start_date = preview_start.clone().add('day', 7).set('hour', 7).set('minute', 0).format('YYYY-MM-DDTHH:mm')
                vm.fund.end_date = preview_start.clone().add('day', 28).set('hour', 23).set('minute', 59).format('YYYY-MM-DDTHH:mm')
                vm.fund.first_coinvestors_email_date = preview_start.clone().add('day', 14).set('hour', 9).set('minute', 0).format('YYYY-MM-DDTHH:mm')
                vm.fund.second_coinvestors_email_date = preview_start.clone().add('day', 21).set('hour', 9).set('minute', 0).format('YYYY-MM-DDTHH:mm')
                vm.fund.closing_date = preview_start.clone().add('day', 35).set('hour', 23).set('minute', 59).format('YYYY-MM-DDTHH:mm')
                vm.fund.ach_off_date = preview_start.clone().add('day', 28).set('hour', 16).set('minute', 0).format('YYYY-MM-DDTHH:mm')


            vm.buildFollowOnData()
            return

        thisDirective =
            templateUrl: 'funds/lifecycle-edit.directive.html'
            restrict: 'A'
            require: '^form'
            scope: thisScope
            bindToController: true
            controllerAs: 'flce'
            controller: thisController
            replace: true

        return thisDirective

    angular
        .module 'fundersclub.funds'
        .directive 'fcFundLifecycleEdit', fcFundLifecycleEdit
