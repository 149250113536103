do ->
    fcUserProfileDetailsEdit = (User, FCUserData, FCToastManager, FCAccomplishmentPromosManager) ->
        thisScope =
            'user': '=fcUserProfileDetailsEdit'
            'profile': '=fcUserProfile'
        thisController = ->
            vm = @

            vm.save = ->
                vm.errors = {}
                fields_to_save = [
                    'first_name', 'last_name', 'profile.bio', 'profile.city',
                    'profile.employer', 'profile.title', 'profile.phone_number',
                    'profile.profile_visible',
                ]
                vm.user.$save fields_to_save
                    .then (response) ->
                        FCToastManager.checkForToasts()
                        # Conditionally grant the user an accomplishment.
                        if vm.user.profile.bio
                            FCAccomplishmentPromosManager.promoCompleted('fill-out-bio')

                        angular.copy vm.user.profile, vm.profile
                    .catch (response) ->
                        angular.extend(vm.errors, response.data)
                    .finally () ->
                        vm.fcSave.saveAttemptFinished(vm.errors)
            return

        thisDirective =
            restrict: 'A'
            scope: thisScope
            templateUrl: 'users/profile-details-edit.directive.html'
            controllerAs: 'upe'
            bindToController: true
            controller: thisController

        return thisDirective

    angular
        .module 'fundersclub.users'
        .directive 'fcUserProfileDetailsEdit', fcUserProfileDetailsEdit
