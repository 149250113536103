class FcFounderOnboardUpdatesSettings implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        company: '<',
    };
    public controller = FcFounderOnboardUpdatesSettingsController;
    public templateUrl = 'founder-onboarding/updates-settings.component.html';
}

class FcFounderOnboardUpdatesSettingsController implements ng.IComponentController {
    public company: any;

    private errors: any = {};
    private fcSave: any;

    constructor(private $window: ng.IWindowService) {}

    public $onInit() {
        this.company = angular.copy(this.company);
    }

    /* tslint:disable:no-unused-variable */
    private finish() {
        this.errors = {};
        this.company
            .updateUpdatesSettings()
            .then((companyResponse: any) => {
                this.company
                    .markFounderOnboarded()
                    .then((response: any) => this.$window.location.href = response.data.url);
            })
            .catch((response: any) => {
                this.fcSave.saveAttemptFinished(this.errors);
                angular.extend(this.errors, response.data);
            });
    }
}

angular
    .module('fundersclub.founderOnboarding')
    .component('fcFounderOnboardUpdatesSettings', new FcFounderOnboardUpdatesSettings());
