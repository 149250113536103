class FcFundKpiList implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        fundKpisData: '<',
    };
    public controller = FcFundKpiListController;
    public templateUrl = 'funds/fund-kpi-list.component.html';
}

class FcFundKpiListController implements ng.IComponentController {
    public fundKpisData: Array<any>;

    private dragControlListeners: any = {};
    private fundKpis: Array<any>;
    private fundKpiEditFormsExpanded: any = {};
    private savedFundKpisToUpdate: Array<any> = [];

    constructor(
        private _: UnderscoreStatic,
        private FCChoices: any,
        private FCGlobals: any,
        private FundKPI: any
    ) {}

    public $onInit() {
        this.fundKpis = this.fundKpisData.map((fundKpi) => new this.FundKPI(fundKpi));
        this.dragControlListeners = {
            orderChanged: (event: any) => {
                this.fundKpis.map((fundKpi: any, index: number) => {
                    fundKpi.order = index + 1;
                    fundKpi.$save('order');
                });
            },
        };
    }

    /* tslint:disable:no-unused-variable */
    private onFundKpiHiddenChanged(fundKpi: any) {
        fundKpi.$save(['hidden']);
        if (fundKpi.hidden && this.fundKpiEditFormsExpanded[fundKpi.id]) {
            this.fundKpiEditFormsExpanded[fundKpi.id] = false;
        } else if (!fundKpi.hidden && !this.fundKpiEditFormsExpanded[fundKpi.id]) {
            this.fundKpiEditFormsExpanded[fundKpi.id] = true;
        }
    }

    private onFundKpiSaved(fundKpi: any) {
        // Add to array of fund investors that will updated in the future so that
        // the edit form has a chance to collapse correctly
        this.savedFundKpisToUpdate.push(fundKpi);
        this.fundKpiEditFormsExpanded[fundKpi.id] = false;
    }

    /* tslint:disable:no-unused-variable */
    private updateSavedFundKpis() {
        this.savedFundKpisToUpdate.map((fundKpi: any) => {
            let index: number = this._.findIndex(this.fundKpis, (tmpFundKpi: any) => {
                return fundKpi.id === tmpFundKpi.id;
            });
            this.fundKpis[index] = fundKpi;
        });
    }
}

angular
    .module('fundersclub.companies')
    .component('fcFundKpiList', new FcFundKpiList());
