class FcStartupSummariesMain implements ng.IComponentOptions {
    public controller = FcStartupSummariesMainController;
    public templateUrl = 'startup-summaries/startup-summaries-main.component.html';
}

class FcStartupSummariesMainController implements ng.IComponentController {
    public startups: Array<any> = [];
    public filteredStartups: Array<any> = [];
    public loading: boolean = false;
    public error: string = '';
    public selectedBatch: any = { label: 'W24', value: 'W24' };  // TODO - Allow user to change this
    public batches: Array<any> = [
        { label: 'W24', value: 'W24' },
        { label: 'S23', value: 'S23' },
        { label: 'W23', value: 'W23' },
        { label: 'W22', value: 'W22' },
        { label: 'S22', value: 'S22' },
    ];
    public hasGithubLinkFilter: boolean = true;
    public sortByField: string = 'total_github_stars';
    public sortDirection: string = 'desc';
    public topStars: Array<any> = [];
    public topStarsPerWeek: Array<any> = [];
    public topDaysSinceStart: Array<any> = [];
    public refreshInfo: string = '';
    public search: string = '';

    constructor (
        private $http: ng.IHttpService
    ) {}

    public $onInit(): void {
        this.loadStartups();
    }

    public getChart(startup: any): any {
        let series: Array<any> = startup.github_star_history.map((gh: any) => [new Date(gh[1]), Number(gh[0])]);
        const chartWidth = 150;
        const chartHeight = 100;

        // Normalize the dates and numbers
        const minDate = Math.min(...series.map((data: any) => data[0]));
        const maxDate = Math.max(...series.map((data: any) => data[0]));
        const minNumber = Math.min(...series.map((data: any) => data[1]));
        const maxNumber = Math.max(...series.map((data: any) => data[1]));

        series = series.map((data: any) => [
            (data[0] - minDate) / (maxDate - minDate) * chartWidth, // Normalize the date
            (data[1] - minNumber) / (maxNumber - minNumber) * chartHeight, // Normalize the number
        ]);

        // Create the SVG content for the chart
        let chart = '';
        for (let i = 0; i < series.length - 1; i++) {
            let x1 = series[i][0] + 3;
            let y1 = chartHeight - series[i][1] + 3;
            let x2 = series[i + 1][0] + 3;
            let y2 = chartHeight - series[i + 1][1] + 3;

            // Add the line to the chart
            chart += `<line x1="${x1}" y1="${y1}" x2="${x2}" y2="${y2}"
                stroke="crimson" stroke-linecap="round" stroke-width="5"/>`;
        }
        return chart;
    }

    public loadStartups(): void {
        console.log('Loading startups');
        this.loading = true;
        this.error = '';

        this.$http.get(
            '/api/startups/?batch=' + this.selectedBatch.value
            ).then(
                (data: any) => {
                    console.log(data);
                    this.startups = data.data;

                    for (let startup of this.startups) {
                        if (startup.github_star_history && startup.github_star_history.length > 0) {
                            startup.ghStarsChart = this.getChart(startup);
                        }
                    }

                    this.filterStartups();
                }
            ).catch(
                (error: any) => {
                    console.error(error.data);
                    if (error.data.hasOwnProperty('error')) {
                        this.error = error.data.error;
                    } else {
                        this.error = 'An error occurred';
                    }
                }
            ).finally(
                () => {
                    this.loading = false;
                }
            );
    }

    public refreshData(): void {
        this.$http.post(
            '/api/startups/refresh/',
            { 'batch': this.selectedBatch.value }
            ).then(
                (data: any) => {
                    this.refreshInfo = 'Data is being refreshed. Please wait a few minutes and refresh the page.';
                }
            ).catch(
                (error: any) => {
                    console.error(error.data);
                    if (error.data.hasOwnProperty('error')) {
                        this.error = error.data.error;
                    } else {
                        this.error = 'An error occurred';
                    }
                }
            );
    }

    public getStats(): void {
        // Calculate the top 3 startups by total github stars and most stars per week
        let sortedByStars = this.filteredStartups.slice().sort(
            (a: any, b: any) => b.total_github_stars - a.total_github_stars
        );
        let sortedByStarsPerWeek = this.filteredStartups.slice().sort(
            (a: any, b: any) => b.github_stars_per_week - a.github_stars_per_week
        );
        let sortedByDaysSinceStart = this.filteredStartups.slice().sort(
            (a: any, b: any) => a.days_since_start - b.days_since_start
        );
        this.topStars = sortedByStars.slice(0, 5);
        this.topStarsPerWeek = sortedByStarsPerWeek.slice(0, 5);
        this.topDaysSinceStart = sortedByDaysSinceStart.slice(0, 5);
    }

    public filterStartups(): void {
        // Filter by search
        let filteredStartups = this.startups.slice();
        let search = (this.search || '').toLowerCase();
        if (search) {
            let searchWords = search.split(' ');
            filteredStartups = this.startups.filter((startup: any) => {
                for (let word of searchWords) {
                    if (startup.name.toLowerCase().indexOf(word) === -1) {
                        return false;
                    }
                }
                return true;
            });
        }

        // Filter by hasGithubLinkFilter
        filteredStartups = filteredStartups.filter((startup: any) => {
            if (this.hasGithubLinkFilter && !startup.github_link) {
                return false;
            }
            return true;
        });

        // Filter by batch
        if (this.selectedBatch.value !== 'All') {
            filteredStartups = filteredStartups.filter((startup: any) => {
                return startup.batch_display_name === this.selectedBatch.value;
            });
        }

        this.filteredStartups = filteredStartups;
        this.sortBy(this.sortByField);
        this.getStats();
    }

    public clickSort(field: string): void {
        if (this.sortByField === field) {
            this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
        }
        this.sortByField = field;
        this.sortBy(this.sortByField);
    }

    public sortBy(field: string): void {
        this.filteredStartups.sort((a: any, b: any) => {
            if (this.sortDirection === 'desc') {
                let temp = a;
                a = b;
                b = temp;
            }
            // Handle strings
            if (typeof a[field] === 'string') {
                return a[field].localeCompare(b[field]);
            }
            if (a[field] < b[field]) {
                return -1;
            } else if (a[field] > b[field]) {
                return 1;
            } else {
                return 0;
            }
        });
        this.sortByField = field;
    }

}

angular
    .module('fundersclub.startup-summaries')
    .component('fcStartupSummariesMain', new FcStartupSummariesMain());
