class FcFounderOnboardUserDetails implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        founder: '<',
        nextState: '<',
        onFounderUpdated: '&',
        onUserUpdated: '&',
        user: '<',
    };
    public controller = FcFounderOnboardUserDetailsController;
    public templateUrl = 'founder-onboarding/user-details.component.html';
}

class FcFounderOnboardUserDetailsController implements ng.IComponentController {
    public founder: any;
    public nextState: string;
    public onFounderUpdated: (data: {founder: any}) => void;
    public onUserUpdated: (data: {user: any}) => void;
    public user: any;

    private errors: any = {};
    private fcSave: any;

    constructor(private $state: any, private CompanyTeamMember: any, private User: any) {}

    public $onInit() {
        this.founder = angular.copy(this.founder);
        this.user = angular.copy(this.user);
    }

    /* tslint:disable:no-unused-variable */
    private next() {
        this.errors = {};
        this.founder.$save()
            .then((founderResponse: any) => {
                this.onFounderUpdated({founder: new this.CompanyTeamMember(founderResponse)});
                this.user.first_name = this.founder.first_name;
                this.user.last_name = this.founder.last_name;
                this.user.profile.phone_number = this.founder.phone_number;
                this.user
                    .$save()
                    .then((userResponse: any) => {
                        this.onUserUpdated({user: new this.User(userResponse)});
                        this.fcSave.saveAttemptFinished(this.errors);
                        this.$state.go(this.nextState);
                    });
            })
            .catch((response: any) => {
                angular.extend(this.errors, response.data);
                this.fcSave.saveAttemptFinished(this.errors);
            });
    }
}

angular
    .module('fundersclub.founderOnboarding')
    .component('fcFounderOnboardUserDetails', new FcFounderOnboardUserDetails());
