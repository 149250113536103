class FcFounderOnboardCompanyDetails implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        company: '<',
        nextState: '<',
        onUpdated: '&',
    };
    public controller = FcFounderOnboardCompanyDetailsController;
    public templateUrl = 'founder-onboarding/company-details.component.html';
}

class FcFounderOnboardCompanyDetailsController implements ng.IComponentController {
    public company: any;
    public nextState: string;
    public onUpdated: (data: {company: any}) => void;

    private defaultLogo: string;
    private errors: any = {};
    private fcSave: any;
    private logoDisplay: string;

    constructor(
        private $state: any,
        private $uibModal: ng.ui.bootstrap.IModalService,
        private $window: ng.IWindowService,
        private Company: any,
        private FCFounderOnboardData: any,
        private FCGlobals: any
    ) {}

    public $onInit() {
        this.company = angular.copy(this.company);
        this.defaultLogo = this.FCFounderOnboardData.urls.DEFAULT_COMPANY_LOGO;

        if (this.company.company_logo) {
            this.logoDisplay = this.FCGlobals.MEDIA_URL + this.company.company_logo;
        } else {
            this.logoDisplay = this.defaultLogo;
        }
    }

    /* tslint:disable:no-unused-variable */
    private cropImage($files: Array<any>) {
        this.$uibModal.open({
            animation: false,
            backdrop: false,
            component: 'FcImageCropperModal',
            resolve: {
                files: () => $files,
                onCropped: () => this.onImageCropped.bind(this),
            },
            windowClass: 'FullTakeoverModal',
        });
    }

    /* tslint:disable:no-unused-variable */
    private next() {
        this.errors = {};
        const fieldsToSave = [
            'company_location',
            'company_logo',
            'company_name',
            'company_url',
        ];

        if (this.company.funded) {
            fieldsToSave.push('public_blurb');
        }

        this.company
            .$save(fieldsToSave)
            .then((companyResponse: any) => {
                this.onUpdated({company: new this.Company(companyResponse)});
                // If this user hasnt been funded yet this is the end of their onboarding
                if (!this.company.funded) {
                    this.company
                        .markFounderOnboarded()
                        .then((response: any) => this.$window.location.href = response.data.url);
                } else {
                    this.fcSave.saveAttemptFinished(this.errors);
                    this.$state.go(this.nextState);
                }
            })
            .catch((response: any) => {
                this.fcSave.saveAttemptFinished(this.errors);
                angular.extend(this.errors, response.data);
            });
    }

    private onImageCropped(croppedImage: any, croppedImageDisplay: any) {
        this.company.company_logo = croppedImage;
        this.logoDisplay = croppedImageDisplay;
    }

    /* tslint:disable:no-unused-variable */
    private removeLogo(): void {
        this.logoDisplay = this.defaultLogo;
        this.company.company_logo = '';
    }
}

angular
    .module('fundersclub.founderOnboarding')
    .component('fcFounderOnboardCompanyDetails', new FcFounderOnboardCompanyDetails());
