class FcInvestorProfileTaxBoundFormW8ben implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        errors: '<',
        investorProfile: '=',
        investorProfileTaxDoc: '=',
        readonlyAll: '<',
        readonlyName: '<',

    };
    public controller = FcInvestorProfileTaxBoundFormW8benController;
    public templateUrl = 'users/investor-profile-tax-bound-form-w8ben.component.html';
}

class FcInvestorProfileTaxBoundFormW8benController implements ng.IComponentController {
    public errors: {[error: string]: Array<any>} = {};
    public investorProfile: IInvestorProfile;
    public investorProfileTaxDoc: IInvestorProfileTaxDoc;
    public readonlyAll: boolean;
    public readonlyName: boolean;

    constructor(private FCChoices: any) {}

}

angular
    .module('fundersclub.users')
    .component('fcInvestorProfileTaxBoundFormW8ben', new FcInvestorProfileTaxBoundFormW8ben());
