do ->
    fcResendDistributionVerificationCode = ($http) ->

        thisController = ($attrs) ->
            vm = @
            vm.code_resent = false
            vm.resend_code_url = $attrs.distributionUrl

            vm.resendVerificationCode = ->
                if not vm.code_resent
                    $http.post(vm.resend_code_url, '').then ->
                        vm.code_resent = true
            return

        thisDirective =
            restrict: 'A'
            bindToController: true
            templateUrl: 'users/dist-verification-code.directive.html'
            controllerAs: 'rdvc'
            controller: thisController

        return thisDirective

    angular
        .module 'fundersclub.users'
        .directive 'fcResendDistributionVerificationCode', fcResendDistributionVerificationCode
