class FcInvestmentAssetListItem implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        asset: '<',
        editFormEnabled: '<',
        onCollapse: '&',
        onDelete: '&',
        onExpand: '&',
        startExpanded: '=',
    };
    public controller = FcInvestmentAssetListItemController;
    public templateUrl = 'investment-assets/investment-asset-list-item.component.html';
}

class FcInvestmentAssetListItemController implements ng.IComponentController {
    public asset: any;
    public editFormEnabled: boolean;
    public onCollapse: () => void;
    public onDelete: () => void;
    public onExpand: () => void;
    public startExpanded: boolean;

    private editFormExpanded = false;
    private editFormPristine = true;

    constructor(
        private InvestmentTerms: any
    ) {
        if (this.startExpanded) {
            this.expandEditForm(true);
        }
    }

    public $onInit() {
        this.asset.terms = new this.InvestmentTerms(this.asset.terms);
    }

    /* tslint:disable:no-unused-variable */
    private deleteAsset() {
        let remove: boolean = confirm('Are you sure you want to delete this asset?');
        if (remove) {
            this.asset.$delete().then(() => this.onDelete());
        }
    }

    private expandEditForm(expandForm: boolean) {
        // Expand the editing form for this asset.
        let currentlyExpanded: boolean = this.editFormExpanded;
        let becomingExpanded: boolean;
        if (typeof expandForm === 'boolean') {
            becomingExpanded = expandForm;
        } else {
            becomingExpanded = !currentlyExpanded;
        }

        // Don't allow user to collapse form unless they've dealt with their
        // changes (e.g. Save or Cancel).
        if (!becomingExpanded && !this.editFormPristine) {
            return;
        }

        // If a expansion callback was provided, fire it off.
        this.editFormExpanded = becomingExpanded;
        if (becomingExpanded) {
            this.onExpand();
        } else {
            this.onCollapse();
        }
    }

    private onAssetEditingCanceled() {
        this.expandEditForm(false);
    }

    private onAssetFormPristineUpdated(pristine: boolean) {
        this.editFormPristine = pristine;
    }

    private onAssetSaved(asset: any) {
        this.asset = asset;
    }
}

angular
    .module('fundersclub.assets')
    .component('fcInvestmentAssetListItem', new FcInvestmentAssetListItem());
