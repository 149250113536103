class FcFundOperationsPreviewLegaldoc implements ng.IComponentOptions {
     public bindings: {[binding: string]: string} = {
        docId: '<',
    };
    public controller = FcFundOperationsPreviewLegaldocController;
    public templateUrl = 'funds/operations-preview-legaldoc.component.html';
}

class FcFundOperationsPreviewLegaldocController implements ng.IComponentController {
    public docId: any;

    private error: boolean = false;
    private generating: boolean = false;
    private previewUrl: string = null;

    constructor(
        private $http: ng.IHttpService,
        private $httpParamSerializer: ng.IHttpParamSerializer,
        private $window: ng.IWindowService
    ) {}

    /* tslint:disable:no-unused-variable */
    private generate(): void {
        this.generating = true;
        this.$http({
            data: this.$httpParamSerializer({
                action: 'legal-doc-preview',
                doc_template: this.docId,
            }),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            method: 'POST',
            url: this.$window.location.href,
        }).success((data: any) => {
            this.generating = false;
            this.previewUrl = data.signing_url;
        }).catch(() => {
            this.generating = false;
            this.error = true;
        });
    }
}

angular
    .module('fundersclub.funds')
    .component('fcFundOperationsPreviewLegaldoc', new FcFundOperationsPreviewLegaldoc());
