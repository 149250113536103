class FcSimSelectStrategy implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        onSelect: '&',
    };
    public controller = FcSimSelectStrategyController;
    public templateUrl = 'strategy-simulator/sim-select-strategy.component.html';
}

class FcSimSelectStrategyController implements ng.IComponentController {

    public rounds: Array<number> = [
        100,
        0,
        0,
        0,
    ];
    public onSelect: (data: {selectedStrategy: Array<number>}) => void;
    public roundTotal: number = 100;

    constructor (
        private $http: ng.IHttpService
    ) {}

    public $onInit(): void {
        this.onSelect({'selectedStrategy': this.rounds});
    }

    public addRound(): void {
        this.rounds.push(0);
    }

    public updateStrategy(index: number, percent: number): void {
        this.rounds[index] = percent;
        this.roundTotal = this.rounds.reduce((a, b) => a + b, 0);
        if (this.roundTotal === 100) {
            this.onSelect({'selectedStrategy': this.rounds});
        } else {
            this.onSelect({'selectedStrategy': []});
        }
    }

    public reset(): void {
        this.rounds = [100, 0, 0, 0];
        this.roundTotal = 100;
        this.onSelect({'selectedStrategy': this.rounds});
    }
}

angular
    .module('fundersclub.strategy-simulator')
    .component('fcSimSelectStrategy', new FcSimSelectStrategy());
