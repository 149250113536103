do ->

    # Uploads images within rich text areas.
    fcUploadManager = (Upload, $q, $window) ->
        placeholderEl = $('<i class="fa fa-cog fa-2x fa-spin u-tC-gray"></i>')
        imgEl = (src) -> $("<img class='img-responsive inline-image' src='#{src}'>")

        fcUploadManager =
            uploadImage: (file, $edtiorEl) ->
                deferred = $q.defer()

                # When pasting wont have access to $editor, so will have to get selection the old fashioned way
                selection = $window.getSelection()
                if selection.getRangeAt and selection.rangeCount
                    range = selection.getRangeAt(0)
                    range.deleteContents()
                    range.insertNode(placeholderEl[0])

                Upload
                    .upload
                        url: '/uploadImage/'
                        data:
                            'image': file
                    .then (response) ->
                        placeholderEl.replaceWith(imgEl(response.data))
                    # Error callback
                    , (response) ->
                        placeholderEl.remove()
                        $window.alert response.data.non_field_errors
                    .finally ->
                        deferred.resolve()

                deferred.promise

        fcUploadManager


    # Get module and add factories.
    angular
        .module 'fundersclub.forms'
        .factory 'fcUploadManager', fcUploadManager
