class FcVerifyAccreditationStatusForm implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        formDownloadUrl: '<',
        userAccreditationVerification: '<',
    };
    public controller = FcVerifyAccreditationStatusFormController;
    public templateUrl = 'users/verify-accreditation-status-form.component.html';
}

class FcVerifyAccreditationStatusFormController implements ng.IComponentController {
    public formDownloadUrl: string;
    public userAccreditationVerification: any;

    private copied: boolean = false;
    private deleting: boolean = false;

    constructor(
        private FCChoices: any,
        private Upload: any,
        private $window: ng.IWindowService,
        private $http: ng.IHttpService,
        private $timeout: ng.ITimeoutService
    ) {}

    public $onInit(): void {
        this.maybePoll();
    }

    public selectLink($event: any): void {
        $event.target.select();
    }

    public copySuccess(): void {
        this.copied = true;
        this.$timeout(() => { this.copied = false; }, 1000);
    }

    public linkReady(): boolean {
        return this.userAccreditationVerification.doc_url.length;
    }

    public deleteForm() {
        this.deleting = true;

        this.$http.post(this.$window.location.href, {
            action: 'delete-form',
        }).then((resp: any) => {
            this.userAccreditationVerification = resp.data;
            this.deleting = false;
        });
    }

    public notInterested() {
        this.$http.post(this.$window.location.href, {
            action: 'not-interested',
        }).then((resp: any) => {
            this.$window.location.href = resp.data.next;
        });
    }

    private maybePoll(): void {
        if (this.linkReady()) {
            return;
        }

        this.$timeout(() => {
            this.$http.post(this.$window.location.href, {
                action: 'poll',
            }).then((resp: any) => {
                angular.extend(this.userAccreditationVerification, resp.data);
                this.maybePoll();
            });
        }, 1000);
    }
}

angular
    .module('fundersclub.users')
    .component('fcVerifyAccreditationStatusForm', new FcVerifyAccreditationStatusForm());
