class FcIdWireVerify implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        investorProfileData: '<',
        userProfile: '<',
        wireDone: '<',
    };
    public controller = FcIdWireVerifyController;
    public templateUrl = 'users/id-wire-verify.component.html';
}

class FcIdWireVerifyController implements ng.IComponentController {
    public investorProfileData: any;
    public userProfile: any;
    public wireDone: boolean;
    public submittingWire: boolean = false;

    private wireAddDirective: any;
    private investorProfile: IInvestorProfile;

    constructor(
        private Upload: any,
        private FCChoices: any,
        private InvestorProfile: IInvestorProfile
    ) {
    }

    public $onInit() {
        this.investorProfile = new this.InvestorProfile(this.investorProfileData);
    }

    public bindWireAdd(instance: any) {
        this.wireAddDirective = instance;
        this.wireAddDirective.wireAccount.recipient_name = this.investorProfile.full_name || '';
    }

    public wireAccountCreationError() {
        this.submittingWire = false;
    }

    public submitWireDetails() {
        this.submittingWire = true;
        this.wireAddDirective.add();
    }

    public wireAccountCreated() {
        this.submittingWire = false;
        this.wireDone = true;
    }

    public onInvestorProfileUpdated(investorProfile: IInvestorProfile) {
        this.investorProfile = investorProfile;
    }
}

angular
    .module('fundersclub.users')
    .component('fcIdWireVerify', new FcIdWireVerify());
