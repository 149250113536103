do ->

    EditCompanyDetailsController = ($uibModal, Company, FCChoices, FCCompanyData) ->
        vm = @

        vm.companyCustomerTypeChoices = FCChoices.CompanyCustomerType
        vm.companyProductTypeChoices = FCChoices.CompanyProductType
        vm.companyBusinessModelChoices = FCChoices.CompanyBusinessModel
        vm.companyIndustryChoicesChoices = FCChoices.CompanyIndustryChoices
        default_logo = FCCompanyData.urls.LOGO_DEFAULT_IMAGE

        vm.company = new Company(FCCompanyData.company)

        vm.cropImage = ($files) ->
            $uibModal.open({
                animation: false,
                backdrop: false,
                component: 'FcImageCropperModal',
                resolve: {
                    files: -> $files,
                    onCropped: -> vm.onImageCropped,
                },
                windowClass: 'FullTakeoverModal',
            })

        vm.onImageCropped = (croppedImage, croppedImageDisplay) ->
            vm.company.company_logo = croppedImage
            vm.company.company_logo_display = croppedImageDisplay
            vm.form.$setDirty()

        vm.removeLogo = ->
            vm.company.company_logo_display = default_logo
            vm.company.company_logo = ''

        # Save
        vm.save = ->
            vm.errors = {}
            vm.company.$save([
                'company_name',
                'public_blurb',
                'company_url',
                'company_logo',
                'company_location',
                'company_customer_type',
                'company_product_type',
                'company_business_model',
                'company_industry',
                'company_twitter_handle',
                'company_blog_rss_feed',
                'company_careers_page_url',
            ])
                # unsaved-warning-form monitors the form pristine/dirty state
                .catch (response) ->
                    angular.extend(vm.errors, response.data)
                .finally () ->
                    vm.fcSave.saveAttemptFinished(vm.errors)


        # End EditCompanyDetailsController
        return

    EditCompanyFoundersController = (CompanyTeamMember, FCCompanyFounderData) ->
        vm = @

        vm.founders = (new CompanyTeamMember(founder) for founder in FCCompanyFounderData.company_founders)
        vm.expanded_child_id = null

        vm.drag_control_listeners =
            orderChanged: (event) ->
                sorted_orders = (founder.order for founder in vm.founders).sort()
                if sorted_orders.length != _.unique(sorted_orders).length
                    sorted_orders = [1..vm.founders.length]

                founders_with_order = _.zip(vm.founders, sorted_orders)
                for [founder, order] in founders_with_order
                    if order != founder.order
                        founder.order = order
                        founder.$save(['order'])

        # Remove specified child from list.
        vm.removeChild = (child_id) ->
            vm.expanded_child_id = null
            child_index = _.findIndex(vm.founders, (child) -> child.id == child_id)
            vm.founders.splice(child_index, 1)

        # End EditCompanyFoundersController
        return


    angular
        .module 'fundersclub.companies'
        .controller 'EditCompanyDetailsController', EditCompanyDetailsController
        .controller 'EditCompanyFoundersController', EditCompanyFoundersController
