class FcFundKpiGraph implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        fundKpiData: '<',
    };
    public controller = FcFundKpiGraphController;
    public templateUrl = 'funds/fund-kpi-graph.component.html';
}

class FcFundKpiGraphController implements ng.IComponentController {
    public fundKpiData: any;

    private fundKpi: any;
    private highchartsConfig = {
        annotations: <Array<any>> [],
        chart: {
            type: <string> null,
        },
        colors: ['#3694E8', '#2668A2', '#0E253A'],
        legend: {
            enabled: false,
        },
        series: <Array<any>> [],
        title: {
            align: 'center',
            margin: 40,
            style: {
                'color': '#323B43',
                'fontFamily': '"proxima-nova", sans-serif',
                'fontSize': '16px',
                'fontWeight': 'bold',
            },
            text: <string> null,
            x: 20,
        },
        tooltip: {
            formatter: <any> null,
        },
        xAxis: {
            labels: {
                formatter: <any> null,
                rotation: -45,
                style: {
                    'color': '#323B43',
                    'fontFamily': '"proxima-nova", sans-serif',
                    'fontSize': '12px',
                    'fontWeight': 'normal',
                },
            },
            min: <number> null,
            title: {
                style: {
                    'display': 'none',
                },
                text: <string> null,
            },
            type: 'datetime',
        },
        yAxis: {
            labels: {
                format: <string> null,
                style: {
                    'color': '#323B43',
                    'fontFamily': '"proxima-nova", sans-serif',
                    'fontSize': '12px',
                    'fontWeight': 'normal',
                },
                x: 0,
            },
            offset: 10,
            showFirstLabel: false,
            title: {
                margin: 30,
                style: {
                    'color': '#323B43',
                    'fontFamily': '"proxima-nova", sans-serif',
                    'fontSize': '12px',
                    'fontWeight': 'bold',
                },
                text: <string> null,
            },
        },
    };
    private periodSettings: any = {};

    constructor(
        private $filter: any,
        private $window: any,
        private FCChoices: any,
        private FundKPI: any,
        private moment: moment.MomentStatic
    ) {}

    public $onInit() {
        // http://api.highcharts.com/highcharts/lang :(
        this.$window.Highcharts.setOptions({
            lang: {
                thousandsSep: ',',
            },
        });

        this.periodSettings = {
            [this.FCChoices.Periods.Weekly]: {
                dateFormat: 'MM/DD/YYYY',
            },
            [this.FCChoices.Periods.Monthly]: {
                dateFormat: 'MMMM YYYY',
            },
            [this.FCChoices.Periods.Quarterly]: {
                dateFormat: '[Q]Q YYYY',
            },
            [this.FCChoices.Periods.Yearly]: {
                dateFormat: 'YYYY',
            },
        };

        this.fundKpi = new this.FundKPI(this.fundKpiData);

        this.highchartsConfig.chart.type = this.fundKpi.highcharts_chart_type;
        this.highchartsConfig.series = [{
            data: this.fundKpi.chart_data_points,
        }];

        if (this.fundKpi.annotations.length) {
            this.highchartsConfig.annotations = [{
                labelOptions: {
                    overflow: 'justify',
                    type: 'path',
                    y: -15,
                    zIndex: 10,
                },
                labels: this.fundKpi.annotations,
            }];
        }
        this.highchartsConfig.title.text = this.fundKpi.chart_title;
        this.highchartsConfig.tooltip.formatter = this.tooltipFormatter(
            this.periodSettings[this.fundKpi.kpi.period].dateFormat
        );
        this.highchartsConfig.xAxis.labels.formatter = this.xAxisLabelFormatter(
            this.periodSettings[this.fundKpi.kpi.period].dateFormat
        );
        this.highchartsConfig.xAxis.min = this.fundKpi.chart_data_points[0].x;
        this.highchartsConfig.xAxis.title.text = this.fundKpi.chart_x_axis_title;
        this.highchartsConfig.yAxis.labels.format = this.fundKpi.highcharts_y_axis_label_format;
        this.highchartsConfig.yAxis.title.text = this.fundKpi.chart_y_axis_title;
    }

    private tooltipFormatter(dateFormat: string) {
        let moment: moment.MomentStatic = this.moment;
        let $filter: any = this.$filter;
        return function() {
            let formattedY: string = $filter('number')(this.y);
            return `
                ${moment.utc(this.x).format(dateFormat)}<br/>
                <b>${this.point.currencyFormat}${formattedY}${this.point.percentageFormat}</b>
            `;
        };
    }

    private xAxisLabelFormatter(dateFormat: string) {
        let moment: moment.MomentStatic = this.moment;
        return function() {
            return moment.utc(this.value).format(dateFormat);
        };
    }
}

angular
    .module('fundersclub.funds')
    .component('fcFundKpiGraph', new FcFundKpiGraph());
