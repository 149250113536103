class FcInvestorProfileEdit implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        investorProfile: '<',
        isAddForm: '<?',
        isSubForm: '<?',
        onCanceled: '&',
        onSaved: '&',
        validateImmediately: '<?',
    };
    public controller = FcInvestorProfileEditController;
    public templateUrl = ($attrs: any) => {
        if ($attrs.useAlternateLayout) {
            return 'users/icp-investor-profile-edit.component.html';
        } else {
            return 'users/investor-profile-edit.component.html';
        }
    };
}

class FcInvestorProfileEditController implements ng.IComponentController {
    public investorProfile: IInvestorProfile;
    public isAddForm: boolean;
    public onSaved: (data: {investorProfile: IInvestorProfile}) => void;
    public onCanceled: () => void;
    public validateImmediately: boolean;

    private errors: {[error: string]: Array<any>} = {};
    private fcSave: any;

    constructor(
        private FCChoices: any,
        private InvestorProfile: IFcModelResource<IInvestorProfile>
    ) {}

    public $onInit(): void {
        this.investorProfile = angular.copy(this.investorProfile);
        if (this.validateImmediately) {
            this.save();
        }
    }

    private save(): void {
        this.errors = {};
        this.investorProfile
            .$save()
            .then((response: any) => {
                this.onSaved({investorProfile: new this.InvestorProfile(response)});
            })
            .catch((response: any) => angular.extend(this.errors, response.data))
            .finally(() => this.fcSave.saveAttemptFinished(this.errors));
    }

    /* tslint:disable:no-unused-variable */
    private cancel(): void {
        this.onCanceled();
    }
}

angular
    .module('fundersclub.users')
    .component('fcInvestorProfileEdit', new FcInvestorProfileEdit());
