do ->

    # Partnership dashboard Fund tiles
    fcPartnershipDashboardFundSection = ->
        # This directive's isolate scope vars.
        thisScope =
            allFunds: '=sectionFunds'
            title: '@sectionTitle'
            statusCode: '@sectionKey'
            searchText: '=sectionSearch'
            sectionCollapsed: '@sectionCollapseSection'

        # This directive's controller function.
        thisController = (Fund, FCGlobals) ->
            vm = @
            vm.default_fund_logo = FCGlobals.default_fund_logo

            vm.showCollapsedSection = ->
                vm.sectionCollapsed = false
                return

            return

        thisDirective =
            scope: thisScope
            restrict: 'A'
            templateUrl: 'partnerships/dashboard-section.directive.html'
            bindToController: true
            controllerAs: 'pdfs'
            controller: thisController
        return thisDirective

    angular
        .module 'fundersclub.partnerships'
        .directive 'fcPartnershipDashboardFundSection', fcPartnershipDashboardFundSection
