do ->

    fcSignupInvested = ->

        thisScope =
            'signupLead': '=lead'
            'register': '&registerCallback'

        thisController = ->
            vm = @
            vm.form = vm.register({'directive': vm})
            vm.showErrorMessages = false

            vm.directiveFields = [
                'invested_angel_groups',
                'invested_capital_funds',
                'invested_online_platforms',
                'invested_other_means',
                'invested_self_selected',
            ]
            # The radio button controlling the tab pane is not passed
            # to the parent controller and is not included in the above
            # list.
            vm.have_invested = 'yes'


            # Convenience functions to know what tab we're on.
            vm.tab_is_invested = -> vm.have_invested == 'yes'
            vm.tab_is_not_invested = -> vm.have_invested == 'no'

            vm.validate = ->
                # Set all "invested" fields to false by default.
                vm.signupLead[formField] = false for formField in vm.directiveFields when vm.signupLead[formField] == null

                # Form is valid if on "yes" tab with at least one option
                # selected. If on "no", always highlght.
                if vm.tab_is_not_invested()
                    return true
                else
                    anySelected = false
                    for field_name in vm.directiveFields
                        if vm.signupLead[field_name] == true
                            anySelected = true
                            break
                    return anySelected


            vm.highlightContinueButton = ->
                # Continue button behavior here matches the validation logic.
                return vm.validate()


            return

        thisDirective =
            bindToController: true
            controller: thisController
            controllerAs: 'isuc'
            replace: true
            restrict: 'E'
            scope: thisScope
            templateUrl: 'signup/steps/invested.directive.html'

        thisDirective


    angular
        .module 'fundersclub.signup'
        .directive 'fcSignupInvested', fcSignupInvested
