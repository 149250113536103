class FcPrepareSubscriptionAgreement implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        investment: '<',
        investorProfile: '<',
        legalDoc: '<',
        onSubscriptionAgreementGenerated: '&',
        userProfile: '<',
    };
    public controller = FcPrepareSubscriptionAgreementController;
    public templateUrl = 'legaldocs/prepare-subscription-agreement.component.html';
}

class FcPrepareSubscriptionAgreementController implements ng.IComponentController {
    public investment: IInvestment;
    public investorProfile: IInvestorProfile;
    public legalDoc: any;
    public onSubscriptionAgreementGenerated: (data: {subscriptionAgreement: any}) => void;
    public userProfile: any;

    private errors = {};
    private fcSave: any;
    private model: any;
    private showSavingWarning = false;

    constructor(
        private FCChoices: any,
        private fcModel: ng.resource.IResourceService,
        private SignableLegalDocument: any
    ) {}

    public $onInit() {
        // Need to copy objects to get correct 1 way data binding
        this.investment = angular.copy(this.investment);
        this.investorProfile = angular.copy(this.investorProfile);
        this.legalDoc = angular.copy(this.legalDoc);
        this.userProfile = angular.copy(this.userProfile);

        let GenerateSubscriptionAgreementModel = this.fcModel(this.investment.urls.generate_legal_doc);
        GenerateSubscriptionAgreementModel.prototype.isUsBasedOnCountry = function() {
            return this.country === 'US';
        };

        this.model = new GenerateSubscriptionAgreementModel({
            city: this.investorProfile.city,
            country: this.investorProfile.country,
            full_name: this.investorProfile.full_name,
            investor_type: this.investorProfile.investor_type,
            legal_country: this.investorProfile.legal_country,
            legal_state: this.investorProfile.legal_state,
            phone_number: this.investorProfile.phone_number || this.userProfile.phone_number,
            state: this.investorProfile.state,
            street_address_1: this.investorProfile.street_address_1,
            street_address_2: this.investorProfile.street_address_2,
            zip_code: this.investorProfile.zip_code,
        });
    }

    /* tslint:disable:no-unused-variable */
    private save() {
        this.showSavingWarning = true;
        this.errors = {};
        this.model
            .$save()
            .then((response: any) => {
                this.onSubscriptionAgreementGenerated({
                    subscriptionAgreement: new this.SignableLegalDocument(response),
                });
            })
            .catch((response: any) => {
                angular.extend(this.errors, response.data);
                this.showSavingWarning = false;
                if (response.data && Object.keys(this.errors).length === 0) {
                    if (typeof(response.data) === 'string') {
                        this.errors = {
                            'error': 'An error occurred while generating the subscription agreement. ' +
                            'Please try again later, or contact support if the issue persists',
                        };
                    }
                }
            })
            .finally(() => this.fcSave.saveAttemptFinished(this.errors));
    };
}

angular
    .module('fundersclub.legaldocs')
    .component('fcPrepareSubscriptionAgreement', new FcPrepareSubscriptionAgreement());
