do ->
    fcGmailContactsModalController = ($uibModalInstance, contacts) ->
        vm = @
        vm.contacts = contacts
        vm.search = ''

        for contact in contacts
            contact.selected = false

        vm.close = ->
            $uibModalInstance.dismiss()

        vm.import = ->
            contacts = _.filter vm.contacts, (contact) -> contact.selected
            $uibModalInstance.close(contacts)

        return


    # Directive to allow people to select their gmail contacts
    # (usually to invite them in some capacity)
    fcGmailContacts = ($uibModal, fcGmailContactsHelper, FCInviteMembersData) ->
        thisScope =
            api_key: '@apiKey'
            client_id: '@clientId'
            invited_emails: '=invitedEmails'
            select_callback: '&onSelect'

        thisController = ($element) ->
            vm = @
            vm.GMAIL_ICON = FCInviteMembersData.GMAIL_ICON
            vm.loadingContacts = false
            contactHelper = new fcGmailContactsHelper(vm.client_id, vm.api_key)

            vm.import = ($event) ->
                $event.preventDefault()
                vm.loadingContacts = true
                contactHelper.getContacts().then (contacts) ->
                    contacts.sort (contact1, contact2) ->
                        if contact1.name.toLowerCase() < contact2.name.toLowerCase()
                            return -1
                        else
                            return 1

                    if vm.invited_emails
                        for contact in contacts
                            if contact.email in vm.invited_emails
                                contact.already_invited = true

                    vm.loadingContacts = false
                    modal = $uibModal.open(
                        templateUrl: 'shared/gmail-contacts.modal.html'
                        controller: 'fcGmailContactsModalController'
                        controllerAs: 'gcmc',
                        resolve:
                            contacts: -> contacts
                    )
                    modal
                        .result
                        .then (contacts) ->
                            vm.select_callback
                                'contacts': contacts

            return

        thisDirective =
            restrict: 'A'
            scope: thisScope
            controller: thisController
            controllerAs: 'gc'
            templateUrl: 'shared/gmail-contacts.directive.html'
            bindToController: true
        return thisDirective



    angular
        .module 'fundersclub'
        .directive 'fcGmailContacts', fcGmailContacts
        .controller 'fcGmailContactsModalController', fcGmailContactsModalController
