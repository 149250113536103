do ->

    UnseenToast = (fcModel) ->
        UnseenToast = fcModel '/api/unseentoasts/:id/',
            id: '@id'
        UnseenToast

    UserNotification = ($http, fcModel) ->
        baseUrl = '/api/usernotifications/:id/'
        params =
            id: '@id'
        methods =
            'dismiss':
                method: 'PATCH'
                url: "#{baseUrl}dismiss/"
        UserNotification = fcModel baseUrl, params, methods
        UserNotification.$dismissAll = (notificationIds) ->
            $http
                method: 'POST'
                url: '/api/usernotifications/dismiss_all/'
                data:
                    'notification_ids': notificationIds
        UserNotification


    AccomplishmentPromo = (fcModel) ->
        baseUrl = '/api/accomplishmentpromos/:id/'
        params =
            id: '@id'
        methods =
            'dismiss':
                method: 'PATCH'
                url: "#{baseUrl}dismiss/"
        AccomplishmentPromo = fcModel baseUrl, params, methods
        AccomplishmentPromo

    angular
        .module 'fundersclub.models'
        .factory 'UnseenToast', UnseenToast
        .factory 'UserNotification', UserNotification
        .factory 'AccomplishmentPromo', AccomplishmentPromo
