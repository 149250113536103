class FcProceedEdit implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        acquiringCompanyName: '<',
        isForAcquisition: '<',
        onDeleted: '&',
        onSaved: '&',
        proceedData: '<',
    };
    public controller = FcProceedEditController;
    public templateUrl = 'investment-assets/proceed-edit.component.html';
}

class FcProceedEditController implements ng.IComponentController {
    public acquiringCompanyName: string;
    public errors: any = {};
    public isForAcquisition: boolean;
    public proceedData: any;
    public onDeleted: (data: {proceed: any}) => void;
    public onSaved: (data: {proceed: any}) => void;

    private assetFieldBeingEdited: string;
    private editing = false;
    private initialProceed: any;
    private proceed: any;
    private saving = false;
    private highlightAssetId: number;

    constructor(
        private _: UnderscoreStatic,
        private $uibModal: ng.ui.bootstrap.IModalService,
        private $window: ng.IWindowService,
        private InvestmentAsset: any,
        private ProceedFromExit: any
    ) {}

    public $onInit() {
        this.proceed = new this.ProceedFromExit(this.proceedData);
        if (this.$window.location.hash && this.$window.location.hash.indexOf('#asset-received-') !== -1) {
            this.highlightAssetId = parseInt(this.$window.location.hash.substring(16), 10);
        }
    }

    /* tslint:disable:no-unused-variable */
    private addAsset(assetField: string, isUnstructured: boolean, unstructuredType: string = 'cash') {
        this.proceed
            .addAsset(assetField, isUnstructured, unstructuredType)
            .then((response: any) => {
                this.proceed[assetField] = response.data;
                this.editAsset(assetField);
                if (assetField === 'asset_received') {
                    this.syncAssetExpected();
                }
            });
    }

    private cancel() {
        this.editing = false;

        // Asset editing is done synchronously so don't want to reset
        // asset data that has already been synced to the server
        this._.extend(
            this.proceed,
            this._.pick(
                this.initialProceed,
                'asset_expected_at',
                'asset_received_at',
                'description',
                'notes'
            )
        );
    }

    /* tslint:disable:no-unused-variable */
    private copyAssetExpected() {
        this.proceed
            .copyAssetExpected()
            .then((response: any) => {
                this.proceed.asset_received = response.data;
                if (!this.proceed.asset_received_at) {
                    this.proceed.asset_received_at = this.proceed.asset_expected_at;
                }
                this.syncAssetExpected();
            });
    }

    private delete() {
        if (!confirm('Are you sure you wish to delete this line item?')) {
            return;
        }

        this.proceed.$delete().then(() => this.onDeleted({proceed: this.proceed}));
    }

    private edit() {
        this.editing = true;
        this.initialProceed = angular.copy(this.proceed);
    }

    private editAsset(assetField: string) {
        this.assetFieldBeingEdited = assetField;

        let resolve: any = {
            asset: () => this.proceed[assetField],
            enableEditChildOfProceed: () => true,
            onSaved: () => this.onAssetSaved.bind(this),
        };

        // We only allow deletion of expected asset if there's no received asset
        if ((assetField === 'asset_received') ||
            (assetField === 'asset_expected' && !this.proceed.asset_received)) {
            resolve.onDeleted = () => this.onAssetDeleted.bind(this);
        }

        let modal: any = this.$uibModal
            .open({
                animation: false,
                backdrop: false,
                component: 'FcInvestmentAssetEditModal',
                resolve: resolve,
                windowClass: 'FullTakeoverModal',
            });
    }

    /* tslint:disable:no-unused-variable */
    private onAssetSaved(asset: any) {
        this.proceed[this.assetFieldBeingEdited] = asset.toJSON();
        if (this.assetFieldBeingEdited === 'asset_received') {
            this.syncAssetExpected();
        }
    }

    private onAssetDeleted() {
        let asset = new this.InvestmentAsset(this.proceed[this.assetFieldBeingEdited]);
        asset.$delete().then(() => {
            this.proceed[this.assetFieldBeingEdited] = null;
            this.proceed[this.assetFieldBeingEdited + '_at'] = null;
            this.initialProceed[this.assetFieldBeingEdited] = null;
        });
    }

    /* tslint:disable:no-unused-variable */
    private save() {
        this.saving = true;
        this.errors = {};

        // Coerce invalid values to null
        if (!this.proceed.asset_expected_at) {
            this.proceed.asset_expected_at = null;
        }
        if (!this.proceed.asset_received_at) {
            this.proceed.asset_received_at = null;
        }

        this.proceed
            .$save([
                'asset_expected_at',
                'asset_received_at',
                'description',
                'notes',
            ])
            .then((response: any) => {
                this.editing = false;
                this.onSaved({proceed: response.toJSON()});
            }).catch((response: any) => {
                angular.extend(this.errors, response.data);
                this.saving = false;
            });
    }

    private syncAssetExpected() {
        let assetExpected = new this.InvestmentAsset(this.proceed.asset_expected);
        assetExpected.$get().then((response: any) => this.proceed.asset_expected = response);
    }
}

angular
    .module('fundersclub.assets')
    .component('fcProceedEdit', new FcProceedEdit());
