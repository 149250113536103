class FcEntityAccreditation implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        fund: '<',
        investment: '<',
        investorProfile: '<',
        onInvestmentUpdated: '&',
        onInvestorProfileUpdated: '&',
        onQuestionnaireSubmitted: '&',
    };
    public controller = FcEntityAccreditationController;
    public templateUrl = 'users/entity-accreditation.component.html';
}

class FcEntityAccreditationController implements ng.IComponentController {
    public fund: any;
    public investment: IInvestment;
    public investorProfile: IInvestorProfile;
    public onInvestmentUpdated: (data: {investment: any}) => void;
    public onInvestorProfileUpdated: (data: {investorProfile: any}) => void;
    public onQuestionnaireSubmitted: () => void;

    private alreadyConfirmed: boolean;
    private alreadyVerified: boolean;
    private choices: any;
    private errors = {};
    private fcSave: any;
    private questionnaire: any;
    private submittingQuestionnaire = false;

    constructor(
        private $scope: ng.IRootScopeService,
        private $state: any,
        private EntityQuestionnaire: any,
        private InvestorProfileEntityQuestionnaire: any,
        private FCChoices: any
    ) {}

    public $onInit() {
        // Need to copy objects to get correct 1 way data binding
        this.fund = angular.copy(this.fund);
        this.investment = angular.copy(this.investment);
        this.investorProfile = angular.copy(this.investorProfile);

        this.choices = this.FCChoices;
        this.choices.yes_no = [
            [true, 'Yes'],
            [false, 'No'],
        ];
        this.alreadyVerified = this.investorProfile.accredited;
        this.alreadyConfirmed = this.investment.questionnaire_completed;

        if (this.alreadyVerified) {
            this.questionnaire = new this.EntityQuestionnaire(
                this.investorProfile.questionnaire
            );
            this.questionnaire.entity_formed_for_fund = false;
        } else {
            this.clearQuestionnaireFields();
        }

        this.$scope.$watch(
            () => this.questionnaire.entity_type,
            (newValue: any, oldValue: any) => {
                if (newValue !== oldValue) {
                    this.clearQuestionnaireFields();
                }

                this.questionnaire.entity_type = newValue;
            }
        );
    }

    private clearQuestionnaireFields() {
        this.questionnaire = new this.EntityQuestionnaire({
            can_entity_owners_vary: null,
            current_position_held: null,
            employer: null,
            entity_formed_for_fund: (this.investorProfile.accredited ? false : null),
            entity_owners_entities_assets: null,
            entity_owners_individual_income: null,
            entity_owners_joint_income: null,
            entity_owners_net_worth: null,
            entity_owners_trusts_assets: null,
            entity_type: 's',
            equity_owners: null,
            i_understand: false,
            invested_past_five_years: null,
            investment_more_than_40: null,
            joint_income: null,
            knowledge_and_experience: false,
            total_assets: null,
            trust_knowledge_and_experience: null,
        });

        if (
            this.investorProfile.accredited &&
            this.investorProfile.investments != null &&
            this.investorProfile.investments.length > 0
        ) {
            this.questionnaire.entity_formed_for_fund = false;
        }
    }

    /* tslint:disable:no-unused-variable */
    private isLookThroughEntity() {
        const totalAssetsIndex: number = (['1mminus', '1m', '2m', '3m', '4m']
            .indexOf(this.questionnaire.total_assets)
        );
        return (
            totalAssetsIndex > -1 ||
            this.questionnaire.entity_formed_for_fund ||
            this.questionnaire.investment_more_than_40 ||
            this.questionnaire.can_entity_owners_vary
        );
    }

    /* tslint:disable:no-unused-variable */
    private submitQuestionnaire() {
        this.errors = {};
        this.submittingQuestionnaire = true;

        let prefixes: any = {
            'i': 'it-',
            'l': 'lt-',
            's': 's-',
        };
        let fieldPrefix = prefixes[this.questionnaire.entity_type];
        let entityFields: any = {
            'i': [ // irrevocable trust
                'i_understand',
                'invested_past_five_years',
                'knowledge_and_experience',
                'total_assets',
                'trust_knowledge_and_experience',
            ],
            'l': [ // living trust
                'current_position_held',
                'employer',
                'i_understand',
                'individual_income',
                'invested_past_five_years',
                'joint_income',
                'knowledge_and_experience',
                'length_of_employment',
                'net_worth',
            ],
            's': [ // simple corporation fields
                'can_entity_owners_vary',
                'entity_formed_for_fund',
                'entity_owners_entities_assets',
                'entity_owners_individual_income',
                'entity_owners_joint_income',
                'entity_owners_net_worth',
                'entity_owners_trusts_assets',
                'equity_owners',
                'i_understand',
                'invested_past_five_years',
                'investment_more_than_40',
                'knowledge_and_experience',
                'total_assets',
            ],
        };

        let submittableQuestionnaire: any = null;
        if (this.investment.id !== -1) {
            submittableQuestionnaire = new this.EntityQuestionnaire({
                investment_id: this.investment.id,
            });
        } else {
                submittableQuestionnaire = new this.InvestorProfileEntityQuestionnaire({
                investor_profile_id: this.investorProfile.id,
            });
        }

        // Yes, we need them both. Sometimes
        submittableQuestionnaire['et-entity_type'] = this.questionnaire.entity_type;
        submittableQuestionnaire[fieldPrefix + 'entity_type'] = this.questionnaire.entity_type;

        // We might not have an entity_type in case of a hack of individual IRA that is not
        // Entrust (for example Investor Profile #33750)
        if (this.questionnaire.entity_type) {
            for (let fieldName of entityFields[this.questionnaire.entity_type]) {
                // We're going to prefix all the appropriate field names with the correct prefix
                // based on the entity type (eg all fields we care about for irrevocable trusts must
                // be prefixed with 'it-'
                if (_.has(this.questionnaire[fieldName], 'value')) {
                    submittableQuestionnaire[fieldPrefix + fieldName] = this.questionnaire[fieldName].value;
                } else {
                    submittableQuestionnaire[fieldPrefix + fieldName] = this.questionnaire[fieldName];
                }
            }
        }

        submittableQuestionnaire
            .$save()
            .then((response: any) => {
                this.investorProfile.accreditation_questionnaire_completed = true;
                this.onInvestorProfileUpdated({
                    investorProfile: this.investorProfile,
                });
                this.investment.questionnaire_completed = true;
                this.onInvestmentUpdated({investment: this.investment});
                this.onQuestionnaireSubmitted();
            }).catch((response: any) => {
                angular.extend(this.errors, response.data);
            }).finally(() => {
                this.submittingQuestionnaire = false;
                this.fcSave.saveAttemptFinished(this.errors);
            });
    }

    /* tslint:disable:no-unused-variable */
    private confirmAsCorrect() {
        this.questionnaire.i_understand = true;
        this.submitQuestionnaire();
    }

    /* tslint:disable:no-unused-variable */
    private confirmAsIncorrect() {
        this.alreadyConfirmed = false;
        this.alreadyVerified = false;

        this.questionnaire.i_understand = false;
        this.questionnaire.knowledge_and_experience = false;
        this.questionnaire.invested_past_five_years = null;
    }
}

angular
    .module('fundersclub.users')
    .component('fcEntityAccreditation', new FcEntityAccreditation());
