do ->
    fcWireAccounts = (WireAccount, FCWireAccountsData) ->
        thisScope =
            selectedAccount: '=?'
            enableAdd: '<?'
            enableDelete: '<?'
            enableSelect: '<?'

        thisController = ($attrs, filterFilter) ->
            vm = @
            vm.accounts = _.map(FCWireAccountsData.accounts, (account) -> new WireAccount(account))
            vm.addFormExpanded = false

            vm.selectedAccount = null
            results = filterFilter(vm.accounts, {'id': $attrs.initialSelectedAccountId})
            if results.length == 1
                vm.selectedAccount = results[0]

            vm.wireAccountAdded = (wireAccount) ->
                vm.accounts.push(wireAccount)
                vm.selectedAccount = wireAccount
                vm.addFormExpanded = false

            vm.delete = (account) ->
                if not confirm('Are you sure you wish to delete this account?')
                    return

                account.$delete()
                    .then (response) ->
                        index = _.findIndex(vm.accounts, (account2) -> account.id == account2.id)
                        vm.accounts.splice(index, 1)
                        if vm.selectedAccount == account
                            vm.selectedAccount = null
                    .catch (response) ->
                        alert 'Error deleting account. Please try again'

                return

            return

        thisDirective =
            scope: thisScope
            restrict: 'E'
            templateUrl: 'payments/wire-accounts.directive.html'
            bindToController: true
            controllerAs: 'wac'
            controller: thisController
        return thisDirective

    angular
        .module 'fundersclub.payments'
        .directive 'fcWireAccounts', fcWireAccounts

