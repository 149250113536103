do ->

    PartnershipInviteMembersController = ($scope, FCPartnershipInviteMembersData) ->
        vm = @
        default_member_to_invite =
            role: 'follower'

        vm.members_to_invite = [angular.copy(default_member_to_invite)]
        vm.invited_emails = FCPartnershipInviteMembersData.invited_emails

        $scope.$watch (-> vm.members_to_invite.length), ->
            members_to_invite_emails = _.map vm.members_to_invite, (member) -> member.email
            vm.invited_emails = FCPartnershipInviteMembersData.invited_emails.concat(members_to_invite_emails)

        vm.add = ($event) ->
            $event.preventDefault()
            vm.members_to_invite.push angular.copy(default_member_to_invite)

        vm.remove= ($index) ->
            vm.members_to_invite.splice $index, 1

        vm.gmailContactsAdded = (contacts) ->
            new_members_to_invite = []
            for contact in contacts
                member_to_invite = angular.copy(default_member_to_invite)
                member_to_invite.email = contact.email

                name_parts = contact.name.split(' ')
                member_to_invite.first_name = name_parts[0]
                member_to_invite.last_name = name_parts.slice(1).join(' ')
                new_members_to_invite.push member_to_invite

            vm.members_to_invite = new_members_to_invite.concat(vm.members_to_invite)

        # End PartnershipInviteMembersController
        return

    angular
        .module 'fundersclub.partnerships'
        .controller 'PartnershipInviteMembersController', PartnershipInviteMembersController
