class FcSimMain implements ng.IComponentOptions {
    public controller = FcSimMainController;
    public templateUrl = 'strategy-simulator/sim-main.component.html';
}

class FcSimMainController implements ng.IComponentController {
    public selectedFunds: Array<any> = [];
    public selectedStrategy: Array<number> = [];
    public cashflows: Array<any> = [];
    public grossIrr: number = 0;
    public grossMultiple: number = 1.0;
    public error: string = '';
    private loading: boolean = false;

    constructor (
        private $http: ng.IHttpService
    ) {}

    public selectFunds(selectedFunds: Array<any>): void {
        this.selectedFunds = selectedFunds;
    }

    public selectStrategy(selectedStrategy: Array<number>): void {
        this.selectedStrategy = selectedStrategy;
    }

    public runSimulation(): void {
        console.log('Running simulation');
        this.loading = true;
        this.error = '';

        this.$http.post(
            '',
            {
                'funds': this.selectedFunds,
                'strategy': this.selectedStrategy,
            }
            ).then(
                (data: any) => {
                    this.cashflows = data.data.cashflows;
                    this.grossIrr = data.data.gross_irr;
                    this.grossMultiple = data.data.gross_multiple;
                }
            ).catch(
                (error: any) => {
                    console.error(error.data);
                    if (error.data.hasOwnProperty('error')) {
                        this.error = error.data.error;
                    } else {
                        this.error = 'An error occurred';
                    }
                }
            ).finally(
                () => {
                    this.loading = false;
                }
            );
    }
}

angular
    .module('fundersclub.strategy-simulator')
    .component('fcSimMain', new FcSimMain());
