class FcCompanySwagSizesBoundForm implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        defaultFounderPicture: '<',
        founders: '=',
    };
    public controller = FcCompanySwagSizesBoundFormController;
    public templateUrl = 'companies/swag-sizes-bound-form.component.html';
}

class FcCompanySwagSizesBoundFormController implements ng.IComponentController {
    public company: any;

    constructor(private FCChoices: any, private FCGlobals: any) {}
}

angular
    .module('fundersclub.companies')
    .component('fcCompanySwagSizesBoundForm', new FcCompanySwagSizesBoundForm());
