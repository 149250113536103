class FcSignLegaldocModal implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        modalInstance: '<',
        resolve: '<',
    };
    public controller = FcSignLegaldocModalController;
    public templateUrl = 'legaldocs/sign-legaldoc-modal.component.html';
}

class FcSignLegaldocModalController implements ng.IComponentController {
    public modalInstance: ng.ui.bootstrap.IModalServiceInstance;
    public resolve: {
        docName: string,
        getLegaldocStatus: () => string,
        getSigningUrl: () => string,
        onResetForm: any,
    };

    private resetting = false;

    constructor(
        private $intercom: any,
        private FCChoices: any,
        private $scope: ng.IScope
    ) {}

    public $onInit() {
        this.$intercom.hideLauncher();

        // Auto-close when signed
        this.$scope.$watch(
            () => { return this.isLegaldocSigned(); },
            (newVal) => {
                if (newVal) {
                    this.close();
                }
            }
        );
    }

    /* tslint:disable:no-unused-variable */
    private close() {
        this.modalInstance.close(this);
    }

    /* tslint:disable:no-unused-variable */
    private resetForm() {
        if (!confirm('Delete this generated document?')) {
            return;
        }
        this.resetting = true;
        this.resolve.onResetForm().then(() => {
            this.close();
        });
    }

    /* tslint:disable:no-unused-variable */
    private isLegaldocSigned() {
        const status: string = this.resolve.getLegaldocStatus();
        return (
            status === this.FCChoices.SigningStatus.RequiresCountersignature ||
            status === this.FCChoices.SigningStatus.Signed
        );
    }
}

angular
    .module('fundersclub.legaldocs')
    .component('fcSignLegaldocModal', new FcSignLegaldocModal());
