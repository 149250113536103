let FounderResourceEmailCtaModalController = function(
    $http: any,
    $uibModalInstance: any,
    FCToastManager: any,
    name: string,
    requestUrl: string
) {
    let vm = this;
    vm.name = name;
    vm.requesting = false;

    vm.close = () => {
        $uibModalInstance.close();
    };

    vm.connect_clicked = () => {
        vm.requesting = true;
        $http
            .post(requestUrl)
            .then((response: any) => {
                FCToastManager.createGenericToast(response.data.message);
                $uibModalInstance.close();
            })
            .finally(() => {
                vm.requesting = false;
            });
    };
};

let FounderResourceController = function($attrs: any, $uibModal: any) {
    let vm = this;

    vm.show_email_cta_modal = function() {
        $uibModal.open({
            controller: 'FounderResourceEmailCtaModalController',
            controllerAs: 'frecmc',
            resolve: {
                name: () => $attrs.name,
                requestUrl: () => $attrs.requestUrl,
            },
            templateUrl: 'companies/founder-resource.email-cta-modal.html',
        });
    };
};

angular
    .module('fundersclub.companies')
    .controller('FounderResourceController', FounderResourceController)
    .controller('FounderResourceEmailCtaModalController', FounderResourceEmailCtaModalController);
