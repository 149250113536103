class UpdateFilters implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        currentFilters: '=',
        filtersData: '=',
    };
    public controller = UpdateFiltersController;
    public templateUrl = 'updates/update-filters.component.html';
}
class UpdateFiltersController implements ng.IComponentController {
    public companyFilters: Array<any>= [];
    public filtersData: any;
    public currentFilters: any;
    public typeFilters: Array<any>= [];
    public statusFilters = [{label: 'All unread', value: 'all-unread'},
        {label: 'All read', value: 'all-read'}];
    public fundFilters: Array<any> = [];
    public filterChoices: Array<any> = [];
    public filterSearch: {[name: string]: any} = {};
    public searchTerms: Array<any> = [];
    public clear = 0;
    public filters: any = {
            company: new Array<any>(),
            fund: new Array<any>(),
            status: new Array<any>(),
            type: new Array<any>(),
        };

    constructor(private _: UnderscoreStatic, private $window: any,  private $cookies: any) {
        this.fundFilters.push(...this.filtersData.funds);
        this.companyFilters.push(...this.filtersData.companies);
        this.typeFilters.push(...this.filtersData.filter_types);
        this.filterChoices.push(...[
            {choices: this.fundFilters, display: 'Fund', field: 'fund'},
            {choices: this.companyFilters, display: 'Company', field: 'company'},
            {choices: this.typeFilters, display: 'Type', field: 'type'},
            {choices: this.statusFilters, display: 'Status', field: 'status'},
        ]);
        this.buildFiltersFromCookie();

    }

    public findFilter(cookieFilters: any[], userFilters: any[], category: string) {
        cookieFilters.forEach((value) => {
            const filtered = userFilters.filter((f) => {
               return String(f.value) === value;
            });
            if (filtered.length === 1) {
                this.pick(category, filtered[0].value, filtered[0].label);
                this.buildQueryString();
            }
        });
    }

    public buildFiltersFromCookie() {
        // 054 is comma encoded by django (comas are restricted in cookies)
        // double parsing is hack. django is double quoting the cookie value
        let filters = JSON.parse(JSON.parse(this.$cookies.get('update_filter').replace(/\\054/g, ',')));

        this.filters = filters;

        this.findFilter(filters.fund, this.fundFilters, 'fund');
        this.findFilter(filters.company, this.companyFilters, 'company');
        this.findFilter(filters.type, this.typeFilters, 'type');
        this.findFilter(filters.status, this.statusFilters, 'status');
    }

    public pick(type: string, value: string, label: string) {
        this.addSearchTerm(label, type, value);
    }

    public clearSearchTerm(searchTerm: any) {
        const index = this.findFilterIndex(searchTerm.value);
        if (index !== -1) {
            this.searchTerms.splice(index, 1);
            this.buildQueryString();
        }
    }

    public clearSearchTerms() {
        this.searchTerms = [];
        this.clear = 1;
    }

    public addSearchTerm(display: string, type: string, value: string) {
        const index = this.findFilterIndex(value);
        if (index === -1) {
            this.searchTerms.push({display: display, type: type, value: value});
        }
        this.buildQueryString();
    }

    public showFilterTerm(searchTerm: any) {
        const index = this.findFilterIndex(searchTerm.value);
        return index === -1;
    }

    private buildQueryString() {
        this.filters = {
            company: new Array<any>(),
            fund: new Array<any>(),
            status: new Array<any>(),
            type: new Array<any>(),
        };
        this.searchTerms.forEach( (term) => {
            if (term.type === 'fund') {
                this.filters.fund.push(term.value);
            } else if (term.type === 'company') {
                this.filters.company.push(term.value);
            } else if (term.type === 'status') {
                this.filters.status.push(term.value);
            } else {
                this.filters.type.push(term.value);
            }
        });
    }

    private findFilterIndex(value: string) {
        return this._.findIndex(this.searchTerms, (term: any) => {
            return term.value === value;
        });
    }
}

angular
    .module('fundersclub.updates', ['ngCookies'])
    .component('updateFilters', new UpdateFilters());
