do ->
    fcCompanySavedContact = ->
        thisScope =
            'contact': '=fcCompanySavedContact'
            'contactSaved': '='
            'savedContacts': '='

        thisController = ($scope, CompanySavedContact) ->
            vm = @
            vm.error = false
            vm.updating = false

            vm.saveContact = ->
                vm.updating = true
                vm.contact.$save()
                    .then (response) ->
                        vm.contact = new CompanySavedContact(response)
                        vm.savedContacts.contacts.push(vm.contact)
                        vm.contactSaved = true
                    .catch (response) ->
                        vm.error = true
                        vm.contactSaved = false
                    .finally ->
                        vm.updating = false

            vm.removeContact = ->
                vm.updating = true
                vm.contact.$delete()
                    .then (response) ->
                        vm.contact = new CompanySavedContact(response)
                        vm.savedContacts.contacts = _.reject(vm.savedContacts.contacts, (contact) ->
                            return (
                                contact.contact_type == vm.contact.contact_type and
                                contact.contact == vm.contact.contact_id
                            )
                        )
                        vm.contactSaved = false
                    .catch (response) ->
                        vm.error = true
                    .finally ->
                        vm.updating = false

            vm.toggleContactState = ->
                if vm.contactSaved
                    vm.removeContact()
                else
                    vm.saveContact()

            return

        thisDirective =
            templateUrl: 'companies/saved-contact.directive.html'
            restrict: 'A'
            scope: thisScope
            bindToController: true
            controllerAs: 'sc'
            controller: thisController

        return thisDirective

    angular
        .module 'fundersclub.companies'
        .directive 'fcCompanySavedContact', fcCompanySavedContact
