class FcIcpInvitePromo implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        companyName: '@',
        invitePageUrl: '@',
        multiCompanyFund: '<',
    };
    public controller = FcIcpInvitePromoController;
    public templateUrl = 'icp/invite-promo.component.html';
}

class FcIcpInvitePromoController implements ng.IComponentController {
    public companyName: string;
    public invitePageUrl: string;
    public multiCompanyFund: boolean;

    private show: boolean = false;

    constructor(private $timeout: ng.ITimeoutService) {}

    public $onInit(): void {
        this.$timeout(() => this.show = true, 3000);
    }
}

angular
    .module('fundersclub.publicPages')
    .component('fcIcpInvitePromo', new FcIcpInvitePromo());
