class FcCompanyAssetTrackingSummaryTable implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        companiesAssetTrackingData: '<',
        currentBatchDate: '<',
    };
    public controller = FcCompanyAssetTrackingSummaryTableController;
    public templateUrl = 'investment-assets/company-asset-tracking-summary-table.component.html';
}

class FcCompanyAssetTrackingSummaryTableController implements ng.IComponentController {
    public companiesAssetTrackingData: any;
    public currentBatchDate: any;

    private assetTrackings: any;
    private creatingNewBatch: boolean;
    private errors: any;
    private filteredAssetTrackings: any;
    private filters: any;

    constructor(
        private $http: ng.IHttpService,
        private $window: ng.IWindowService,
        private $location: ng.ILocationService,
        private CompanyAssetTracking: any
    ) {
        this.filters = {
            notAcquisitionDissolutionOrIPO: false,
            onlyAcquiredCompanies: false,
            onlyDissolvedCompanies: false,
            onlyMissingProceeds: false,
            onlyMissingTerms: false,
            onlySecondarySell: false,
            onlyStepsMissing: false,
            onlyUnreviewedAssets: false,
            onlyUnreviewedCorpActions: false,
            onlyUnreviewedMarkdowns: false,
            onlyValuationSourceSanityFailed: false,
            onlyWireSanityFailed: false,
        };
    }

    public $onInit() {
        this.assetTrackings = this.companiesAssetTrackingData.map((data: any) => {
            return  new this.CompanyAssetTracking(data);
        });
        this.updateFilters();
    }

    /* tslint:disable:no-unused-variable */
    private createNewBatch(batchDate: string) {
        this.errors = {};
        this.creatingNewBatch = true;
        this.$http({
            data: {
                batch_date: batchDate,
            },
            method: 'POST',
            url: this.$location.url(),
        }).then(
            () => {
                window.location.reload();
            },
            (response) => {
                this.creatingNewBatch = false;
                this.errors = response.data;
            }
        );
    }

    /* tslint:disable:no-unused-variable */
    private updateFilters() {
        this.filteredAssetTrackings = this.assetTrackings.slice();

        if (this.filters.onlyAcquiredCompanies) {
            this.filteredAssetTrackings = this.filteredAssetTrackings.filter((data: any) => {
                return data.has_been_acquired;
            });
        }

        if (this.filters.onlyDissolvedCompanies) {
            this.filteredAssetTrackings = this.filteredAssetTrackings.filter((data: any) => {
                return data.has_been_dissolved;
            });
        }

        if (this.filters.onlyStepsMissing) {
            this.filteredAssetTrackings = this.filteredAssetTrackings.filter((data: any) => {
                return (data.has_assets_with_missing_terms ||
                        data.corpActionsIsUserActionNeeded() ||
                        data.assets_review_status !== 'green' ||
                        data.markdowns_reviewed === '' ||
                        data.wire_sanity.length !== 0 ||
                        !data.are_valuation_rounds_sane);
            });
        }

        if (this.filters.onlyMissingTerms) {
            this.filteredAssetTrackings = this.filteredAssetTrackings.filter((data: any) => {
                return data.has_assets_with_missing_terms;
            });
        }

        if (this.filters.onlyUnreviewedCorpActions) {
            console.log('sux');
            this.filteredAssetTrackings = this.filteredAssetTrackings.filter((data: any) => {
                return data.corpActionsIsUserActionNeeded();
            });
        }

        if (this.filters.onlyUnreviewedAssets) {
            this.filteredAssetTrackings = this.filteredAssetTrackings.filter((data: any) => {
                return data.assets_review_status !== 'green';
            });
        }

        if (this.filters.onlyUnreviewedMarkdowns) {
            this.filteredAssetTrackings = this.filteredAssetTrackings.filter((data: any) => {
                return data.markdowns_reviewed === '';
            });
        }

        if (this.filters.onlyWireSanityFailed) {
            this.filteredAssetTrackings = this.filteredAssetTrackings.filter((data: any) => {
                return data.wire_sanity.length !== 0;
            });
        }

        if (this.filters.onlyMissingProceeds) {
            this.filteredAssetTrackings = this.filteredAssetTrackings.filter((data: any) => {
                return data.missing_proceeds;
            });
        }

        if (this.filters.onlyValuationSourceSanityFailed) {
            this.filteredAssetTrackings = this.filteredAssetTrackings.filter((data: any) => {
                return !data.are_valuation_rounds_sane;
            });
        }

        if (this.filters.notAcquisitionDissolutionOrIPO) {
            this.filteredAssetTrackings = this.filteredAssetTrackings.filter((data: any) => {
                return data.not_acquisition_dissolution_or_ipo || data.has_been_closed;
            });
        }

        if (this.filters.onlySecondarySell) {
            this.filteredAssetTrackings = this.filteredAssetTrackings.filter((data: any) => {
                return data.secondary_sell;
            });
        }
    }
}

angular
    .module('fundersclub.assets')
    .component('fcCompanyAssetTrackingSummaryTable', new FcCompanyAssetTrackingSummaryTable());
