do ->
    angular
        .module 'fundersclub'
        .config (
            $interpolateProvider,
            $provide,
            $windowProvider,
            $urlMatcherFactoryProvider,
            $httpProvider,
        ) ->
            $window = $windowProvider.$get()
            $urlMatcherFactoryProvider.strictMode(false)

            # TODO: Come up with a better plan to selectively use
            # angular routing
            if not $window.clientSideRouting
                # http://stackoverflow.com/questions/18611214/turn-off-url-manipulation-in-angularjs
                $provide.decorator('$browser', ['$delegate', (($delegate) ->
                    $delegate.onUrlChange = ->
                    $delegate.url = -> ""
                    $delegate
                )])

            # Support header injection for overriding the user id used for API requests
            $httpProvider.interceptors.push('apiUserIdHttpRequestInterceptor')

            # Angular does not provide a way to disable {{ }} interpolation, yet we have
            # no use of it and want to protect ourselves from XSS, so this hack is used.
            # The idea is that every time the page loads the start and stop symbols will be
            # completely random, and as such an attacker can never predict them.
            randStr = (length=40) ->
                id = ""
                id += Math.random().toString(36).substr(2) while id.length < length
                id.substr 0, length
            $interpolateProvider.startSymbol(randStr()).endSymbol(randStr())

            # Configure WYSIWYG editor.
            $provide.decorator 'taOptions', [
                'taRegisterTool', '$delegate', 'fcUploadManager',
                (taRegisterTool, taOptions, fcUploadManager) ->

                    # Add image upload button.
                    taRegisterTool 'fcInsertImage',
                        iconclass: 'fa fa-image'
                        action: ->
                            editorEl = $(@$editor().displayElements.text[0])
                            inputEl = $('<input style="display: none;" type="file" />')

                            inputEl.on 'change', (event) ->
                                fcUploadManager
                                    .uploadImage(event.target.files[0], editorEl)
                                    .finally ->
                                        inputEl.remove()

                            $($window.document.body).append(inputEl)
                            inputEl.click()

                    # To use our own custom $sanitize method and not textAngularSanitize
                    taOptions.forceTextAngularSanitize = false

                    # Add video link button.
                    taRegisterTool 'fcInsertVideo',
                        iconclass: 'fa fa-video-camera'
                        action: ->
                            promptText = "Please copy and paste the embed code for the video you would like to add"
                            embedHTML = window.prompt(promptText)
                            if embedHTML
                                @$editor().wrapSelection('insertHTML', embedHTML, true)

                    selectionParentElement = (tag_name) ->
                        node = $window.getSelection().focusNode.parentNode
                        while node
                            if node.localName == tag_name
                                return node
                            node = node.parentNode
                        return null

                    wrapElement = (el, tag_name) ->
                        el.outerHTML = "<#{tag_name}>#{el.outerHTML}</#{tag_name}>"

                    # Add indent button that only works in list elements
                    taRegisterTool 'fcIndent',
                        iconclass: 'fa fa-indent'
                        action: ->
                            node = selectionParentElement('li')
                            if node
                                wrapElement(node, 'ul')
                        commandKeyCode: 'TabKey'

                    # Add outdent button that only works in list elements
                    taRegisterTool 'fcOutdent',
                        iconclass: 'fa fa-outdent'
                        action: ->
                            node = selectionParentElement('li')
                            if node
                                @$editor().wrapSelection('outdent', null)
                        commandKeyCode: 'ShiftTabKey'

                    # Define toolbar buttons.
                    taOptions.toolbar = [
                        ['bold', 'italics', 'underline', 'ul', 'ol', 'fcIndent', 'fcOutdent', 'clear']
                        ['insertLink', 'fcInsertImage', 'fcInsertVideo',]
                    ]

                    angular.extend(taOptions.classes,
                        toolbarGroup: 'btn-group btn-group-sm'
                        toolbarButton: 'btn btn-sm btn-default'
                    )

                    ###
                    Enables:
                        1) Pasting images from clipboard
                        2) Underline using keyboard shortcut
                    ###
                    taOptions.setup.textEditorSetup = ($element) ->
                        $element.on 'paste', (event) ->
                            data = (event.clipboardData or event.originalEvent.clipboardData)
                            if data.items
                                file = data.items[0].getAsFile()
                                if file
                                    fcUploadManager.uploadImage file, $element

                        $element.on 'keydown', (event) ->
                            if (event.metaKey or event.crtlKey) and event.keyCode == 85
                                $window.document.execCommand('underline', false, null)

                    taOptions
            ]
