class FcSpecialDistSell implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        endDate: '<',
        fundName: '<',
        legalName: '<',
        sellData: '<',
    };
    public controller = FcSpecialDistSellController;
    public templateUrl = 'funds/special-dist-sell.component.html';
}

class FcSpecialDistSellController implements ng.IComponentController {
    public endDate: string;
    public fundName: string;
    public isNumber: any;
    public legalName: string;
    public sellData: any;

    private claiming: boolean = false;
    private errors: any;
    private generatingLegalDoc: boolean = false;
    private isSafari: boolean;
    private legalDoc: any;
    private participationCheckbox: any;
    private popupWindow: any;
    private sell: any;
    private sellPercents: any;

    constructor(
        private $uibModal: any,
        private $window: any,
        private FCChoices: any,
        private SignableLegalDocument: any,
        private SpecialDistSell: any,
        private _: UnderscoreStatic
    ) {}

    public $onInit() {
        this.sell = new this.SpecialDistSell(this.sellData);

        switch (this.sell.participation) {
            case this.FCChoices.SpecialDistSellParticipation.NotInterested:
                this.participationCheckbox = '0';
                break;

            case this.FCChoices.SpecialDistSellParticipation.PercentBalance:
                if ((this.sell.sell_percents_requested === '25.00') ||
                    (this.sell.sell_percents_requested === '50.00') ||
                    (this.sell.sell_percents_requested === '75.00') ||
                    (this.sell.sell_percents_requested === '100.00')) {
                   this.participationCheckbox = this.sell.sell_percents_requested.split('.')[0];
                } else {
                   this.participationCheckbox = 'other';
                   this.sellPercents = this.sell.sell_percents_requested;
                }
                break;

            default:
                break;
        }

        if (!this._.isEmpty(this.sell.legal_doc)) {
            this.legalDoc = new this.SignableLegalDocument(this.sell.legal_doc);
        } else {
            // subscription doc not generated
            this.legalDoc = new this.SignableLegalDocument({
                id: null,
                status: null,
            });
        }
        this.legalDoc.pollForUpdates();

        this.isSafari = (
            this.$window.navigator.userAgent.indexOf('Safari') !== -1 &&
            this.$window.navigator.userAgent.indexOf('Chrome') === -1
        );
    }

    public claim() {
        this.claiming = true;
        this.errors = {};
        this.sell.claim()
            .then((response: any) => {
                angular.extend(this.sell, response.data);
            })
            .catch((response: any) => {
                angular.extend(this.errors, response.data);
            })
            .finally(() => {
                this.claiming = false;
            });
    }

    public signLegalDoc() {
        if (this.legalDoc.signing_url) {
            if (this.isSafari) {
                this.popupWindow = this.$window.open(
                    this.legalDoc.signing_url,
                    'Popup',
                    'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=no,width=800,height=600'
                );

                this.$window.docPostSignCallback = (doc: any) => {
                    angular.extend(this.legalDoc, doc);
                    this.$window.docPostSignCallback = null;
                    this.popupWindow.close();
                };
            } else {
                this.$uibModal.open({
                    animation: false,
                    ariaDescribedBy: 'modal-body',
                    ariaLabelledBy: 'modal-title',
                    component: 'fcSignLegaldocModal',
                    resolve: {
                        docName: () => 'Request for Special Distribution Election Form',
                        getLegaldocStatus: () => this.getLegaldocStatus.bind(this),
                        getSigningUrl: () => this.getSigningUrl.bind(this),
                        onResetForm: () => this.onResetForm.bind(this),
                    },
                    size: 'lg',
                    windowClass: 'complete-investment--sign-legal-doc--modal',
                });
            }
        } else {
            this.generatingLegalDoc = true;
            this.errors = {};

            switch (this.participationCheckbox) {
                case '0':
                    this.sell.participation = this.FCChoices.SpecialDistSellParticipation.NotInterested;
                    this.sell.sell_percents_requested = this.participationCheckbox;
                    break;

                case '25':
                case '50':
                case '75':
                case '100':
                    this.sell.participation = this.FCChoices.SpecialDistSellParticipation.PercentBalance;
                    this.sell.sell_percents_requested = this.participationCheckbox;
                    break;

                case 'other':
                    this.sell.participation = this.FCChoices.SpecialDistSellParticipation.PercentBalance;
                    this.sell.sell_percents_requested = this.sellPercents;
                    break;

                default:
                    this.sell.participation = this.FCChoices.SpecialDistSellParticipation.Unknown;
                    this.sell.sell_percents_requested = null;
                    break;
            }

            this.sell.generateLegalDoc()
                .then((response: any) => {
                    angular.extend(this.sell, response.data);
                    angular.extend(this.legalDoc, response.data.legal_doc);
                    this.signLegalDoc();
                })
                .catch((response: any) => {
                    angular.extend(this.errors, response.data);
                })
                .finally(() => {
                    this.generatingLegalDoc = false;
                });
        }
    }

    public sellAmountByPercents(percents: any) {
        return parseFloat(this.sell.max_shares_value) * (parseFloat(percents) / 100.0);
    }

    private getLegaldocStatus(): string {
        return this.legalDoc.status;
    }

    private getSigningUrl(): string {
        return this.legalDoc.signing_url;
    }

    private onResetForm(): void {
        return this.sell.abandonLegalDoc()
            .then((response: any) => {
                angular.extend(this.sell, response.data);
                angular.extend(this.legalDoc, response.data.legal_doc);
            });
    }
}

angular
    .module('fundersclub.funds')
    .component('fcSpecialDistSell', new FcSpecialDistSell());
