let fcConfirmTransaction = (Transaction: any) => {
    let thisScope = {};
    let thisController = function($state: any, $stateParams: any) {
        if (!($stateParams.transaction || $stateParams.transactionId)) {
            $state.go('icp');
        }

        let vm = this;
        vm.confirmingCharge = false;

        if (!$stateParams.transaction) {
            vm.transactionLoaded = false;
            vm.transaction = new Transaction({'id': $stateParams.transactionId});
            vm.transaction
                .$get()
                .then(function(response: any) {
                    vm.transactionLoaded = true;
                    angular.extend(vm.transaction, response);
                })
                .catch(function() {
                    $state.go('icp');
                });
        } else {
            vm.transactionLoaded = true;
            vm.transaction = new Transaction($stateParams.transaction);
        }

        vm.confirmCharge = function() {
            vm.confirmingCharge = true;
            vm.transaction.confirmCharge()
                .then(function(response: any) {
                    vm.confirmingCharge = false;
                    angular.extend(vm.transaction, response.data);
                    $state.go('icp', {'transaction': vm.transaction});
                });
        };
    };
    let thisDirective = {
        bindToController: true,
        controller: thisController,
        controllerAs: 'ctc',
        restrict: 'E',
        scope: thisScope,
        templateUrl: 'icp/confirm-transaction.directive.html',
    };
    return thisDirective;
};
angular
    .module('fundersclub.icp')
    .directive('fcConfirmTransaction', fcConfirmTransaction);
