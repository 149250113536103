class FcCompanyAssetTrackingAssetsReview implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        assetsData: '<',
        companyAssetTrackingData: '<',
    };
    public controller = FcCompanyAssetTrackingAssetsReviewController;
    public templateUrl = 'investment-assets/company-asset-tracking-assets-review.component.html';
}

class FcCompanyAssetTrackingAssetsReviewController implements ng.IComponentController {
    public assetsData: any;
    public companyAssetTrackingData: any;

    private assetTracking: any;
    private assets: any;

    constructor(
        private CompanyAssetTracking: any,
        private InvestmentAsset: any
    ) {}

    public $onInit() {
        this.assetTracking = new this.CompanyAssetTracking(this.companyAssetTrackingData);

        this.assets = this.assetsData.map((asset: any) => {
            return new this.InvestmentAsset(asset);
        });
    }

    /* tslint:disable:no-unused-variable */
    private done() {
        return this.assets.every((asset: any) => {
            return asset.asset_review_completed;
        });
    }
}

angular
    .module('fundersclub.assets')
    .component('fcCompanyAssetTrackingAssetsReview', new FcCompanyAssetTrackingAssetsReview());
