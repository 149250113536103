do ->
    InvestmentAccountsController = (AutoInvestPreferences, FCAutoInvestPreferencesData) ->
        vm = @
        vm.autoInvestPreferences = new AutoInvestPreferences(
            FCAutoInvestPreferencesData.auto_invest_preferences
        )
        vm.autoInvestAvailableInvestorProfiles = FCAutoInvestPreferencesData.available_investor_profiles

        return

    angular
        .module 'fundersclub.users'
        .controller 'InvestmentAccountsController', InvestmentAccountsController
