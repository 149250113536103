let fcAutoInvestExpressInterest = (AutoInvestPreferences: any) => {
    let thisScope = {
        'autoInvestPreferences': '=fcAutoInvestExpressInterest',
        'changeSettingsUrl': '@changeSettingsUrl',
        'investableFundsExist': '@investableFundsExist',
    };
    let thisController = function() {
        let vm = this;

        /* initialize - we want these as false rather than null */
        vm.autoInvestPreferences.dont_want_single_asset = false;
        vm.autoInvestPreferences.dont_want_multi_asset = false;

        /* we want this to default to true */
        vm.autoInvestPreferences.create_reservations = true;

        vm.save = () => {
            vm.errors = {};
            let tmpAutoInvestPref = angular.copy(vm.autoInvestPreferences);

            /* change the values to null so that the API knows that the user is declining to invest intentionally (vs
             * has just entered 0.00 into the input box) */
            tmpAutoInvestPref.single_asset_amount = vm.autoInvestPreferences.single_asset_amount || 0.0;
            tmpAutoInvestPref.multi_asset_amount = vm.autoInvestPreferences.multi_asset_amount || 0.0;

            tmpAutoInvestPref.$save().then((response: any) => {
                vm.autoInvestPreferences = new AutoInvestPreferences(response);
            }).catch((response: any) => {
                angular.extend(vm.errors, response.data);
            }).finally(() => {
                vm.fcSave.saveAttemptFinished(vm.errors);
            });
        };

        vm.onToggleDontWantSingleAsset = () => {
            if (vm.autoInvestPreferences.dont_want_single_asset) {
                vm.autoInvestPreferences.single_asset_amount = 0.0;
            } else {
                vm.autoInvestPreferences.single_asset_amount = null;
            }
        };

        vm.onToggleDontWantMultiAsset = () => {
            if (vm.autoInvestPreferences.dont_want_multi_asset) {
                vm.autoInvestPreferences.multi_asset_amount = 0.0;
            } else {
                vm.autoInvestPreferences.multi_asset_amount = null;
            }
        };
    };
    let thisDirective = {
        bindToController: true,
        controller: thisController,
        controllerAs: 'aeic',
        restrict: 'A',
        scope: thisScope,
        templateUrl: 'autoinvest/express-interest.directive.html',
    };
    return thisDirective;
};
angular.module('fundersclub.autoinvest').directive('fcAutoInvestExpressInterest', fcAutoInvestExpressInterest);
