do ->

    # Form responsible for displaying and editing fund screenshots.
    fcInvestmentTermsEdit = (Fund, InvestmentTerms, FCFundData, FCChoices) ->
        thisScope =
            'terms': '=fcInvestmentTermsEdit'
            'isEmbeddedInWireRequestForm': '@isEmbeddedInWireRequestForm'

        thisController = ($scope, $attrs) ->
            vm = @
            vm.fund = new Fund(FCFundData.fund)
            vm.choices = FCChoices
            initialTermsData = angular.copy(vm.terms)

            vm.currency = ->
                for choice in vm.choices.CurrencyChoices.choices
                    if choice[0] == vm.terms.security_currency
                        return choice[1]

            vm.save = ->
                vm.errors = {}
                # hasError is used if we are embedded in a WireRequestForm
                vm.hasError = false
                vm.terms.$save()
                    .then (response) ->
                        angular.copy(response, initialTermsData)
                    .catch (response) ->
                        angular.extend(vm.errors, response.data)
                        vm.hasError = true
                    .finally () ->
                        vm.fcSave.saveAttemptFinished(vm.errors)

            vm.cancel = ->
                angular.extend(vm.terms, initialTermsData)
                vm.errors = {}

            # A wire request has an investment terms object associated with it.
            # Instead of copying over a bunch of HTML and potentially missing out
            # on logic/display changes, instead we want to embed this directive in
            # the wire request directive.  When we save the wire request form we need
            # to first save this form, so the wire request directivce needs access to this
            # directive to call its save method
            if vm.isEmbeddedInWireRequestForm
                $scope.$parent.investmentTermsDirective = vm

            return


        thisDirective =
            templateUrl: 'funds/investment-terms-edit.directive.html'
            restrict: 'A'
            require: '^form'
            scope: thisScope
            bindToController: true
            controllerAs: 'itec'
            controller: thisController
            replace: true
        return thisDirective

    angular
        .module 'fundersclub.funds'
        .directive 'fcInvestmentTermsEdit', fcInvestmentTermsEdit
