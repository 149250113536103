class FcCompanyAssetTrackingCorpActions implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        companyAssetTrackingData: '<',
    };
    public controller = FcCompanyAssetTrackingCorpActionsController;
    public templateUrl = 'investment-assets/company-asset-tracking-corp-actions.component.html';
}

class FcCompanyAssetTrackingCorpActionsController implements ng.IComponentController {
    public companyAssetTrackingData: any;

    private allCorpActionsAdded: any;
    private assetTracking: any;
    private errors: {[error: string]: any} = {};
    private fcSave: any;
    private newCorpActions: any;

    constructor(
        private CompanyAssetTracking: any
    ) {}

    public $onInit() {
        this.assetTracking = new this.CompanyAssetTracking(this.companyAssetTrackingData);
    }

    /* tslint:disable:no-unused-variable */
    private done() {
        return !this.assetTracking.corpActionsIsUserActionNeeded();
    }

    /* tslint:disable:no-unused-variable */
    private reset() {
        this.allCorpActionsAdded = false;
        this.assetTracking.corp_actions_reviewed = false;

        this.assetTracking
            .$save(['corp_actions_reviewed'])
            .catch((response: any) => {
                alert('Failed resetting. Please refresh the page!');
            });
    }

    /* tslint:disable:no-unused-variable */
    private saveCorpActionsReviewed() {
        this.errors = {};

        const doneWithNewCorpActions = (
            (this.newCorpActions === false) ||
            (this.allCorpActionsAdded)
        );

        if (doneWithNewCorpActions) {
            this.assetTracking.corp_actions_reviewed = true;
        }

        this.assetTracking
            .$save(['corp_actions_reviewed'])
            .then((response: any) => {
                // Empty on purpose
            })
            .catch((response: any) => {
                angular.extend(this.errors, response.data);
            })
            .finally(() => this.fcSave.saveAttemptFinished(this.errors));
    }
}

angular
    .module('fundersclub.assets')
    .component('fcCompanyAssetTrackingCorpActions', new FcCompanyAssetTrackingCorpActions());
