class FcFillEntityAccreditationModal implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        modalInstance: '<',
        resolve: '<',
    };
    public controller = FcFillEntityAccreditationModalController;
    public templateUrl = 'users/fill-entity-accreditation-modal.component.html';
}

class FcFillEntityAccreditationModalController implements ng.IComponentController {
    public modalInstance: ng.ui.bootstrap.IModalServiceInstance;
    public resolve: {
        investorProfile: IInvestorProfile,
        onEntityAccreditationQuestionnaireSubmitted: any,
    };
    public investment: any;
    public investorProfile: any;

    public $onInit(): void {
        this.investment = {
            id: -1,
            questionnaire_completed: false,
        };
        this.investorProfile = angular.copy(this.resolve.investorProfile);

    }

    public onEntityAccreditationQuestionnaireSubmitted(): void {
        this.modalInstance.close();
        this.resolve.onEntityAccreditationQuestionnaireSubmitted();
    }
}

angular
    .module('fundersclub.users')
    .component('fcFillEntityAccreditationModal', new FcFillEntityAccreditationModal());
