do ->

    fcCompanyAdd = ->
        thisController = ($filter, Company) ->
            $ctrl = @

            newCompanyTemplate =
                company_name: ''
                company_slug: ''
                public_blurb: ''
            $ctrl.newCompany = new Company angular.copy(newCompanyTemplate)
            $ctrl.errors = {}

            $ctrl.add = ->
                $ctrl.errors = {}
                $ctrl.addForm.hasError = false
                $ctrl.newCompany
                    .$create()
                    .then (response) ->
                        $ctrl.modalInstance.close({company: response})
                    .catch (response) ->
                        angular.extend($ctrl.errors, response.data)
                        $ctrl.addForm.$setDirty()
                        $ctrl.addForm.hasError = true

            $ctrl.cancelAdd = ->
                $ctrl.modalInstance.dismiss()

            $ctrl.newCompanyNameChanged = ->
                $ctrl.newCompany.company_slug = $filter('slugify')($ctrl.newCompany.company_name)

            return

        return thisComponent =
            bindings:
                modalInstance: '<'
            controller: thisController
            templateUrl: 'shared/company-add.component.html'
            require: '^form'


    angular
        .module 'fundersclub'
        .component 'fcCompanyAdd', fcCompanyAdd()
