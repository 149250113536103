class FcInvestmentAssetEditModal implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        modalInstance: '<',
        resolve: '<',
    };
    public controller = FcInvestmentAssetEditModalController;
    public templateUrl = 'investment-assets/investment-asset-edit-modal.component.html';
}

class FcInvestmentAssetEditModalController implements ng.IComponentController {
    public modalInstance: ng.ui.bootstrap.IModalServiceInstance;
    public resolve: {
        asset: any,
        enableEditChildOfProceed: boolean,
        onDeleted: () => void;
        onSaved: (data: {asset: any}) => void;
    };

    private asset: any;

    constructor(private InvestmentAsset: any) {}

    public $onInit(): void {
        this.asset = new this.InvestmentAsset(this.resolve.asset);
    }

    /* tslint:disable:no-unused-variable */
    private onAssetEditingCanceled() {
        this.modalInstance.dismiss();
    }

    /* tslint:disable:no-unused-variable */
    private onAssetSaved(asset: any) {
        this.resolve.onSaved(asset);
        this.modalInstance.close();
    }

    /* tslint:disable:no-unused-variable */
    private onAssetDeleted() {
        this.resolve.onDeleted();
        this.modalInstance.close();
    }

}

angular
    .module('fundersclub.assets')
    .component('fcInvestmentAssetEditModal', new FcInvestmentAssetEditModal());
