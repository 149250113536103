class FcTestHellosign implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        docName: '<',
        legalDoc: '<',
        onResetForm: '&',
        onSigningModalClosed: '&?',
    };
    public controller = FcTestHellosignController;
    public templateUrl = 'icp/test-hellosign.component.html';
}

class FcTestHellosignController implements ng.IComponentController {
    public docName: string;
    public legalDoc: any;
    public onResetForm: () => void;
    public onSigningModalClosed: () => void;
    public signed: any;

    constructor(
        private $scope: ng.IRootScopeService,
        private $state: any,
        private $uibModal: any,
        private $window: any,
        private FCChoices: any
    ) {}

    public $onInit() {
        // HACK: Noramlly we would copy legalDoc to get correct 1 way data binding
        // but rely on polling in this component's parent to update the legalDocs's
        // status so we want the pass by reference behavior here

       this.$uibModal.open({
            animation: false,
            ariaDescribedBy: 'modal-body',
            ariaLabelledBy: 'modal-title',
            component: 'fcTestHellosignModal',
            resolve: {
                docName: () => this.docName,
                getLegaldocStatus: () => this.getLegaldocStatus.bind(this),
                getSigningUrl: () => this.getSigningUrl.bind(this),
                onResetForm: () => this.onResetForm.bind(this),
            },
            size: 'lg',
            windowClass: 'complete-investment--sign-legal-doc--modal',
        }).result.then((result: any) => {
            if (result === 'signed') {
                this.signed = true;
                this.$window.scrollTo(0, 0);
            }
            this.onSigningModalClosed();
        });
    }

    private getLegaldocStatus(): string {
        return this.legalDoc.status;
    }

    private getSigningUrl(): string {
        return this.legalDoc.signing_url;
    }
}

angular
    .module('fundersclub.icp')
    .component('fcTestHellosign', new FcTestHellosign());
