class FcReserveInvestmentFirstTime implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        availableBankAccountData: '<',
        entityInvestorProfileData: '<',
        individualInvestorProfileData: '<',
        investmentData: '<',
    };
    public controller = FcReserveInvestmentFirstTimeController;
    public templateUrl = 'invest/reserve-investment-first-time.component.html';
}

class FcReserveInvestmentFirstTimeController implements ng.IComponentController {
    public availableBankAccountData: any;
    public entityInvestorProfileData: any;
    public individualInvestorProfileData: any;
    public investmentData: any;

    private availableBankAccount: any;
    private entityInvestorProfile: IFcModelResource<IInvestorProfile>;
    private errors: any = {};
    private individualInvestorProfile: IFcModelResource<IInvestorProfile>;
    private investing: boolean = false;
    private investment: IInvestment;
    private investorType: any = '';
    private iraInvestorProfile: IFcModelResource<IInvestorProfile>;
    private paymentMethod: any = '';
    private saveInvestmentAmount: ng.IPromise<any>;

    constructor(
        private $intercom: any,
        private $timeout: ng.ITimeoutService,
        private $uibModal: ng.ui.bootstrap.IModalService,
        private $window: ng.IWindowService,
        private BankAccount: any,
        private FCChoices: any,
        private Investment: IFcModelResource<IInvestment>,
        private InvestorProfile: IFcModelResource<IInvestorProfile>,
        private Raven: RavenStatic
    ) {}

    public $onInit(): void {
        if (this.availableBankAccountData) {
            this.availableBankAccount = new this.BankAccount(
                this.availableBankAccountData
            );
        }
        if (this.individualInvestorProfileData) {
            this.individualInvestorProfile = new this.InvestorProfile(
                this.individualInvestorProfileData
            );

            // IRA investments can only be made by individuals, which is why it's sitting
            // inside this if() block
            this.iraInvestorProfile = new this.InvestorProfile();
        }

        this.entityInvestorProfile = new this.InvestorProfile(
            this.entityInvestorProfileData || {
                investor_type: this.FCChoices.InvestorTypeChoices.Entity,
            }
        );

        this.investment = new this.Investment(this.investmentData);
    }

    /* tslint:disable:no-unused-variable */
    private invest(): ng.IHttpPromise<any> {
        this.investing = true;

        let investorProfile: IFcModelResource<IInvestorProfile>;
        switch (this.investorType) {
            case this.FCChoices.InvestorTypeChoices.Individual:
                investorProfile = this.individualInvestorProfile;
                break;

            case this.FCChoices.InvestorTypeChoices.Entity:
                investorProfile = this.entityInvestorProfile;
                break;

            case this.FCChoices.InvestorTypeChoices.IRA:
                investorProfile = this.iraInvestorProfile;
                break;

            default:
                investorProfile = null;
                break;
        }

        this.errors = {};
        let investPromise = this.investment.reserveFirstTime(
            this.paymentMethod,
            this.availableBankAccount ? this.availableBankAccount.url : null,
            investorProfile,
            this.investorType ? this.investorType : null
        );
        investPromise.then((response: any) => {
            this.$window.location.href = response.data.url;
        }).catch((response: any) => {
            this.investing = false;

            // Server returns well-formed objects.
            if (angular.isObject(response.data)) {
                angular.extend(this.errors, response.data);

                if (this.errors.investor_profile) {
                    this.$window.scrollTo(0, 350);
                }

                return;
            }

            // We need to handle other errors explicitly.
            let ravenMessage = '';
            let errorMessage = '';
            switch (response.status) {
                case 404:
                    ravenMessage = 'Investment failed: ReserveFirstTime API call returned 404';
                    errorMessage = `
                        We could not complete your investment at this time.
                        Please refresh this page and try again. (Error 404)
                    `;
                    break;
                case 500:
                    ravenMessage = 'Investment failed: ReserveFirstTime API call returned 500';
                    errorMessage = `
                        We were unable to process your investment at this time.
                        Our engineering team has been notified.
                        Please try again in a few moments. (Error 500)
                    `;
                    break;
                // If the request times out, $http returns a -1 status code.
                case -1:
                    ravenMessage = 'Investment failed: ReserveFirstTime API call timed out';
                    errorMessage = `
                        We could not complete your investment at this time.
                        Please ensure that you are currently online and try again.
                        (Error -1)
                    `;
                    break;
                default:
                    ravenMessage = 'Investment failed: ReserveFirstTime API call failed';
                    errorMessage = `
                        We could not complete your investment at this time.
                        Please refresh this page and try again.
                    `;
            }

            angular.extend(this.errors, {
                'non_field_errors': [errorMessage],
            });
            this.Raven.captureMessage(ravenMessage, {
                extra: {
                    availableBankAccount: this.availableBankAccount,
                    investment: this.investment,
                    investorProfile: investorProfile,
                    paymentMethod: this.paymentMethod,
                    response: response,
                    targetUrl: `/api/investments/${this.investment.id}/reserve_first_time/`,
                },
            });
        });

        return investPromise;
    }

    /* tslint:disable:no-unused-variable */
    private isOnlinePayment() {
        return this.paymentMethod === this.FCChoices.ReservationPaymentMethods.Online;
    }

    /* tslint:disable:no-unused-variable */
    private onInvestmentAmountUpdated(investmentAmount: string): void {
        this.investment.investment_amount = parseFloat(investmentAmount.replace(/,/g, ''));

        if (this.saveInvestmentAmount) {
            this.$timeout.cancel(this.saveInvestmentAmount);
        }

        this.saveInvestmentAmount = this.$timeout(() => {
            this.investment.backgroundSave(['investment_amount'], this.errors);
        }, 500);
    }

    /* tslint:disable:no-unused-variable */
    private onBankAccountAdded(bankAccount: any) {
        this.availableBankAccount = bankAccount;
    }

    /* tslint:disable:no-unused-variable */
    private reservedAmount(): number {
        return this.investment.investment_amount - this.waitlistedAmount();
    }

    /* tslint:disable:no-unused-variable */
    private waitlistedAmount(): number {
        if (
            this.investment.fund.is_in_followon_early_access_state ||
            (this.investment.fund.has_spots_left && !this.investment.fund.has_maximum)
        ) {
            return 0;
        }

        let amount: number = this.investment.investment_amount || 0;

        // If out of spots, entire investment is on waitlist.
        if (!this.investment.fund.has_spots_left) {
            return amount;
        // Otherwise, only portion that currently won't fit in fund is
        // waitlisted (which could be entire amount).
        } else {
            return Math.max(
                0,
                amount - this.investment.fund.amount_under_oversubscribed_without_minimum
            );
        }
    }

    /* tslint:disable:no-unused-variable */
    private iraAllowed(): boolean {
        // Check if the fund allows IRA investments
        if (!this.investment.fund.accepts_ira_investments) {
            return false;
        }

        // Check if we're a proxy VC - proxy VCs won't have an individual investor profile
        if (!this.individualInvestorProfile) {
            return false;
        }

        // We can invest using IRA!
        return true;
    }
}

angular
    .module('fundersclub.invest')
    .component('fcReserveInvestmentFirstTime', new FcReserveInvestmentFirstTime());
