do ->

    # Form responsible for creating new company news items.
    fcCompanyNewsItemAdd = (CompanyNewsItem, $timeout) ->
        thisScope =
            'add_form_enabled': '=addFormEnabled'
            'news_items': '=newsItems'
            'company_url': '@fcCompanyNewsItemAdd'
            'expandCallback': '&onExpand'
            'collapseCallback': '&onCollapse'

        thisController = ->
            vm = @
            vm.errors = {}
            vm.add_form_expanded = false

            news_item_template =
                company: vm.company_url

            vm.news_item = angular.copy(news_item_template)

            # Expand the form for adding a news item.
            vm.expandAddForm = ->
                vm.add_form_expanded = !vm.add_form_expanded
                if vm.add_form_expanded
                    vm.expandCallback()
                else
                    vm.collapseCallback()
                    $timeout(->
                        vm.resetForm()
                    , 1500)

            # Add
            vm.add = ->
                vm.errors = {}
                news_item = new CompanyNewsItem(vm.news_item)
                news_item.$save()
                    # unsaved-warning-form monitors the form pristine/dirty state
                    .then (response) ->
                        vm.news_items.unshift(response)
                        vm.news_item = angular.copy(news_item_template)
                        vm.expandAddForm()
                    .catch (response) ->
                        angular.extend(vm.errors, response.data)
                    .finally () ->
                        vm.fcSave.saveAttemptFinished(vm.errors)

            # Resets the form state.
            vm.resetForm = ->
                # Clear form feedback, clear out input values.
                vm.errors = {}
                vm.news_item = angular.copy(news_item_template)
                vm.form.$setPristine()

            # Cancel
            vm.cancel = ->
                vm.expandAddForm()

            return

        thisDirective =
            templateUrl: 'companies/news-and-press-add.directive.html'
            restrict: 'A'
            scope: thisScope
            bindToController: true
            controllerAs: 'anic'
            controller: thisController
        return thisDirective

    angular
        .module 'fundersclub.companies'
        .directive 'fcCompanyNewsItemAdd', fcCompanyNewsItemAdd
