angular
    .module('fundersclub.founderOnboarding')
    .config(function(
        $stateProvider: any,
        $windowProvider: any
    ) {
        let $window = $windowProvider.$get();

        if ($window.clientSideRouting && $window.founderOnboardingRouting) {
            $stateProvider
                .state('founderOnboarding', {
                    template: `<ui-view></ui-view>`,
                    url: '/',
                })
                .state('founderOnboarding.completeSignup', {
                    template: `
                        <fc-founder-onboard-complete-signup
                            company="$ctrl.company"
                            founder="$ctrl.founder"
                            linkedin-signup-url="$ctrl.linkedinSignupUrl"
                            user="$ctrl.user"
                        ></fc-founder-onboard-complete-signup>
                    `,
                    url: 'complete-signup',
                })
                .state('founderOnboarding.userDetails', {
                    template: `
                        <fc-founder-onboard-user-details
                            founder="$ctrl.founder"
                            next-state="'founderOnboarding.founderDetails'"
                            on-founder-updated="$ctrl.onFounderUpdated(founder)"
                            on-user-updated="$ctrl.onUserUpdated(user)"
                            user="$ctrl.user"
                        ></fc-founder-onboard-user-details>
                    `,
                    url: 'user-details',
                })
                .state('founderOnboarding.founderDetails', {
                    template: `
                        <fc-founder-onboard-founder-details
                            company="$ctrl.company"
                            founder="$ctrl.founder"
                            next-state="'founderOnboarding.companyDetails'"
                            on-updated="$ctrl.onFounderUpdated(founder)"
                        ></fc-founder-onboard-founder-details>
                    `,
                    url: 'founder-details',
                })
                .state('founderOnboarding.companyDetails', {
                    template: `
                        <fc-founder-onboard-company-details
                            company="$ctrl.company"
                            next-state="'founderOnboarding.inviteFounders'"
                            on-updated="$ctrl.onCompanyUpdated(company)"
                        ></fc-founder-onboard-company-details>
                    `,
                    url: 'company-details',
                })
                .state('founderOnboarding.inviteFounders', {
                    template: `
                        <fc-founder-onboard-invite-founders
                            company="$ctrl.company"
                            founders="$ctrl.founders"
                            next-state="$ctrl.inviteFoundersRouteNextState"
                            on-invited="$ctrl.onFounderInvited(founder)"
                        ></fc-founder-onboard-invite-founders>
                    `,
                    url: 'invite-founders',
                })
                .state('founderOnboarding.swagSizes', {
                    template: `
                        <fc-founder-onboard-swag-sizes
                            founders="$ctrl.founders"
                            next-state="'founderOnboarding.mailingAddress'"
                            on-updated="$ctrl.onFounderInListUpdated(founder)"
                        ></fc-founder-onboard-swag-sizes>
                    `,
                    url: 'swag-sizes',
                })
                .state('founderOnboarding.mailingAddress', {
                    template: `
                        <fc-founder-onboard-mailing-address
                            company="$ctrl.company"
                            next-state="'founderOnboarding.updatesAddress'"
                            on-updated="$ctrl.onCompanyUpdated(company)"
                        ></fc-founder-onboard-mailing-address>
                    `,
                    url: 'mailing-address',
                })
                .state('founderOnboarding.updatesAddress', {
                    template: `
                        <fc-founder-onboard-updates-address
                            company="$ctrl.company"
                            next-state="'founderOnboarding.updatesSettings'"
                        ></fc-founder-onboard-updates-address>
                    `,
                    url: 'updates-address',
                })
                .state('founderOnboarding.updatesSettings', {
                    template: `
                        <fc-founder-onboard-updates-settings
                            company="$ctrl.company"
                        ></fc-founder-onboard-updates-settings>
                    `,
                    url: 'updates-settings',
                });
        }
    });
