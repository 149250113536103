do ->

    fcWireRequestAdd = (FCFundData, WireRequest) ->
        thisScope =
            'collapseCallback': '&onCollapse'
            'createdCallback': '&onCreate'
            'edit_form_enabled': '=editFormEnabled'
            'expandCallback': '&onExpand'
            'fromWithheldInvestable': '=fromWithheldInvestable'
            'isFcvc': '=isFcvc'

        thisController = ($scope) ->
            vm = @
            vm.edit_form_expanded = false
            vm.new_company_form_expanded = false
            vm.allCompanies = FCFundData.all_companies
            vm.countersigned_amount_net_of_fees_remaining = FCFundData.countersigned_amount_net_of_fees_remaining

            setNewWireRequest = ->
                template = angular.copy(wireRequestTemplate)
                template.investment_terms = FCFundData.investmentTerms
                vm.wireRequest = new WireRequest(template)

            wireRequestTemplate =
                automatically_submit_wire: false
                fund: FCFundData.fund.url
                company: FCFundData.company
                wiring_instructions: ''
                wiring_instructions_file: ''
            setNewWireRequest()

            vm.wiringInstructionsFileChanged = ($files) ->
                if not $files or not $files.length then return
                file = $files[0]
                if not file or not file.size then return

                vm.wireRequest.wiring_instructions_file = file
                vm.wireRequest.wiring_instructions_file_url = ''
                vm.form.$setDirty()

            vm.deleteWiringInstructions = ->
                vm.wireRequest.wiring_instructions = ''
                vm.wireRequest.wiring_instructions_file = ''

            # Expand the add form for this wire request.
            vm.expandAddForm = (expand_form) ->
                currently_expanded = vm.edit_form_expanded
                becoming_expanded = if typeof expand_form is 'boolean' then expand_form else !currently_expanded

                # Don't allow user to collapse form unless they've dealt with their
                # changes (e.g. Save or Cancel).
                if not becoming_expanded and not vm.form.$pristine then return

                # If a expansion callback was provided, fire it off.
                vm.edit_form_expanded = becoming_expanded
                if becoming_expanded then vm.expandCallback() else vm.collapseCallback()

            vm.saveInvestmentTerms = ->
                vm.errors = {}
                investmentTermsDirective = $scope.investmentTermsDirective
                investmentTermsDirective
                    .save()
                    .finally ->
                        if investmentTermsDirective.hasError
                            vm.fcSave.saveAttemptFinished(investmentTermsDirective.errors)
                        else
                            vm.save()

            vm.save = ->
                vm.errors = {}
                wireRequest = angular.copy(vm.wireRequest)
                wireRequest.company = vm.wireRequest.company.url
                wireRequest.investment_terms = vm.wireRequest.investment_terms.url
                # Angular doesnt correctly copy the wiring_instructions_file if it is was
                # just set on the client, so we reset the wiring_instructions_file we want to save
                # from the directive instance
                wireRequest.wiring_instructions_file = vm.wireRequest.wiring_instructions_file
                wireRequest.$save()
                    .then (response) ->
                        vm.form.$setPristine()
                        newWireRequest = new WireRequest(response)
                        vm.createdCallback({'item': newWireRequest})
                        setNewWireRequest()
                        vm.expandAddForm(false)
                    .catch (response) ->
                        angular.extend(vm.errors, response.data)
                    .finally ->
                        vm.fcSave.saveAttemptFinished(vm.errors)

            vm.cancel = ->
                # Reset form data.
                $scope.investmentTermsDirective.cancel()
                angular.extend(vm.wireRequest, vm.initialWireRequestData)
                vm.form.$setPristine()
                vm.errors = {}
                vm.expandAddForm(false)

            vm.companySelected = ->
            vm.companyAdded = (company) ->
                vm.allCompanies.push(company)
                vm.wireRequest.company = company

            if vm.fromWithheldInvestable
                vm.expandAddForm()
                vm.wireRequest.withheld_investable_capital_id = vm.fromWithheldInvestable.id
                vm.wireRequest.wiring_amount = vm.fromWithheldInvestable.amount
                for company in vm.allCompanies
                    if vm.fromWithheldInvestable.company_id == company.id
                        vm.wireRequest.company = company
                        break

            return

        thisDirective =
            templateUrl: 'funds/wire-request-add.directive.html'
            restrict: 'A'
            require: '^form'
            scope: thisScope
            bindToController: true
            controllerAs: 'awrc'
            controller: thisController
            replace: true
        return thisDirective

    angular
        .module 'fundersclub.funds'
        .directive 'fcWireRequestAdd', fcWireRequestAdd
