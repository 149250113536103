do ->
    openLinkNewTab = ($timeout) ->
        thisLink = (scope, element, attr) ->
            $timeout(->
                element.find('a').not('[href*="fundersclub.com"]').attr('target', '_blank')
                0
            )

        thisDirective =
            restrict: 'A'
            link: thisLink
        return thisDirective

    angular
        .module 'fundersclub'
        .directive 'openLinkNewTab', openLinkNewTab
