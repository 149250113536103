let fcEditAutoInvestPreferences = (AutoInvestPreferences: any) => {
    let thisScope = {
        'autoInvestPreferences': '=fcEditAutoInvestPreferences',
        'availableInvestorProfiles': '=fcAvailableInvestorProfiles',
        'learnMoreUrl': '@learnMoreUrl',
    };
    let thisController = function() {
        let vm = this;
        vm.deleting = false;
        vm.save = () => {
            vm.errors = {};

            vm.autoInvestPreferences.dont_want_single_asset = !vm.autoInvestPreferences.single_asset_amount;
            vm.autoInvestPreferences.dont_want_multi_asset = !vm.autoInvestPreferences.multi_asset_amount;

            vm.autoInvestPreferences.$save().then((response: any) => {
                vm.autoInvestPreferences = new AutoInvestPreferences(response);
            }).catch((response: any) => {
                angular.extend(vm.errors, response.data);
            }).finally(() => {
                vm.fcSave.saveAttemptFinished(vm.errors);
            });
        };
        vm.delete = () => {
            vm.deleting = true;
            vm.autoInvestPreferences.$delete().then(() => {
                vm.autoInvestPreferences = new AutoInvestPreferences({});
            }).finally(() => {
                vm.deleting = false;
            });
        };
    };
    let thisDirective = {
        bindToController: true,
        controller: thisController,
        controllerAs: 'eaipc',
        restrict: 'A',
        scope: thisScope,
        templateUrl: 'autoinvest/edit-autoinvest-preferences.directive.html',
    };
    return thisDirective;
};

angular.module('fundersclub.autoinvest').directive('fcEditAutoInvestPreferences', fcEditAutoInvestPreferences);
