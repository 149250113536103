let fcFoundersApply = (ColdInboundLead: any, FCFoundersApplyData: any) => {

    let thisController = function() {
        let vm = this;

        vm.application = new ColdInboundLead({
          'ch-ch_launch': 'False',
          'cs-cs_launch': 'False',
          'eh-eh_launch': 'False',
          'es-es_launch': 'False',
        });
        vm.data = FCFoundersApplyData;
        vm.errors = {};
        vm.submitting = false;
        vm.submitted = false;

        vm.showErrorMessage = function(field: any) {
            return vm.errors[field];
        };

        vm.submit = function(event: any) {
            vm.submitting = true;

            vm.application
                .$save()
                .then((response: any) => {
                    vm.submitting = false;
                    vm.errors = {};
                    vm.submitted = true;
                }).catch((response: any) => {
                    vm.errors = {};
                    vm.submitting = false;
                    for (let field in response.data.errors) {
                        if (response.data.errors.hasOwnProperty(field)) {
                            vm.errors[field] = response.data.errors[field][0];
                        }
                    }
                });
        };
    };

    let thisDirective = {
        bindToController: true,
        controller: thisController,
        controllerAs: 'faf',
        replace: true,
        restrict: 'A',
        templateUrl: 'public-pages/founders-apply-form.directive.html',
    };
    return thisDirective;
};

angular
    .module('fundersclub.publicPages')
    .directive('fcFoundersApply', fcFoundersApply);
