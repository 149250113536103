do ->

    # Updates/removes the user's profile picture.
    fcUserAvatarManager = ($http, Upload, FCUserData) ->
        set_url = FCUserData.userprofile.urls.avatar_upload
        remove_url = FCUserData.userprofile.urls.avatar_delete

        fcUserAvatarManager =
            set: (file) ->
                Upload.upload
                    url: set_url
                    data:
                        avatar: file

            remove: ->
                $http
                    method: 'delete'
                    url: remove_url
        fcUserAvatarManager


    # Get module and add factories.
    angular
        .module 'fundersclub.users'
        .factory 'fcUserAvatarManager', fcUserAvatarManager

