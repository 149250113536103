let fcIpoForm = (Company: any, Partnership: any, FCToastManager: any) => {
    let thisScope = {
        'closeWithCancel': '&onCancel',
        'closeWithSave': '&onSave',
        'companyId': '@',
        'partnershipId': '@',
    };
    let thisController = function() {
        let vm = this;
        vm.dataLoaded = false;
        vm.errors = {};
        vm.partnership = new Partnership({id: vm.partnershipId});
        vm.selectedStockExchange = null;

        // Do API call to load required form data.
        vm.partnership.$get().then(() => {
            vm.partnership.ipo_details_for_company(vm.companyId)
                .then((response: any) => {
                    vm.bindCompanyData(response.data);
                })
                .catch((response: any) => {
                    // It's hard to know if there's something better to do here...
                    alert('This form failed to load. Please refresh the page and try again');
                    console.log(response);
                });
        });

        vm.saveForm = () => {
            let params = {
                company_id: vm.company.id,
                effective_date: vm.company.effective_date,
                price_per_share: vm.company.price_per_share,
                stock_exchange: vm.selectedStockExchange,
                stock_symbol: vm.company.stock_symbol,
            };
            vm.errors = {};
            vm.partnership.execute_ipo(params)
                .then(() => {
                    FCToastManager.createGenericToast('Successfully added IPO for ' + vm.company.company_name + '.');
                    vm.closeWithSave();
                })
                .catch((response: any) => {
                    angular.extend(vm.errors, response.data);
                })
                .finally(() => {
                    vm.fcSave.saveAttemptFinished(vm.errors);
                });
        };

        vm.bindCompanyData = (data: any) => {
            vm.company = data;
            vm.dataLoaded = true;
        };

        vm.cancelForm = () => vm.closeWithCancel();
    };
    let thisDirective = {
        bindToController: true,
        controller: thisController,
        controllerAs: 'fipof',
        restrict: 'A',
        scope: thisScope,
        templateUrl: 'investment-assets/ipo.directive.html',
    };
    return thisDirective;
};

angular
    .module('fundersclub.assets')
    .directive('fcIpoForm', fcIpoForm);
