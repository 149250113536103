do ->

    # Configure $resource/$http to play nice with Django/DRF.
    apiConfig = ($resourceProvider, $httpProvider) ->
        $resourceProvider.defaults.stripTrailingSlashes = false

        # make a different part of django happy (csrf)
        $httpProvider.defaults.xsrfCookieName = 'csrftoken'
        $httpProvider.defaults.xsrfHeaderName = 'X-CSRFToken'
        $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

    # Setup module.
    angular
        .module 'fundersclub.api'
        .config apiConfig
