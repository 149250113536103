class FcToiSignDocModal implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        modalInstance: '<',
        resolve: '<',
    };
    public controller = FcToiSignDocModalController;
    public templateUrl = 'users/toi-sign-doc-modal.component.html';
}

class FcToiSignDocModalController implements ng.IComponentController {
    public modalInstance: ng.ui.bootstrap.IModalServiceInstance;
    public resolve: {
        getToiItem: any,
    };

    private initialDocStatus: any;
    private isSafari: boolean = false;
    private signingUrl: any;

    constructor(
        private $intercom: any,
        private FCChoices: any,
        private $scope: ng.IScope,
        private $window: ng.IWindowService
    ) {}

    public $onInit() {
        this.$intercom.hideLauncher();

        this.isSafari = (
            this.$window.navigator.userAgent.indexOf('Safari') !== -1 &&
            this.$window.navigator.userAgent.indexOf('Chrome') === -1
        );

        // Auto-close when signed
        this.$scope.$watch(
            () => { return this.isLegaldocSigned(); },
            (newVal) => {
                if (newVal) {
                    this.close();
                }
            }
        );

        this.initialDocStatus = this.resolve.getToiItem().doc_status;
        this.signingUrl = this.resolve.getToiItem().doc_signing_url;
    }

    /* tslint:disable:no-unused-variable */
    private close() {
        this.modalInstance.close(this);
    }

    /* tslint:disable:no-unused-variable */
    private isLegaldocSigned() {
        return (this.resolve.getToiItem().doc_status !== this.initialDocStatus);
    }
}

angular
    .module('fundersclub.users')
    .component('fcToiSignDocModal', new FcToiSignDocModal());
