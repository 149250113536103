do ->

    fcPreviewEmailModalController = ($uibModalInstance, from, subject, emailBody) ->
        vm = @
        vm.from = from
        vm.subject = subject
        vm.emailBody = emailBody

        vm.close = ->
            $uibModalInstance.dismiss()
            return

        return


    fcPreviewEmail = ->
        thisScope =
            from: '=from'
            subject: '=subject'
            emailBody: '=emailBody'

        thisController = ($uibModal) ->
            vm = @
            vm.preview = ->
                modal = $uibModal
                    .open(
                        templateUrl: 'shared/preview-email.modal.html',
                        controller: 'fcPreviewEmailModalController'
                        controllerAs: 'pemc',
                        resolve:
                            from: -> vm.from
                            subject: -> vm.subject
                            emailBody: -> vm.emailBody
                    )
                    .rendered
                    .then(->
                        $('.body-edit-preview').contents().find('body').html(vm.emailBody)
                    )

            return

        thisDirective =
            restrict: 'A'
            scope: thisScope
            templateUrl: 'shared/preview-email.directive.html'
            controller: thisController
            controllerAs: 'pec'
            bindToController: true
        return thisDirective

    angular
        .module 'fundersclub'
        .directive 'fcPreviewEmail', fcPreviewEmail
        .controller 'fcPreviewEmailModalController', fcPreviewEmailModalController
