do ->

    # Displays all the investment assets within this outgoing investment.
    OutgoingInvestmentController = (InvestmentAsset, FCInvestmentAssetData, $attrs) ->
        vm = @
        vm.investment_assets = (new InvestmentAsset(asset) for asset in FCInvestmentAssetData.all_assets)
        vm.expanded_child_id = null

        if $attrs.initialExpandedAssetId
            vm.initial_expanded_child_id = $attrs.initialExpandedAssetId
            vm.expanded_child_id = vm.initial_expanded_child_id

        # Remove specified child from list.
        vm.removeChild = (child_id) ->
            vm.expanded_child_id = null
            child_index = _.findIndex(vm.investment_assets, (child) -> child.id == child_id)
            vm.investment_assets.splice(child_index, 1)

        # Delete
        vm.deleteOutgoingInvestment = ($event) ->
            remove = confirm('Are you sure you want to delete this investment?')
            if not remove
                $event.preventDefault()
                return false

        # End OutgoingInvestmentController
        return


    angular
        .module 'fundersclub.assets'
        .controller 'OutgoingInvestmentController', OutgoingInvestmentController
