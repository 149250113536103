## Based on https://raw.githubusercontent.com/facultymatt/angular-unsavedChanges/develop/src/unsavedChanges.js
## Converted to coffee using http://js2.coffee/
## We need our own copy because the original library does not allow message customization at runtime :(


angular.module('unsavedChanges', [ 'resettable' ]).provider('unsavedWarningsConfig', ->
    _this = this
    # defaults
    logEnabled = false
    useTranslateService = true
    routeEvent = [
        '$locationChangeStart'
        '$stateChangeStart'
    ]
    navigateMessage = 'You will lose unsaved changes if you leave this page'
    reloadMessage = 'You will lose unsaved changes if you reload this page'
    Object.defineProperty _this, 'navigateMessage',
        get: ->
            navigateMessage
        set: (value) ->
            navigateMessage = value
            return
    Object.defineProperty _this, 'reloadMessage',
        get: ->
            reloadMessage
        set: (value) ->
            reloadMessage = value
            return
    Object.defineProperty _this, 'useTranslateService',
        get: ->
            useTranslateService
        set: (value) ->
            useTranslateService = ! !value
            return
    Object.defineProperty _this, 'routeEvent',
        get: ->
            routeEvent
        set: (value) ->
            if typeof value == 'string'
                value = [ value ]
            routeEvent = value
            return
    Object.defineProperty _this, 'logEnabled',
        get: ->
            logEnabled
        set: (value) ->
            logEnabled = ! !value
            return
    @$get = [
        '$injector'
        ($injector) ->
            publicInterface = log: ->
                if console.log and logEnabled and arguments.length
                    newarr = [].slice.call(arguments)
                    if typeof console.log == 'object'
                        log.apply.call console.log, console, newarr
                    else
                        console.log.apply console, newarr
                return

            translateIfAble = (message) ->
                if $injector.has('$translate') and useTranslateService
                    $injector.get('$translate').instant message
                else
                    false

            Object.defineProperty publicInterface, 'useTranslateService', get: ->
                useTranslateService
            Object.defineProperty publicInterface, 'reloadMessage', get: ->
                translateIfAble(reloadMessage) or reloadMessage
            Object.defineProperty publicInterface, 'navigateMessage', get: ->
                translateIfAble(navigateMessage) or navigateMessage
            Object.defineProperty publicInterface, 'routeEvent', get: ->
                routeEvent
            Object.defineProperty publicInterface, 'logEnabled', get: ->
                logEnabled
            publicInterface
    ]
    return
).service('unsavedWarningSharedService', [
    '$rootScope'
    'unsavedWarningsConfig'
    '$injector'
    '$window'
    ($rootScope, unsavedWarningsConfig, $injector, $window) ->
        # Controller scopped variables
        _this = this
        allForms = []
        areAllFormsClean = true
        removeFunctions = []
        # @note only exposed for testing purposes.
        # Check all registered forms
        # if any one is dirty function will return true

        allFormsClean = ->
            areAllFormsClean = true
            angular.forEach allForms, (item, idx) ->
                unsavedWarningsConfig.log 'Form : ' + item.$name + ' dirty : ' + item.$dirty
                if item.$dirty
                    areAllFormsClean = false
                return
            areAllFormsClean
            # no dirty forms were found

        getDirtyFormsTitles = ->
            titles = []
            angular.forEach allForms, (item, idx) ->
                data = item.unsavedWarningFormData
                title = data.attrs.unsavedWarningForm || data.formElement.find('[unsaved-warning-form-title]').text().trim().replace(/( |\n)+/g, ' ')
                if item.$dirty and title
                        titles.push(title)
            titles

        tearDown = ->
            unsavedWarningsConfig.log 'No more forms, tearing down'
            angular.forEach removeFunctions, (fn) ->
                fn()
                return
            removeFunctions = []
            $window.onbeforeunload = null
            return

        # bind to window close
        # @todo investigate new method for listening as discovered in previous tests

        setup = ->
            unsavedWarningsConfig.log 'Setting up'
            $window.onbeforeunload = _this.confirmExit
            eventsToWatchFor = unsavedWarningsConfig.routeEvent
            angular.forEach eventsToWatchFor, (aEvent) ->
                #calling this function later will unbind this, acting as $off()
                removeFn = $rootScope.$on(aEvent, (event, next, current) ->
                    unsavedWarningsConfig.log 'user is moving with ' + aEvent
                    # @todo this could be written a lot cleaner!
                    if !allFormsClean()
                        unsavedWarningsConfig.log 'a form is dirty'

                        dirty_titles = getDirtyFormsTitles()
                        if dirty_titles.length
                                msg = 'The following section(s) contain unsaved changes: ' + dirty_titles.join(', ') + '\n'
                        else
                                msg = ''

                        if !confirm(msg + unsavedWarningsConfig.navigateMessage)
                            unsavedWarningsConfig.log 'user wants to cancel leaving'
                            event.preventDefault()
                            # user clicks cancel, wants to stay on page
                        else
                            unsavedWarningsConfig.log 'user doesn\'t care about loosing stuff'
                            $rootScope.$broadcast 'resetResettables'
                    else
                        unsavedWarningsConfig.log 'all forms are clean'
                    return
                )
                removeFunctions.push removeFn
                return
            return

        @allForms = ->
            allForms

        # adds form controller to registered forms array
        # this array will be checked when user navigates away from page

        @init = (form) ->
            if allForms.length == 0
                setup()
            unsavedWarningsConfig.log 'Registering form', form
            allForms.push form
            return

        @removeForm = (form) ->
            idx = allForms.indexOf(form)
            # this form is not present array
            # @todo needs test coverage
            if idx == -1
                return
            allForms.splice idx, 1
            unsavedWarningsConfig.log 'Removing form from watch list', form
            if allForms.length == 0
                tearDown()
            return

        # Function called when user tries to close the window

        @confirmExit = ->
            if !allFormsClean()
                dirty_titles = getDirtyFormsTitles()
                if dirty_titles.length
                        msg = 'The following section(s) contain unsaved changes: ' + dirty_titles.join(', ') + '\n'
                else
                        msg = ''
                return msg + unsavedWarningsConfig.reloadMessage
            $rootScope.$broadcast 'resetResettables'
            tearDown()
            return

        return
]).directive('unsavedWarningClear', [
    'unsavedWarningSharedService'
    (unsavedWarningSharedService) ->
        {
            scope: {}
            require: '^form'
            priority: 10
            link: (scope, element, attrs, formCtrl) ->
                element.bind 'click', (event) ->
                    formCtrl.$setPristine()
                    return
                return

        }
]).directive 'unsavedWarningForm', [
    'unsavedWarningSharedService'
    '$rootScope'
    (unsavedWarningSharedService, $rootScope) ->
        {
            scope: {}
            require: '^form'
            link: (scope, formElement, attrs, formCtrl) ->
                # @todo refactor, temp fix for issue #22
                # where user might use form on element inside a form
                # we shouldnt need isolate scope on this, but it causes the tests to fail
                # traverse up parent elements to find the form.
                # we need a form element since we bind to form events: submit, reset
                count = 0
                while formElement[0].tagName != 'FORM' and count < 3
                    count++
                    formElement = formElement.parent()
                if count >= 3
                    throw 'unsavedWarningForm must be inside a form element'
                # register this form
                formCtrl.unsavedWarningFormData =
                        attrs: attrs
                        formElement: formElement
                unsavedWarningSharedService.init formCtrl
                # bind to form submit, this makes the typical submit button work
                # in addition to the ability to bind to a seperate button which clears warning
                formElement.bind 'submit', (event) ->
                    if formCtrl.$valid
                        formCtrl.$setPristine()
                    return
                # bind to form submit
                # developers can hook into resetResettables to do
                # do things like reset validation, present messages, etc.
                formElement.bind 'reset', (event) ->
                    event.preventDefault()
                    # trigger resettables within this form or element
                    resettables = angular.element(formElement[0].querySelector('[resettable]'))
                    if resettables.length
                        scope.$apply resettables.triggerHandler('resetResettables')
                    # sets for back to valid and pristine states
                    formCtrl.$setPristine()
                    return
                # @todo check destroy on clear button too?
                scope.$on '$destroy', ->
                    unsavedWarningSharedService.removeForm formCtrl
                    return
                return

        }
]

###*
# --------------------------------------------
# resettable models adapted from vitalets lazy model
# @see https://github.com/vitalets/lazy-model/
#
# The main difference is that we DO set the model value
# as the user changes the inputs. However we provide a hook
# to reset the model to original value. This we can then
# broadcast on from reset which triggers resettable to revert
# to original value.
# --------------------------------------------
#
# @note we don't create a seperate scope so the model value
# is still available onChange within the controller scope.
# This fixes https://github.com/facultymatt/angular-unsavedChanges/issues/19
#
###

angular.module('resettable', []).directive 'resettable', [
    '$parse'
    '$compile'
    '$rootScope'
    ($parse, $compile, $rootScope) ->
        {
            restrict: 'A'
            link: (scope, elem, attr, ngModelCtrl) ->
                setter = undefined
                getter = undefined
                originalValue = undefined
                # save getters and setters and store the original value.
                attr.$observe 'ngModel', (newValue) ->
                    getter = $parse(attr.ngModel)
                    setter = getter.assign
                    originalValue = getter(scope)
                    return
                # reset our form to original value

                resetFn = ->
                    setter scope, originalValue
                    return

                elem.on 'resetResettables', resetFn
                # @note this doesn't work if called using
                # $rootScope.on() and $rootScope.$emit() pattern
                removeListenerFn = scope.$on('resetResettables', resetFn)
                scope.$on '$destroy', removeListenerFn
                return

        }
]

# ---
# generated by js2coffee 2.1.0
