do ->

    angular
        .module 'fundersclub', [
            # Core
            'fundersclub.api'
            'fundersclub.models'
            'fundersclub.templates'
            'fundersclub.forms'
            'fundersclub.payments'

            # Sections
            'fundersclub.autoinvest'
            'fundersclub.accreditation'
            'fundersclub.assets'
            'fundersclub.companies'
            'fundersclub.email'
            'fundersclub.founderOnboarding'
            'fundersclub.funds'
            'fundersclub.header'
            'fundersclub.icp'
            'fundersclub.internal'
            'fundersclub.introductionRequests'
            'fundersclub.invest'
            'fundersclub.learn'
            'fundersclub.legaldocs'
            'fundersclub.newsfeed'
            'fundersclub.partnerships'
            'fundersclub.publicPages'
            'fundersclub.signup'
            'fundersclub.staff'
            'fundersclub.strategy-simulator'
            'fundersclub.startup-summaries'
            'fundersclub.support'
            'fundersclub.users'
            'fundersclub.updates'

            # Layout
            'fundersclub.layout'

            # Third party
            'angularMoment'
            'initialValue'
            'monospaced.elastic'
            'ngCookies'
            'ngclipboard'
            'sticky'
            'tagged.directives.infiniteScroll'
            'textAngular'
            'ui.bootstrap'
            'ui.utils.masks'
            'ui.odometer'
            'ui.select'
            'unsavedChanges'
            'as.sortable'
            'ngRaven'
            'underscore'
        ]
