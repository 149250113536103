class FcNewFund implements ng.IComponentOptions {
    public controller = FcNewFundController;
    public templateUrl = 'funds/new-fund.component.html';
}

class FcNewFundController implements ng.IComponentController {
    private companyPocRelationshipOwner: any = null;
    private errors: any;
    private fcSave: any;
    private fund: any;
    private relationshipOwners: Array<any>;
    private selectedCompany: any = null;
    private selectedReferral: any;

    constructor (
        private $filter: any,
        private $window: any,
        private Company: any,
        private FCChoices: any,
        private FCPartnershipNewFundData: any,
        private Fund: any,
        private FundProfile: any,
        private User: any
    ) {}

    public $onInit(): void {
        this.errors = {};
        this.fund = new this.Fund({
            companies: Array<any>(),
            confidential_company: false,
            partnership: this.FCPartnershipNewFundData.partnership_slug,
        });

        this.relationshipOwners = this.FCPartnershipNewFundData.relationship_owners.map((user: any) => {
            return new this.User(user);
        });
        this.selectedReferral = {};
    }

    /* tslint:disable:no-unused-variable */
    private onCompanySelected(company: any) {
        this.selectedCompany = new this.Company(company);
        this.fund.fund_display_name = `${company.company_name} Fund`;
        this.onSingleAssetFundNameChanged();
        this.fund.companies = [company.id];

        /* grab the POC relationship owner and turn it into a User */
        for (let user of this.relationshipOwners) {
            if (user.id === company.poc_relationship_owner) {
                this.companyPocRelationshipOwner = user;
                break;
            }
        }
    }

    /* tslint:disable:no-unused-variable */
    private onCompanyAdded(company: any) {
        this.onCompanySelected(company);
        this.FCPartnershipNewFundData.all_companies.push(company);
    }

    /* tslint:disable:no-unused-variable */
    private onSingleAssetFundNameChanged() {
        // Remove trailing '-fund' if present.
        this.fund.slug = this.$filter('slugify')(this.fund.fund_display_name).replace(/\-fund$/, '');
    }

    /* tslint:disable:no-unused-variable */
    private setPrivateFundName() {
        this.fund.fund_display_name = this.FCPartnershipNewFundData.private_fund_name;
        this.onSingleAssetFundNameChanged();
        /* tslint:disable:max-line-length */
        this.fund.blurb = 'Private fund visible to and accessible to active FC members only. View the profile to review more information. All information is strictly confidential under our Terms of Service agreement.';
    }

    /* tslint:disable:no-unused-variable */
    private createFund() {
        let tmpFund = angular.copy(this.fund);
        this.errors = {
            company: [],
            fund: [],
        };

        /* update the selected company's POC relationship owner if appropriate */
        if (this.selectedCompany
            && this.companyPocRelationshipOwner
            && this.selectedCompany.poc_relationship_owner !== this.companyPocRelationshipOwner.id) {
            this.selectedCompany.poc_relationship_owner = this.companyPocRelationshipOwner.id;
            this.selectedCompany.$update()
                .catch((response: any) => {
                    this.errors.company = {};
                    angular.extend(this.errors.company, response.data);
                });
        }

        if (this.selectedReferral && this.selectedReferral.id) {
            tmpFund.referral = this.selectedReferral.id;
        }

        tmpFund.$create()
            .then((response: any) => {
                this.$window.location.href = response.new_fund_url;
            }).catch((response: any) => {
                angular.extend(this.errors.fund, response.data);
                this.fcSave.saveAttemptFinished(this.errors);
            });
    }
}

angular
    .module('fundersclub.funds')
    .component('fcNewFund', new FcNewFund());
