// See definition in angular/index.d.ts
class FcInputmask implements ng.IDirective {
    public static Factory() {
        let directive = () => {
            return new FcInputmask();
        };
        return directive;
    }

    public bindToController = true;
    public controller = FcInputmaskController;
    public controllerAs = '$ctrl';
    public restrict = 'A';
    public scope = {
        options: '<?fcInputmaskOptions',
        type: '@fcInputmask',
    };
}

class FcInputmaskController implements ng.IComponentController {
    public options: any;
    public type: string;

    constructor(private $element: any) {}

    public $onInit() {
        this.options = this.options || {};
        this.$element.inputmask(this.type, this.options);
    }
}

angular
    .module('fundersclub')
    .directive('fcInputmask', FcInputmask.Factory());
