let fcKpiReportsFilter = () =>  {
    let thisFilter = (kpis: Array<any>, searchFilters: {[name: string]: any}): Array<any> => {
        let filteredKpis: Array<any> = [];
        for (let kpi of kpis) {
            let match = true;
            /* tslint:disable:forin */
            for (let category in searchFilters) {
                /* Within each category, test if a kpi or-matches any of the filters */
                match = searchFilters[category].indexOf(kpi[category]) !== -1;
                if (!match) {
                    break;
                }
            }
            if (match) {
                filteredKpis.push(kpi);
            }
        }
        return filteredKpis;
    };

    return thisFilter;
};

let fcKpiReportsKeyword = () =>  {
    let thisFilter = (kpis: Array<any>, keywords: String[]): Array<any> => {
        let filteredKpis: Array<any> = [];
        for (let kpi of kpis) {
            let match = true;
            for (let keyword of keywords) {
                let keywordLower = keyword.toLowerCase();
                /* tslint:disable:no-string-literal */
                if (kpi['fund_display_name'].toLowerCase().indexOf(keywordLower) === -1 &&
                        kpi['company_name'].toLowerCase().indexOf(keywordLower) === -1 &&
                        kpi['label'].toLowerCase().indexOf(keywordLower) === -1
                ) {
                    match = false;
                    break;
                }
            }
            if (match) {
                filteredKpis.push(kpi);
            }
        }
        return filteredKpis;
    };

    return thisFilter;
};

let fcKpiReportsKpis = () => {
    let thisFilter = (kpis: Array<any>, searchFilters: {[name: string]: any}, keywords: String[]): string => {
        let filteredKpis = fcKpiReportsFilter()(kpis, searchFilters);
        filteredKpis = fcKpiReportsKeyword()(filteredKpis, keywords);
        return filteredKpis.length !== 1 ? `${filteredKpis.length} KPIs` : '1 KPI';
    };
    return thisFilter;
};

angular
    .module('fundersclub.staff')
    .filter('fcKpiReportsFilter', fcKpiReportsFilter)
    .filter('fcKpiReportsKeyword', fcKpiReportsKeyword)
    .filter('fcKpiReportsKpis', fcKpiReportsKpis);
