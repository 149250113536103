let fcStockSplitForm = (Company: any, Partnership: any) => {
    let thisScope = {
        'closeWithCancel': '&onCancel',
        'closeWithSave': '&onSave',
        'companyId': '@',
        'partnershipId': '@',
    };
    let thisController = function() {
        let vm = this;
        vm.errors = {};
        vm.dataLoaded = false;
        vm.partnership = new Partnership({id: vm.partnershipId});
        vm.selectedAssets = {};

        // Do API call to load required form data.
        vm.partnership.$get().then(() => {
            vm.partnership.stock_split_details_for_company(vm.companyId)
                .then((response: any) => {
                    vm.bindCompanyData(response.data);
                })
                .catch((response: any) => {
                    // It's hard to know if there's something better to do here...
                    alert('This form failed to load. Please refresh the page and try again');
                    console.log(response);
                });
        });

        vm.saveForm = () => {
            let selectedAssetIds: any = [];
            for (let assetId in vm.selectedAssets) {
                if (vm.selectedAssets[assetId]) {
                    selectedAssetIds.push(assetId);
                }
            }
            let params = {
                company_id: vm.company.id,
                effective_date: vm.company.effective_date,
                reverse_split: vm.company.reverse_split,
                split_value: vm.company.split_value,
                target_assets: selectedAssetIds,
            };
            vm.partnership.execute_stock_split(params)
                .then(() => {
                    vm.closeWithSave();
                })
                .catch((response: any) => {
                    angular.extend(vm.errors, response.data);
                })
                .finally(() => {
                    vm.fcSave.saveAttemptFinished(vm.errors);
                });
        };

        vm.bindCompanyData = (data: any) => {
            vm.company = data;
            vm.dataLoaded = true;
        };

        vm.cancelForm = () => vm.closeWithCancel();
    };
    let thisDirective = {
        bindToController: true,
        controller: thisController,
        controllerAs: 'fssf',
        restrict: 'A',
        scope: thisScope,
        templateUrl: 'investment-assets/stock-split.directive.html',
    };
    return thisDirective;
};

angular
    .module('fundersclub.assets')
    .directive('fcStockSplitForm', fcStockSplitForm);
