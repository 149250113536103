class FcFounderOnboardCompleteSignup implements ng.IComponentOptions {
    public bindings: {[binding: string]: string} = {
        company: '<',
        founder: '<',
        linkedinSignupUrl: '<',
        user: '<',
    };
    public controller = FcFounderOnboardCompleteSignupController;
    public templateUrl = 'founder-onboarding/complete-signup.component.html';
}

class FcFounderOnboardCompleteSignupController implements ng.IComponentController {
    public company: any;
    public founder: any;
    public linkedinSignupUrl: string;
    public user: any;

    private errors: any = {};
    private signingUp = false;
    /* tslint:disable:no-unused-variable */
    private showEmailSignupForm = false;

    constructor(
        private $window: ng.IWindowService,
        private FCFounderOnboardData: any,
        private FCGlobals: any
    ) {}

    public $onInit() {
        this.company = angular.copy(this.company);
        this.founder = angular.copy(this.founder);
        this.user = angular.copy(this.user);
    }

    /* tslint:disable:no-unused-variable */
    private signupWithEmail() {
        this.errors = {};
        this.signingUp = true;
        this.user
            .companyFounderCompleteEmailSignup(this.company.id)
            .then((response: any) => this.$window.location.reload())
            .catch((response: any) => {
                angular.extend(this.errors, response.data);
                this.signingUp = false;
            });
    }
}

angular
    .module('fundersclub.founderOnboarding')
    .component('fcFounderOnboardCompleteSignup', new FcFounderOnboardCompleteSignup());
